import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTaskPrioritySelectBoxFieldRender } from 'components/elements/crewTaskPrioritySelectBoxFieldRender/crewTaskPrioritySelectBoxFieldRender'
import { CrewTaskPrioritySelectBoxItemRender } from 'components/elements/crewTaskPrioritySelectBoxItemRender/crewTaskPrioritySelectBoxItemRender'
import { CrewBadgeSelectBoxField } from 'components/forms/crewBadgeSelectBoxField'
import { CrewDatePickerField } from 'components/forms/crewDatePickerField'
import { CrewHtmlEditorField } from 'components/forms/crewHtmlEditorField'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { DatePickerDateFormat } from 'enums/system'
import { FC, memo } from 'react'
import { useTaskDetailCommentInputForm } from './useTaskDetailCommentInputForm'
import { useProjectPermissions } from '@crew/hooks'
import { EntityType } from '@crew/enums/dist/domain'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useAppSelector } from 'states/hooks'
import {
  useGetTaskQuery,
  useGetTaskCommentQuery,
} from '@crew/apis/task/taskApis'
import dayjs from '@crew/modules/dayjs'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { UploadFile } from 'models/domain/uploadFile'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { GetTaskRequest } from '@crew/apis/dist/task/models/getTask/request'
import { GetTaskCommentRequest } from '@crew/apis/dist/task/models/getTaskComment/request'
import { FormValues } from './useTaskDetailCommentInputForm'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import {
  CrewAvatar,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { CrewTimePickerField } from 'components/forms/crewTimePickerField'
import ArrowRight from '~icons/material-symbols/arrow-right'
import {
  convertMinutesToHHMM,
  convertScheduledTimeToMinutes,
  generateItemsComboBoxActualProgressWorkingTime,
} from '@crew/utils'
import { JsonDateFormat } from '@crew/enums/system'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { CrewComboBoxField } from 'components/forms/crewComboBoxField'

type AssignToUserFieldProps = {
  id: string
  displayName: string
  version: number
} | null

// select box assign to user render field
// renderとして使うのでmemo不可
const AssignToUserField: FC<AssignToUserFieldProps> = (props) => {
  return (
    <div className="flex items-center gap-x-0.5 w-full">
      {props && (
        <CrewAvatar
          displayName={props.displayName}
          imageURL={generateImageAvatarUrl(EntityType.User, props.id)}
          className="flex-none ml-2.5"
          size={CrewAvatarSize.xs}
          cacheValue={props.id + props.version}
        />
      )}

      <CrewTextBox defaultValue={props?.displayName} readOnly={false} />
    </div>
  )
}

type AssignToUserItemProps = {
  id: string
  displayName: string
  version: number
} | null

// select box assign to user render item
// renderとして使うのでmemo不可
const AssignToUserItem: FC<AssignToUserItemProps> = (props) => (
  <div className="w-full flex items-center gap-x-2.5">
    {props && (
      <CrewAvatar
        displayName={props.displayName}
        imageURL={generateImageAvatarUrl(EntityType.User, props.id)}
        size={CrewAvatarSize.xs}
        cacheValue={props.id + props.version}
      />
    )}
    <p>{props?.displayName}</p>
  </div>
)

export type TaskDetailCommentInputFormProps = {
  onClose: () => void
  isEditMode: boolean
  description?: string
  taskCommentId?: string
}

export const TaskDetailCommentInputForm = memo(
  (props: TaskDetailCommentInputFormProps) => {
    const {
      control,
      reset,
      getValues,
      setValue,
      formState,
      handleSubmit,
      setError,
      clearErrors,

      entityType,
      entityRecordId,
      setEntityType,
      setEntityRecordId,

      validateRules,

      taskStateDataSource,
      taskPriorityDataSource,
      userDataSource,
      notificationRecipientDataSource,

      insertTaskComment,
      updateTaskComment,
      isLoadingInsertTaskComment,
      isLoadingUpdateTaskComment,
    } = useTaskDetailCommentInputForm()

    const { t } = useTranslation()
    const toast = useToast()

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    const taskId = useAppSelector((state) => state.taskDetail.taskId)
    const taskCommentEventMessage = useAppSelector(
      (state) => state.app.taskCommentEventMessage
    )
    const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
    const [remainingWorkTimes, setRemainingWorkTimes] = useState<number>(0)

    const [version, setVersion] = useState(0)

    const [needNotification, setNeedNotification] = useState(false)

    // 添付ファイル（アップロードファイル）情報格納用の配列
    const [uploadedFileList, setUploadedFileList] = useState<UploadFile[]>([])

    // メンションされたユーザーのIDを格納する配列
    // TODO: メンション機能はCREW-7497で削除予定。変数未使用のwarningが出るため暫定でコメントアウト
    //       https://break-tmc.atlassian.net/browse/CREW-7497
    // const [mentionUserIds, setMentionUserIds] = useState<string[] | undefined>(
    //   undefined
    // )
    const [, setMentionUserIds] = useState<string[] | undefined>(undefined)

    const canSend = useMemo(
      // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState]
    )

    const [showApiErrors] = useShowApiErrorsWithForm(setError)

    // タスク詳細を取得する
    // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
    const getTaskParam: GetTaskRequest | undefined = taskId
      ? {
          taskId: taskId,
        }
      : undefined
    const { data: getTaskResult } = useGetTaskQuery(getTaskParam ?? skipToken)

    // タスクコメント編集時のデータを取得する
    // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
    const getTaskCommentParam: GetTaskCommentRequest | undefined =
      props.taskCommentId
        ? {
            taskCommentId: props.taskCommentId,
          }
        : undefined
    const { data: getTaskCommentResult, refetch: refetchTaskComment } =
      useGetTaskCommentQuery(getTaskCommentParam ?? skipToken)

    // refetch task comment
    useEffect(() => {
      props.taskCommentId && refetchTaskComment()
    }, [props.taskCommentId, refetchTaskComment, taskCommentEventMessage])

    // フォーム初期化処理関数
    const initializeForm = useCallback(() => {
      const formInitialValues: FormValues = {
        actualWorkTimes: null,
        assignToUserId: null,
        description: null,
        startDate: null,
        dueDate: null,
        remainingWorkTimes: null,
        taskStateId: '',
        taskPriority: null,
        notificationUserIds: null,
        needNotification: false,
        actualProgress: null,
        endTime: null,
        startTime: null,
        workDate: null,
      }

      // タスク詳細が取得できたらフォームに反映する
      if (getTaskResult?.task) {
        const startDate = getTaskResult.task.startDate
          ? dayjs(getTaskResult.task.startDate).toDate()
          : null

        const endDate = getTaskResult.task.dueDate
          ? dayjs(getTaskResult.task.dueDate).toDate()
          : null

        setRemainingWorkTimes(getTaskResult.task.remainingWorkTimes ?? 0)
        setEntityType(getTaskResult.task.entityType)
        setEntityRecordId(getTaskResult.task.entityRecordId)

        formInitialValues.taskStateId = getTaskResult.task.taskState.id
        formInitialValues.taskPriority = getTaskResult.task.taskPriority
        formInitialValues.assignToUserId =
          getTaskResult.task.assignToUser?.id ?? null
        formInitialValues.startDate = startDate
        formInitialValues.dueDate = endDate
        formInitialValues.description = props.description ?? ''
        formInitialValues.actualProgress = getTaskResult.task.actualProgress

        //init data edit comment mode
        if (props.isEditMode && props.taskCommentId) {
          // タスクコメントデータが取得できていたらフォームに反映する
          if (getTaskCommentResult?.taskComment) {
            setVersion(getTaskCommentResult.taskComment.version)
            //get actual work time and remaining work times from task work
            if (getTaskCommentResult.taskComment.taskWork) {
              setIsShowWorkInputForm(true)

              const workDate = new Date(
                dayjs(getTaskCommentResult.taskComment.taskWork.workDate)
                  .tz(String(defaultUserProfileRegion))
                  .format(JsonDateFormat.YYYYMMDDHHmmss)
              )

              const startTime = getTaskCommentResult.taskComment.taskWork
                .startTime
                ? new Date(
                    dayjs(getTaskCommentResult.taskComment.taskWork.startTime)
                      .tz(String(defaultUserProfileRegion))
                      .format(JsonDateFormat.YYYYMMDDHHmmss)
                  )
                : null

              const endTime = getTaskCommentResult.taskComment.taskWork.endTime
                ? new Date(
                    dayjs(getTaskCommentResult.taskComment.taskWork.endTime)
                      .tz(String(defaultUserProfileRegion))
                      .format(JsonDateFormat.YYYYMMDDHHmmss)
                  )
                : null

              formInitialValues.actualWorkTimes = convertMinutesToHHMM(
                getTaskCommentResult.taskComment.taskWork.actualWorkTimes
              )
              formInitialValues.remainingWorkTimes = convertMinutesToHHMM(
                getTaskCommentResult.taskComment.taskWork.remainingWorkTimes
              )
              formInitialValues.workDate = workDate
              formInitialValues.startTime = startTime
              formInitialValues.endTime = endTime
            }
          }
        }
      }

      // フォームをリセット
      reset(formInitialValues)
    }, [
      defaultUserProfileRegion,
      getTaskCommentResult?.taskComment,
      getTaskResult?.task,
      props.description,
      props.isEditMode,
      props.taskCommentId,
      reset,
      setEntityRecordId,
      setEntityType,
    ])

    // 初期化処理をuseEffect化
    // TODO: 本当はuseEffectを外したいのだが、レンダリングが大量に走ってしまうためこのようにしている。以下タスクで調査・対応予定
    // https://break-tmc.atlassian.net/browse/CREW-6028
    useEffect(() => {
      // フォーム初期化を実行
      initializeForm()
    }, [initializeForm])

    //when out focus actual work times input fill value to remaining times
    const handleActualWorkTimesInputChange = useCallback(() => {
      const currentActualWorkTimes = convertScheduledTimeToMinutes(
        getValues('actualWorkTimes')
      )

      // 以下の場合は残時間=0とする
      // ・ 実績時間は入力できない
      // ・ 残存作業時間 = 0 (tasks.remaining_work_times = 0)
      // ・ 実績時間が残存作業時間より大きい
      if (
        !currentActualWorkTimes ||
        !remainingWorkTimes ||
        (remainingWorkTimes && currentActualWorkTimes > remainingWorkTimes)
      ) {
        setValue('remainingWorkTimes', convertMinutesToHHMM(0))
        clearErrors('remainingWorkTimes')
        return
      }

      // 残時間 = 残存作業時間(tasks.remaining_work_times) - 実績時間
      const timeRemaining = remainingWorkTimes - currentActualWorkTimes

      setValue('remainingWorkTimes', convertMinutesToHHMM(timeRemaining))
      clearErrors('remainingWorkTimes')
    }, [getValues, remainingWorkTimes, setValue, clearErrors])

    // handle submit form and call api register task comment
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (data: FormValues) => {
        try {
          //if no taskId then return
          if (!taskId) {
            return
          }

          if (props.isEditMode && props.taskCommentId) {
            // Execute update task comment process
            await updateTaskComment(props.taskCommentId, data, version)

            //close input form
            props.onClose()
            toast.success(t('message.task.taskCommentUpdated'))
          } else {
            // Execute insert task comment process
            await insertTaskComment(taskId, data, uploadedFileList)

            //close input form
            props.onClose()
            toast.success(t('message.task.taskCommentRegistered'))
          }
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [
      handleSubmit,
      insertTaskComment,
      props,
      showApiErrors,
      t,
      taskId,
      toast,
      updateTaskComment,
      uploadedFileList,
      version,
    ])

    const handleMentionTargetChange = useCallback(
      (targetUserIds: string[]) =>
        setMentionUserIds(
          targetUserIds.length === 0 ? undefined : targetUserIds
        ),
      []
    )

    // ファイルアップロード中かどうか
    const isFileUploading = useMemo(() => {
      return uploadedFileList.some((file) => file.progress)
    }, [uploadedFileList])

    // 添付ファイルアップロード完了後
    const handleUploaded = useCallback((file: UploadFile) => {
      // ファイル一覧にアップロードしたファイルを追加
      setUploadedFileList((baseData) => {
        //replace same file name
        const index = baseData.findIndex((item) => item.name === file.name)
        if (index === -1) {
          return [...baseData, file]
        } else {
          // replace the file with the same name
          baseData[index] = file
          return [...baseData]
        }
      })
    }, [])

    // 添付ファイル削除ボタン押下時
    const handleDeleteFile = useCallback((file: UploadFile) => {
      // uploadedFileListに格納している該当ファイル情報を削除する
      setUploadedFileList((baseData) =>
        baseData.filter((item) => item.name !== file.name)
      )
    }, [])

    // Get permission for create file
    const { hasPrjFileCreatePermission } = useProjectPermissions(
      EntityType.Task,
      taskId
    )

    // チェックボックスの値が変更されたときの処理
    const handleNeedNotificationChange = useCallback(() => {
      setNeedNotification(getValues('needNotification') ?? false)
    }, [getValues])

    //set 担当者 to current user
    const handleAssignTextLinkClick = useCallback(() => {
      if (loggedInUser) {
        setValue('assignToUserId', loggedInUser.id)
      }
    }, [loggedInUser, setValue])

    // 時間変更時の処理
    const handleTimeChange = useCallback(() => {
      const endTime = getValues('endTime')
      const startTime = getValues('startTime')

      if (!endTime || !startTime) return

      if (endTime >= startTime) {
        // actualWorkTime = endTime - startTime
        // div 1000*60 = 60000 to convert milliseconds to minutes
        const actualWorkTime =
          endTime.getTime() / 60000 - startTime.getTime() / 60000

        setValue('actualWorkTimes', convertMinutesToHHMM(actualWorkTime))
        clearErrors('actualWorkTimes')

        // 実績時間が変更されたので残時間を再計算
        handleActualWorkTimesInputChange()
      }
    }, [getValues, handleActualWorkTimesInputChange, setValue, clearErrors])

    const [isShowWorkInputForm, setIsShowWorkInputForm] = useState(false)

    // 作業入力フォームを表示
    const handleShowWorkInputForm = useCallback(() => {
      setIsShowWorkInputForm(true)
    }, [])

    // 「子タスクが存在しない」かつ「タスクコメントの新規登録」の場合は、「予定開始日」、「予定終了日」、「予定作業時間」の編集可能とする
    const canEditPlanStartAndEndDate = useMemo(
      () => !getTaskResult?.task?.hasChildTasks && !props.isEditMode,
      [getTaskResult?.task?.hasChildTasks, props.isEditMode]
    )

    return (
      <form className="flex flex-col gap-y-2.5">
        {/* FIXME: CrewHtmlEditor自体にスタイルクラスを組み込むようにするべきか検討する
                    https://break-tmc.atlassian.net/browse/CREW-4450 */}
        <div className="crew-slim-toolbar-item">
          <CrewHtmlEditorField
            id="description"
            name="description"
            rules={validateRules.description}
            minHeight="4rem"
            control={control}
            fileUploaderDisabled={
              // 編集時 または ファイル作成権限を持っていない場合はファイルアップロード無効
              props.isEditMode || !hasPrjFileCreatePermission
            }
            uploadedFileList={uploadedFileList}
            onUploaded={handleUploaded}
            onDeleteUploadedFile={handleDeleteFile}
            onMentionTargetChange={handleMentionTargetChange}
            entityType={entityType ?? undefined}
            entityRecordId={entityRecordId ?? undefined}
            showLabel={false}
            disabledMention={true}
          />
        </div>
        <div className="flex flex-col gap-2.5">
          <div className="flex gap-2.5 flex-wrap">
            {/* ステータス */}
            <CrewBadgeSelectBoxField
              id="taskStateId"
              name="taskStateId"
              control={control}
              dataSource={taskStateDataSource}
              label={t('label.state')}
              rules={validateRules.taskStateId}
              disabled={props.isEditMode}
              showClearButton={false}
              className="w-36"
              deferRendering={false}
            />

            {/* 優先度 */}
            <CrewSelectBoxField
              id="taskPriority"
              name="taskPriority"
              control={control}
              dataSource={taskPriorityDataSource}
              fieldRender={CrewTaskPrioritySelectBoxFieldRender}
              itemRender={CrewTaskPrioritySelectBoxItemRender}
              labelMode="hidden"
              valueExpr="id"
              displayExpr="name"
              searchEnabled={false}
              minSearchLength={0}
              label={t('label.priority')}
              rules={validateRules.taskPriority}
              disabled={props.isEditMode}
              showClearButton={false}
              className="w-28"
            />

            {/* 担当者 */}
            <div className="flex flex-col gap-y-1">
              <div className="flex items-center justify-between">
                <CrewFieldLabel text={t('label.assignedUser')} />

                {/* 私が担当 */}
                <CrewLink
                  className="text-sm underline"
                  onClick={handleAssignTextLinkClick}
                >
                  {t('label.assignToMe')}
                </CrewLink>
              </div>
              <CrewSelectBoxField
                id="assignToUserId"
                name="assignToUserId"
                control={control}
                valueExpr="id"
                displayExpr="displayName"
                searchEnabled={true}
                searchExpr="displayName"
                dataSource={userDataSource}
                disabled={props.isEditMode}
                minSearchLength={0}
                fieldRender={AssignToUserField}
                itemRender={AssignToUserItem}
              />
            </div>

            {/* 開始日 */}
            <CrewDatePickerField
              id="startDate"
              name="startDate"
              control={control}
              labelMode="hidden"
              displayFormat={DatePickerDateFormat.YYYYMMDD}
              showClearButton={true}
              label={t('label.startDate')}
              disabled={!canEditPlanStartAndEndDate}
              className="w-48"
            />

            {/* 期限 */}
            <CrewDatePickerField
              id="dueDate"
              name="dueDate"
              control={control}
              labelMode="hidden"
              displayFormat={DatePickerDateFormat.YYYYMMDD}
              showClearButton={true}
              label={t('label.deadline')}
              disabled={!canEditPlanStartAndEndDate}
              className="w-48"
            />
          </div>

          {!isShowWorkInputForm ? (
            <CrewLink
              className="text-sm underline"
              onClick={handleShowWorkInputForm}
            >
              {t('label.displayWorkInputForm')}
            </CrewLink>
          ) : (
            <div className="flex gap-2.5 flex-wrap items-start">
              {/* 作業日 */}
              <CrewDatePickerField
                id="workDate"
                name="workDate"
                control={control}
                labelMode="hidden"
                displayFormat={DatePickerDateFormat.YYYYMMDD}
                label={t('label.workDate')}
                rules={validateRules.workDate}
                className="w-36"
                disabled={props.isEditMode}
              />

              <div className="flex flex-row items-start gap-x-0.5">
                {/* 開始時刻 */}
                <div className="w-28">
                  <CrewTimePickerField
                    id="startTime"
                    name="startTime"
                    control={control}
                    label={t('label.startTime')}
                    rules={validateRules.startTime}
                    displayFormat="HH:mm"
                    className="max-w-full"
                    interval={30}
                    onValueChanged={handleTimeChange}
                    disabled={props.isEditMode}
                  />
                </div>

                <ArrowRight width={24} height={24} className="mt-7" />

                {/* 終了時刻 */}
                <div className="w-28">
                  <CrewTimePickerField
                    id="endTime"
                    name="endTime"
                    control={control}
                    label={t('label.endTime')}
                    rules={validateRules.endTime}
                    displayFormat="HH:mm"
                    className="max-w-full"
                    interval={30}
                    onValueChanged={handleTimeChange}
                    disabled={props.isEditMode}
                  />
                </div>
              </div>

              {/* 実績時間 */}
              <div className="w-24">
                <CrewTextBoxField
                  id="actualWorkTimes"
                  name="actualWorkTimes"
                  control={control}
                  labelMode="hidden"
                  label={t('label.actualTime')}
                  rules={validateRules.actualWorkTimes}
                  onChange={handleActualWorkTimesInputChange}
                  placeholder="hh:mm"
                  className="max-w-full"
                  disabled={props.isEditMode}
                />
              </div>

              {/* 残時間 */}
              <div className="w-24">
                <CrewTextBoxField
                  id="remainingWorkTimes"
                  name="remainingWorkTimes"
                  control={control}
                  labelMode="hidden"
                  label={t('label.remainingWorkTimes')}
                  rules={validateRules.remainingWorkTimes}
                  placeholder="hh:mm"
                  className="max-w-full"
                  disabled={props.isEditMode}
                />
              </div>

              <div className="w-24">
                <CrewComboBoxField
                  label={t('label.progressRate')}
                  key="actualProgress"
                  id="actualProgress"
                  name="actualProgress"
                  control={control}
                  items={generateItemsComboBoxActualProgressWorkingTime()}
                  unit="%"
                  dataType="number"
                  rules={validateRules.actualProgress}
                  disabled={props.isEditMode}
                />
              </div>
            </div>
          )}

          {!props.isEditMode && (
            <>
              {/* チャットに投稿する */}
              <CrewCheckBoxField
                id="needNotification"
                name="needNotification"
                control={control}
                label={t('label.needNotification')}
                rules={validateRules.needNotification}
                defaultValue={false}
                onValueChanged={handleNeedNotificationChange}
              />

              {needNotification && (
                <div>
                  {/* 通知先 */}
                  <CrewTagBoxField
                    id="notificationUserIds"
                    name="notificationUserIds"
                    control={control}
                    displayExpr="displayName"
                    valueExpr="id"
                    dataSource={notificationRecipientDataSource}
                    searchEnabled={true}
                    searchMode="contains"
                    searchExpr="displayName"
                    searchTimeout={SEARCH_TIMEOUT_MSEC}
                    minSearchLength={0}
                    labelMode="hidden"
                    label={t('label.notificationRecipient')}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex justify-end gap-x-2.5 items-center">
          <CrewButton
            type="primary"
            text={t('action.register')}
            onClick={handleSubmitButtonClick}
            disabled={
              !canSend ||
              isFileUploading ||
              isLoadingInsertTaskComment ||
              isLoadingUpdateTaskComment
            }
          />
          <CrewButton
            type="normal"
            stylingMode="outlined"
            text={t('action.cancel')}
            onClick={props.onClose}
          />
        </div>
      </form>
    )
  }
)
