import { memo } from 'react'
import { useContractChangeStoragePage } from './useContractChangeStoragePage'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { BillingCycle } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/i18n'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useCallback, useMemo } from 'react'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import {
  useGetBillingCycleQuery,
  useGetStorageCapacityQuery,
} from '@crew/apis/dist/contract/contractApis'
import { FormValues } from './useContractChangeStoragePage'
import { formatContractStorageSize } from '@crew/utils/dist/number'
import { useWatch } from 'react-hook-form'

/**
 *  Contract change storage page
 * @date 8/16/2023 -9:14:14 AM
 *
 * @type {*}
 */

// TODO: 英語表記にした際に崩れるので要対応
// https://break-tmc.atlassian.net/browse/CREW-14306
export const ContractChangeStoragePage = memo(() => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    updateStorageCapacity,
    isLoadingUpdateStorageCapacity,
  } = useContractChangeStoragePage()

  const { historyBack, navigate } = useCrewNavigate()

  const { t } = useTranslation()

  const [showApiErrors] = useShowApiErrors()

  const { success } = useToast()

  const { data: storageData, isFetching } = useGetStorageCapacityQuery()

  // handle upgrade contact plan
  const handleChangeStoragePlanButtonClick = useCallback(() => {
    // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
    const onSubmit = async (data: FormValues) => {
      const selectedCapacity = storageData?.storageCapacityList?.find(
        (item) => item.storageCapacity === getValues('storageUpgrade')
      )
      // undefinedになることはないが、型制約上undefined許容になるため型チェックを行う
      if (!selectedCapacity) return

      try {
        // Execute update storage capacity process
        await updateStorageCapacity(selectedCapacity.storageCapacity)

        success(t('message.contract.changeStoragePlanSuccess'))

        // go contract portal page
        navigate('/contract/portal')
      } catch (error) {
        showApiErrors(error)
      }
    }

    // ファイル更新実行
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    storageData?.storageCapacityList,
    getValues,
    updateStorageCapacity,
    success,
    t,
    navigate,
    showApiErrors,
  ])

  // go back prev screen
  const handleCancelButtonClick = useCallback(() => {
    historyBack()
  }, [historyBack])

  //get storage upgrade list
  const storageCapacityList = useMemo(() => {
    // APIフェッチ完了後に初期値を設定する必要があるため、フェッチ中は空配列を返す
    if (isFetching) return []

    return (
      storageData?.storageCapacityList
        // remove current storage in list upgrade
        .filter((item) => {
          return (
            item.storageCapacity !==
            storageData?.currentStorageCapacity.storageCapacity
          )
        })
        .map((item, index) => {
          // 初期値を設定する
          if (index === 0) {
            setValue('storageUpgrade', item.storageCapacity)
          }

          return {
            value: item.storageCapacity,
            displayNm:
              // format storage display
              formatContractStorageSize(item.storageCapacity),
          }
        })
    )
  }, [
    isFetching,
    storageData?.storageCapacityList,
    storageData?.currentStorageCapacity.storageCapacity,
    setValue,
  ])

  // Get the  list of billing cycle
  const { data: billingCycleData } = useGetBillingCycleQuery()

  // 契約中の請求サイクル
  const currentBillingCycle = useMemo(() => {
    const currentBillingCycle = billingCycleData?.billingCycle.find((item) => {
      return item.isCurrentCycle === true
    })?.billingCycle

    // 型制約上undefined許容になるため型チェックを行う
    if (!currentBillingCycle) return undefined

    return currentBillingCycle
  }, [billingCycleData?.billingCycle])

  // 契約中のストレージ容量の金額
  const currentStorageAmount = useMemo(() => {
    // 契約中の情報が取得できない場合は「-」
    if (!storageData || isFetching) {
      return '-'
    }

    // 金額が0の場合は「無料」
    if (storageData.currentStorageCapacity.amount === 0) {
      return t('label.free')
    }

    const price = storageData.currentStorageCapacity.amount.toLocaleString()

    // {金額}{請求単位}の形式で金額表示
    return currentBillingCycle === BillingCycle.Month
      ? t('label.priceMonthlyText', {
          price: price,
        })
      : t('label.priceAnnualText', {
          price: price,
        })
  }, [currentBillingCycle, isFetching, storageData, t])

  // 選択中のストレージ容量
  const storageUpgrade = useWatch({
    control,
    name: 'storageUpgrade',
  })

  // 選択中のストレージ容量の金額
  const selectedStorageCapacityAmount = useMemo(() => {
    // 変更後の情報が取得できない場合は「-」
    if (!storageData || isFetching) {
      return ''
    }

    const selectedStorageCapacity = storageData?.storageCapacityList?.find(
      (item) => item.storageCapacity === storageUpgrade
    )

    // 該当するストレージ情報が取得できない場合は「-」
    if (!selectedStorageCapacity) {
      return '-'
    }

    // 金額が0の場合は「無料」
    if (selectedStorageCapacity.amount === 0) {
      return t('label.free')
    }

    const price = selectedStorageCapacity.amount.toLocaleString()

    // {金額}{請求単位}の形式で金額表示
    return currentBillingCycle === BillingCycle.Month
      ? t('label.priceMonthlyText', {
          price: price,
        })
      : t('label.priceAnnualText', {
          price: price,
        })
  }, [currentBillingCycle, isFetching, storageData, storageUpgrade, t])

  return (
    <form>
      <div className="flex flex-col gap-y-2.5 mx-auto w-full max-w-2xl">
        <div className="py-2.5 w-full flex-col justify-start items-start gap-2.5 flex">
          <span className="text-md font-bold">{t('label.changeStorage')}</span>
          <div className="flex-col w-full justify-start items-start gap-2.5 inline-flex">
            {/* Current storage information */}
            <div className="w-full justify-start items-center gap-2.5 inline-flex">
              {/* 「現在」のラベル */}
              <div className="w-20 self-stretch justify-start items-center gap-2.5 flex">
                <div className="crew-text-gray-4 text-md ">
                  {t('label.contractNow')}
                </div>
              </div>

              {/* 組織全体でxxまで使用可能 */}
              <div className="grow shrink justify-start items-center gap-2.5 flex">
                <div className="crew-text-gray-4 text-md ">
                  {t('label.acrossTheOrganization')}
                </div>
                <div className="crew-text-gray-4 text-md ">
                  {/** format storage display */}
                  {!isFetching && storageData?.currentStorageCapacity
                    ? formatContractStorageSize(
                        storageData.currentStorageCapacity.storageCapacity
                      )
                    : '-'}
                </div>
                <div className="crew-text-gray-4 text-md ">
                  {t('label.canBeUse')}
                </div>
              </div>

              {/* 金額 */}
              <div className="self-stretch justify-end items-center gap-2.5 flex">
                <div className=" text-blue-500 text-md ">
                  {currentStorageAmount}
                </div>
              </div>
            </div>

            {/* After change storage information */}
            <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
              {/* 「変更後」のラベル */}
              <div className="w-20 self-stretch justify-start items-center gap-2.5 flex">
                <div className="crew-text-gray-4 text-md ">
                  {t('label.contractAfterChange')}
                </div>
              </div>

              {/* 組織全体でxxまで使用可能 */}
              <div className="grow shrink justify-start items-center gap-2.5 flex">
                <div className="crew-text-gray-4 text-md justify-center">
                  {t('label.acrossTheOrganization')}
                </div>
                {/* select storage plan */}
                <CrewSelectBoxField
                  control={control}
                  id="storageUpgrade"
                  name="storageUpgrade"
                  className="w-24"
                  items={storageCapacityList}
                  showClearButton={false}
                  minSearchLength={0}
                  searchEnabled={false}
                  displayExpr="displayNm"
                  valueExpr="value"
                  disabled={isFetching} // ローディング中は選択不可
                />

                <div className="crew-text-gray-4 text-md ">
                  {t('label.canBeUse')}
                </div>
              </div>

              {/* 金額 */}
              <div className="self-stretch justify-end items-center gap-2.5 flex">
                <div className=" text-blue-500 text-md ">
                  {selectedStorageCapacityAmount}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-x-2.5 items-center">
          {/* Change storage button */}
          <CrewButton
            text={t('action.change')}
            type="primary"
            onClick={handleChangeStoragePlanButtonClick}
            disabled={isFetching || isLoadingUpdateStorageCapacity}
          />

          {/* Cancel button */}
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={handleCancelButtonClick}
          />
        </div>
      </div>
    </form>
  )
})
