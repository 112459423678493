import { FC, memo } from 'react'
import { ChatMessage } from '@crew/models/domain'
import {
  AvatarPosition,
  CrewChatMessageItemAvatar,
} from 'components/elements/crewChatMessageItem/components/crewChatMessageItemAvatar/crewChatMessageItemAvatar'
import { CrewChatMessageItemHeader } from 'components/elements/crewChatMessageItem/components/crewChatMessageItemHeader/crewChatMessageItemHeader'
import { CrewChatMessageEditor } from 'components/elements/crewChatMessageEditor/crewChatMessageEditor'

export type CrewEditMessageItemProps = {
  message: ChatMessage // 投稿メッセージデータ
  onEditModeCancel: () => void // 編集モードをキャンセルする関数
  canUploadFile: boolean // 添付ファイルをアップロードできるかどうか
  isLargeAvatar: boolean // アバターの表示サイズを大きくするかどうか
  avatarPosition: AvatarPosition // アバターの表示位置
}

/**
 * メッセージ編集
 */
export const CrewEditMessageItem: FC<CrewEditMessageItemProps> = memo(
  (props) => {
    return (
      <>
        {/* メッセージ本体 */}
        <div className="flex flex-row gap-2 p-2 crew-hover-gray-1">
          {/* アバター */}
          <CrewChatMessageItemAvatar
            isLargeAvatar={props.isLargeAvatar} // 返信かどうかに応じてアバターのサイズを変更する
            user={props.message.createdBy}
            position={props.avatarPosition}
            omitUserAvatar={false} // 必ず表示する
          />
          <div className="flex flex-grow flex-col gap-1 min-w-0">
            {/* ユーザー名、投稿日、関連先リンク */}
            <CrewChatMessageItemHeader
              message={props.message}
              isShowChatRoomName={false} // 編集時、投稿にチャットルーム名は表示しない
            />
          </div>
        </div>
        <CrewChatMessageEditor
          isEditMode={true}
          message={props.message}
          chatRoomId={props.message.chatRoomId}
          // 編集時、対象投稿がトピック投稿の場合はundefined、スレッド返信の場合は親投稿のIDを設定する
          threadRootMessageId={props.message.parentChatMessageId ?? undefined}
          entityType={props.message.chatRoom.entityType}
          entityRecordId={props.message.chatRoom.entityRecordId}
          onEditModeCancel={props.onEditModeCancel}
          canUploadFile={props.canUploadFile}
        />
      </>
    )
  }
)
