import { FC, memo } from 'react'
import { useTaskKindEntryForm } from './useTaskKindEntryForm'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewBadge } from 'components/elements/crewBadge/crewBadge'
import { CrewErrorSummary } from 'components/forms/crewErrorSummary'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { GetTaskKindRequest } from '@crew/apis/dist/task/models/getTaskKind/request'
import { useGetTaskKindQuery } from '@crew/apis/task/taskApis'
import { useTranslation } from '@crew/modules/i18n'
import { ComponentCallbackHandler } from '@crew/utils'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { CrewColorSelectBoxField } from 'components/forms/crewColorSelectBoxField'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useBadgeInvertedColorClassDataSource } from 'hooks/dataSource/useResourceDataSource'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormValues } from './useTaskKindEntryForm'

export type TaskKindEntryFormProps = {
  isEditMode: boolean
  taskKindId?: string | undefined
  onSubmit: () => void
  onClose: () => void
}

export const TaskKindEntryForm: FC<TaskKindEntryFormProps> = memo((props) => {
  const {
    control,
    reset,
    formState,
    handleSubmit,
    clearErrors,
    setError,

    validateRules,

    insertTaskKind,
    updateTaskKind,
    isLoadingInsertTaskKind,
    isLoadingUpdateTaskKind,
  } = useTaskKindEntryForm()

  const { t } = useTranslation()
  const { success } = useToast()

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // バッジプレビュー表示用項目
  const [previewBadgeName, setPreviewBadgeName] = useState<string>('')
  const [previewBadgeDisplayColor, setPreviewBadgeDisplayColor] = useState<
    string | null
  >(null)

  // タスク種別取得
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getTaskKindParam: GetTaskKindRequest | undefined = props.taskKindId
    ? {
        taskKindId: props.taskKindId,
      }
    : undefined

  const { data: getTaskKindQueryResult } = useGetTaskKindQuery(
    getTaskKindParam ?? skipToken
  )

  // フォーム初期化処理関数
  const initializeForm = useCallback(() => {
    if (props.isEditMode && props.taskKindId) {
      if (getTaskKindQueryResult?.taskKind) {
        reset({
          id: getTaskKindQueryResult.taskKind?.id,
          name: getTaskKindQueryResult.taskKind?.name,
          displayColor: getTaskKindQueryResult.taskKind?.displayColor,
          initialValue: getTaskKindQueryResult.taskKind?.initialValue,
          version: getTaskKindQueryResult.taskKind?.version,
        })
      }
    }
  }, [
    getTaskKindQueryResult?.taskKind,
    props.isEditMode,
    props.taskKindId,
    reset,
  ])

  // 初期化処理をuseEffect化
  // TODO: 本当はuseEffectを外したいのだが、レンダリングが大量に走ってしまうためこのようにしている。以下タスクで調査・対応予定
  // https://break-tmc.atlassian.net/browse/CREW-6028
  useEffect(() => {
    // フォーム初期化を実行
    initializeForm()
  }, [initializeForm])

  // バッジの色スタイルクラスのリスト
  const colorClassListDataSource = useBadgeInvertedColorClassDataSource()

  // 名称テキスト変更時
  const handleNameChanged = useCallback<
    ComponentCallbackHandler<typeof CrewTextBoxField, 'onValueChanged'>
  >((event) => {
    setPreviewBadgeName(event.value)
  }, [])

  // バッジカラー変更時
  const handleDisplayColorChanged = useCallback<
    ComponentCallbackHandler<typeof CrewColorSelectBoxField, 'onValueChanged'>
  >((event) => {
    setPreviewBadgeDisplayColor(event.value)
  }, [])

  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        if (props.isEditMode) {
          await updateTaskKind(data)
          success(t('message.taskKind.taskKindUpdated'))
        } else {
          await insertTaskKind(data)
          success(t('message.taskKind.taskKindRegistered'))
        }
        reset()
        clearErrors()
        props.onSubmit && props.onSubmit()
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    clearErrors,
    handleSubmit,
    insertTaskKind,
    props,
    reset,
    showApiErrors,
    success,
    t,
    updateTaskKind,
  ])

  // タスク種別ダイアログのキャンセルボタン押下
  const handleCancelButtonClick = useCallback(() => {
    props.onClose()
  }, [props])

  const canSend = useMemo(
    // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState]
  )

  return (
    <form>
      <div>
        {/* task type name */}
        <div className="h-20">
          <CrewTextBoxField
            id="name"
            name="name"
            control={control}
            labelMode="hidden"
            label={t('label.name')}
            required={true}
            valueChangeEvent="keyup"
            onValueChanged={handleNameChanged}
            rules={validateRules.name}
          />
        </div>

        <div className="flex gap-4 h-20">
          {/* display color */}
          <div>
            <CrewColorSelectBoxField
              id="displayColor"
              name="displayColor"
              control={control}
              labelMode="hidden"
              label={t('label.color')}
              required={true}
              dataSource={colorClassListDataSource}
              displayExpr="name"
              valueExpr="id"
              onValueChanged={handleDisplayColorChanged}
              width="200px"
              rules={validateRules.displayColor}
            />
          </div>

          {/* badge preview */}
          <div className="inline-flex items-center">
            {previewBadgeName && previewBadgeDisplayColor ? (
              <CrewBadge displayColor={previewBadgeDisplayColor}>
                {previewBadgeName}
              </CrewBadge>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* initial value */}
        <div className="h-20">
          <CrewCheckBoxField
            id="initialValue"
            control={control}
            name="initialValue"
            label={t('label.initialValue')}
          />
        </div>
        <CrewErrorSummary formState={formState} />
      </div>
      <div className="flex justify-between items-center mt-3">
        <div className="ml-auto flex gap-x-5">
          <CrewButton
            text={t('action.register')}
            type="primary"
            disabled={
              !canSend || isLoadingInsertTaskKind || isLoadingUpdateTaskKind
            }
            onClick={handleSubmitButtonClick}
          />
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={handleCancelButtonClick}
          />
        </div>
      </div>
    </form>
  )
})
