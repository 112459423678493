import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useUpdateStorageCapacityMutation } from '@crew/apis/contract/contractApis'
import { StorageCapacity } from '@crew/enums/dist/app'

export type FormValues = {
  storageUpgrade: StorageCapacity
}
const formInitialValues: FormValues = {
  storageUpgrade: StorageCapacity.GB100,
}
export const useContractChangeStoragePage = () => {
  const { control, handleSubmit, getValues, setValue } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // Get update storage capacity function
  const [
    updateStorageCapacityMutation,
    { isLoading: isLoadingUpdateStorageCapacity },
  ] = useUpdateStorageCapacityMutation()

  // Update storage capacity process
  const updateStorageCapacity = useCallback(
    async (storageCapacity: StorageCapacity) => {
      await updateStorageCapacityMutation({
        storageCapacity: storageCapacity,
      }).unwrap()
    },
    [updateStorageCapacityMutation]
  )

  return {
    control,
    handleSubmit,
    getValues,
    setValue,
    updateStorageCapacity,
    isLoadingUpdateStorageCapacity,
  }
}
