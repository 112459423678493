import { RoleType } from '@crew/enums/domain'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthorityListPanel } from './useAuthorityListPanel'
import { camelCase } from 'lodash'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import BaselineDelete from '~icons/ic/baseline-delete'
import { Role as NewRole } from '../../useAuthoritySettingPanel'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { Permissions } from '@crew/enums/app'
import { useTranslation } from '@crew/modules/dist/i18n'
import { FormValues, Role } from './useAuthorityListPanel'
import { useToast } from 'hooks/useToast'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useModal } from 'components/layouts/modal/useModal'
import { RoleSorted } from './useAuthorityListPanel'
import { useAppSelector } from 'states/hooks'
import { useGetRolesQuery } from '@crew/apis/role/roleApis'
import { useFieldArray } from 'react-hook-form'
import { GetRolesRequest } from '@crew/apis/role/models/getRoles/request'

export type AuthorityListProps = {
  roleType: RoleType
  newRole: NewRole | null
  onClearNewRole?: () => void
  onDeleteTempRole?: (roleName: string) => void
}

// system role: system_tenant_admin > sys_admin > sys_user > sys_external_user
const SystemRoleOrder: { [key: string]: number } = {
  sys_tenant_admin: 1,
  sys_admin: 2,
  sys_user: 3,
  sys_external_user: 4,
}

// project role: project_admin > project_user > project_external_user
const ProjectRoleOrder: { [key: string]: number } = {
  prj_admin: 1,
  prj_member: 2,
  prj_guest: 3,
}

export const AuthorityListPanel: FC<AuthorityListProps> = memo((props) => {
  const {
    register,
    handleSubmit,
    control,
    formState,
    setDisabledRoleValue,

    deleteRole,
    updateRoles,
    isLoadingUpdateRoles,
  } = useAuthorityListPanel()
  const { t } = useTranslation()
  const { success } = useToast()
  const [showApiErrors] = useShowApiErrors()

  const roleEventMessage = useAppSelector((state) => state.app.roleEventMessage)
  const [tempRoles, setTempRoles] = useState<RoleSorted[]>([])

  const canSend = useMemo(
    // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState]
  )

  // Get list role permission of system/project
  const useGetRolesParam: GetRolesRequest = {
    roleType: props.roleType,
  }

  const { data: getRolesResult, refetch: getRolesRefetch } =
    useGetRolesQuery(useGetRolesParam)

  // refetch roles
  useEffect(() => {
    getRolesRefetch()
  }, [getRolesRefetch, roleEventMessage])

  const { fields, remove, append, replace } = useFieldArray({
    control,
    name: 'permissionRole',
    keyName: '_id', // default to "id", you can change the key name
  })

  // List the sorted permission roles results
  // Sort the list of roles so that there is no clutter when getting data from the api
  const rolesSorted: RoleSorted[] = useMemo(() => {
    if (!getRolesResult?.roles) {
      return []
    }

    // get system role
    if (props.roleType === RoleType.System) {
      const roles = getRolesResult.roles.map((role) => {
        return {
          ...role,
          rolePermissions: role.rolePermissions.map((item) => {
            return {
              permissionCode: item.permissionCode,
              selected: true,
            }
          }),
        }
      })
      // sort system role
      return roles.sort((a: RoleSorted, b: RoleSorted) => {
        if (
          a.roleCode &&
          a.roleCode in SystemRoleOrder &&
          b.roleCode &&
          b.roleCode in SystemRoleOrder
        ) {
          return SystemRoleOrder[a.roleCode] - SystemRoleOrder[b.roleCode]
        }

        return 0
      })
    } else {
      // get project role
      const roles = getRolesResult.roles.map((role) => {
        return {
          ...role,
          rolePermissions: role.rolePermissions.map((item) => {
            return {
              permissionCode: item.permissionCode,
              selected: true,
            }
          }),
        }
      })
      // sort project role
      return roles.sort((a: RoleSorted, b: RoleSorted) => {
        if (
          a.roleCode &&
          a.roleCode in ProjectRoleOrder &&
          b.roleCode &&
          b.roleCode in ProjectRoleOrder
        ) {
          return ProjectRoleOrder[a.roleCode] - ProjectRoleOrder[b.roleCode]
        } else if (a.isUserDefined && b.isUserDefined) {
          // role deined by user
          // sort by name
          if (a.name && b.name) {
            return a.name.localeCompare(b.name)
          }
          // sort between user defined role and system role
        } else if (!a.isUserDefined && b.isUserDefined) {
          return -1
        }

        return 0
      })
    }
  }, [getRolesResult?.roles, props.roleType])

  // when new role is added
  useEffect(() => {
    if (props.newRole) {
      const originalRoleId = props.newRole.originalRoleId
      const newRoleName = props.newRole.name

      // return if new role name is exist
      if (tempRoles.find((role) => role.name === newRoleName)) return

      // find original role
      const originalRole = fields.find(
        (role) => role.id === originalRoleId || role.name === originalRoleId
      )

      // get default permissions
      const defaultPermissions = Object.values(Permissions)
        .filter((permission) => permission.type === props.roleType)
        .map((item) => ({
          permissionCode: item.value,
          selected: false,
        }))

      // if new role copy from original role so get rolePermissions from original role
      // else get default permissions
      const permissions =
        props.newRole.originalRoleId && originalRole
          ? originalRole.rolePermissions
          : defaultPermissions

      // copy role from original role
      const newRole: RoleSorted = {
        id: null,
        name: props.newRole.name,
        roleType: props.roleType,
        isUserDefined: true,
        roleCode: null,
        version: 1,
        rolePermissions: permissions,
      }

      setTempRoles((currentRoles) => [...currentRoles, newRole])

      // add new role to form data
      append(newRole)
    }
  }, [append, fields, props.newRole, props.roleType, tempRoles])

  // フォーム初期化処理関数
  useEffect(() => {
    if (rolesSorted.length > 0) {
      const roles: Role[] = []
      // List roles checkbox in permission row
      rolesSorted.forEach((role: RoleSorted) => {
        const roleItem: Role = {
          id: role.id,
          name: role.name,
          version: role.version,
          roleCode: role.roleCode,
          rolePermissions: [],
        }

        Object.values(Permissions)
          .filter((permission) => permission.type === props.roleType)
          .forEach((permission) => {
            roleItem.rolePermissions.push({
              permissionCode: permission.value,
              selected: role.rolePermissions.some(
                (item) =>
                  item.permissionCode === permission.value && item.selected
              ),
            })
          })
        roles.push(roleItem)
      })
      // init form data
      replace(roles)
    }
  }, [props.roleType, replace, rolesSorted])

  // 登録ボタン押下
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        await updateRoles(data, props.roleType)

        // clear new role
        props.onClearNewRole && props.onClearNewRole()

        // clear temp roles
        setTempRoles([])

        success(t('message.tenant.roleUpdated'))
      } catch (error) {
        showApiErrors(error)
      }
    }
    handleSubmit(onSubmit)()
  }, [handleSubmit, updateRoles, props, success, t, showApiErrors])

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState<string>('')

  const [roleId, setRoleId] = useState<string | null>(null)

  const [roleName, setRoleName] = useState<string | null>(null)

  const [version, setVersion] = useState<number>(0)

  // 確認ダイアログの表示（処理は確認ダイアログのOKボタン押下時に行う）
  const handleDeleteRoleButtonClick = useCallback(
    (roleId: string | null, roleName: string | null, version: number) => {
      // Set message of delete confirm dialog
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
      // Update params to request delete
      setRoleId(roleId)
      setRoleName(roleName)
      setVersion(version)
    },
    [openConfirmDialog, t]
  )

  // 削除確認ダイアログ OKボタン
  const handleDeleteRolePermitButtonClick = useCallback(async () => {
    try {
      if (roleId) {
        await deleteRole(roleId, version)
      } else if (roleName) {
        // get index of role in form data
        const removeIndex = [...rolesSorted, ...tempRoles].findIndex(
          (role) => role.name === roleName
        )
        // remove role from form data
        remove(removeIndex)

        // find delete role in temp roles by name
        const deleteRole = tempRoles.find((role) => role.name === roleName)
        if (deleteRole) {
          // remove role from temp roles
          setTempRoles((currentRoles) =>
            currentRoles.filter((role) => role.name !== roleName)
          )
          // clear new role if delete role is new role
          if (props.newRole?.name === roleName) {
            props.onClearNewRole && props.onClearNewRole()
          }
          // delete temp role
          props.onDeleteTempRole && props.onDeleteTempRole(roleName)
        }
      }
      // Close confirm dialog and notify delete role success
      closeConfirmDialog()
      success(t('message.tenant.roleDeleted'))
    } catch (error) {
      showApiErrors(error)
    }
  }, [
    closeConfirmDialog,
    deleteRole,
    props,
    remove,
    roleId,
    roleName,
    rolesSorted,
    showApiErrors,
    success,
    t,
    tempRoles,
    version,
  ])

  const roles = [...rolesSorted, ...tempRoles]
  return (
    <>
      <div className="flex flex-col gap-2.5">
        <table className="crew-table custom-table">
          <thead className="text-sm">
            <tr>
              <th className="text-left">
                <span>{t('label.authority')}</span>
              </th>
              {roles?.map((role) => (
                <th key={role.id}>
                  <div className="flex justify-center items-center">
                    <span>
                      {role.roleCode && !role.isUserDefined
                        ? t(`label.roles.${camelCase(role.roleCode)}`)
                        : role.name}
                    </span>
                    {/* Show delete icon when role is defined by user */}
                    {props.roleType === RoleType.Project &&
                      role.isUserDefined && (
                        <div
                          className="cursor-pointer hover:opacity-70"
                          onClick={() =>
                            handleDeleteRoleButtonClick(
                              role.id,
                              role.name,
                              role.version
                            )
                          }
                        >
                          <BaselineDelete width={24} height={24} />
                        </div>
                      )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.values(Permissions)
              .filter((permission) => permission.type === props.roleType)
              .map((item, index) => (
                <tr key={item.value}>
                  <td className="text-left">{t(item.text)}</td>
                  {fields.map((role, roleIndex: number) => (
                    <td key={role.id}>
                      <input
                        hidden
                        {...register(`permissionRole.${roleIndex}.id`)}
                      />
                      <input
                        hidden
                        {...register(`permissionRole.${roleIndex}.name`)}
                      />
                      <input
                        hidden
                        {...register(`permissionRole.${roleIndex}.version`, {
                          required: true,
                        })}
                      />
                      <CrewCheckBoxField
                        control={control}
                        name={`permissionRole.${roleIndex}.rolePermissions.${index}.selected`}
                        disabled={setDisabledRoleValue(
                          props.roleType,
                          role.roleCode,
                          item.value
                        )}
                        className="mx-auto"
                      />
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>

        {/* 保存ボタン */}
        <div className="flex flex-1">
          <CrewButton
            type="primary"
            text={t('action.toSave')}
            onClick={handleSubmitButtonClick}
            disabled={!canSend || isLoadingUpdateRoles}
          />
        </div>
      </div>

      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeleteRolePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </>
  )
})
