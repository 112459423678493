import classNames from 'classnames'
import { FC, memo, useMemo } from 'react'

import { LIMIT_FOR_UNREAD_DISPLAY_COUNT } from 'configs/constants'
import { selectUnreadCountTotal, useUserSetting } from '@crew/states'
import { useAppSelector } from 'states/hooks'
import { SettingKeyType } from '@crew/enums/dist/app'

// 最低幅として、1文字分確保できるようにする
const badgeBaseClassNames =
  'crew-badge text-sm truncate text-center min-w-[1.5rem] h-5 leading-3 !font-normal'

// 未読がある場合に適用するクラス
const hasUnreadClassNames = 'has-unread'

// 空要素の場合に適用するクラス
const emptyClassNames = 'w-0 h-0'

export const CrewFeedUnreadBadge: FC = memo(() => {
  // 「メンションと返信」に通知する内容の個人設定値
  const newsMentionAndReplies = useUserSetting(
    SettingKeyType.NewsMentionAndReplies
  )

  const { total, attention, mentionToMe, mentionToGroup } = useAppSelector(
    selectUnreadCountTotal(newsMentionAndReplies)
  )

  // if unread number is greater than 99, display 99+
  const counterText = useMemo(
    () =>
      attention > LIMIT_FOR_UNREAD_DISPLAY_COUNT
        ? `${LIMIT_FOR_UNREAD_DISPLAY_COUNT}+`
        : `${attention}`,
    [attention]
  )

  const targetBackgroundClassNames = useMemo(
    () =>
      mentionToMe > 0
        ? 'crew-badge-red-inverted' // 赤：ユーザー個人宛のメンションがある場合
        : mentionToGroup > 0
        ? 'crew-badge-orange-inverted' // オレンジ：ユーザが属するグループ宛のメンションがある場合。@allや@hereなど
        : 'crew-badge-blue-inverted', // 青：上記以外
    [mentionToMe, mentionToGroup]
  )

  if (attention <= 0) {
    if (total <= 0) {
      // 未読が完全に0なら何も表示しない
      return null
    } else {
      // 未読があるがreplyAndMentionが0なら、hasUnreadClassNamesクラスを持った空のspan返す。バッジなしで太字になる
      return (
        <span className={classNames(hasUnreadClassNames, emptyClassNames)} />
      )
    }
  }

  return (
    <span
      className={classNames(
        badgeBaseClassNames,
        hasUnreadClassNames,
        targetBackgroundClassNames
      )}
    >
      {counterText}
    </span>
  )
})
