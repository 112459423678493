import classNames from 'classnames'
import { FC, memo, useCallback } from 'react'

import { RightSideBarDisplayType } from 'enums/app'
import { ChatCompactPanel } from 'features/chat/components/chatCompactPanel/chatCompactPanel'

import { ToggleButtonUpdateWidth } from './components/toggleButtonUpdateWidth/toggleButtonUpdateWidth'

import { ChatThreadPanel } from 'features/chat/components/chatThreadPanel/chatThreadPanel'
import { useAppSelector } from 'states/hooks'
import { Chat } from '@crew/states'

import { useMemo } from 'react'
import { useMatch } from 'react-router-dom'

export const AppRightSideBar: FC = memo(() => {
  // 右サイドバーの表示状態
  const rightSideBarOpened = useAppSelector(
    (state) => state.app.rightSideBarOpened
  )

  // 右サイドバーの幅を大きくするか
  const isRightSideBarLargeWidth = useAppSelector(
    (state) => state.app.isRightSideBarLargeWidth
  )

  // 現在のパス。右サイドバーの表示種別を判定するために使用する
  const isFeed = useMatch('/newArrival')
  const isAttention = useMatch('/mentionAndReply/*')
  const isBookmark = useMatch('/bookmark')
  const isProjectDetail = useMatch('/projects/:projectId/*')
  const isEventDetail = useMatch('/projects/:projectId/events/:eventId/*')
  const isTaskDetail = useMatch('/tasks/:taskId/*')
  const isFileDetail = useMatch('/files/:fileId/*')
  const isDirectChannel = useMatch('/direct-channels/:projectId/*')
  const isCrossSearchChat = useMatch('/search/chat')

  // TODO: 2ペイン表示とコンパクト表示の制御を、以下の表示状態制御の方法を検討後に変更する予定
  // https://break-tmc.atlassian.net/browse/CREW-11399
  // プロジェクト詳細のチャットタブかどうか（右パネルの表示制御に使用する）
  // 元々はcurrentChatStyleを使用していたが、Fullを設定するロジックが
  // チャットタブ内の処理で実行されており、処理順の関係でcurrentChatStyleが使用できないため
  const isProjectDetailChatTab = useMatch('/projects/:projectId/chat')
  const isDirectChannelChatTab = useMatch('/direct-channels/:projectId/chat')

  /**
   * TODO: 暫定でuseMatch()で判定しているが、表示状態をどうやって取得するかは下記タスクで検討する
   *       https://break-tmc.atlassian.net/browse/CREW-11399
   */
  /** 表示種別 */
  const displayType = useMemo(() => {
    if (isFeed) {
      return RightSideBarDisplayType.Feed
    } else if (isAttention) {
      return RightSideBarDisplayType.Attention
    } else if (isBookmark) {
      return RightSideBarDisplayType.Bookmark
    } else if (isProjectDetail) {
      return RightSideBarDisplayType.ProjectDetail
    } else if (isEventDetail) {
      return RightSideBarDisplayType.EventDetail
    } else if (isTaskDetail) {
      return RightSideBarDisplayType.TaskDetail
    } else if (isFileDetail) {
      return RightSideBarDisplayType.FileDetail
    } else if (isDirectChannel) {
      return RightSideBarDisplayType.DirectChannel
    } else if (isCrossSearchChat) {
      return RightSideBarDisplayType.CrossSearchChat
    } else {
      return undefined
    }
  }, [
    isAttention,
    isBookmark,
    isCrossSearchChat,
    isDirectChannel,
    isFeed,
    isFileDetail,
    isEventDetail,
    isProjectDetail,
    isTaskDetail,
  ])

  // チャットルームの表示スタイル(2ペイン表示かコンパクト表示か)を取得する
  const currentChatStyle = useAppSelector(
    (state) => state.message.chat.current.style
  )

  const rightSideBarSelectedMessageId = useAppSelector(
    (state) => state.app.rightSideBarSelectedMessageId
  )

  // 画面サイズが小さい場合：サイドバーの幅を相対単位"vw"で制御する
  // 画面サイズが大きい場合(xl以上)：サイドバーの幅を540pxで固定する
  // 画面サイズが小さいときにvwを使用しているのは、サイドバーの領域が広くなりすぎてしまうのを防ぐため
  const rightSideBarWidth = isRightSideBarLargeWidth
    ? 'w-[50vw]'
    : 'w-[40vw] xl:w-[540px]'

  // render chat panel by display type
  const renderChatPanel = useCallback(() => {
    switch (displayType) {
      case RightSideBarDisplayType.Feed:
      case RightSideBarDisplayType.Attention:
      case RightSideBarDisplayType.Bookmark:
        return (
          <ChatCompactPanel selectedItemId={rightSideBarSelectedMessageId} />
        )
      case RightSideBarDisplayType.CrossSearchChat:
        // スレッド表示
        return (
          <ChatThreadPanel
            displayType={displayType}
            selectedItemId={rightSideBarSelectedMessageId}
          />
        )
      case RightSideBarDisplayType.DirectChannel:
        // チャットタブの場合はスレッド表示、それ以外はコンパクト表示
        if (isDirectChannelChatTab) {
          return <ChatThreadPanel displayType={displayType} />
        }
        return <ChatCompactPanel />
      case RightSideBarDisplayType.ProjectDetail:
        // チャットタブの場合はスレッド表示、それ以外はコンパクト表示
        if (isProjectDetailChatTab) {
          return <ChatThreadPanel displayType={displayType} />
        }
        return <ChatCompactPanel />
      case RightSideBarDisplayType.EventDetail:
      case RightSideBarDisplayType.TaskDetail:
      case RightSideBarDisplayType.FileDetail:
        // 2ペイン表示がある画面は、チャットスタイルに応じて「スレッド表示」or「コンパクト表示」を切り替える
        switch (currentChatStyle) {
          // 2ペイン表示の場合
          case Chat.Style.Full:
            return <ChatThreadPanel displayType={displayType} />

          // コンパクト表示の場合
          case Chat.Style.Compact:
            return <ChatCompactPanel />
        }
        break
      // 上記以外の場合、チャットが存在していない旨のメッセージを表示する
      default:
        return null
    }
  }, [
    currentChatStyle,
    displayType,
    isDirectChannelChatTab,
    isProjectDetailChatTab,
    rightSideBarSelectedMessageId,
  ])

  return (
    // 外側のコンテナ。サイズ変更ボタンを境界線上に(=コンテナの外側にはみ出させて)表示させるために必要。
    // 内側のコンテナはサイドバー出し入れでoverflow-hiddenするため、ボタンはその外側に置かないとはみ出して表示できない
    <div
      id="app-right-side-bar"
      data-testid="app-right-side-bar"
      className={classNames(
        'relative group/item', //サイズ変更ボタンの設置・出し入れのための設定
        'flex-shrink-0', //サイドバーのサイズを固定して縮小させない。縮小はセンターのコンテンツに対して行う
        'border-l crew-border-gray', //枠線
        'h-full', // 縦幅は親要素に合わせる。入力欄は下に張り付き、メッセージが多い場合はスクロールバーが表示される
        'w-fit' //横幅は内側のコンテナに合わせる
      )}
    >
      {/* サイズ変更ボタン */}
      {rightSideBarOpened && (
        <div
          className={classNames(
            'absolute top-1/2 left-0 -translate-y-1/2 -translate-x-1/2', //位置を調整
            'invisible group-hover/item:visible', //外側のコンテナをhoverしたときのみ表示する
            'z-10' // 後続の要素より手前に表示する
          )}
        >
          <ToggleButtonUpdateWidth />
        </div>
      )}

      {/* 内側のコンテナ。サイドバー出し入れのため横幅を可変させる */}
      <div
        className={classNames(
          'overflow-hidden', //サイドバーが閉じているとき、中身をはみ出させない(≒隠す)。これがないと中身が右側にはみ出て、その分左にズレる
          'ease-in-out duration-300', // アニメーションの設定
          'h-full', // 縦幅は親要素に合わせる。入力欄は下に張り付き、メッセージが多い場合はスクロールバーが表示される
          rightSideBarOpened // 横幅はサイドバーの表示状態で切り替える
            ? rightSideBarWidth // サイドバーが開いているときは横幅=サイドバーの横幅。w-fitだとアニメーションが効かないため値を指定
            : 'w-0' // サイドバーが閉じているときは横幅0(≒隠す)
        )}
      >
        <div
          className={classNames(
            'h-full', // 縦幅は親要素に合わせる。入力欄は下に張り付き、メッセージが多い場合はスクロールバーが表示される
            rightSideBarWidth, //横幅はボタン切り替え可能な値を指定
            'flex flex-col flex-none' //チャット要素の表示設定
          )}
        >
          {/* chat panel */}
          {renderChatPanel()}
        </div>
      </div>
    </div>
  )
})
