import { useLazyGetLookupProjectMembersQuery } from '@crew/apis/lookup/lookupApis'
import { useDataSource } from 'hooks/dataSource/useDataSource'
import {
  useDeleteCustomScheduleMemberMutation,
  useInsertCustomScheduleMembersMutation,
  useLazyGetCustomScheduleMembersQuery,
} from '@crew/apis/dist/customScheduleMember/customScheduleMemberApis'
import { useCallback } from 'react'
import { useAppSelector } from 'states/hooks'

export const useScheduleGroupMonthly = (projectId: string | undefined) => {
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const [lazyGetLookupProjectMembersQuery] =
    useLazyGetLookupProjectMembersQuery()
  const [lazyGetCustomScheduleMembersQuery] =
    useLazyGetCustomScheduleMembersQuery()

  const [
    insertCustomScheduleMembersMutation,
    { isLoading: isLoadingInsertCustomScheduleMembers },
  ] = useInsertCustomScheduleMembersMutation()
  const [deleteCustomScheduleMemberMutation] =
    useDeleteCustomScheduleMemberMutation()

  //Get list project members
  const usersDataSource = useDataSource(
    () => ({
      loadMode: 'raw',
      key: 'id',
      load: async (loadOptions) => {
        if (!loggedInUser) return []

        // If projectId is not specified, get custom schedule members
        if (!projectId) {
          const response = await lazyGetCustomScheduleMembersQuery().unwrap()

          const customScheduleMembers = response.customScheduleMembers.flatMap(
            (member) => member.member
          )

          return [
            {
              id: loggedInUser.id,
              displayName: loggedInUser.displayName,
              version: loggedInUser.version,
            },
            ...customScheduleMembers,
          ]
        } else {
          // Get project members by projectId
          const response = await lazyGetLookupProjectMembersQuery({
            projectId,
            userId: undefined,
            keyword: undefined,
            excludeUserId: undefined,
            // Show only enabled user
            isEnabledUser: true,
          }).unwrap()

          return response.projectMembers.map((member) => {
            return {
              id: member.id,
              displayName: member.displayName,
              version: member.version,
            }
          })
        }
      },
    }),
    [
      lazyGetCustomScheduleMembersQuery,
      lazyGetLookupProjectMembersQuery,
      loggedInUser,
      projectId,
    ]
  )

  // Insert custom schedule members
  const insertCustomScheduleMembers = useCallback(
    async (memberIds: string[]) => {
      await insertCustomScheduleMembersMutation({
        memberIds,
      }).unwrap()
    },
    [insertCustomScheduleMembersMutation]
  )

  // delete custom schedule member
  const deleteCustomScheduleMember = useCallback(
    async (memberId: string) => {
      await deleteCustomScheduleMemberMutation({
        memberId,
      }).unwrap()
    },
    [deleteCustomScheduleMemberMutation]
  )

  return {
    usersDataSource,
    insertCustomScheduleMembers,
    deleteCustomScheduleMember,
    isLoadingInsertCustomScheduleMembers,
  }
}
