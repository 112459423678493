import { memo } from 'react'
import { TenantUserTable } from './components/tenantUserTable/tenantUserTable'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useEffect, useMemo, useState } from 'react'

import { useGetTenantSettingMembersQuery } from '@crew/apis/tenantSetting/tenantSettingApis'

import { useAppSelector } from 'states/hooks'
import { SelectedUserType, useTenantUserListContext } from '../../useUserList'
import { useSearchParams } from 'react-router-dom'
import { RowSelectionState } from '@tanstack/react-table'
import { GetTenantSettingMembersRequest } from '@crew/apis/tenantSetting/models/getTenantSettingMembers/request'
import { TenantMemberFilter } from '@crew/enums/domain'

export const UserListGrid = memo(() => {
  const { setSelectedUsers } = useTenantUserListContext()

  const [searchParams] = useSearchParams()

  const stateFilter =
    searchParams.get('filter') || TenantMemberFilter.AllTenantMembers

  const keyword = useAppSelector(
    (state) => state.tenantSettingPage.user.keyword
  )

  const tenantSettingUserEventMessage = useAppSelector(
    (state) => state.app.tenantSettingUserEventMessage
  )

  const getTenantSettingMembersParams: GetTenantSettingMembersRequest = {
    filter: stateFilter as TenantMemberFilter,
    keyword: keyword,
  }

  // Call API get list user
  const {
    data: getTenantSettingMembersResult,
    refetch: getTenantSettingMembersRefetch,
  } = useGetTenantSettingMembersQuery(
    getTenantSettingMembersParams ?? skipToken
  )

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  // get selected files from row selection
  const selectedMembers = useMemo(() => {
    if (!getTenantSettingMembersResult?.tenantSettingMembers) return []

    return getTenantSettingMembersResult.tenantSettingMembers.filter(
      (member) => {
        return rowSelection[member.tenantMemberPendingId ?? member.userId]
      }
    )
  }, [getTenantSettingMembersResult?.tenantSettingMembers, rowSelection])

  // refresh user list
  useEffect(() => {
    getTenantSettingMembersRefetch()

    return () => {
      // reset selected user list when leave page
      setSelectedUsers([])
      setRowSelection({})
    }
  }, [
    tenantSettingUserEventMessage,
    getTenantSettingMembersRefetch,
    setSelectedUsers,
  ])

  // update select users when change
  useEffect(() => {
    setSelectedUsers(
      selectedMembers.map((item) => {
        const user: SelectedUserType = {
          userId: item.tenantMemberPendingId ?? item.userId,
          userType: item.userType,
          version: item.version,
        }
        return user
      })
    )
  }, [selectedMembers, setSelectedUsers])

  return (
    <>
      {/* Tenant user table */}
      <div className="flex overflow-y-hidden">
        <TenantUserTable
          tenantSettingMembers={
            getTenantSettingMembersResult?.tenantSettingMembers ?? []
          }
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      </div>
    </>
  )
})
