import { useCallback } from 'react'
import {
  useDeleteTaskCategoryMutation,
  useReorderTaskCategoryMutation,
} from '@crew/apis/task/taskApis'
import { useAppDispatch } from 'states/hooks'
import { ReorderTaskCategoryRequest } from '@crew/apis/task/models/reorderTaskCategory/request'
import { NotifyEventType } from 'enums/app'
import {
  ObjectEventMessage,
  notifyProjectSettingTaskCategoryEvent,
} from 'features/app/states/appSlice'

export type SelectedTaskCategory = {
  taskCategoryId: string
  version: number
}
export const useTaskCategoryListGrid = () => {
  const dispatch = useAppDispatch()

  // Get functions for change the task category infomation
  const [deleteTaskCategoryMutation] = useDeleteTaskCategoryMutation()
  const [reorderTaskCategoryMutation] = useReorderTaskCategoryMutation()

  // Delete task category process
  const deleteTaskCategory = useCallback(
    async (taskCategory: SelectedTaskCategory) => {
      await deleteTaskCategoryMutation(taskCategory).unwrap()

      //trigger event refresh project setting task category list
      const objectEventMessage: ObjectEventMessage<void> = {
        eventType: NotifyEventType.Deleted,
        id: taskCategory.taskCategoryId,
        object: undefined,
      }
      dispatch(notifyProjectSettingTaskCategoryEvent(objectEventMessage))
    },
    [deleteTaskCategoryMutation, dispatch]
  )

  // Reorder task category process
  const reorderTaskCategory = useCallback(
    async (taskCategoryId: string, targetPositionTaskCategoryId: string) => {
      const request: ReorderTaskCategoryRequest = {
        taskCategoryId,
        targetPositionTaskCategoryId,
      }

      await reorderTaskCategoryMutation(request).unwrap()

      // Trigger event refresh event category list
      const objectEventMessage: ObjectEventMessage<void> = {
        eventType: NotifyEventType.Updated,
        id: taskCategoryId,
        object: undefined,
      }
      dispatch(notifyProjectSettingTaskCategoryEvent(objectEventMessage))
    },
    [dispatch, reorderTaskCategoryMutation]
  )

  return {
    deleteTaskCategory,
    reorderTaskCategory,
  }
}
