import { memo, useCallback, useState } from 'react'
import { useContractChangeCycleBillingPage } from './useContractChangeCycleBillingPage'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useGetBillingCycleQuery } from '@crew/apis/dist/contract/contractApis'
import { CrewContractChangeBillingCycleRadioGroup } from 'components/elements/crewContractChangeBillingCycleRadioGroup/crewContractChangeBillingCycleRadioGroup'
import { ComponentCallbackHandler } from '@crew/utils'
import { CrewRadioGroup } from 'components/devextreme/crewRadioGroup'
import { BillingCycle } from '@crew/enums/app'
import { useValueChangeEffect } from '@crew/hooks'

export const ContractChangeCycleBillingPage = memo(() => {
  const { updateBillingCycle, isLoadingUpdateBillingCycle } =
    useContractChangeCycleBillingPage()

  const { historyBack, navigate } = useCrewNavigate()

  const { t } = useTranslation()

  const [showApiErrors] = useShowApiErrors()

  const { success } = useToast()

  // 請求サイクルを取得する
  const { data: billingCycleData, isFetching } = useGetBillingCycleQuery()

  // 請求サイクルのラジオボタンに値を渡すための変数
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingCycle>(BillingCycle.Month)

  // 請求サイクルのラジオボタンの選択値が変わったときのイベントハンドラ
  const handleBillingCycleRadioGroupValueChanged = useCallback<
    ComponentCallbackHandler<typeof CrewRadioGroup, 'onValueChanged'>
  >((event) => {
    setSelectedBillingCycle(event.value)
  }, [])

  // 「変更する」ボタンクリック時のイベントハンドラ
  const handleChangeCyclePlanButtonClick = useCallback(async () => {
    // 請求サイクルの選択肢が取得できていない場合は、正しい更新処理にならない恐れがあるので処理しない
    if (!billingCycleData) {
      return
    }

    // 選択されている請求サイクルの値をもとに登録用のデータを取得する
    const selectedCyclePlan = billingCycleData.billingCycle.find(
      (item) => item.billingCycle === selectedBillingCycle
    )

    // 選択された請求サイクルが取得できなかった場合は、正しい更新処理にならない恐れがあるので処理しない
    if (!selectedCyclePlan) {
      return
    }

    try {
      // 請求サイクルの更新処理を実行する
      await updateBillingCycle(selectedCyclePlan.billingCycle)

      success(t('message.contract.changeCyclePlanSuccess'))

      // 処理に成功したら請求ポータル画面に遷移する
      navigate('/contract/portal')
    } catch (error) {
      showApiErrors(error)
    }
  }, [
    billingCycleData,
    selectedBillingCycle,
    updateBillingCycle,
    success,
    t,
    navigate,
    showApiErrors,
  ])

  // go back prev screen
  const handleCancelButtonClick = useCallback(() => {
    historyBack()
  }, [historyBack])

  // 請求サイクルを取得できたらラジオボタンの選択値に反映する
  useValueChangeEffect(
    () => {
      if (billingCycleData) {
        billingCycleData.billingCycle.forEach((item) => {
          if (item.isCurrentCycle) {
            setSelectedBillingCycle(item.billingCycle)
          }
        })
      }
    },
    [billingCycleData],
    billingCycleData
  )

  return (
    <form>
      <div className="flex flex-col gap-y-2.5 mx-auto w-full max-w-2xl">
        <div className="py-2.5 w-full flex-col justify-start items-start gap-2.5 flex">
          <span className="text-md font-bold">
            {t('label.contractCycleChange')}
          </span>
          <div className="flex-col w-full justify-start items-start gap-2.5 inline-flex">
            {/* 請求サイクル変更用のラジオボタン */}
            <CrewContractChangeBillingCycleRadioGroup
              id="billingCycle"
              name="billingCycle"
              value={selectedBillingCycle}
              onValueChanged={handleBillingCycleRadioGroupValueChanged}
            />
          </div>
        </div>

        <div className="flex flex-row gap-x-2.5">
          {/* Change cycle plan button */}
          <CrewButton
            text={t('action.change')}
            type="primary"
            onClick={handleChangeCyclePlanButtonClick}
            disabled={isFetching || isLoadingUpdateBillingCycle}
          />

          {/* Cancel button */}
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={handleCancelButtonClick}
          />
        </div>
      </div>
    </form>
  )
})
