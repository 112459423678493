import { memo, useCallback, useMemo, useState } from 'react'

import { LoadIndicator } from 'devextreme-react'
import {
  CrewAvatar,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { generateImageAvatarUrl } from '@crew/utils/avatar'
import { EntityType } from '@crew/enums/domain'
import { useParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query'
import { CrewFollowerUs } from 'components/elements/crewFollowerUs/crewFollowerUs'
import { CrewFavorite } from 'components/elements/crewFavorite/crewFavorite'
import { GetProjectForDirectChannelRequest } from '@crew/apis/project/models/getProjectForDirectChannel/request'
import { useGetProjectForDirectChannelQuery } from '@crew/apis/project/projectApis'
import {
  CrewUserPresence,
  UserPresenceState,
} from 'components/elements/crewUserPresence/crewUserPresence'
import { CrewPresenceStateIconSize } from 'components/elements/crewPresenceStateIcon/crewPresenceStateIcon'
import { useTranslation } from '@crew/modules/i18n'

export const DirectMessageHeadPanel = memo(() => {
  const { t } = useTranslation()
  const { projectId } = useParams()

  // ユーザー詳細を取得する
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getProjectForDirectChannel:
    | GetProjectForDirectChannelRequest
    | undefined = projectId
    ? {
        projectId,
      }
    : undefined
  const {
    data: getDirectChannelResult,
    isLoading: isLoadingUserDetail,
    isError: isErrorUserDetail,
  } = useGetProjectForDirectChannelQuery(
    getProjectForDirectChannel ?? skipToken
  )

  const [userPresenceState, setUserPresenceState] =
    useState<UserPresenceState>()

  // handle presence state change
  const handleUserPresenceStateChange = useCallback(
    (userPresence: UserPresenceState) => {
      setUserPresenceState(userPresence)
    },
    []
  )

  // text presence state
  const textPresenceState = useMemo(() => {
    if (!userPresenceState) return t('label.unknown')

    if (!userPresenceState.presenceStateMessage) return userPresenceState.name

    return `${userPresenceState.name} / ${userPresenceState.presenceStateMessage}`
  }, [t, userPresenceState])

  if (!getDirectChannelResult?.directChannel) {
    return <></>
  }

  // Get the partner of the logged in user in the current channel
  const partner = getDirectChannelResult.directChannel.user2

  if (isErrorUserDetail) {
    return <></>
  }
  if (isLoadingUserDetail) {
    return <LoadIndicator height="1em" width="1em" />
  }

  return (
    <>
      <div className="flex flex-row items-center gap-2">
        <div className="relative">
          <CrewAvatar
            displayName={partner.displayName}
            size={CrewAvatarSize.lg}
            imageURL={generateImageAvatarUrl(
              EntityType.DirectChannel,
              projectId
            )}
            cacheValue={partner.id + partner.version}
            showPresenceState
            textPresenceState={textPresenceState}
          />

          {/* crew user presence */}
          <div className="absolute bottom-0 right-0">
            <CrewUserPresence
              userId={partner.id}
              size={CrewPresenceStateIconSize.base}
              onChange={handleUserPresenceStateChange}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-2">
            {/* 表示名 */}
            <div
              className="font-bold text-2xl line-clamp-1 max-w-xs"
              title={partner.displayName}
            >
              {partner.displayName}
            </div>
            {/* フォローバッジ */}
            <CrewFollowerUs
              entityType={EntityType.Project}
              entityRecordId={getDirectChannelResult.directChannel.id}
            />

            {/* お気に入りアイコン */}
            <CrewFavorite
              key={getDirectChannelResult.directChannel.id}
              entityType={EntityType.Project}
              entityRecordId={getDirectChannelResult?.directChannel.id}
              isFavorite={
                getDirectChannelResult.directChannel.directChannelFavorite
                  ? true
                  : false
              }
            />
          </div>

          {/* text presence state */}
          <div className="text-base crew-text-gray-4">{textPresenceState}</div>
        </div>
      </div>
    </>
  )
})
