import { memo, useCallback, useState } from 'react'
import {
  CrewPresenceStateIcon,
  CrewPresenceStateIconSize,
} from '../crewPresenceStateIcon/crewPresenceStateIcon'
import { useGetUserPresenceStateQuery } from '@crew/apis/user/userApis'
import { GetUserPresenceStateRequest } from '@crew/apis/user/models/getUserPresenceState/request'
import { PresenceState, usePresenceState } from '@crew/states'
import { useValueChangeEffect } from '@crew/hooks'
import { PresenceStateType } from '@crew/enums/domain'
import { useSubscribeUserPresenceState } from '@crew/providers/dist/websocket'
import { UpdateUserPresenceState } from '@crew/apis/dist/websocket/models/updateUserPresenceState/updateUserPresenceState'
import { useTranslation } from '@crew/modules/dist/i18n'

export type UserPresenceState = PresenceState & {
  presenceStateMessage: string | null
}

type CrewUserPresenceProps = {
  userId: string
  size?: CrewPresenceStateIconSize
  onChange?: (userPresenceState: UserPresenceState) => void
}
export const CrewUserPresence = memo((props: CrewUserPresenceProps) => {
  const { t } = useTranslation()

  // get user presence state
  const paramsUserPresenceState: GetUserPresenceStateRequest = {
    userId: props.userId,
  }
  const { data: dataUserPresenceState } = useGetUserPresenceStateQuery(
    paramsUserPresenceState
  )

  // 在籍状態ID
  const [presenceStateId, setPresenceStateId] = useState<string>('')
  // 在籍状態メッセージ
  const [presenceStateMessage, setPresenceStateMessage] = useState<string>('')

  // ユーザー在籍状態の初期フェッチ完了時、在籍状態ID・メッセージのLocalStateを更新
  useValueChangeEffect(
    () => {
      setPresenceStateId(
        dataUserPresenceState?.userPresenceState?.presenceStateId ?? ''
      )
      setPresenceStateMessage(
        dataUserPresenceState?.userPresenceState?.presenceStateMessage ?? ''
      )
    },
    [dataUserPresenceState],
    dataUserPresenceState
  )

  // 在籍状態IDから在籍状態を取得
  const presenceState = usePresenceState(presenceStateId)

  //  在籍状態・在籍状態メッセージが変更された場合、変更後の在籍状態を親コンポーネントに通知
  useValueChangeEffect(
    () => {
      const userPresenceState: UserPresenceState = {
        id: presenceStateId,
        name: presenceState?.name ?? t('label.unknown'),
        presenceStateType:
          presenceState?.presenceStateType ?? PresenceStateType.Unknown,
        presenceStateMessage,
      }

      props.onChange?.(userPresenceState)
    },
    [
      presenceStateMessage,
      dataUserPresenceState,
      presenceState,
      props,
      props.onChange,
      presenceStateId,
      t,
    ],
    {
      presenceState,
      currentPresenceMessage: presenceStateMessage,
    }
  )

  // ユーザー在籍状態が更新されたときの処理
  const handleUserPresenceUpdated = useCallback(
    (presenceState: UpdateUserPresenceState) => {
      // 在籍状態ID・在籍状態メッセージを更新
      setPresenceStateMessage(presenceState.presenceStateMessage ?? '')
      setPresenceStateId(presenceState.presenceStateId)
    },
    []
  )

  // ユーザー在籍状態の更新をsubscribeする
  useSubscribeUserPresenceState(props.userId, handleUserPresenceUpdated)

  return (
    <CrewPresenceStateIcon
      size={props.size ?? CrewPresenceStateIconSize.sm}
      presenceStateType={
        presenceState?.presenceStateType ?? PresenceStateType.Unknown
      }
    />
  )
})
