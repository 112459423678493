import { EntityType } from '@crew/enums/domain'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import {
  FormValues,
  useCrewSaveFilterForm,
} from 'components/elements/crewSaveFilterDialog/components/crewSaveFilterForm/useCrewSaveFilterForm'
import { CrewErrorSummary } from 'components/forms/crewErrorSummary'
import { CrewRadioGroupField } from 'components/forms/crewRadioGroupField'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import {
  FC,
  FormEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useModal } from 'components/layouts/modal/useModal'
import { Filter } from '@crew/apis/filter/models/replaceFilter/request'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { CrewFieldLabel } from 'components/elements/crewFieldLabel'
import {
  useLazyCheckExistenceFilterNameQuery,
  useLazyGetFilterQuery,
} from '@crew/apis/filter/filterApis'
import { CheckExistenceFilterNameResultType, FilterType } from '@crew/enums/app'
import { useAppSelector } from 'states/hooks'

export type CrewSaveFilterFormProps = {
  isEditMode: boolean
  onSubmit: (id?: string) => void
  onCancel: () => void
  definition: string
  entityType: EntityType
  filterId?: string
}
export type FilterScopeItem = {
  id: string
  name: string
}

export const CrewSaveFilterForm: FC<CrewSaveFilterFormProps> = memo((props) => {
  const {
    t,
    control,
    formState,
    filterScopeDataSource,
    validateRules,
    getValues,
    reset,
    clearErrors,
    setError,
    handleSubmit,
    saveFilter,
    updateFilter,
    isLoadingUpdateFilter,
    isLoadingReplaceFilter,
  } = useCrewSaveFilterForm()

  const [showApiErrors] = useShowApiErrorsWithForm(setError)
  const toast = useToast()

  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const [version, setVersion] = useState(0) // Default version is 0
  const [isBuiltInFilter, setIsBuiltInFilter] = useState(false)
  const [isMyFilter, setIsMyFilter] = useState(false)

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const [lazyGetFilterQuery] = useLazyGetFilterQuery()
  const [
    lazyCheckExistenceFilterNameQuery,
    { isLoading: isLoadingCheckExistenceFilterName },
  ] = useLazyCheckExistenceFilterNameQuery()

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  // 投稿可能か
  const canClickRegisterButton = useMemo(
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    [formState]
  )

  useEffect(() => {
    const getFilter = async (filterId: string) => {
      const result = await lazyGetFilterQuery({
        filterId,
      }).unwrap()

      // Init form data for edit mode
      if (result.filter) {
        // Check if the filter created by the logged-in user
        if (result.filter.createdById === loggedInUser?.id) {
          setIsMyFilter(true)
        }
        setVersion(result.filter.version)
        setIsBuiltInFilter(result.filter.filterType === FilterType.BuiltIn)
        reset({
          name:
            // Built-in filter name is defined in i18n
            result.filter.filterType === FilterType.BuiltIn
              ? t('label.' + result.filter.name)
              : result.filter.name,
          scope: result.filter.scope,
          default: result.filter.default,
        })
      }
    }

    if (props.isEditMode && props.filterId) {
      getFilter(props.filterId)
    }
  }, [
    reset,
    props.entityType,
    props.isEditMode,
    props.filterId,
    lazyGetFilterQuery,
    t,
    loggedInUser?.id,
  ])

  // Press submit confirmation button to update filter
  const handleSubmitPermitButtonClick = useCallback(async () => {
    try {
      const data = getValues()

      if (props.isEditMode) {
        if (!props.filterId) return

        // Request to update filter
        const response = await updateFilter(data, version, props.filterId)

        toast.success(t('message.filter.filterUpdated'))

        props.onSubmit(response.id)
      } else {
        // Request to insert filter
        const payload: Filter = {
          entityType: props.entityType,
          name: data.name,
          scope: data.scope,
          definition: props.definition,
          default: data.default,
        }

        // Request to update filter
        const filterResponse = await saveFilter(payload)
        props.onSubmit(filterResponse?.id)
      }

      toast.success(t('message.filter.filterUpdated'))
      reset()
      clearErrors()
      closeConfirmDialog()
    } catch (err) {
      showApiErrors(err)
    }
  }, [
    props,
    toast,
    t,
    reset,
    clearErrors,
    closeConfirmDialog,
    getValues,
    updateFilter,
    version,
    saveFilter,
    showApiErrors,
  ])

  // execute save request processing to the filter
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // フィルタ名の存在チェックに関する処理。
        // 他の人により作成されたフィルタを修正する場合、バックエンド側(update_filter)で「自分で作成したフィルタではない場合、デフォルトのフィルタ(dynamoDb:setting)しか更新できず、フィルタ情報を更新しない」処理を実行したため、フィルタ名の存在をチェックする必要がない。
        // 自分で作成したフィルタを修正する、またはフィルタを新規作成する場合、フィルタ名の重複をチェックする。
        if (!props.isEditMode || (props.isEditMode && isMyFilter)) {
          const checkExistenceFilterNameResult =
            await lazyCheckExistenceFilterNameQuery({
              entityType: props.entityType,
              name: data.name,
              excludeFilterId: props.filterId,
            }).unwrap()

          if (props.isEditMode) {
            if (
              checkExistenceFilterNameResult.result ===
              CheckExistenceFilterNameResultType.CreatedByMyself
            ) {
              toast.error(
                t('message.general.existedFilterName', { name: data.name })
              )
              return
            }
          } else {
            // If the filter name is already used by my self, do not create duplicate filter name
            if (
              checkExistenceFilterNameResult.result ===
              CheckExistenceFilterNameResultType.CreatedByMyself
            ) {
              setConfirmMessage(t('message.filter.filterExistConfirm'))
              openConfirmDialog()
              return
            }
          }
        }

        if (props.isEditMode) {
          if (!props.filterId) return

          // Request to update filter
          const response = await updateFilter(data, version, props.filterId)

          toast.success(t('message.filter.filterUpdated'))

          props.onSubmit(response.id)
        } else {
          // Request to insert filter
          const payload: Filter = {
            entityType: props.entityType,
            name: data.name,
            scope: data.scope,
            definition: props.definition,
            default: data.default,
          }

          // Request to insert filter
          const filterResponse = await saveFilter(payload)

          toast.success(t('message.filter.filterRegistered'))

          props.onSubmit(filterResponse?.id)
        }

        reset()
        clearErrors()
      } catch (err) {
        showApiErrors(err)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    props,
    isMyFilter,
    reset,
    clearErrors,
    lazyCheckExistenceFilterNameQuery,
    toast,
    t,
    openConfirmDialog,
    updateFilter,
    version,
    saveFilter,
    showApiErrors,
  ])

  // Handle cancel button on save filter dialog
  const handleCancelButtonClick = useCallback(() => {
    reset()
    clearErrors()
    props.onCancel()
  }, [clearErrors, props, reset])

  // render scrop radio button
  const renderProjectRadioItem = useCallback(
    (itemData: FilterScopeItem) => {
      return (
        <div>
          <p id={`radioId-${itemData.id}`}>{t(itemData.name)}</p>
        </div>
      )
    },
    [t]
  )

  // Form内Enterキー押下によるSubmit制御
  // TODO: 一時的な暫定対応。対処方法についてはCREW-3338で検討する。
  //       https://break-tmc.atlassian.net/browse/CREW-3338
  const handleFormSubmit = useCallback<FormEventHandler>((event) => {
    event.preventDefault()
  }, [])

  return (
    <>
      <form
        // Form内Enterキー押下によるSubmit制御
        // TODO: 一時的な暫定対応。対処方法についてはCREW-3338で検討する。
        // https://break-tmc.atlassian.net/browse/CREW-3338
        onSubmit={handleFormSubmit}
      >
        <div className="flex flex-col gap-y-5">
          {/* フィルター名 */}
          <CrewTextBoxField
            id="name"
            name="name"
            control={control}
            labelMode="hidden"
            label={t('label.filterName')}
            required={true}
            rules={validateRules.name}
            disabled={isBuiltInFilter || (props.isEditMode && !isMyFilter)} // User can't edit built-in filter or other user's filter
          />

          {/* 公開範囲 */}
          <CrewRadioGroupField
            id="radioId"
            name="scope"
            control={control}
            layout="horizontal"
            dataSource={filterScopeDataSource}
            valueExpr="id"
            displayExpr="name"
            itemRender={renderProjectRadioItem}
            label={t('label.scope')}
            required={true}
            rules={validateRules.scope}
            disabled={isBuiltInFilter || (props.isEditMode && !isMyFilter)} // User can't edit built-in filter or other user's filter
          />

          {/* 既定 */}
          <div className="flex flex-col gap-y-1">
            <CrewFieldLabel text={t('label.defaultLabel')} />
            <CrewCheckBoxField
              name="default"
              control={control}
              label={t('label.makeDefaultFilter')}
              className="crew-text-default"
            />
          </div>

          <CrewErrorSummary formState={formState} />
        </div>
        <div className="flex justify-end items-center gap-x-5">
          <CrewButton
            text={t('action.register')}
            type="primary"
            onClick={handleSubmitButtonClick}
            disabled={
              !canClickRegisterButton ||
              isLoadingCheckExistenceFilterName ||
              isLoadingUpdateFilter ||
              isLoadingReplaceFilter
            }
          />
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={handleCancelButtonClick}
          />
        </div>
      </form>
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleSubmitPermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />
    </>
  )
})
