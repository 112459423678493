import {
  BulkChangeWorkTimesRequest,
  BulkChangeWorkTimesRequest_WorkTime_Added,
  BulkChangeWorkTimesRequest_WorkTime_Deleted,
  BulkChangeWorkTimesRequest_WorkTime_Updated,
} from '@crew/apis/task/models/bulkChangeWorkTimes/request'
import {
  useBulkChangeWorkTimesMutation,
  useLazyGetMyActualWorkTimesQuery,
} from '@crew/apis/task/taskApis'
import { JsonDateFormat } from '@crew/enums/system'
import { ProjectRef, UserRef } from '@crew/models/refs'
import { TaskWork } from '@crew/models/domain'
import dayjs from '@crew/modules'
import { NotifyEventType } from 'enums/app'
import {
  notifyTaskWorkEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { useCallback } from 'react'
import { useAppDispatch } from 'states/hooks'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  convertMinutesToHHMM,
  convertScheduledTimeToMinutes,
  isRegexFormatScheduledTime,
} from '@crew/utils'

// concat date and time
const concatDateAndTime = (date: Date, time: Date | null) => {
  if (!time) return null

  const newDate = dayjs(date).format(JsonDateFormat.YYYYMMDD)
  const newTime = dayjs(time).format(JsonDateFormat.HHmm)

  return dayjs(`${newDate} ${newTime}`, JsonDateFormat.YYYYMMDDHHmm)
}

export type Worktime = {
  startTime: Date | null
  endTime: Date | null
  actualWorkTimes: string
  project: ProjectRef
  subject: string
  assignToUser: UserRef | undefined
  taskId: string
  workTimesId: string | undefined
  version: number | undefined
  actualProgress: number | undefined
}

export type FormValues = {
  worktimes: Worktime[]
}

export const useWorkingTimeEntryForm = (currentDate: Date) => {
  // react-hook-formの各種データを取得
  const { control, handleSubmit, clearErrors, watch, getValues, setValue } =
    useForm<FormValues>({
      criteriaMode: 'all',
    })

  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'worktimes',
  })

  const [lazyGetMyActualWorkTimesQuery] = useLazyGetMyActualWorkTimesQuery()
  const [bulkChangeWorkTimesMutation] = useBulkChangeWorkTimesMutation()
  const dispatch = useAppDispatch()

  // Bulk change work times for a specific date
  const bulkChangeWorkTimes = useCallback(
    async (
      targetDate: Date,
      values: FormValues,
      deletedWorkTimes: BulkChangeWorkTimesRequest_WorkTime_Deleted[]
    ) => {
      const workDate = dayjs(targetDate).format(JsonDateFormat.YYYYMMDD)

      const updatedWorkTimes: BulkChangeWorkTimesRequest_WorkTime_Updated[] = []
      const addedWorkTimes: BulkChangeWorkTimesRequest_WorkTime_Added[] = []

      values.worktimes.forEach((worktime) => {
        const startTimeFormatted = worktime.startTime
          ? workDate +
            ' ' +
            dayjs(worktime.startTime).format(JsonDateFormat.HHmmss)
          : undefined
        const endTimeFormatted = worktime.endTime
          ? workDate +
            ' ' +
            dayjs(worktime.endTime).format(JsonDateFormat.HHmmss)
          : undefined

        // Update work times
        if (worktime.workTimesId) {
          updatedWorkTimes.push({
            taskWorkId: worktime.workTimesId,
            startTime: startTimeFormatted,
            endTime: endTimeFormatted,
            actualWorkTimes:
              convertScheduledTimeToMinutes(worktime.actualWorkTimes) ?? 0,
            actualProgress: worktime.actualProgress,
            version: worktime.version ?? 0,
          })
        } else {
          // Register new work times
          if (worktime.actualWorkTimes) {
            addedWorkTimes.push({
              taskId: worktime.taskId,
              startTime: startTimeFormatted,
              endTime: endTimeFormatted,
              actualWorkTimes:
                convertScheduledTimeToMinutes(worktime.actualWorkTimes) ?? 0,
              actualProgress: worktime.actualProgress,
            })
          }
        }
      })

      const payload: BulkChangeWorkTimesRequest = {
        workDate,
        workTimes: {
          addedWorkTimes,
          updatedWorkTimes,
          deletedWorkTimes,
        },
      }

      await bulkChangeWorkTimesMutation(payload).unwrap()

      const objectEventMessage: ObjectEventMessage<TaskWork> = {
        eventType: NotifyEventType.Updated,
        // addedWorkTimesの場合、TaskWorkIdがないし、EventMessageハンドラでは`id`の値が使用されないため、ここでid = ''とする
        id: '',
        object: undefined,
      }

      dispatch(notifyTaskWorkEvent(objectEventMessage))
    },
    [bulkChangeWorkTimesMutation, dispatch]
  )

  // Copy tasks work times from a specific date to another date
  const copyTasksForWorkTimes = useCallback(
    async (targetCopyDate: Date) => {
      const targetCopyDateFormated = dayjs(targetCopyDate).format(
        JsonDateFormat.YYYYMMDD
      )

      const result = await lazyGetMyActualWorkTimesQuery({
        startDate: targetCopyDateFormated,
        endDate: targetCopyDateFormated,
      }).unwrap()

      const cloneTasks = result.workTimes.map((task) => {
        return {
          taskId: task.taskId,
          subject: task.subject,
          version: undefined,
          assignToUser: task.assignToUser,
          project: task.project,
          workTimesId: undefined,
          actualWorkTimes: undefined,
          startTime: undefined,
          endTime: undefined,
          actualProgress: undefined,
        }
      })

      return cloneTasks
    },
    [lazyGetMyActualWorkTimesQuery]
  )

  // Get next date from current date
  const getNextDate = useCallback((currentDate: Date) => {
    const nextDate = new Date(currentDate)
    nextDate.setDate(nextDate.getDate() + 1)
    return nextDate
  }, [])

  // Get previous date from current date
  const getPreviousDate = useCallback((currentDate: Date) => {
    const previousDate = new Date(currentDate)
    previousDate.setDate(previousDate.getDate() - 1)
    return previousDate
  }, [])

  //custom validate start time and end time
  const validateTimeRange = useCallback(
    (formValues: FormValues, index: number) => {
      // Get start time and end time
      const startTime = concatDateAndTime(
        currentDate,
        formValues.worktimes[index].startTime
      )

      const endTime = concatDateAndTime(
        currentDate,
        formValues.worktimes[index].endTime
      )

      if (!startTime || !endTime || startTime <= endTime) {
        clearErrors(`worktimes.${index}.startTime`)
        clearErrors(`worktimes.${index}.endTime`)
        return true
      }

      return false
    },
    [clearErrors, currentDate]
  )

  // Actual work times duration validation
  const validateActualWorkTimesDuration = useCallback(
    (value: string, formValues: FormValues, index: number) => {
      // 「開始日時」と「終了日時」の差分より大きい「実績時間」は登録できないようにする
      const startTime = concatDateAndTime(
        currentDate,
        formValues.worktimes[index].startTime
      )

      const endTime = concatDateAndTime(
        currentDate,
        formValues.worktimes[index].endTime
      )

      if (startTime && endTime) {
        const diffMinutes = endTime.diff(startTime, 'minute')
        const actualWorkTimes = convertScheduledTimeToMinutes(value)

        if (actualWorkTimes && actualWorkTimes > diffMinutes) {
          return false
        }
      }

      return true
    },
    [currentDate]
  )

  // Custom validate actual work times string
  const validateActualWorkTimes = useCallback((value: string) => {
    return isRegexFormatScheduledTime(value)
  }, [])

  // Custom required validation for work times
  const validateRequiredWorkTimes = useCallback(
    (value: string, formValues: FormValues, index: number) => {
      // 「開始時刻」「終了時刻」のいずれかが入力されている場合のみ、「作業時間」が入力されているかチェックする
      if (
        (formValues.worktimes[index].startTime ||
          formValues.worktimes[index].endTime) &&
        !value
      ) {
        return false
      }

      return true
    },
    []
  )

  // automatically calculate actualWorkTimes when entering startTime with endTime
  const calculateActualWorkTimes = useCallback(
    (index: number) => {
      // convert start time to date
      const startTime = concatDateAndTime(
        currentDate,
        getValues().worktimes[index].startTime as Date
      )?.toDate()

      // convert end time to date
      const endTime = concatDateAndTime(
        currentDate,
        getValues().worktimes[index].endTime as Date
      )?.toDate()

      if (!startTime || !endTime) return

      if (endTime >= startTime) {
        // actualWorkTime = endTime - startTime
        // div 1000*60 = 60000 to convert milliseconds to minutes
        const actualWorkTime =
          endTime.getTime() / 60000 - startTime.getTime() / 60000

        setValue(
          `worktimes.${index}.actualWorkTimes`,
          convertMinutesToHHMM(actualWorkTime)
        )
        clearErrors(`worktimes.${index}.actualWorkTimes`)
      }
    },
    [clearErrors, getValues, setValue, currentDate]
  )

  // custom required validation for actual progress
  const validateActualProgress = useCallback(
    (index: number) => {
      const actualProgress = getValues().worktimes[index].actualProgress

      // actual progress must be between 0 and 100
      if (actualProgress && (actualProgress < 0 || actualProgress > 100))
        return false

      return true
    },
    [getValues]
  )

  return {
    control,
    fields,
    replace,
    append,
    remove,
    handleSubmit,
    validateActualWorkTimes,
    validateActualWorkTimesDuration,
    validateTimeRange,
    validateRequiredWorkTimes,
    watch,

    bulkChangeWorkTimes,
    copyTasksForWorkTimes,
    getNextDate,
    getPreviousDate,
    calculateActualWorkTimes,
    validateActualProgress,
  }
}
