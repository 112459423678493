import {
  useDeleteRoleMutation,
  useUpdateRolesMutation,
} from '@crew/apis/role/roleApis'
import { Roles, Permissions } from '@crew/enums/app'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import {
  UpdateRolesRequest,
  UpdateRolesRequest_Role,
} from '@crew/apis/role/models/updateRoles/request'
import { DeleteRoleRequest } from '@crew/apis/role/models/deleteRole/request'
import { RoleType } from '@crew/enums/domain'
import { useAppDispatch } from 'states/hooks'
import {
  ObjectEventMessage,
  notifyRoleEvent,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'

export type PermissionsType = {
  permissionCode: string
  selected: boolean
}

export type Role = {
  id: string | null
  name: string | null
  version: number
  roleCode: string | null
  rolePermissions: PermissionsType[]
}

export type RoleSorted = {
  id: string | null
  roleType: RoleType
  name: string | null
  roleCode: string | null
  isUserDefined: boolean
  version: number
  rolePermissions: PermissionsType[]
}

export type FormValues = {
  permissionRole: Role[]
}

export const useAuthorityListPanel = () => {
  const dispatch = useAppDispatch()

  // Get functions for update role information
  const [updateRolesMutation, { isLoading: isLoadingUpdateRoles }] =
    useUpdateRolesMutation()
  const [deleteRoleMutation] = useDeleteRoleMutation()

  // react-hook-formの各種データを取得
  const { register, handleSubmit, control, formState } = useForm<FormValues>({
    defaultValues: {
      permissionRole: [],
    },
  })

  // Update roles process
  const updateRoles = useCallback(
    async (data: FormValues, roleType: RoleType) => {
      const roleParams: UpdateRolesRequest_Role[] = data.permissionRole.map(
        (item) => {
          return {
            roleId: item.id,
            name: item.name,
            version: item.version,
            permissionCodes: item.rolePermissions
              .filter((permission) => permission.selected)
              .map((permission) => permission.permissionCode),
          }
        }
      )

      // Params of api update permissions roles
      const params: UpdateRolesRequest = {
        roleType: roleType,
        roles: roleParams,
      }
      // Update permissions roles
      await updateRolesMutation(params).unwrap()

      // Refresh list permissions roles after update
      const objectEventMessage: ObjectEventMessage<UpdateRolesRequest> = {
        eventType: NotifyEventType.Updated,
        id: '',
        object: params,
      }
      dispatch(notifyRoleEvent(objectEventMessage))
    },
    [dispatch, updateRolesMutation]
  )

  // Delete role process
  const deleteRole = useCallback(
    async (roleId: string, version: number) => {
      // Params of delete role
      const params: DeleteRoleRequest = {
        roleId,
        version,
      }
      // Delete role
      await deleteRoleMutation(params).unwrap()
      // Refresh list permissions roles after update
      const objectEventMessage: ObjectEventMessage<UpdateRolesRequest> = {
        eventType: NotifyEventType.Deleted,
        id: roleId,
        object: undefined,
      }
      dispatch(notifyRoleEvent(objectEventMessage))
    },
    [deleteRoleMutation, dispatch]
  )

  // 編集不可項目の設定
  // ・システム権限
  //   組織管理者：全項目編集不可
  //   組織管理者以外：契約情報編集不可
  // ・プロジェクト権限
  //   全て編集可
  const setDisabledRoleValue = useCallback(
    (
      roleType: RoleType,
      roleCode: string | null,
      permissionCode: string | null
    ) => {
      if (roleType === RoleType.System) {
        // システム権限
        switch (roleCode) {
          case Roles.SysTenantAdmin.value:
            // 組織管理者は全項目編集不可
            return true
          case Roles.SysAdmin.value:
            // 管理者は全項目編集不可
            return true
          case Roles.SysUser.value:
            // 一般ユーザーと外部ユーザーの場合、次の項目を編集できない
            // - 契約情報の表示/変更
            // - 組織設定の表示/変更
            return (
              permissionCode === Permissions.SysContractViewEdit.value ||
              permissionCode === Permissions.SysTenantSettingViewEdit.value
            )
          case Roles.SysExternalUser.value:
            // 外部ユーザーは「パブリックプロジェクトの表示/参加,ユーザーリストの表示」以外のすべてのシステム権限をなしとする
            return (
              permissionCode !== Permissions.SysPublicProjectViewJoin.value &&
              permissionCode !== Permissions.SysUserListView.value
            )

          default:
            // 上記以外は不正な値のため編集不可
            return true
        }
      } else if (roleType === RoleType.Project) {
        // プロジェクト権限
        if (roleCode == null) {
          // role_codeがnullの場合は、カスタム役割のため編集できる
          return false
        }

        switch (roleCode) {
          case Roles.PrjAdmin.value:
            // 管理者は全項目編集不可
            return true
          case Roles.PrjMember.value:
            // メンバーの場合、すべての項目を編集できる
            return false
          case Roles.PrjGuest.value:
            // ゲストの場合、次の項目を編集できない
            // - プロジェクトの編集
            // - プロジェクト設定の変更
            // - 組織内ユーザーの追加
            // - 組織内ユーザーの招待
            // - 組織外ユーザーの招待
            // - フォルダの作成
            // - フォルダの編集
            // - フォルダの削除
            // - プロジェクトメンバーの役割変更
            return (
              permissionCode === Permissions.PrjProjectEdit.value ||
              permissionCode === Permissions.PrjMemberAdd.value ||
              permissionCode === Permissions.PrjProjectSettingEdit.value ||
              permissionCode === Permissions.PrjMemberEdit.value ||
              permissionCode === Permissions.PrjMemberDelete.value ||
              permissionCode === Permissions.PrjMemberRoleEdit.value ||
              permissionCode === Permissions.PrjFolderCreate.value ||
              permissionCode === Permissions.PrjFolderEdit.value ||
              permissionCode === Permissions.PrjFolderDelete.value
            )
          default:
            // 上記以外は不正な値のため編集不可
            return true
        }
      } else {
        // 上記以外は不正な値のため編集不可
        return true
      }
    },
    []
  )

  return {
    register,
    handleSubmit,
    control,
    formState,
    setDisabledRoleValue,

    deleteRole,
    updateRoles,
    isLoadingUpdateRoles,
  }
}
