import { FC, memo } from 'react'

import { CrewChatMessageEditor } from 'components/elements/crewChatMessageEditor/crewChatMessageEditor'

import { ChatTimelineHeader } from './components/chatTimelineHeader/chatTimelineHeader'
import { ChatTimelineMessageList } from './components/chatTimelineMessageList/chatTimelineMessageList'
import { useChatTimelineService } from '@crew/states'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useProjectPermissions } from '@crew/hooks'
import { useUserSetting } from '@crew/states'
import { UserChatSettingDisplayRange } from 'enums/app'
import { SearchRange, SettingKeyType } from '@crew/enums/dist/app'

type ChatTimelineProps = {
  selectedItemId?: string | undefined // 選択中のメッセージ。ハイライト用に使用する
}

/**
 * チャットルームをタイムライン形式で表示するコンポーネント
 */
export const ChatTimeline: FC<ChatTimelineProps> = memo((props) => {
  const dispatch = useAppDispatch()

  const chatTimelineService = useChatTimelineService(dispatch)

  // チャットメッセージの表示範囲の個人設定値
  const defaultDisplayRange = useUserSetting(SettingKeyType.ChatDisplayRange)
  // ユーザ設定によるデフォルト検索範囲
  const defaultSearchRange =
    defaultDisplayRange === null ||
    defaultDisplayRange === UserChatSettingDisplayRange.DisplayAll.value
      ? SearchRange.AllMessage
      : SearchRange.OnlyThisRoom

  // 処理対象のチャットルームをViewModelから取得
  const currentChatRoom = useAppSelector(
    (state) => state.message.chat.current.chatRoom
  )

  // 処理対象のチャットルームの検索範囲をViewModelから取得
  const currentSearchRange = useAppSelector((state) =>
    currentChatRoom?.id !== undefined
      ? state.message.chat.timeline.entities[currentChatRoom.id]?.searchRange
      : undefined
  )

  // ViewModelから取得した検索範囲が取得出来ない場合は、デフォルト値を使用する
  const searchRange = currentSearchRange ?? defaultSearchRange

  // ViewModelの検索範囲が未設定の場合のみ、デフォルト値を設定する
  if (!currentSearchRange) {
    if (currentChatRoom?.id) {
      chatTimelineService.updateTimelineSearchRange({
        chatRoomId: currentChatRoom?.id,
        searchRange,
      })
    }
  }

  const { hasPrjFileCreatePermission } = useProjectPermissions(
    currentChatRoom?.entityType,
    currentChatRoom?.entityRecordId
  )

  // 表示に必要なデータが指定されていない場合、表示することができないので、エラーを表示する
  if (!currentChatRoom) {
    return <></>
  }

  // チャット
  return (
    <div className="flex flex-col w-full h-full gap-1 overflow-hidden">
      {/* ヘッダ（検索範囲フィルタや検索ボタンを表示） */}
      <ChatTimelineHeader />

      {/* メッセージリスト */}
      {/* TODO: 後ほどkey指定を外すが、これを現時点で外すとチャットルームやスレッドを切り替えた際にうまく処理が走らない */}
      <ChatTimelineMessageList
        key={currentChatRoom.id}
        selectedItemId={props.selectedItemId}
      />

      {/* チャット投稿コンポーネント */}
      <CrewChatMessageEditor
        isEditMode={false}
        chatRoomId={currentChatRoom.id}
        threadRootMessageId={undefined} // タイムライン形式の際はスレッド返信ではないのでundefinedを渡す
        entityType={currentChatRoom.entityType}
        entityRecordId={currentChatRoom.entityRecordId}
        canUploadFile={hasPrjFileCreatePermission}
      />
    </div>
  )
})
