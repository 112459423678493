import { useCallback } from 'react'
import {
  useDeletePresenceStateMutation,
  useReorderPresenceStateMutation,
} from '@crew/apis/presenceState/presenceStateApis'
import { NotifyEventType } from 'enums/app'
import { useAppDispatch } from 'states/hooks'
import { PresenceState } from '@crew/models/domain'
import {
  notifyPresenceEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { ReorderPresenceStateRequest } from '@crew/apis/presenceState/models/reorderPresenceState/request'

export type SelectedPresenceStatus = {
  presenceStatusId: string
  version: number
}

export const usePresenceStateListGrid = () => {
  const dispatch = useAppDispatch()

  const [reorderPresenceStateMutation] = useReorderPresenceStateMutation()
  const [deletePresenceStateMutation] = useDeletePresenceStateMutation()

  // delete presence state process
  const deletePresenceState = useCallback(
    async (presenceStateId: string, version: number) => {
      await deletePresenceStateMutation({
        presenceStateId,
        version,
      }).unwrap()

      // Notify presence state event
      const objectEventMessage: ObjectEventMessage<PresenceState> = {
        eventType: NotifyEventType.Deleted,
        id: presenceStateId,
        object: undefined,
      }
      dispatch(notifyPresenceEvent(objectEventMessage))
    },
    [deletePresenceStateMutation, dispatch]
  )

  // Reorder presence state process
  const reorderPresenceState = useCallback(
    async (presenceStateId: string, targetPositionPresenceStateId: string) => {
      const request: ReorderPresenceStateRequest = {
        presenceStateId,
        targetPositionPresenceStateId,
      }
      await reorderPresenceStateMutation(request).unwrap()
    },
    [reorderPresenceStateMutation]
  )

  return {
    deletePresenceState,
    reorderPresenceState,
  }
}
