import { useTranslation } from '@crew/modules/dist/i18n'
import { CrewNumberBoxField } from 'components/forms/crewNumberBoxField'
import { memo, useCallback, useMemo } from 'react'
import { useContractPurchaseExternalUserPage } from './useContractPurchaseExternalUserPage'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { ValueChangedEvent as NumberBoxValueChangedEvent } from 'devextreme/ui/number_box'
import { FormValues } from './useContractPurchaseExternalUserPage'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useGetExternalUsersCountQuery } from '@crew/apis/dist/user/userApis'
import { useWatch } from 'react-hook-form'
import { BillingCycle } from '@crew/enums/app'

type RenderExternalUsersAmountProps = {
  billingCycle: BillingCycle
  totalAmount: number
}

// 外部ユーザーの年額表示か月額表示を描画するコンポーネント
const RenderExternalUsersAmount = memo(
  (props: RenderExternalUsersAmountProps) => {
    const { t } = useTranslation()
    // 年間契約
    if (props.billingCycle === BillingCycle.Year) {
      return (
        <div className="text-blue-500">
          {props.totalAmount === 0
            ? t('label.free') // 0の場合は「無料」と表示する
            : t('label.priceAnnualText', {
                price: props.totalAmount.toLocaleString(),
              })}
        </div>
      )
    } else {
      // 月間契約
      return (
        <div className="text-blue-500">
          {props.totalAmount === 0
            ? t('label.free') // 0の場合は「無料」と表示する
            : t('label.priceMonthlyText', {
                price: props.totalAmount.toLocaleString(),
              })}
        </div>
      )
    }
  }
)

// TODO: 英語表記にした際に崩れるので要対応
// https://break-tmc.atlassian.net/browse/CREW-14306
export const ContractPurchaseExternalUserPage = memo(() => {
  const { historyBack, navigate } = useCrewNavigate()

  const {
    control,
    handleSubmit,
    setValue,
    updateInvitableExternalUserCount,
    isLoadingUpdateExternalUserCount,
  } = useContractPurchaseExternalUserPage()

  const [showApiErrors] = useShowApiErrors()

  const { success } = useToast()

  const { t } = useTranslation()

  const { data: getExternalUsersCountResult } = useGetExternalUsersCountQuery()

  // 選択中の招待可能ユーザー数。入力値の変更に追従して表示を変更するためuseWatchを使用する
  const selectedExternalUsersCount = useWatch({
    control,
    name: 'numberOfInvitees',
  })

  // 変更ボタン押下時の処理
  const handlePurchaseExternalUserButtonClick = useCallback(() => {
    // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
    const onSubmit = async (data: FormValues) => {
      try {
        // 招待可能人数を更新する
        await updateInvitableExternalUserCount(data.numberOfInvitees)

        success(t('message.contract.purchaseExternalUserSuccess'))

        // go contract portal page
        navigate('/contract/portal')
      } catch (error) {
        showApiErrors(error)
      }
    }
    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    navigate,
    showApiErrors,
    success,
    t,
    updateInvitableExternalUserCount,
  ])

  // キャンセルボタン押下時の処理
  const handleCancelButtonClick = useCallback(() => {
    historyBack()
  }, [historyBack])

  // ナンバーボックスの値変更時の処理、10単位での入力にする
  const handleNumberOfInviteesValueChanged = useCallback(
    (e: NumberBoxValueChangedEvent) => {
      if (e.value !== undefined) {
        // 10単位での入力にする
        const newValue = Math.floor(e.value / 10) * 10
        setValue('numberOfInvitees', newValue)
      }
    },
    [setValue]
  )

  // 現在の外部ユーザーオプション数(追加購入している分の人数)
  const currentExternalUsersCount = useMemo(() => {
    if (getExternalUsersCountResult) {
      return getExternalUsersCountResult.currentSubscribedExternalUsersCount
    }

    return 0
  }, [getExternalUsersCountResult])

  // 1ユーザーあたりの年額単価(月間契約の場合は値がないためnullが返る)
  const annualUnitPrice = useMemo(() => {
    if (getExternalUsersCountResult) {
      return getExternalUsersCountResult.annualUnitPrice
    }
  }, [getExternalUsersCountResult])

  // 1ユーザーあたりの月額単価
  const monthlyUnitPrice = useMemo(() => {
    if (getExternalUsersCountResult) {
      return getExternalUsersCountResult.monthlyUnitPrice
    }

    return 0
  }, [getExternalUsersCountResult])

  // 請求サイクル
  const billingCycle = useMemo(() => {
    // 年払いの時のみannualUnitPriceが存在するため、その値で年払いか月払いかを判定する
    if (annualUnitPrice) {
      return BillingCycle.Year
    }
    return BillingCycle.Month
  }, [annualUnitPrice])

  // 現在の外部ユーザーオプションの合計額を返却する
  const currentExternalUsersAmount = useMemo(() => {
    // 年払い
    if (billingCycle === BillingCycle.Year) {
      // 年払いの場合は必ずannualUnitPriceが存在するが、型の都合上nullチェックが必要
      if (!annualUnitPrice) {
        return 0
      }
      // 単価×人数の年額
      return annualUnitPrice * currentExternalUsersCount
    } else {
      // 月払い

      // 単価×人数の月額
      return monthlyUnitPrice * currentExternalUsersCount
    }
  }, [
    annualUnitPrice,
    billingCycle,
    currentExternalUsersCount,
    monthlyUnitPrice,
  ])

  // 変更後の外部ユーザーオプションの合計額を返却する
  const changedExternalUsersAmount = useMemo(() => {
    // 年払い
    if (billingCycle === BillingCycle.Year) {
      // 年払いの場合は必ずannualUnitPriceが存在するが、型の都合上nullチェックが必要
      if (!annualUnitPrice) {
        return 0
      }
      // 単価×人数の年額
      return annualUnitPrice * selectedExternalUsersCount
    } else {
      // 月払い

      // 単価×人数の月額
      return monthlyUnitPrice * selectedExternalUsersCount
    }
  }, [
    annualUnitPrice,
    billingCycle,
    monthlyUnitPrice,
    selectedExternalUsersCount,
  ])

  return (
    <form>
      <div className="flex-col flex w-full max-w-2xl mx-auto gap-y-2.5">
        <div className="text-md font-bold py-2.5">
          {t('label.addInvitableExternalUser')}
        </div>

        <div className="flex-col flex gap-1.5 text-md">
          <div>{t('label.invitableExternalUserForFree')}</div>
          <div className=" text-blue-500">
            {t('label.invitableExternalUser', {
              total: getExternalUsersCountResult?.baseExternalUsersCount,
              remaining:
                getExternalUsersCountResult?.inviteableExternalUsersCount,
            })}
          </div>
          <div>{t('label.expandInviteeLimit')}</div>
        </div>

        <div className="flex-col flex gap-3 text-md crew-text-gray-4 w-full">
          {/* 現在 */}
          <div className="flex-row flex gap-2.5 items-center">
            <div className="w-20">{t('label.contractNow')}</div>
            <div className="grow">
              {t('label.canBeInvite', {
                people: getExternalUsersCountResult?.baseExternalUsersCount,
                additionalPeople:
                  getExternalUsersCountResult?.currentSubscribedExternalUsersCount,
              })}
            </div>
            {/* 変更前の金額表示 */}
            <RenderExternalUsersAmount
              billingCycle={billingCycle}
              totalAmount={currentExternalUsersAmount}
            />
          </div>
          {/* 変更後 */}
          <div className="flex-row flex gap-2.5 items-center">
            <div className="w-20">{t('label.contractAfterChange')}</div>
            <div className="flex-row flex items-center grow">
              <div>
                {t('label.canBeInvite2.form1', {
                  people: getExternalUsersCountResult?.baseExternalUsersCount,
                })}
              </div>
              <CrewNumberBoxField
                control={control}
                id="numberOfInvitees"
                name="numberOfInvitees"
                step={10}
                showLabel={false}
                width={80}
                className="mx-1"
                onValueChanged={handleNumberOfInviteesValueChanged}
                min={0}
                showSpinButtons={true}
              />
              <div>{t('label.canBeInvite2.form2')}</div>
            </div>
            {/* 変更後の金額表示 */}
            <RenderExternalUsersAmount
              billingCycle={billingCycle}
              totalAmount={changedExternalUsersAmount}
            />
          </div>
        </div>

        {/* ボタン */}
        <div className="flex-row flex gap-2.5 items-center">
          <CrewButton
            text={t('action.change')}
            type="primary"
            onClick={handlePurchaseExternalUserButtonClick}
            disabled={isLoadingUpdateExternalUserCount}
          />
          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={handleCancelButtonClick}
          />
        </div>
      </div>
    </form>
  )
})
