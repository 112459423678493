import classNames from 'classnames'
import { FC, memo, useMemo } from 'react'

import { skipToken } from '@reduxjs/toolkit/query'

import { useGetLookupRelatedItemQuery } from '@crew/apis/lookup/lookupApis'
import { EntityType, ProjectType } from '@crew/enums/domain'
import { getDefaultTabValue } from '@crew/utils/enum'

import {
  DirectMessageTabs,
  EventDetailTabs,
  FileDetailListTabs,
  ProjectListTabs,
  TaskDetailListTabs,
} from 'enums/app'
import { useAppSelector } from 'states/hooks'

import { CrewLink, LinkColor } from '../crewLink/crewLink'

type CrewRelatedItemLinkProps = {
  entityType: EntityType
  id: string
  className?: string
  color?: LinkColor
  targetChatMessageId?: string
}

export const CrewRelatedItemLink: FC<CrewRelatedItemLinkProps> = memo(
  (props) => {
    //TODO: 新着～ブックマークのリンクからだと正しく取得できない。下記タスクで対応する
    //      https://break-tmc.atlassian.net/browse/CREW-10988

    // get chatRoom EntityRecordId
    const chatRoomEntityRecordId = useAppSelector(
      (state) => state.message.chat.current.chatRoom?.entityRecordId
    )

    const getLookupRelatedItemParams =
      props.entityType && props.id
        ? {
            entityType: props.entityType,
            id: props.id,
          }
        : undefined

    const { data } = useGetLookupRelatedItemQuery(
      getLookupRelatedItemParams ?? skipToken
    )

    const renderLink = useMemo(() => {
      const itemId = data?.relatedItem?.id
      if (itemId === undefined) {
        return
      }

      const params = props.targetChatMessageId
        ? `?messageId=${props.targetChatMessageId}`
        : ''

      switch (props.entityType) {
        case EntityType.Project:
          if (data?.relatedItem?.type === ProjectType.DirectChannel) {
            return `/direct-channels/${itemId}/${getDefaultTabValue(
              DirectMessageTabs
            )}${params}`
          } else {
            return `/${EntityType.Project}/${itemId}/${getDefaultTabValue(
              ProjectListTabs
            )}${params}`
          }
        case EntityType.Task:
          return `/${EntityType.Task}/${itemId}/${getDefaultTabValue(
            TaskDetailListTabs
          )}${params}`
        case EntityType.Event:
          return `/${
            EntityType.Project
          }/${chatRoomEntityRecordId}/events/${itemId}/${getDefaultTabValue(
            EventDetailTabs
          )}${params}`
        case EntityType.File:
          return `/${EntityType.File}/${itemId}/${getDefaultTabValue(
            FileDetailListTabs
          )}${params}`
        default:
          return
      }
    }, [
      data?.relatedItem?.id,
      data?.relatedItem?.type,
      props.targetChatMessageId,
      props.entityType,
      chatRoomEntityRecordId,
    ])

    return renderLink ? (
      <CrewLink
        color={props.color}
        to={renderLink}
        title={data?.relatedItem?.name}
        className={classNames(props.className)}
      >
        {data?.relatedItem?.name}
      </CrewLink>
    ) : (
      <></>
    )
  }
)
