import { SettingKeyType } from '@crew/enums/app'
import { useValueChangeEffect } from '@crew/hooks'
import { useTranslation } from '@crew/modules/i18n'
import { useUserSetting } from '@crew/states'
import { skipToken } from '@reduxjs/toolkit/query'
import { PaginationState } from '@tanstack/react-table'

import { DEFAULT_PAGING_PARAMS, OPERATION_KEY } from 'configs/constants'
import { TaskSearchOptions } from 'enums/app'
import _ from 'lodash'
import qs from 'qs'
import { memo, useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from 'states/hooks'
import {
  createEventsGroupMap,
  getParamAsString,
  getParamsOperator,
} from 'utils'
import { eventCountUpdated } from '../../states/searchPageSlice'
import { GetEventsRequest } from '@crew/apis/dist/event/models/getEvents/request'
import { useGetEventsQuery } from '@crew/apis/dist/event/eventApis'
import { CrewPagination } from 'components/elements/crewPagination/crewPagination'
import { CrewEventItem } from 'components/elements/crewEventItem/crewEventItem'

export const SearchEventResultList = memo(() => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const params = qs.parse(searchParams.toString())

  const defaultListDisplayNumber = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  // Pagination params
  const pagination: PaginationState = useMemo(
    () => ({
      pageIndex: Number(
        getParamAsString('pageIndex', params) ?? DEFAULT_PAGING_PARAMS.pageIndex
      ),
      pageSize: Number(
        getParamAsString('pageSize', params) ?? defaultListDisplayNumber
      ),
    }),
    [defaultListDisplayNumber, params]
  )

  // Keyword params
  const keyword = useMemo(
    () => getParamAsString(TaskSearchOptions.Keyword.id, params),
    [params]
  )

  // Get keyword filter condition
  const keywordFilterCondition = useMemo(
    () => getParamsOperator(OPERATION_KEY, params),
    [params]
  )

  // Get events request
  const getEventsParams: GetEventsRequest | undefined = keyword
    ? {
        keyword,
        keywordFilterCondition,
        attendeeId: undefined,
        eventKindIds: undefined,
        eventCategoryIds: undefined,
        startDate: undefined,
        endDate: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        createdById: undefined,
        updatedById: undefined,
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      }
    : undefined

  const { data: getEventsResponse } = useGetEventsQuery(
    getEventsParams ?? skipToken
  )

  // create events group
  const eventsGroupMap = useMemo(() => {
    return createEventsGroupMap(getEventsResponse?.events ?? [])
  }, [getEventsResponse?.events])

  const totalCount = useMemo(
    () => getEventsResponse?.totalCount ?? 0,
    [getEventsResponse?.totalCount]
  )

  // update event badge count when total count of events change
  useValueChangeEffect(
    () => {
      dispatch(eventCountUpdated(totalCount))
    },
    [dispatch, totalCount],
    totalCount
  )

  // handle change pagination grid mode
  const handlePaginationGridChange = useCallback(
    (pageIndex: number, pageSize: number) => {
      const newParams = {
        ...params,
        pageIndex,
        pageSize,
      }

      // Do not navigate if params have not changed
      if (_.isEqual(params, newParams)) return

      const newQueryString = qs.stringify(newParams, {
        arrayFormat: 'repeat',
        skipNulls: true,
      })

      navigate(`?${newQueryString}`)
    },
    [navigate, params]
  )

  return (
    <div className="grow min-h-0 h-full w-full flex flex-col">
      <div className="flow-root p-2">
        <CrewPagination
          pageSize={pagination.pageSize}
          pageIndex={pagination.pageIndex}
          pageCount={Math.ceil(totalCount / pagination.pageSize)}
          onPaginationChange={handlePaginationGridChange}
        />

        <div className="flex flex-col gap-2.5">
          {Object.keys(eventsGroupMap).map((key) => {
            return (
              <div className="flex flex-col gap-2.5" key={key}>
                <div className="font-bold py-1 px-1.5 crew-bg-gray-1 rounded">
                  {t('format.yearMonthWithText', {
                    value: key,
                  })}
                </div>

                {eventsGroupMap[key].map((event) => (
                  <CrewEventItem
                    event={event}
                    key={event.id}
                    displayRelatedName={true}
                  />
                ))}
              </div>
            )
          })}
        </div>

        <CrewPagination
          pageSize={pagination.pageSize}
          pageIndex={pagination.pageIndex}
          pageCount={Math.ceil(totalCount / pagination.pageSize)}
          onPaginationChange={handlePaginationGridChange}
        />
      </div>
    </div>
  )
})
