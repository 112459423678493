import { useCallback } from 'react'
import { useMeetingManager } from 'modules/amazon-chime-sdk-component-library-devextreme'
import { useJoinMeetingMutation } from '@crew/apis/dist/meeting/meetingApis'
import { useAppDispatch } from 'states/hooks'
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js'
import { MeetingManagerJoinOptions } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/MeetingProvider/types'
import {
  setIsOrganizer,
  setIsLeaveMeeting,
  setJoinState,
} from 'features/webMeeting/states/webMeetingSlice'
import { showMeetingPanelUpdated } from 'features/project/components/eventDetailPage/states/eventDetailPageSlice'

export const useWebMeetingJoinForm = () => {
  const dispatch = useAppDispatch()

  const meetingManager = useMeetingManager()

  const [joinMeetingMutation] = useJoinMeetingMutation()

  // join meeting
  const joinMeeting = useCallback(
    async (displayName: string, eventId: string) => {
      const joinMeetingResponse = await joinMeetingMutation({
        displayName,
        eventId,
      }).unwrap()

      // save joinState to store
      dispatch(setJoinState(joinMeetingResponse.joinInfo.joinState))

      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        joinMeetingResponse.joinInfo.meeting,
        joinMeetingResponse.joinInfo.attendee
      )

      // VoiceFocusを使用可能にするためにミーティング接続時、オプションを指定
      const joinOptions: MeetingManagerJoinOptions = {
        enableWebAudio: true,
      }

      // Create a `MeetingSession` using `join()` function with the `MeetingSessionConfiguration`
      await meetingManager.join(meetingSessionConfiguration, joinOptions)

      // 開催者判定を設定
      dispatch(setIsOrganizer(joinMeetingResponse.joinInfo.isOrganizer))
    },
    [dispatch, joinMeetingMutation, meetingManager]
  )

  // start meeting
  const startMeeting = useCallback(async () => {
    // At this point you could let users setup their devices, or by default
    // the SDK will select the first device in the list for the kind indicated
    // by `deviceLabels` (the default value is DeviceLabels.AudioAndVideo)

    // Start the `MeetingSession` to join the meeting
    await meetingManager.start()

    // Web会議コンポーネントに切り替え
    dispatch(showMeetingPanelUpdated(true))
    // 会議の退室判定を初期化
    dispatch(setIsLeaveMeeting(false))
  }, [dispatch, meetingManager])

  return {
    joinMeeting,
    startMeeting,
  }
}
