import { memo } from 'react'
import { useTaskDetailWorkInputForm } from './useTaskDetailWorkInputForm'
import { DatePickerDateFormat } from 'enums/system'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewTextAreaField } from 'components/forms/crewTextAreaField'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useAppSelector } from 'states/hooks'
import { useGetTaskQuery, useGetTaskWorkQuery } from '@crew/apis/task/taskApis'
import dayjs from '@crew/modules/dayjs'
import { useToast } from 'hooks/useToast'
import { GetTaskRequest } from '@crew/apis/dist/task/models/getTask/request'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { GetTaskWorkRequest } from '@crew/apis/dist/task/models/getTaskWork/request'

import { FormValues } from './useTaskDetailWorkInputForm'
import { CrewDatePickerField } from 'components/forms/crewDatePickerField'
import { CrewTimePickerField } from 'components/forms/crewTimePickerField'
import ArrowRight from '~icons/material-symbols/arrow-right'
import {
  convertMinutesToHHMM,
  convertScheduledTimeToMinutes,
  generateItemsComboBoxActualProgressWorkingTime,
} from '@crew/utils'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { JsonDateFormat } from '@crew/enums/system'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { CrewComboBoxField } from 'components/forms/crewComboBoxField'

export type TaskDetailWorkInputFormProps = {
  isEditMode: boolean
  taskWorkId?: string
  version?: number
  onClose: () => void
}

export const TaskDetailWorkInputForm = memo(
  (props: TaskDetailWorkInputFormProps) => {
    const {
      control,
      reset,
      getValues,
      setValue,
      setError,
      clearErrors,
      formState,
      handleSubmit,

      validateRules,

      updateTaskWork,
      insertTaskWork,
      isLoadingInsertTaskWork,
      isLoadingUpdateTaskWork,
    } = useTaskDetailWorkInputForm()

    const { t } = useTranslation()
    const taskId = useAppSelector((state) => state.taskDetail.taskId)
    const toast = useToast()
    const [showApiErrors] = useShowApiErrorsWithForm(setError)
    const [remainingWorkTimes, setRemainingWorkTimes] = useState<number | null>(
      null
    )

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    // 実績時間テキストボックスの変更時（またはフォーカスアウト時）
    const handleActualWorkTimesInputChange = useCallback(() => {
      // actualWorkTimesは入力フォームの実績時間
      // remainingWorkTimesはタスク詳細（worksテーブル）の残時間

      const actualWorkTimes = convertScheduledTimeToMinutes(
        getValues('actualWorkTimes')
      )
      // 実績時間が未入力、または残時間がnull、または残時間が有る状態で実績時間が残時間より多い場合は残時間を0にする
      if (
        !actualWorkTimes ||
        !remainingWorkTimes ||
        (remainingWorkTimes && actualWorkTimes > remainingWorkTimes)
      ) {
        setValue('remainingWorkTimes', convertMinutesToHHMM(0))
        clearErrors('remainingWorkTimes')
        return
      }

      // 実績時間が入力済み、且つ残時間が有り、さらに実績時間が残時間より少ない場合に残時間を再計算
      if (
        actualWorkTimes &&
        remainingWorkTimes &&
        actualWorkTimes <= remainingWorkTimes
      ) {
        const timeRemaining = remainingWorkTimes - actualWorkTimes
        setValue('remainingWorkTimes', convertMinutesToHHMM(timeRemaining))
        clearErrors('remainingWorkTimes')

        return
      }
    }, [clearErrors, getValues, remainingWorkTimes, setValue])

    // 送信ボタン活性判定
    const canSend = useMemo(
      // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState]
    )

    // 登録ボタン押下時
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (data: FormValues) => {
        if (props.isEditMode) {
          if (!props.taskWorkId || !props.version) return

          // タスク作業編集
          try {
            // Execute update task work process
            await updateTaskWork(
              taskId as string,
              props.taskWorkId,
              props.version,
              data
            )

            // コメントブロックに戻す
            props.onClose()
            toast.success(t('message.taskComment.commentUpdated'))
          } catch (err) {
            showApiErrors(err)
          }
        } else {
          // タスク作業登録
          try {
            // Execute insert task work process
            await insertTaskWork(taskId as string, data)

            // 1行テキストに戻す
            props.onClose()
            toast.success(t('message.taskComment.commentRegistered'))
          } catch (err) {
            showApiErrors(err)
          }
        }
      }
      handleSubmit(onSubmit)()
    }, [
      handleSubmit,
      props,
      updateTaskWork,
      taskId,
      toast,
      t,
      insertTaskWork,
      showApiErrors,
    ])

    // タスク詳細を取得する
    // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
    const getTaskParam: GetTaskRequest | undefined = taskId
      ? {
          taskId: taskId,
        }
      : undefined
    const { data: getTaskResult } = useGetTaskQuery(getTaskParam ?? skipToken)

    // タスク作業編集時のデータを取得する
    // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
    const getTaskWorkParam: GetTaskWorkRequest | undefined = props.taskWorkId
      ? {
          taskWorkId: props.taskWorkId,
        }
      : undefined
    const { data: getTaskWorkResult } = useGetTaskWorkQuery(
      getTaskWorkParam ?? skipToken
    )

    // タスク作業入力フォームの初期値設定
    const initializeForm = useCallback(() => {
      if (props.isEditMode && props.taskWorkId) {
        // タスク作業データが取得できていたらフォームに反映する
        if (getTaskWorkResult?.taskWork) {
          setRemainingWorkTimes(getTaskWorkResult.taskWork.remainingWorkTimes)

          // dayjs().toDate() allays return local time
          // We need to convert the time to the user setting region time

          const workDate = new Date(
            dayjs(getTaskWorkResult.taskWork.workDate)
              .tz(String(defaultUserProfileRegion))
              .format(JsonDateFormat.YYYYMMDD)
          )

          const startTime = getTaskWorkResult.taskWork.startTime
            ? new Date(
                dayjs(getTaskWorkResult.taskWork.startTime)
                  .tz(String(defaultUserProfileRegion))
                  .format(JsonDateFormat.YYYYMMDDHHmmss)
              )
            : null

          const endTime = getTaskWorkResult.taskWork.endTime
            ? new Date(
                dayjs(getTaskWorkResult.taskWork.endTime)
                  .tz(String(defaultUserProfileRegion))
                  .format(JsonDateFormat.YYYYMMDDHHmmss)
              )
            : null

          reset({
            description: getTaskWorkResult.taskWork.description ?? '',
            workDate,
            startTime,
            endTime,
            actualWorkTimes: convertMinutesToHHMM(
              getTaskWorkResult.taskWork.actualWorkTimes
            ),
            remainingWorkTimes: convertMinutesToHHMM(
              getTaskWorkResult.taskWork.remainingWorkTimes
            ),
            actualProgress: getTaskWorkResult.taskWork.actualProgress,
          })
        }
      } else {
        // タスク詳細が取得できたらフォームに反映する
        if (getTaskResult?.task) {
          setRemainingWorkTimes(getTaskResult.task.remainingWorkTimes)

          const workDate = getTaskResult.task.startDate
            ? new Date(
                dayjs(getTaskResult.task.startDate)
                  .tz(String(defaultUserProfileRegion))
                  .format(JsonDateFormat.YYYYMMDDHHmm)
              )
            : new Date()

          reset({
            workDate,
            actualProgress: getTaskResult.task.actualProgress,
          })
        }
      }
    }, [
      defaultUserProfileRegion,
      getTaskResult?.task,
      getTaskWorkResult?.taskWork,
      props.isEditMode,
      props.taskWorkId,
      reset,
    ])

    // 初期化処理をuseEffect化
    // TODO: 本当はuseEffectを外したいのだが、レンダリングが大量に走ってしまうためこのようにしている。以下タスクで調査・対応予定
    // https://break-tmc.atlassian.net/browse/CREW-6028
    useEffect(() => {
      // フォーム初期化を実行
      initializeForm()
    }, [initializeForm])

    // 時間変更時の処理
    const handleTimeChange = useCallback(() => {
      const endTime = getValues('endTime')
      const startTime = getValues('startTime')

      if (!endTime || !startTime) return

      if (endTime >= startTime) {
        // actualWorkTime = endTime - startTime
        // div 1000*60 = 60000 to convert milliseconds to minutes
        const actualWorkTime =
          endTime.getTime() / 60000 - startTime.getTime() / 60000

        setValue('actualWorkTimes', convertMinutesToHHMM(actualWorkTime))
        clearErrors('actualWorkTimes')

        // 実績時間が変更されたので残時間を再計算
        handleActualWorkTimesInputChange()
      }
    }, [clearErrors, getValues, handleActualWorkTimesInputChange, setValue])

    return (
      <form className="flex flex-col gap-y-2.5">
        <div className="flex gap-2.5 flex-wrap items-start">
          {/* 作業日 */}
          <CrewDatePickerField
            id="workDate"
            name="workDate"
            control={control}
            labelMode="hidden"
            displayFormat={DatePickerDateFormat.YYYYMMDD}
            label={t('label.workDate')}
            rules={validateRules.workDate}
            required
            className="w-36"
          />

          <div className="flex flex-row items-start gap-x-0.5">
            {/* 開始時刻 */}
            <div className="w-28">
              <CrewTimePickerField
                id="startTime"
                name="startTime"
                control={control}
                label={t('label.startTime')}
                rules={validateRules.startTime}
                displayFormat="HH:mm"
                className="max-w-full"
                interval={30}
                onValueChanged={handleTimeChange}
              />
            </div>

            <ArrowRight width={24} height={24} className="mt-7" />

            {/* 終了時刻 */}
            <div className="w-28">
              <CrewTimePickerField
                id="endTime"
                name="endTime"
                control={control}
                label={t('label.endTime')}
                rules={validateRules.endTime}
                displayFormat="HH:mm"
                className="max-w-full"
                interval={30}
                onValueChanged={handleTimeChange}
              />
            </div>
          </div>

          <div className="w-24">
            <CrewTextBoxField
              id="actualWorkTimes"
              name="actualWorkTimes"
              control={control}
              labelMode="hidden"
              label={t('label.actualTime')}
              rules={validateRules.actualWorkTimes}
              onChange={handleActualWorkTimesInputChange}
              placeholder="hh:mm"
              className="max-w-full"
              required
            />
          </div>

          <div className="w-24">
            <CrewTextBoxField
              id="remainingWorkTimes"
              name="remainingWorkTimes"
              control={control}
              labelMode="hidden"
              label={t('label.remainingWorkTimes')}
              rules={validateRules.remainingWorkTimes}
              placeholder="hh:mm"
              className="max-w-full"
              required
            />
          </div>
          <div className="w-24">
            <CrewComboBoxField
              key="actualProgress"
              label={t('label.progressRate')}
              id="actualProgress"
              name="actualProgress"
              control={control}
              items={generateItemsComboBoxActualProgressWorkingTime()}
              unit="%"
              dataType="number"
              rules={validateRules.actualProgress}
              disabled={props.isEditMode}
            />
          </div>
        </div>

        {/* コメント */}
        <div className="crew-slim-toolbar-item">
          <CrewTextAreaField
            id="description"
            name="description"
            height="120px"
            control={control}
            rules={validateRules.description}
            label={t('label.comment')}
          />
        </div>
        <div className="flex justify-end gap-x-2.5 items-center">
          <CrewButton
            type="primary"
            text={t('action.register')}
            onClick={handleSubmitButtonClick}
            disabled={
              !canSend || isLoadingInsertTaskWork || isLoadingUpdateTaskWork
            }
          />
          <CrewButton
            type="normal"
            stylingMode="outlined"
            text={t('action.cancel')}
            onClick={props.onClose}
          />
        </div>
      </form>
    )
  }
)
