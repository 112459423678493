var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createEntityAdapter, createSelector, createSlice, } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetChatMessageQuery } from '@crew/apis/chat/chatApis';
import { AttentionDisplayGroup, ChatView, NewsMentionAndReplies, TimelineDirection, } from '@crew/enums/app';
import { EntityType, ProjectType, PubSubEventDataType, PubSubEventTargetType, } from '@crew/enums/domain';
import dayjs from '@crew/modules/dayjs';
import { arrayUniq, assertNever, dateComparer, itemComparer, ulidItemComparer, } from '@crew/utils';
import { compareUlid, ULID_ZERO } from '@crew/utils/ulid';
//**************************************************** types ***********************************************************
/**
 * チャット系の型を定義するnamespace
 */
export var Chat;
(function (Chat) {
    /**
     * チャットの表示形式
     */
    Chat.Style = {
        /**
         * 2ペイン表示
         */
        Full: 'full',
        /**
         * コンパクト表示
         */
        Compact: 'compact',
    };
    /**
     * TargetType
     */
    Chat.TargetType = {
        /**
         * チャットルーム
         */
        ChatRoom: 'chatRoom',
        /**
         * チャットスレッド
         */
        ChatThread: 'chatThread',
        /**
         * ユーザ
         */
        User: 'user',
    };
    /**
     * モード
     */
    Chat.Mode = {
        /**
         * チャット
         */
        Chat: 'chat',
        /**
         * 検索
         */
        Search: 'search',
    };
})(Chat || (Chat = {}));
/**
 * 未読系の型を定義するnamespace
 */
var Unread;
(function (Unread) {
    Unread.getInitialCount = () => ({
        total: 0,
        normal: 0,
        reply: 0,
        mentionToGroup: 0,
        mentionToMe: 0,
    });
})(Unread || (Unread = {}));
/**
 * 購読系の型を定義するnamespace
 */
export var Subscription;
(function (Subscription) {
    /**
     * subscribe状態
     */
    Subscription.State = {
        /**
         * 未サブスクライブ
         */
        notSubscribed: 'notSubscribed',
        /**
         * サブスクライブ要求中
         */
        subscribing: 'subscribing',
        /**
         * サブスクライブ済み
         */
        subscribed: 'subscribed',
    };
})(Subscription || (Subscription = {}));
//**************************************************** entity adapter ***********************************************************
const unreadChatInfoAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
});
const unreadAncestorDescendantsAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
});
const chatTimelineMessageAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: ulidItemComparer('asc'), // メッセージはulidの昇順でソートする必要があるため、sortComparerを設定する
});
const chatTimelineAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
});
const chatThreadListMessageAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: ulidItemComparer('asc'), // メッセージはulidの昇順でソートする必要があるため、sortComparerを設定する
});
const chatThreadListAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
});
const chatThreadMessageAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: ulidItemComparer('asc'), // メッセージはulidの昇順でソートする必要があるため、sortComparerを設定する
});
const chatThreadAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
});
const chatSearchMessageAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: ulidItemComparer('desc'), // メッセージはulidの降順でソートする必要があるため、sortComparerを設定する
});
const chatSearchAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
});
const chatDraftAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
});
const subscriptionHandlerAdapter = createEntityAdapter({
    selectId: (entity) => entity.messageType,
    // sortComparer は指定しない。messageTypeでソートする必要性は特にないため
});
const subscriptionChatInfoAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
});
const subscriptionPresenceInfoAdapter = createEntityAdapter({
    selectId: (entity) => entity.userId,
});
const feedAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: ulidItemComparer('desc'), // メッセージはulidの降順でソートする必要があるため、sortComparerを設定する
});
const attentionMessageAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: ulidItemComparer('desc'), // メッセージはulidの降順でソートする必要があるため、sortComparerを設定する
});
const bookmarkAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => {
        return dateComparer(dayjs(a.createdAt), dayjs(b.createdAt), 'desc');
    }, // ブックマークの登録日時の降順でソートする
});
const cacheMessageAdapter = createEntityAdapter({
    selectId: (message) => message.id,
});
// アクティブチャットルーム操作用
const activeChatRoomAdapter = createEntityAdapter({
    selectId: (entity) => entity.chatRoomId,
});
//**************************************************** slice ***********************************************************
const initialSliceState = {
    chat: {
        timeline: chatTimelineAdapter.getInitialState(),
        threadList: chatThreadListAdapter.getInitialState(),
        thread: chatThreadAdapter.getInitialState(),
        search: chatSearchAdapter.getInitialState(),
        current: {
            style: Chat.Style.Compact, // Full表示はプロジェクト詳細画面のみなのでデフォルトはコンパクト表示にしておく
            mode: Chat.Mode.Chat,
            displayFormat: undefined,
            chatRoom: undefined,
            chatThread: undefined,
        },
        draft: chatDraftAdapter.getInitialState(),
        cacheMessages: cacheMessageAdapter.getInitialState(),
    },
    unread: {
        chatRooms: unreadChatInfoAdapter.getInitialState(),
        chatThreads: unreadChatInfoAdapter.getInitialState(),
        ancestorDescendants: unreadAncestorDescendantsAdapter.getInitialState(),
    },
    subscription: {
        userMetaChannel: {
            subscribeState: Subscription.State.notSubscribed,
            handlers: subscriptionHandlerAdapter.getInitialState(),
        },
        userDataChannel: {
            subscribeState: Subscription.State.notSubscribed,
            handlers: subscriptionHandlerAdapter.getInitialState(),
        },
        chatRoomMetaChannels: subscriptionChatInfoAdapter.getInitialState(),
        chatRoomDataChannels: subscriptionChatInfoAdapter.getInitialState(),
        presenceMetaChannels: subscriptionPresenceInfoAdapter.getInitialState(),
    },
    feed: {
        messages: feedAdapter.getInitialState(),
        scrollToMessageId: undefined,
    },
    attention: {
        all: {
            messages: attentionMessageAdapter.getInitialState(),
            scrollToEntityRecordId: undefined,
        },
        mention: {
            messages: attentionMessageAdapter.getInitialState(),
            scrollToEntityRecordId: undefined,
        },
        reply: {
            messages: attentionMessageAdapter.getInitialState(),
            scrollToEntityRecordId: undefined,
        },
        reaction: {
            messages: attentionMessageAdapter.getInitialState(),
            scrollToEntityRecordId: undefined,
        },
    },
    bookmark: {
        messages: bookmarkAdapter.getInitialState(),
        scrollToMessageId: undefined,
        archiveFilter: 'unarchive', // ブックマーク画面はアーカイブされていないものをデフォルトで表示する
        keyword: undefined,
    },
    activeChatRoom: {
        chatRooms: activeChatRoomAdapter.getInitialState(),
    },
};
//**************************************************** utilities ***********************************************************/
/**
 * 2つ以上のTimelineMessagesをマージする
 * 重複しているアイテムはマージされ、時間軸上での隣接情報を最適値に更新する
 * @param direction マージするアイテムの時間軸上の方向
 * @param isLoadedOfEndOfSourceItems マージするアイテムの最後まで読み込んだかどうか
 * @param timelineMessages マージ対象のtimelineMessageの配列
 * @returns マージ後のtimelineMessageの配列
 */
const mergeTimelineMessages = (direction, isLoadedOfEndOfSourceItems, ...timelineMessages) => timelineMessages
    //一旦全部flatに並べて
    .flat()
    // id順でソートしたあと
    .sort(itemComparer('asc', 'id'))
    // 同じidのアイテムをマージする
    .reduce((merged, item) => {
    var _a, _b, _c;
    // マージ処理済の1つ前の要素を取り出して処理対象にする
    const prev = merged.pop();
    if (!prev) {
        // マージ済の要素がまだない(=最初の要素)時はそのままpush
        merged.push(item);
    }
    else {
        if (prev.id !== item.id) {
            // マージ済の1つ前の要素と違うID、マージせずどちらも残す
            merged.push(prev);
            merged.push(item);
        }
        else {
            // マージ済の1つ前の要素と同じID、マージして一つだけpush
            merged.push({
                id: item.id,
                chatRoomId: item.chatRoomId,
                topicId: (_a = prev.topicId) !== null && _a !== void 0 ? _a : item.topicId,
                prevId: (_b = prev.prevId) !== null && _b !== void 0 ? _b : item.prevId,
                nextId: (_c = prev.nextId) !== null && _c !== void 0 ? _c : item.nextId,
                hasMorePrev: prev.hasMorePrev && item.hasMorePrev, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                hasMoreNext: prev.hasMoreNext && item.hasMoreNext, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                createdById: item.createdById,
            });
        }
    }
    return merged;
}, [])
    // 最後に prevId とnextId を棚卸しする
    // ここまでで時系列順でマージした状態ではあるが、
    // 各アイテムのprevIdとnextIdはマージ前の状態のままなので、
    // prevIdとnextIdのつなぎ込みと、追加ロードが必要か（hasMoreNext,hasMorePrev）を更新する
    .map((item, index, items) => {
    if (items.length < 2) {
        // itemsが2未満の場合は前後比較が出来ないので何もしない
        return item;
    }
    // 処理対象のアイテムが最後のアイテムでない場合
    if (index !== items.length - 1) {
        // 今のアイテムは最後のアイテムでないので、一つ後のアイテムが比較対象になる
        const nextItem = items[index + 1];
        // 一つ後のアイテムのprevIdに有効な値が設定されている(prev方向にアイテムが存在している)場合
        if (nextItem.prevId) {
            // 後のアイテム(nextItem)曰く、時間軸上で連続している(item.id === nextItem.prevIdである)
            // 今のアイテムにも連続していると記録する
            item.nextId = nextItem.id;
            // next方向へ追加ロードが不要という状態にしておく(既にnextIdが存在しているため)
            item.hasMoreNext = false;
        }
        else if (!item.nextId) {
            // 処理中のアイテム(item)曰く、後ろは無し(=自分がnextの端)と記録されているが、
            // 実際は後のアイテムがあるので矛盾している（ここは最後のアイテムでない場合に走る処理なので）
            // 後のアイテム(nextItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と後のアイテム(nextItem)の間に抜けているアイテムがある可能性がある
            // よって、next方向へ追加ロードが必要という状態にしておく
            item.hasMoreNext = true;
        }
    }
    else {
        // 処理対象のアイテムが最後のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Newer ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなのでこれ以上新しい情報は存在しない=false
            item.hasMoreNext = false;
            item.nextId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    // 処理対象のアイテムが最初のアイテムでない場合
    if (index !== 0) {
        // 今のアイテムは最初のアイテムでないので、一つ前のアイテムが比較対象になる
        const prevItem = items[index - 1];
        // 一つ前のアイテムのnextIdに有効な値が設定されている(next方向にアイテムが存在している)場合
        if (prevItem.nextId) {
            // 前のアイテム曰く時間軸上で連続している。今のアイテムにも連続していると記録する
            // 前のアイテム(prevItem)曰く、時間軸上で連続している(item.id === prevItem.nextIdである)
            // 今のアイテムにも連続していると記録する
            item.prevId = prevItem.id;
            // prev方向へ追加ロードが不要という状態にしておく(既にprevIdが存在しているため)
            item.hasMorePrev = false;
        }
        else if (!item.prevId) {
            // 処理中のアイテム(item)曰く、前は無し(=自分がprevの端)と記録されているが、
            // 実際は前のアイテムがあるので矛盾している（ここは最初のアイテムでない場合に走る処理なので）
            // 前のアイテム(prevItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と前のアイテム(prevItem)の間に抜けているアイテムがある可能性がある
            // よって、prev方向へ追加ロードが必要という状態にしておく
            item.hasMorePrev = true;
        }
    }
    else {
        // 処理対象のアイテムが最初のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Older ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなのでこれ以上古い情報は存在しない=false
            item.hasMorePrev = false;
            item.prevId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    return item;
});
/**
 * 引数のチャットメッセージデータから、Sliceに格納するためのTimelineMessageを生成する関数
 * @param chatMessages チャットメッセージデータ
 * @param criterionMessageId メッセージ取得時の基準となったメッセージID（基準がない場合はundefined）
 * @param direction メッセージの取得方向
 * @param isLoadedOfEndOfSourceItems 最後まで取得したかどうか
 * @returns 生成したTimelineMessageの配列
 */
const createTimelineMessagesByParams = (chatMessages, criterionMessageId, direction, isLoadedOfEndOfSourceItems) => {
    if (chatMessages.length === 0 &&
        criterionMessageId &&
        isLoadedOfEndOfSourceItems) {
        return [];
    }
    return (Array.from(chatMessages) // sortは破壊的操作なので、引数を破壊しないよう最初にコピーする
        // チャットメッセージのIdの昇順でソート
        .sort(itemComparer('asc', 'id'))
        .map((item, index, sorted) => {
        var _a;
        // 前隣のアイテムIDを即時関数で判定する
        const prevId = (() => {
            if (index === 0) {
                // 最初の要素だけ特殊な処理が必要
                if (direction === TimelineDirection.Newer) {
                    // 取得方向が新しい方向なら前隣は取得の基準にしたアイテム
                    // 基準にしたアイテムがない場合は最古のアイテムなので前隣は存在しない=null
                    return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
                }
                else {
                    // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなので前隣は存在しない=null
                    // 最終アイテムまで取得していない場合は前隣は未確定=undefined
                    return isLoadedOfEndOfSourceItems ? null : undefined;
                }
            }
            // 最初の要素でない、前隣は一つ前の要素
            return sorted[index - 1].id;
        })();
        // 後隣のアイテムIDを即時関数で判定する
        const nextId = (() => {
            if (index !== sorted.length - 1) {
                // 最後の要素でない、後隣は一つ後の要素
                return sorted[index + 1].id;
            }
            // 最後の要素だけ特殊な処理が必要
            if (direction === TimelineDirection.Older) {
                // 取得方向が古い方向なら後隣は取得の基準にしたアイテム
                // 基準にしたアイテムがない場合は最新のアイテムなので後隣は存在しない=null
                return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
            }
            else {
                // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなので後隣は存在しない=null
                // 最終アイテムまで取得していない場合は後隣は未確定=undefined
                return isLoadedOfEndOfSourceItems ? null : undefined;
            }
        })();
        return {
            id: item.id,
            chatRoomId: item.chatRoomId,
            prevId: prevId !== null && prevId !== void 0 ? prevId : undefined,
            hasMorePrev: prevId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
            nextId: nextId !== null && nextId !== void 0 ? nextId : undefined,
            hasMoreNext: nextId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
            topicId: (_a = item.parentChatMessageId) !== null && _a !== void 0 ? _a : undefined,
            createdById: item.createdById,
        };
    }));
};
/**
 * 2つ以上のThreadListMessagesをマージする
 * 重複しているアイテムはマージされ、時間軸上での隣接情報を最適値に更新する
 * @param direction マージするアイテムの時間軸上の方向
 * @param isLoadedOfEndOfSourceItems マージするアイテムの最後まで読み込んだかどうか
 * @param threadListMessages マージ対象のthreadListMessageの配列
 * @returns マージ後のthreadListMessageの配列
 */
const mergeThreadListMessages = (direction, isLoadedOfEndOfSourceItems, ...threadListMessages) => threadListMessages
    //一旦全部flatに並べて
    .flat()
    // id順でソートしたあと
    .sort(itemComparer('asc', 'id'))
    // 同じidのアイテムをマージする
    .reduce((merged, item) => {
    var _a, _b, _c;
    // マージ処理済の1つ前の要素を取り出して処理対象にする
    const prev = merged.pop();
    if (!prev) {
        // マージ済の要素がまだない(=最初の要素)時はそのままpush
        merged.push(item);
    }
    else {
        if (prev.id !== item.id) {
            // マージ済の1つ前の要素と違うID、マージせずどちらも残す
            merged.push(prev);
            merged.push(item);
        }
        else {
            // マージ済の1つ前の要素と同じID、マージして一つだけpush
            merged.push({
                id: item.id,
                chatRoomId: item.chatRoomId,
                prevId: (_a = prev.prevId) !== null && _a !== void 0 ? _a : item.prevId,
                nextId: (_b = prev.nextId) !== null && _b !== void 0 ? _b : item.nextId,
                hasMorePrev: prev.hasMorePrev && item.hasMorePrev, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                hasMoreNext: prev.hasMoreNext && item.hasMoreNext, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                replyCount: (_c = prev.replyCount) !== null && _c !== void 0 ? _c : item.replyCount,
                createdById: item.createdById,
            });
        }
    }
    return merged;
}, [])
    // 最後に prevId とnextId を棚卸しする
    // ここまでで時系列順でマージした状態ではあるが、
    // 各アイテムのprevIdとnextIdはマージ前の状態のままなので、
    // prevIdとnextIdのつなぎ込みと、追加ロードが必要か（hasMoreNext,hasMorePrev）を更新する
    .map((item, index, items) => {
    if (items.length < 2) {
        // itemsが2未満の場合は前後比較が出来ないので何もしない
        return item;
    }
    // 処理対象のアイテムが最後のアイテムでない場合
    if (index !== items.length - 1) {
        // 今のアイテムは最後のアイテムでないので、一つ後のアイテムが比較対象になる
        const nextItem = items[index + 1];
        // 一つ後のアイテムのprevIdに有効な値が設定されている(prev方向にアイテムが存在している)場合
        if (nextItem.prevId) {
            // 後のアイテム(nextItem)曰く、時間軸上で連続している(item.id === nextItem.prevIdである)
            // 今のアイテムにも連続していると記録する
            item.nextId = nextItem.id;
            // next方向へ追加ロードが不要という状態にしておく(既にnextIdが存在しているため)
            item.hasMoreNext = false;
        }
        else if (!item.nextId) {
            // 処理中のアイテム(item)曰く、後ろは無し(=自分がnextの端)と記録されているが、
            // 実際は後のアイテムがあるので矛盾している（ここは最後のアイテムでない場合に走る処理なので）
            // 後のアイテム(nextItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と後のアイテム(nextItem)の間に抜けているアイテムがある可能性がある
            // よって、next方向へ追加ロードが必要という状態にしておく
            item.hasMoreNext = true;
        }
    }
    else {
        // 処理対象のアイテムが最後のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Newer ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなのでこれ以上新しい情報は存在しない=false
            item.hasMoreNext = false;
            item.nextId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    // 処理対象のアイテムが最初のアイテムでない場合
    if (index !== 0) {
        // 今のアイテムは最初のアイテムでないので、一つ前のアイテムが比較対象になる
        const prevItem = items[index - 1];
        // 一つ前のアイテムのnextIdに有効な値が設定されている(next方向にアイテムが存在している)場合
        if (prevItem.nextId) {
            // 前のアイテム曰く時間軸上で連続している。今のアイテムにも連続していると記録する
            // 前のアイテム(prevItem)曰く、時間軸上で連続している(item.id === prevItem.nextIdである)
            // 今のアイテムにも連続していると記録する
            item.prevId = prevItem.id;
            // prev方向へ追加ロードが不要という状態にしておく(既にprevIdが存在しているため)
            item.hasMorePrev = false;
        }
        else if (!item.prevId) {
            // 処理中のアイテム(item)曰く、前は無し(=自分がprevの端)と記録されているが、
            // 実際は前のアイテムがあるので矛盾している（ここは最初のアイテムでない場合に走る処理なので）
            // 前のアイテム(prevItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と前のアイテム(prevItem)の間に抜けているアイテムがある可能性がある
            // よって、prev方向へ追加ロードが必要という状態にしておく
            item.hasMorePrev = true;
        }
    }
    else {
        // 処理対象のアイテムが最初のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Older ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなのでこれ以上古い情報は存在しない=false
            item.hasMorePrev = false;
            item.prevId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    return item;
});
/**
 * 引数のチャットメッセージデータから、Sliceに格納するためのThreadListMessageを生成する関数
 * @param chatMessages チャットメッセージデータ
 * @param criterionMessageId メッセージ取得時の基準となったメッセージID（基準がない場合はundefined）
 * @param direction メッセージの取得方向
 * @param isLoadedOfEndOfSourceItems 最後まで取得したかどうか
 * @returns 生成したThreadListMessageの配列
 */
const createThreadListMessagesByParams = (chatMessages, criterionMessageId, direction, isLoadedOfEndOfSourceItems) => {
    if (chatMessages.length === 0 &&
        criterionMessageId &&
        isLoadedOfEndOfSourceItems) {
        return [];
    }
    return (Array.from(chatMessages) // sortは破壊的操作なので、引数を破壊しないよう最初にコピーする
        // チャットメッセージのIdの昇順でソート
        .sort(itemComparer('asc', 'id'))
        .map((item, index, sorted) => {
        // 前隣のアイテムIDを即時関数で判定する
        const prevId = (() => {
            if (index === 0) {
                // 最初の要素だけ特殊な処理が必要
                if (direction === TimelineDirection.Newer) {
                    // 取得方向が新しい方向なら前隣は取得の基準にしたアイテム
                    // 基準にしたアイテムがない場合は最古のアイテムなので前隣は存在しない=null
                    return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
                }
                else {
                    // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなので前隣は存在しない=null
                    // 最終アイテムまで取得していない場合は前隣は未確定=undefined
                    return isLoadedOfEndOfSourceItems ? null : undefined;
                }
            }
            // 最初の要素でない、前隣は一つ前の要素
            return sorted[index - 1].id;
        })();
        // 後隣のアイテムIDを即時関数で判定する
        const nextId = (() => {
            if (index !== sorted.length - 1) {
                // 最後の要素でない、後隣は一つ後の要素
                return sorted[index + 1].id;
            }
            // 最後の要素だけ特殊な処理が必要
            if (direction === TimelineDirection.Older) {
                // 取得方向が古い方向なら後隣は取得の基準にしたアイテム
                // 基準にしたアイテムがない場合は最新のアイテムなので後隣は存在しない=null
                return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
            }
            else {
                // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなので後隣は存在しない=null
                // 最終アイテムまで取得していない場合は後隣は未確定=undefined
                return isLoadedOfEndOfSourceItems ? null : undefined;
            }
        })();
        return {
            id: item.id,
            chatRoomId: item.chatRoomId,
            prevId: prevId !== null && prevId !== void 0 ? prevId : undefined,
            hasMorePrev: prevId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
            nextId: nextId !== null && nextId !== void 0 ? nextId : undefined,
            hasMoreNext: nextId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
            replyCount: item.replyCount,
            createdById: item.createdById,
        };
    }));
};
/**
 * 2つ以上のThreadMessagesをマージする
 * 重複しているアイテムはマージされ、時間軸上での隣接情報を最適値に更新する
 * @param direction マージするアイテムの時間軸上の方向
 * @param isLoadedOfEndOfSourceItems マージするアイテムの最後まで読み込んだかどうか
 * @param threadMessages マージ対象のthreadMessageの配列
 * @returns マージ後のthreadMessageの配列
 */
const mergeThreadMessages = (direction, isLoadedOfEndOfSourceItems, ...threadMessages) => threadMessages
    //一旦全部flatに並べて
    .flat()
    // id順でソートしたあと
    .sort(itemComparer('asc', 'id'))
    // 同じidのアイテムをマージする
    .reduce((merged, item) => {
    var _a, _b;
    // マージ処理済の1つ前の要素を取り出して処理対象にする
    const prev = merged.pop();
    if (!prev) {
        // マージ済の要素がまだない(=最初の要素)時はそのままpush
        merged.push(item);
    }
    else {
        if (prev.id !== item.id) {
            // マージ済の1つ前の要素と違うID、マージせずどちらも残す
            merged.push(prev);
            merged.push(item);
        }
        else {
            // マージ済の1つ前の要素と同じID、マージして一つだけpush
            merged.push({
                id: item.id,
                prevId: (_a = prev.prevId) !== null && _a !== void 0 ? _a : item.prevId,
                nextId: (_b = prev.nextId) !== null && _b !== void 0 ? _b : item.nextId,
                hasMorePrev: prev.hasMorePrev && item.hasMorePrev, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                hasMoreNext: prev.hasMoreNext && item.hasMoreNext, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                createdById: item.createdById,
            });
        }
    }
    return merged;
}, [])
    // 最後に prevId とnextId を棚卸しする
    // ここまでで時系列順でマージした状態ではあるが、
    // 各アイテムのprevIdとnextIdはマージ前の状態のままなので、
    // prevIdとnextIdのつなぎ込みと、追加ロードが必要か（hasMoreNext,hasMorePrev）を更新する
    .map((item, index, items) => {
    if (items.length < 2) {
        // itemsが2未満の場合は前後比較が出来ないので何もしない
        return item;
    }
    // 処理対象のアイテムが最後のアイテムでない場合
    if (index !== items.length - 1) {
        // 今のアイテムは最後のアイテムでないので、一つ後のアイテムが比較対象になる
        const nextItem = items[index + 1];
        // 一つ後のアイテムのprevIdに有効な値が設定されている(prev方向にアイテムが存在している)場合
        if (nextItem.prevId) {
            // 後のアイテム(nextItem)曰く、時間軸上で連続している(item.id === nextItem.prevIdである)
            // 今のアイテムにも連続していると記録する
            item.nextId = nextItem.id;
            // next方向へ追加ロードが不要という状態にしておく(既にnextIdが存在しているため)
            item.hasMoreNext = false;
        }
        else if (!item.nextId) {
            // 処理中のアイテム(item)曰く、後ろは無し(=自分がnextの端)と記録されているが、
            // 実際は後のアイテムがあるので矛盾している（ここは最後のアイテムでない場合に走る処理なので）
            // 後のアイテム(nextItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と後のアイテム(nextItem)の間に抜けているアイテムがある可能性がある
            // よって、next方向へ追加ロードが必要という状態にしておく
            item.hasMoreNext = true;
        }
    }
    else {
        // 処理対象のアイテムが最後のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Newer ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなのでこれ以上新しい情報は存在しない=false
            item.hasMoreNext = false;
            item.nextId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    // 処理対象のアイテムが最初のアイテムでない場合
    if (index !== 0) {
        // 今のアイテムは最初のアイテムでないので、一つ前のアイテムが比較対象になる
        const prevItem = items[index - 1];
        // 一つ前のアイテムのnextIdに有効な値が設定されている(next方向にアイテムが存在している)場合
        if (prevItem.nextId) {
            // 前のアイテム曰く時間軸上で連続している。今のアイテムにも連続していると記録する
            // 前のアイテム(prevItem)曰く、時間軸上で連続している(item.id === prevItem.nextIdである)
            // 今のアイテムにも連続していると記録する
            item.prevId = prevItem.id;
            // prev方向へ追加ロードが不要という状態にしておく(既にprevIdが存在しているため)
            item.hasMorePrev = false;
        }
        else if (!item.prevId) {
            // 処理中のアイテム(item)曰く、前は無し(=自分がprevの端)と記録されているが、
            // 実際は前のアイテムがあるので矛盾している（ここは最初のアイテムでない場合に走る処理なので）
            // 前のアイテム(prevItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と前のアイテム(prevItem)の間に抜けているアイテムがある可能性がある
            // よって、prev方向へ追加ロードが必要という状態にしておく
            item.hasMorePrev = true;
        }
    }
    else {
        // 処理対象のアイテムが最初のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Older ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなのでこれ以上古い情報は存在しない=false
            item.hasMorePrev = false;
            item.prevId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    return item;
});
/**
 * 引数のチャットメッセージデータから、Sliceに格納するためのThreadMessageを生成する関数
 * @param chatMessages チャットメッセージデータ
 * @param criterionMessageId メッセージ取得時の基準となったメッセージID（基準がない場合はundefined）
 * @param direction メッセージの取得方向
 * @param isLoadedOfEndOfSourceItems 最後まで取得したかどうか
 * @returns 生成したThreadMessageの配列
 */
const createThreadMessagesByParams = (chatMessages, criterionMessageId, direction, isLoadedOfEndOfSourceItems) => {
    if (chatMessages.length === 0 &&
        criterionMessageId &&
        isLoadedOfEndOfSourceItems) {
        return [];
    }
    return (Array.from(chatMessages) // sortは破壊的操作なので、引数を破壊しないよう最初にコピーする
        // チャットメッセージのIdの昇順でソート
        .sort(itemComparer('asc', 'id'))
        .map((item, index, sorted) => {
        // 前隣のアイテムIDを即時関数で判定する
        const prevId = (() => {
            if (index === 0) {
                // 最初の要素だけ特殊な処理が必要
                if (direction === TimelineDirection.Newer) {
                    // 取得方向が新しい方向なら前隣は取得の基準にしたアイテム
                    // 基準にしたアイテムがない場合は最古のアイテムなので前隣は存在しない=null
                    return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
                }
                else {
                    // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなので前隣は存在しない=null
                    // 最終アイテムまで取得していない場合は前隣は未確定=undefined
                    return isLoadedOfEndOfSourceItems ? null : undefined;
                }
            }
            // 最初の要素でない、前隣は一つ前の要素
            return sorted[index - 1].id;
        })();
        // 後隣のアイテムIDを即時関数で判定する
        const nextId = (() => {
            if (index !== sorted.length - 1) {
                // 最後の要素でない、後隣は一つ後の要素
                return sorted[index + 1].id;
            }
            // 最後の要素だけ特殊な処理が必要
            if (direction === TimelineDirection.Older) {
                // 取得方向が古い方向なら後隣は取得の基準にしたアイテム
                // 基準にしたアイテムがない場合は最新のアイテムなので後隣は存在しない=null
                return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
            }
            else {
                // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなので後隣は存在しない=null
                // 最終アイテムまで取得していない場合は後隣は未確定=undefined
                return isLoadedOfEndOfSourceItems ? null : undefined;
            }
        })();
        return {
            id: item.id,
            prevId: prevId !== null && prevId !== void 0 ? prevId : undefined,
            hasMorePrev: prevId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
            nextId: nextId !== null && nextId !== void 0 ? nextId : undefined,
            hasMoreNext: nextId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
            createdById: item.createdById,
        };
    }));
};
/**
 * 2つ以上のSearchMessagesをマージする
 * 重複しているアイテムはマージされ、時間軸上での隣接情報を最適値に更新する
 * @param searchMessages マージ対象のsearchMessageの配列
 * @returns マージ後のsearchMessageの配列
 */
const mergeSearchMessages = (direction, isLoadedOfEndOfSourceItems, ...searchMessages) => searchMessages
    // 一旦全部flatに並べて
    .flat()
    // id順でソートしたあと
    .sort(itemComparer('asc', 'id'))
    // 同じidのアイテムをマージする
    .reduce((merged, item) => {
    var _a, _b;
    // マージ処理済の1つ前の要素を取り出して処理対象にする
    const prev = merged.pop();
    if (!prev) {
        // マージ済の要素がまだない(=最初の要素)時はそのままpush
        merged.push(item);
    }
    else {
        if (prev.id !== item.id) {
            // マージ済の1つ前の要素と違うID、マージせずどちらも残す
            merged.push(prev);
            merged.push(item);
        }
        else {
            // マージ済の1つ前の要素と同じID、マージして一つだけpush
            merged.push({
                id: item.id,
                prevId: (_a = prev.prevId) !== null && _a !== void 0 ? _a : item.prevId,
                nextId: (_b = prev.nextId) !== null && _b !== void 0 ? _b : item.nextId,
                hasMorePrev: prev.hasMorePrev && item.hasMorePrev, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                hasMoreNext: prev.hasMoreNext && item.hasMoreNext, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
            });
        }
    }
    return merged;
}, [])
    // 最後に prevId と nextId を棚卸しする
    // ここまでで時系列順でマージした状態ではあるが、
    // 各アイテムのprevIdとnextIdはマージ前の状態のままなので、
    // prevIdとnextIdのつなぎ込みと、追加ロードが必要か（hasMoreNext,hasMorePrev）を更新する
    .map((item, index, items) => {
    if (items.length < 2) {
        // itemsが2未満の場合は前後比較が出来ないので何もしない
        return item;
    }
    // 処理対象のアイテムが最後のアイテムでない場合
    if (index !== items.length - 1) {
        // 今のアイテムは最後のアイテムでないので、一つ後のアイテムが比較対象になる
        const nextItem = items[index + 1];
        // 一つ後のアイテムのprevIdに有効な値が設定されている(prev方向にアイテムが存在している)場合
        if (nextItem.prevId) {
            // 後のアイテム(nextItem)曰く、時間軸上で連続している(item.id === nextItem.prevIdである)
            // 今のアイテムにも連続していると記録する
            item.nextId = nextItem.id;
            // next方向へ追加ロードが不要という状態にしておく(既にnextIdが存在しているため)
            item.hasMoreNext = false;
        }
        else if (!item.nextId) {
            // 処理中のアイテム(item)曰く、後ろは無し(=自分がnextの端)と記録されているが、
            // 実際は後のアイテムがあるので矛盾している（ここは最後のアイテムでない場合に走る処理なので）
            // 後のアイテム(nextItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と後のアイテム(nextItem)の間に抜けているアイテムがある可能性がある
            // よって、next方向へ追加ロードが必要という状態にしておく
            item.hasMoreNext = true;
        }
    }
    else {
        // 処理対象のアイテムが最後のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Newer ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなのでこれ以上新しい情報は存在しない=false
            item.hasMoreNext = false;
            item.nextId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    // 処理対象のアイテムが最初のアイテムでない場合
    if (index !== 0) {
        // 今のアイテムは最初のアイテムでないので、一つ前のアイテムが比較対象になる
        const prevItem = items[index - 1];
        // 一つ前のアイテムのnextIdに有効な値が設定されている(next方向にアイテムが存在している)場合
        if (prevItem.nextId) {
            // 前のアイテム曰く時間軸上で連続している。今のアイテムにも連続していると記録する
            // 前のアイテム(prevItem)曰く、時間軸上で連続している(item.id === prevItem.nextIdである)
            // 今のアイテムにも連続していると記録する
            item.prevId = prevItem.id;
            // prev方向へ追加ロードが不要という状態にしておく(既にprevIdが存在しているため)
            item.hasMorePrev = false;
        }
        else if (!item.prevId) {
            // 処理中のアイテム(item)曰く、前は無し(=自分がprevの端)と記録されているが、
            // 実際は前のアイテムがあるので矛盾している（ここは最初のアイテムでない場合に走る処理なので）
            // 前のアイテム(prevItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と前のアイテム(prevItem)の間に抜けているアイテムがある可能性がある
            // よって、prev方向へ追加ロードが必要という状態にしておく
            item.hasMorePrev = true;
        }
    }
    else {
        // 処理対象のアイテムが最初のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Older ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなのでこれ以上古い情報は存在しない=false
            item.hasMorePrev = false;
            item.prevId = undefined;
        }
    }
    return item;
});
/**
 * 引数のチャットメッセージデータから、Sliceに格納するためのSearchMessageを生成する関数
 * @param chatMessages チャットメッセージデータ
 * @param criterionMessageId メッセージ取得時の基準となったメッセージID（基準がない場合はundefined）
 * @param direction メッセージの取得方向
 * @param isLoadedOfEndOfSourceItems 最後まで取得したかどうか
 * @returns 生成したSearchMessageの配列
 */
const createSearchMessagesByParams = (chatMessages, criterionMessageId, direction, isLoadedOfEndOfSourceItems) => {
    if (chatMessages.length === 0 &&
        criterionMessageId &&
        isLoadedOfEndOfSourceItems) {
        return [];
    }
    return (Array.from(chatMessages) // sortは破壊的操作なので、引数を破壊しないよう最初にコピーする
        // チャットメッセージのIdの昇順でソート
        .sort(itemComparer('asc', 'id'))
        .map((item, index, sorted) => {
        // 前隣のアイテムIDを即時関数で判定する
        const prevId = (() => {
            if (index === 0) {
                // 最初の要素だけ特殊な処理が必要
                if (direction === TimelineDirection.Newer) {
                    // 取得方向が新しい方向なら前隣は取得の基準にしたアイテム
                    // 基準にしたアイテムがない場合は最古のアイテムなので前隣は存在しない=null
                    return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
                }
                else {
                    // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなので前隣は存在しない=null
                    // 最終アイテムまで取得していない場合は前隣は未確定=undefined
                    return isLoadedOfEndOfSourceItems ? null : undefined;
                }
            }
            // 最初の要素でない、前隣は一つ前の要素
            return sorted[index - 1].id;
        })();
        // 後隣のアイテムIDを即時関数で判定する
        const nextId = (() => {
            if (index !== sorted.length - 1) {
                // 最後の要素でない、後隣は一つ後の要素
                return sorted[index + 1].id;
            }
            // 最後の要素だけ特殊な処理が必要
            if (direction === TimelineDirection.Older) {
                // 取得方向が古い方向なら後隣は取得の基準にしたアイテム
                // 基準にしたアイテムがない場合は最新のアイテムなので後隣は存在しない=null
                return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
            }
            else {
                // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなので後隣は存在しない=null
                // 最終アイテムまで取得していない場合は後隣は未確定=undefined
                return isLoadedOfEndOfSourceItems ? null : undefined;
            }
        })();
        return {
            id: item.id,
            prevId: prevId !== null && prevId !== void 0 ? prevId : undefined,
            hasMorePrev: prevId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
            nextId: nextId !== null && nextId !== void 0 ? nextId : undefined,
            hasMoreNext: nextId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
        };
    }));
};
/**
 * 2つ以上のFeedMessagesをマージする
 * 重複しているアイテムはマージされ、時間軸上での隣接情報を最適値に更新する
 * @param feedMessages マージ対象のfeedMessageの配列
 * @returns マージ後のfeedMessageの配列
 */
const mergeFeedMessages = (direction, isLoadedOfEndOfSourceItems, ...feedMessages) => feedMessages
    // 一旦全部flatに並べて
    .flat()
    // id順でソートしたあと
    .sort(itemComparer('asc', 'id'))
    // 同じidのアイテムをマージする
    .reduce((merged, item) => {
    var _a, _b;
    // マージ処理済の1つ前の要素を取り出して処理対象にする
    const prev = merged.pop();
    if (!prev) {
        // マージ済の要素がまだない(=最初の要素)時はそのままpush
        merged.push(item);
    }
    else {
        if (prev.id !== item.id) {
            // マージ済の1つ前の要素と違うID、マージせずどちらも残す
            merged.push(prev);
            merged.push(item);
        }
        else {
            // マージ済の1つ前の要素と同じID、マージして一つだけpush
            merged.push({
                id: item.id,
                chatMessageId: item.chatMessageId,
                prevId: (_a = prev.prevId) !== null && _a !== void 0 ? _a : item.prevId,
                nextId: (_b = prev.nextId) !== null && _b !== void 0 ? _b : item.nextId,
                hasMorePrev: prev.hasMorePrev && item.hasMorePrev, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                hasMoreNext: prev.hasMoreNext && item.hasMoreNext, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
            });
        }
    }
    return merged;
}, [])
    // 最後に prevId とnextId を棚卸しする
    // ここまでで時系列順でマージした状態ではあるが、
    // 各アイテムのprevIdとnextIdはマージ前の状態のままなので、
    // prevIdとnextIdのつなぎ込みと、追加ロードが必要か（hasMoreNext,hasMorePrev）を更新する
    .map((item, index, items) => {
    if (items.length < 2) {
        // itemsが2未満の場合は前後比較が出来ないので何もしない
        return item;
    }
    // 処理対象のアイテムが最後のアイテムでない場合
    if (index !== items.length - 1) {
        // 今のアイテムは最後のアイテムでないので、一つ後のアイテムが比較対象になる
        const nextItem = items[index + 1];
        // 一つ後のアイテムのprevIdに有効な値が設定されている(prev方向にアイテムが存在している)場合
        if (nextItem.prevId) {
            // 後のアイテム(nextItem)曰く、時間軸上で連続している(item.id === nextItem.prevIdである)
            // 今のアイテムにも連続していると記録する
            item.nextId = nextItem.id;
            // next方向へ追加ロードが不要という状態にしておく(既にnextIdが存在しているため)
            item.hasMoreNext = false;
        }
        else if (!item.nextId) {
            // 処理中のアイテム(item)曰く、後ろは無し(=自分がnextの端)と記録されているが、
            // 実際は後のアイテムがあるので矛盾している（ここは最後のアイテムでない場合に走る処理なので）
            // 後のアイテム(nextItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と後のアイテム(nextItem)の間に抜けているアイテムがある可能性がある
            // よって、next方向へ追加ロードが必要という状態にしておく
            item.hasMoreNext = true;
        }
    }
    else {
        // 処理対象のアイテムが最後のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Newer ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなのでこれ以上新しい情報は存在しない=false
            item.hasMoreNext = false;
            item.nextId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    // 処理対象のアイテムが最初のアイテムでない場合
    if (index !== 0) {
        // 今のアイテムは最初のアイテムでないので、一つ前のアイテムが比較対象になる
        const prevItem = items[index - 1];
        // 一つ前のアイテムのnextIdに有効な値が設定されている(next方向にアイテムが存在している)場合
        if (prevItem.nextId) {
            // 前のアイテム曰く時間軸上で連続している。今のアイテムにも連続していると記録する
            // 前のアイテム(prevItem)曰く、時間軸上で連続している(item.id === prevItem.nextIdである)
            // 今のアイテムにも連続していると記録する
            item.prevId = prevItem.id;
            // prev方向へ追加ロードが不要という状態にしておく(既にprevIdが存在しているため)
            item.hasMorePrev = false;
        }
        else if (!item.prevId) {
            // 処理中のアイテム(item)曰く、前は無し(=自分がprevの端)と記録されているが、
            // 実際は前のアイテムがあるので矛盾している（ここは最初のアイテムでない場合に走る処理なので）
            // 前のアイテム(prevItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と前のアイテム(prevItem)の間に抜けているアイテムがある可能性がある
            // よって、prev方向へ追加ロードが必要という状態にしておく
            item.hasMorePrev = true;
        }
    }
    else {
        // 処理対象のアイテムが最初のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Older ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなのでこれ以上古い情報は存在しない=false
            item.hasMorePrev = false;
            item.prevId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    return item;
});
/**
 * 引数のチャットメッセージデータから、Sliceに格納するためのFeedMessageを生成する関数
 * @param chatMessages チャットメッセージデータ
 * @param criterionMessageId メッセージ取得時の基準となったメッセージID（基準がない場合はundefined）
 * @param direction メッセージの取得方向
 * @param isLoadedOfEndOfSourceItems 最後まで取得したかどうか
 * @returns 生成したFeedMessageの配列
 */
const createFeedMessagesByParams = (chatMessages, criterionMessageId, direction, isLoadedOfEndOfSourceItems) => {
    if (chatMessages.length === 0 &&
        criterionMessageId &&
        isLoadedOfEndOfSourceItems) {
        return [];
    }
    return (Array.from(chatMessages) // sortは破壊的操作なので、引数を破壊しないよう最初にコピーする
        // チャットメッセージのIdの昇順でソート
        .sort(itemComparer('asc', 'id'))
        .map((item, index, sorted) => {
        // 前隣のアイテムIDを即時関数で判定する
        const prevId = (() => {
            if (index === 0) {
                // 最初の要素だけ特殊な処理が必要
                if (direction === TimelineDirection.Newer) {
                    // 取得方向が新しい方向なら前隣は取得の基準にしたアイテム
                    // 基準にしたアイテムがない場合は最古のアイテムなので前隣は存在しない=null
                    return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
                }
                else {
                    // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなので前隣は存在しない=null
                    // 最終アイテムまで取得していない場合は前隣は未確定=undefined
                    return isLoadedOfEndOfSourceItems ? null : undefined;
                }
            }
            // 最初の要素でない、前隣は一つ前の要素
            return sorted[index - 1].id;
        })();
        // 後隣のアイテムIDを即時関数で判定する
        const nextId = (() => {
            if (index !== sorted.length - 1) {
                // 最後の要素でない、後隣は一つ後の要素
                return sorted[index + 1].id;
            }
            // 最後の要素だけ特殊な処理が必要
            if (direction === TimelineDirection.Older) {
                // 取得方向が古い方向なら後隣は取得の基準にしたアイテム
                // 基準にしたアイテムがない場合は最新のアイテムなので後隣は存在しない=null
                return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
            }
            else {
                // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなので後隣は存在しない=null
                // 最終アイテムまで取得していない場合は後隣は未確定=undefined
                return isLoadedOfEndOfSourceItems ? null : undefined;
            }
        })();
        return {
            id: item.id, // フィードID（現状はチャットメッセージIDと同じ）
            chatMessageId: item.id, // チャットメッセージID
            prevId: prevId !== null && prevId !== void 0 ? prevId : undefined,
            hasMorePrev: prevId === undefined, // 前隣のアイテムが存在しない(即時関数の結果がundefinedである)場合、追加ロードを行うようにする
            nextId: nextId !== null && nextId !== void 0 ? nextId : undefined,
            hasMoreNext: nextId === undefined, // 後隣のアイテムが存在しない(即時関数の結果がundefinedである)場合、追加ロードを行うようにする
            topicId: item.parentChatMessageId,
        };
    }));
};
/**
 * 2つ以上のAttentionをマージする
 * 重複しているアイテムはマージされ、時間軸上での隣接情報を最適値に更新する
 * @param attentions マージ対象のAttentionの配列
 * @returns マージ後のAttentionの配列
 */
const mergeAttentions = (direction, isLoadedOfEndOfSourceItems, ...attentions) => attentions
    // 一旦全部flatに並べて
    .flat()
    // id順でソートしたあと
    .sort(itemComparer('asc', 'id'))
    // 同じidのアイテムをマージする
    .reduce((merged, item) => {
    var _a, _b, _c, _d, _e;
    // マージ処理済の1つ前の要素を取り出して処理対象にする
    const prev = merged.pop();
    if (!prev) {
        // マージ済の要素がまだない(=最初の要素)時はそのままpush
        merged.push(item);
    }
    else {
        if (prev.id !== item.id) {
            // マージ済の1つ前の要素と違うID、マージせずどちらも残す
            merged.push(prev);
            merged.push(item);
        }
        else {
            // マージ済の1つ前の要素と同じID、マージして一つだけpush
            merged.push({
                id: item.id,
                attentionType: (_a = prev.attentionType) !== null && _a !== void 0 ? _a : item.attentionType,
                attentionUserName: (_b = prev.attentionUserName) !== null && _b !== void 0 ? _b : item.attentionUserName,
                chatMessageId: (_c = prev.chatMessageId) !== null && _c !== void 0 ? _c : item.chatMessageId,
                prevId: (_d = prev.prevId) !== null && _d !== void 0 ? _d : item.prevId,
                nextId: (_e = prev.nextId) !== null && _e !== void 0 ? _e : item.nextId,
                hasMorePrev: prev.hasMorePrev && item.hasMorePrev, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                hasMoreNext: prev.hasMoreNext && item.hasMoreNext, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
            });
        }
    }
    return merged;
}, [])
    // 最後に prevId と nextId を棚卸しする
    // ここまでで時系列順でマージした状態ではあるが、
    // 各アイテムのprevIdとnextIdはマージ前の状態のままなので、
    // prevIdとnextIdのつなぎ込みと、追加ロードが必要か（hasMoreNext,hasMorePrev）を更新する
    .map((item, index, items) => {
    if (items.length < 2) {
        // itemsが2未満の場合は前後比較が出来ないので何もしない
        return item;
    }
    // 処理対象のアイテムが最後のアイテムでない場合
    if (index !== items.length - 1) {
        // 今のアイテムは最後のアイテムでないので、一つ後のアイテムが比較対象になる
        const nextItem = items[index + 1];
        // 一つ後のアイテムのprevIdに有効な値が設定されている(prev方向にアイテムが存在している)場合
        if (nextItem.prevId) {
            // 後のアイテム(nextItem)曰く、時間軸上で連続している(item.id === nextItem.prevIdである)
            // 今のアイテムにも連続していると記録する
            item.nextId = nextItem.id;
            // next方向へ追加ロードが不要という状態にしておく(既にnextIdが存在しているため)
            item.hasMoreNext = false;
        }
        else if (!item.nextId) {
            // 処理中のアイテム(item)曰く、後ろは無し(=自分がnextの端)と記録されているが、
            // 実際は後のアイテムがあるので矛盾している（ここは最後のアイテムでない場合に走る処理なので）
            // 後のアイテム(nextItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と後のアイテム(nextItem)の間に抜けているアイテムがある可能性がある
            // よって、next方向へ追加ロードが必要という状態にしておく
            item.hasMoreNext = true;
        }
    }
    else {
        // 処理対象のアイテムが最後のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Newer ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなのでこれ以上新しい情報は存在しない=false
            item.hasMoreNext = false;
            item.nextId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    // 処理対象のアイテムが最初のアイテムでない場合
    if (index !== 0) {
        // 今のアイテムは最初のアイテムでないので、一つ前のアイテムが比較対象になる
        const prevItem = items[index - 1];
        // 一つ前のアイテムのnextIdに有効な値が設定されている(next方向にアイテムが存在している)場合
        if (prevItem.nextId) {
            // 前のアイテム曰く時間軸上で連続している。今のアイテムにも連続していると記録する
            // 前のアイテム(prevItem)曰く、時間軸上で連続している(item.id === prevItem.nextIdである)
            // 今のアイテムにも連続していると記録する
            item.prevId = prevItem.id;
            // prev方向へ追加ロードが不要という状態にしておく(既にprevIdが存在しているため)
            item.hasMorePrev = false;
        }
        else if (!item.prevId) {
            // 処理中のアイテム(item)曰く、前は無し(=自分がprevの端)と記録されているが、
            // 実際は前のアイテムがあるので矛盾している（ここは最初のアイテムでない場合に走る処理なので）
            // 前のアイテム(prevItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と前のアイテム(prevItem)の間に抜けているアイテムがある可能性がある
            // よって、prev方向へ追加ロードが必要という状態にしておく
            item.hasMorePrev = true;
        }
    }
    else {
        // 処理対象のアイテムが最初のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Older ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなのでこれ以上古い情報は存在しない=false
            item.hasMorePrev = false;
            item.prevId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    return item;
});
/**
 * 引数のチャットメッセージデータから、Sliceに格納するためのAttentionを生成する関数
 * @param attentionItems チャットメッセージデータ
 * @param criterionMessageId メッセージ取得時の基準となったメッセージID（基準がない場合はundefined）
 * @param direction メッセージの取得方向
 * @param isLoadedOfEndOfSourceItems 最後まで取得したかどうか
 * @returns 生成したTimelineMessageの配列
 */
const createAttentionsByParams = (attentionItems, criterionMessageId, direction, isLoadedOfEndOfSourceItems) => {
    if (attentionItems.length === 0 &&
        criterionMessageId &&
        isLoadedOfEndOfSourceItems) {
        return [];
    }
    return (Array.from(attentionItems) // sortは破壊的操作なので、引数を破壊しないよう最初にコピーする
        // チャットメッセージのIdの昇順でソート
        .sort(itemComparer('asc', 'id'))
        .map((item, index, sorted) => {
        // 前隣のアイテムIDを即時関数で判定する
        const prevId = (() => {
            if (index === 0) {
                // 最初の要素だけ特殊な処理が必要
                if (direction === TimelineDirection.Newer) {
                    // 取得方向が新しい方向なら前隣は取得の基準にしたアイテム
                    // 基準にしたアイテムがない場合は最古のアイテムなので前隣は存在しない=null
                    return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
                }
                else {
                    // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなので前隣は存在しない=null
                    // 最終アイテムまで取得していない場合は前隣は未確定=undefined
                    return isLoadedOfEndOfSourceItems ? null : undefined;
                }
            }
            // 最初の要素でない、前隣は一つ前の要素
            return sorted[index - 1].id;
        })();
        // 後隣のアイテムIDを即時関数で判定する
        const nextId = (() => {
            if (index !== sorted.length - 1) {
                // 最後の要素でない、後隣は一つ後の要素
                return sorted[index + 1].id;
            }
            // 最後の要素だけ特殊な処理が必要
            if (direction === TimelineDirection.Older) {
                // 取得方向が古い方向なら後隣は取得の基準にしたアイテム
                // 基準にしたアイテムがない場合は最新のアイテムなので後隣は存在しない=null
                return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
            }
            else {
                // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなので後隣は存在しない=null
                // 最終アイテムまで取得していない場合は後隣は未確定=undefined
                return isLoadedOfEndOfSourceItems ? null : undefined;
            }
        })();
        return {
            id: item.id,
            prevId: prevId !== null && prevId !== void 0 ? prevId : undefined,
            hasMorePrev: prevId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
            nextId: nextId !== null && nextId !== void 0 ? nextId : undefined,
            hasMoreNext: nextId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
            attentionType: item.attentionType,
            attentionUserName: item.attentionUserName,
            chatMessageId: item.chatMessageId,
        };
    }));
};
/**
 * 2つ以上のBookmarksをマージする
 * 重複しているアイテムはマージされ、時間軸上での隣接情報を最適値に更新する
 * @param bookmarks マージ対象のBookmarkの配列
 * @returns マージ後のBookmarkの配列
 */
const mergeBookmarks = (direction, isLoadedOfEndOfSourceItems, ...bookmarks) => bookmarks
    // 一旦全部flatに並べて
    .flat()
    //ブックマークの登録日時の昇順でソートしたあと
    .sort((a, b) => {
    return dateComparer(dayjs(a.createdAt), dayjs(b.createdAt), 'asc');
})
    // 同じ登録日時のアイテムをマージする
    .reduce((merged, item) => {
    var _a, _b, _c, _d;
    // マージ処理済の1つ前の要素を取り出して処理対象にする
    const prev = merged.pop();
    if (!prev) {
        // マージ済の要素がまだない(=最初の要素)時はそのままpush
        merged.push(item);
    }
    else {
        if (prev.createdAt !== item.createdAt) {
            // マージ済の1つ前の要素と違う登録日時、マージせずどちらも残す
            merged.push(prev);
            merged.push(item);
        }
        else {
            // マージ済の1つ前の要素と同じ登録日時
            if (prev.id !== item.id) {
                // メッセージIDが異なる場合はどちらも残す
                merged.push(prev);
                merged.push(item);
            }
            else {
                // マージ済の1つ前の要素と同じ登録日時・メッセージID、マージして一つだけpush
                merged.push({
                    id: item.id,
                    archived: (_a = prev.archived) !== null && _a !== void 0 ? _a : item.archived,
                    createdAt: (_b = prev.createdAt) !== null && _b !== void 0 ? _b : item.createdAt,
                    prevId: (_c = prev.prevId) !== null && _c !== void 0 ? _c : item.prevId,
                    nextId: (_d = prev.nextId) !== null && _d !== void 0 ? _d : item.nextId,
                    hasMorePrev: prev.hasMorePrev && item.hasMorePrev, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                    hasMoreNext: prev.hasMoreNext && item.hasMoreNext, // 前後のアイテムのどちらも追加読み込みが必要となっていたらtrueにする
                });
            }
        }
    }
    return merged;
}, [])
    // 最後に prevId と nextId を棚卸しする
    // ここまでで時系列順でマージした状態ではあるが、
    // 各アイテムのprevIdとnextIdはマージ前の状態のままなので、
    // prevIdとnextIdのつなぎ込みと、追加ロードが必要か（hasMoreNext,hasMorePrev）を更新する
    .map((item, index, items) => {
    if (items.length < 2) {
        // itemsが2未満の場合は前後比較が出来ないので何もしない
        return item;
    }
    // 処理対象のアイテムが最後のアイテムでない場合
    if (index !== items.length - 1) {
        // 今のアイテムは最後のアイテムでないので、一つ後のアイテムが比較対象になる
        const nextItem = items[index + 1];
        // 一つ後のアイテムのprevIdに有効な値が設定されている(prev方向にアイテムが存在している)場合
        if (nextItem.prevId) {
            // 後のアイテム(nextItem)曰く、時間軸上（Bookmarkの場合はcreatedAtのこと）で連続している
            // 今のアイテムにも連続していると記録する
            item.nextId = nextItem.id;
            // next方向へ追加ロードが不要という状態にしておく(既にnextIdが存在しているため)
            item.hasMoreNext = false;
        }
        else if (!item.nextId) {
            // 処理中のアイテム(item)曰く、後ろは無し(=自分がnextの端)と記録されているが、
            // 実際は後のアイテムがあるので矛盾している（ここは最後のアイテムでない場合に走る処理なので）
            // 後のアイテム(nextItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と後のアイテム(nextItem)の間に抜けているアイテムがある可能性がある
            // よって、next方向へ追加ロードが必要という状態にしておく
            item.hasMoreNext = true;
        }
    }
    else {
        // 処理対象のアイテムが最後のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Newer ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなのでこれ以上新しい情報は存在しない=false
            item.hasMoreNext = false;
            item.nextId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    // 処理対象のアイテムが最初のアイテムでない場合
    if (index !== 0) {
        // 今のアイテムは最初のアイテムでないので、一つ前のアイテムが比較対象になる
        const prevItem = items[index - 1];
        // 一つ前のアイテムのnextIdに有効な値が設定されている(next方向にアイテムが存在している)場合
        if (prevItem.nextId) {
            // 前のアイテム曰く時間軸上で連続している。今のアイテムにも連続していると記録する
            // 前のアイテム(prevItem)曰く、時間軸上（Bookmarkの場合はcreatedAtのこと）で連続している
            // 今のアイテムにも連続していると記録する
            item.prevId = prevItem.id;
            // prev方向へ追加ロードが不要という状態にしておく(既にprevIdが存在しているため)
            item.hasMorePrev = false;
        }
        else if (!item.prevId) {
            // 処理中のアイテム(item)曰く、前は無し(=自分がprevの端)と記録されているが、
            // 実際は前のアイテムがあるので矛盾している（ここは最初のアイテムでない場合に走る処理なので）
            // 前のアイテム(prevItem)は新しく追加されたものと考えられるが、
            // 処理中のアイテム(item)と前のアイテム(prevItem)の間に抜けているアイテムがある可能性がある
            // よって、prev方向へ追加ロードが必要という状態にしておく
            item.hasMorePrev = true;
        }
    }
    else {
        // 処理対象のアイテムが最初のアイテムの場合
        // ※マージ対象となるmessagesが、「sliceから取得したmessages」と「空配列」になる場合があり、両端のアイテムの設定値が洗い替えされないことがある。
        //   そのため、最終アイテムまで取得した時のみ、その時点の情報で再設定しなおす。
        if (isLoadedOfEndOfSourceItems &&
            (direction === TimelineDirection.Older ||
                direction === TimelineDirection.BothNewerAndOlder)) {
            // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなのでこれ以上古い情報は存在しない=false
            item.hasMorePrev = false;
            item.prevId = undefined;
        }
        // 上記以外の場合は元々設定している値をそのまま使う
    }
    return item;
});
/**
 * 引数のチャットメッセージデータから、Sliceに格納するためのBookmarksを生成する関数
 * @param bookmarkItems チャットメッセージデータ
 * @param criterionMessageId メッセージ取得時の基準となったメッセージID（基準がない場合はundefined）
 * @param direction メッセージの取得方向
 * @param isLoadedOfEndOfSourceItems 最後まで取得したかどうか
 * @returns 生成したTimelineMessageの配列
 */
const createBookmarksByParams = (bookmarkItems, criterionMessageId, direction, isLoadedOfEndOfSourceItems) => {
    if (bookmarkItems.length === 0 &&
        criterionMessageId &&
        isLoadedOfEndOfSourceItems) {
        return [];
    }
    return (Array.from(bookmarkItems) // sortは破壊的操作なので、引数を破壊しないよう最初にコピーする
        // チャットメッセージの登録日時の昇順でソート
        .sort((a, b) => {
        return dateComparer(dayjs(a.bookmarks[0].createdAt), dayjs(b.bookmarks[0].createdAt), 'asc');
    })
        .map((item, index, sorted) => {
        // 前隣のアイテムのIDを即時関数で判定する
        const prevId = (() => {
            if (index === 0) {
                // 最初の要素だけ特殊な処理が必要
                if (direction === TimelineDirection.Newer) {
                    // 取得方向が新しい方向なら前隣は取得の基準にしたアイテム
                    // 基準にしたアイテムがない場合は最古のアイテムなので前隣は存在しない=null
                    return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
                }
                else {
                    // 取得方向が古い方向且つ最終アイテムまで取得していた場合は最古のアイテムなので前隣は存在しない=null
                    // 最終アイテムまで取得していない場合は前隣は未確定=undefined
                    return isLoadedOfEndOfSourceItems ? null : undefined;
                }
            }
            // 最初の要素でない、前隣は一つ前の要素
            return sorted[index - 1].id;
        })();
        // 後隣のアイテムのIDを即時関数で判定する
        const nextId = (() => {
            if (index !== sorted.length - 1) {
                // 最後の要素でない、後隣は一つ後の要素
                return sorted[index + 1].id;
            }
            // 最後の要素だけ特殊な処理が必要
            if (direction === TimelineDirection.Older) {
                // 取得方向が古い方向なら後隣は取得の基準にしたアイテム
                // 基準にしたアイテムがない場合は最新のアイテムなので後隣は存在しない=null
                return criterionMessageId !== null && criterionMessageId !== void 0 ? criterionMessageId : null;
            }
            else {
                // 取得方向が新しい方向且つ最終アイテムまで取得していた場合は最新のアイテムなので後隣は存在しない=null
                // 最終アイテムまで取得していない場合は後隣は未確定=undefined
                return isLoadedOfEndOfSourceItems ? null : undefined;
            }
        })();
        return {
            id: item.id,
            archived: item.bookmarks[0].archived,
            createdAt: item.bookmarks[0].createdAt,
            prevId: prevId !== null && prevId !== void 0 ? prevId : undefined,
            hasMorePrev: prevId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
            nextId: nextId !== null && nextId !== void 0 ? nextId : undefined,
            hasMoreNext: nextId === undefined, // 即時関数の結果がundefinedの場合、追加ロードを行うようにする
        };
    }));
};
/**
 * 同じリアクションか
 * @param reaction
 * @param newReaction
 * @returns
 */
const isSameReaction = (reaction, newReaction) => {
    return (reaction.chatMessageId === newReaction.chatMessageId &&
        reaction.userId === newReaction.userId &&
        reaction.reaction === newReaction.reaction);
};
export const messageSlice = createSlice({
    name: 'message',
    initialState: initialSliceState,
    reducers: {
        // スライス全体をリセットする
        resetMessages: () => {
            return initialSliceState;
        },
        // --------------------------------------------
        // Chat
        // --------------------------------------------
        // チャットの表示形式を設定する(2ペイン表示かコンパクト表示か)
        setChatCurrentStyle: (state, action) => {
            const style = action.payload;
            state.chat.current.style = style;
            // スタイルが変更されたら、modeもchatに戻す
            state.chat.current.mode = Chat.Mode.Chat;
        },
        // チャットのトピック表示形式を設定する(Timeline or ThreadList)
        setChatCurrentDisplayFormat: (state, action) => {
            const displayFormat = action.payload;
            state.chat.current.displayFormat = displayFormat;
        },
        // --------------------------------------------
        // Chat タイムライン形式
        // --------------------------------------------
        // Timeline形式のデータ初期化処理
        initializeTimelineChatRoom: (state, action) => {
            const payload = action.payload;
            // 初期化対象のデータを取得
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            // データが取得できない場合は初期化する
            if (!targetChatTimelineEntity) {
                chatTimelineAdapter.addOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    messages: chatTimelineMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを指定する
                    topicId: undefined, // 初期化なのでundefinedを指定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
        },
        // Timeline形式のチャットメッセージを1件追加する
        addChatTimelineMessage: (state, action) => {
            const payload = action.payload;
            // 対象のチャットルーム
            let targetChatRoom = state.chat.timeline.entities[payload.chatRoomId];
            if (!targetChatRoom) {
                // チャットルームが存在しない場合は何もしない
                return;
            }
            // 対象のチャットルームのメッセージリスト
            const messages = targetChatRoom.messages;
            // 現在の最新メッセージID
            let latestMessageId = undefined;
            // チャットルーム内にメッセージが既に存在する場合は、その最新メッセージのnextIdを追加されたメッセージidで更新する
            if (messages.ids.length > 0) {
                latestMessageId = messages.ids[messages.ids.length - 1];
                chatTimelineMessageAdapter.updateOne(messages, {
                    id: latestMessageId,
                    changes: { nextId: payload.chatMessageId }, // 追加するメッセージのIDをnextIdに設定
                });
            }
            // チャットメッセージを追加
            chatTimelineMessageAdapter.addOne(messages, {
                id: payload.chatMessageId,
                chatRoomId: payload.addTargetChatRoomId,
                topicId: payload.topicId,
                prevId: latestMessageId ? latestMessageId : undefined,
                hasMorePrev: true, // 読み込まれていない歯抜けになったメッセージがある可能性があるため、trueを設定
                nextId: undefined,
                hasMoreNext: false, // このメッセージ以降の新しいメッセージは存在しないため、falseを設定
                createdById: payload.createdById,
            });
        },
        // Timeline形式のmessagesデータ追加処理
        addChatTimelineMessages: (state, action) => {
            const payload = action.payload;
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            // undefined(初期化されていない)場合は処理しない
            if (!targetChatTimelineEntity) {
                return;
            }
            // 対象チャットルームのメッセージDictionary
            const chatTimelineMessageDictionary = targetChatTimelineEntity.messages.entities;
            // 対象チャットルームのIDリスト
            const chatTimelineMessageIds = targetChatTimelineEntity.messages.ids;
            // 追加されたデータをもとにnextId,prevIdを再計算する必要があるので、
            // 対象チャットルームの現在のTimelineMessagesをすべて取得する
            const currentTimelineMessages = chatTimelineMessageIds.reduce((result, id) => {
                const message = chatTimelineMessageDictionary[id];
                if (message) {
                    result.push(message);
                }
                return result;
            }, []);
            // 既存データと追加データをもとにマージする（prevId,nextIdの再計算）
            const mergedTimeline = mergeTimelineMessages(payload.direction, payload.isLoadedOfEndOfSourceItems, ...payload.timelineMessages, // 今回追加するデータから生成したTimelineMessages
            ...currentTimelineMessages // 既に存在するTimelineMessages
            );
            // 対象チャットルームに紐づけてmessagesを追加する
            chatTimelineMessageAdapter.setMany(targetChatTimelineEntity.messages, mergedTimeline);
        },
        // Timeline形式の指定されたmessageデータ削除処理
        deleteChatTimelineMessage: (state, action) => {
            const payload = action.payload;
            // 処理対象のEntityを取得
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            // undefinedである(ChatTimelineが作成されていない)場合は処理しない
            if (!targetChatTimelineEntity) {
                return;
            }
            // 削除対象のChatTimelineMessageを取得
            const deleteTargetChatTimelineMessageEntity = targetChatTimelineEntity.messages.entities[payload.chatMessageId];
            // undefinedである(対象メッセージが存在しない)場合は処理しない
            if (!deleteTargetChatTimelineMessageEntity) {
                return;
            }
            /**
             * 削除対象のアイテムのnextIdがnullでない → より新しいアイテムが存在する（prevIdが削除対象のidであるもの）ので、その新しいアイテムの
             * 参照する古いアイテムを削除対象のアイテムの次のアイテムに差し替える
             *
             * 【例】
             * (itemA) → (itemB) → (itemC)
             * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
             */
            if (deleteTargetChatTimelineMessageEntity.nextId) {
                chatTimelineMessageAdapter.updateOne(targetChatTimelineEntity.messages, {
                    id: deleteTargetChatTimelineMessageEntity.nextId,
                    changes: {
                        prevId: deleteTargetChatTimelineMessageEntity.prevId,
                    },
                });
            }
            /**
             * 削除対象のアイテムのprevIdがnullでない → より古いアイテムが存在する（nextIdが削除対象のidであるもの）ので、その古いアイテムの
             * 参照する新しいアイテムを削除対象のアイテムの前のアイテムに差し替える
             *
             * 【例】
             * (itemA) → (itemB) → (itemC)
             * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
             */
            if (deleteTargetChatTimelineMessageEntity.prevId) {
                chatTimelineMessageAdapter.updateOne(targetChatTimelineEntity.messages, {
                    id: deleteTargetChatTimelineMessageEntity.prevId,
                    changes: {
                        nextId: deleteTargetChatTimelineMessageEntity.nextId,
                    },
                });
            }
            // 対象チャットルームに紐づく、指定されたmessageを削除する
            chatTimelineMessageAdapter.removeOne(targetChatTimelineEntity.messages, payload.chatMessageId);
        },
        // Timeline形式の指定されたmessageデータ削除処理
        deleteAllChatTimelineMessage: (state, action) => {
            const payload = action.payload;
            // 処理対象のEntityを取得
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            // undefinedである(ChatTimelineが作成されていない)場合は処理しない
            if (!targetChatTimelineEntity) {
                return;
            }
            // 対象チャットルームに紐づく、指定されたmessageを全削除する
            chatTimelineMessageAdapter.removeAll(targetChatTimelineEntity.messages);
        },
        // Timeline形式のmessagesデータ差し替え処理
        replaceChatTimelineMessages: (state, action) => {
            const payload = action.payload;
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            // undefined(初期化されていない)場合は処理しない
            if (!targetChatTimelineEntity) {
                return;
            }
            // 全て差し替える
            chatTimelineMessageAdapter.setAll(targetChatTimelineEntity.messages, payload.timelineMessages);
        },
        // Timeline形式のスクロール位置保存
        setTimelineScrollToMessageId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            if (!targetChatTimelineEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatTimelineAdapter.addOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    messages: chatTimelineMessageAdapter.getInitialState(),
                    scrollToMessageId: payload.chatMessageId,
                    topicId: undefined, // 初期化なのでundefinedを設定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は更新する
                chatTimelineAdapter.updateOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    changes: {
                        scrollToMessageId: payload.chatMessageId,
                    },
                });
            }
        },
        // Timeline形式のスクロール位置リセット
        resetTimelineScrollToMessageId: (state, action) => {
            const payload = action.payload;
            // リセット対象のデータを取得
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            if (!targetChatTimelineEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatTimelineAdapter.addOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    messages: chatTimelineMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // リセットなのでundefinedを設定する
                    topicId: undefined, // 初期化なのでundefinedを設定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は対象データをundefinedで更新する
                chatTimelineAdapter.updateOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    changes: {
                        scrollToMessageId: undefined,
                    },
                });
            }
        },
        // Timeline形式のトピックID保存
        setTimelineTopicId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            if (!targetChatTimelineEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatTimelineAdapter.addOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    messages: chatTimelineMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを設定する
                    topicId: payload.chatMessageId,
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は更新する
                chatTimelineAdapter.updateOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    changes: {
                        topicId: payload.chatMessageId,
                    },
                });
            }
            // currentのchatThreadに値をセットする
            // この処理が走る際にはcurrent.chatRoomは設定されているはずだが、型の都合上undefinedのチェックを行う
            if (state.chat.current.chatRoom) {
                state.chat.current.chatThread = {
                    topicId: payload.chatMessageId,
                    chatRoomId: payload.chatRoomId,
                    entityType: state.chat.current.chatRoom.entityType,
                    entityRecordId: state.chat.current.chatRoom.entityRecordId,
                };
            }
        },
        // Timeline形式のトピックIDリセット
        resetTimelineTopicId: (state, action) => {
            const payload = action.payload;
            // リセット対象のデータを取得
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            if (!targetChatTimelineEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatTimelineAdapter.addOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    messages: chatTimelineMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを設定する
                    topicId: undefined, // リセットなのでundefinedを設定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は対象データをundefinedで更新する
                chatTimelineAdapter.updateOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    changes: {
                        topicId: undefined,
                    },
                });
            }
            // currentのchatThreadをリセットする
            state.chat.current.chatThread = undefined;
        },
        // Timeline形式の検索範囲保存
        updateTimelineSearchRange: (state, action) => {
            const payload = action.payload;
            // 設定対象のチャットルームを取得
            const targetChatRoomTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            if (!targetChatRoomTimelineEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatTimelineAdapter.addOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    messages: chatTimelineMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを設定する
                    topicId: undefined, // 初期化なのでundefinedを設定する
                    searchRange: payload.searchRange,
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は更新する
                chatTimelineAdapter.updateOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    changes: {
                        searchRange: payload.searchRange,
                    },
                });
            }
        },
        // 選択されたメッセージIDを設定する
        setTimelineSelectedMessageId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            if (!targetChatTimelineEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatTimelineAdapter.addOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    messages: chatTimelineMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを設定する
                    topicId: undefined, // 初期化なのでundefinedを設定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: payload.chatMessageId,
                });
            }
            else {
                // データが取得できた場合は更新する
                chatTimelineAdapter.updateOne(state.chat.timeline, {
                    id: payload.chatRoomId,
                    changes: {
                        selectedMessageId: payload.chatMessageId,
                        scrollToMessageId: undefined, // 画面切り替え時のスクロール位置が干渉するため、リセットする（メッセージ選択が優先）
                    },
                });
            }
        },
        // 選択されたメッセージIDをリセットする
        resetTimelineSelectedMessageId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            if (!targetChatTimelineEntity) {
                // データが取得できない場合は設定してもしょうがないので処理しない
                return;
            }
            chatTimelineAdapter.updateOne(state.chat.timeline, {
                id: payload.chatRoomId,
                changes: {
                    selectedMessageId: undefined,
                },
            });
        },
        // 最新メッセージのhasMoreNextを強制的にtrueにする
        forceUpdateTimelineMessageHasMoreNext: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatTimelineEntity = state.chat.timeline.entities[payload.chatRoomId];
            if (!targetChatTimelineEntity) {
                // データが取得できない場合は処理しない
                return;
            }
            if (targetChatTimelineEntity.messages.ids.length > 0) {
                // 最新のメッセージIDを取得
                const latestMessageId = targetChatTimelineEntity.messages.ids[targetChatTimelineEntity.messages.ids.length - 1];
                // hasMoreNextをtrueに更新する
                chatTimelineMessageAdapter.updateOne(targetChatTimelineEntity.messages, {
                    id: latestMessageId,
                    changes: {
                        hasMoreNext: true,
                    },
                });
            }
        },
        // --------------------------------------------
        // Chat スレッドリスト形式
        // --------------------------------------------
        // ThreadList形式のデータ初期化処理
        initializeThreadListChatRoom: (state, action) => {
            const payload = action.payload;
            // 初期化対象のデータを取得
            const targetChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            // データが取得できない場合は初期化する
            if (!targetChatThreadListEntity) {
                chatThreadListAdapter.addOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    messages: chatThreadListMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを指定する
                    topicId: undefined, // 初期化なのでundefinedを設定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
        },
        // ThreadList形式のmessagesにデータを1件追加する
        addChatThreadListMessage: (state, action) => {
            const payload = action.payload;
            // 該当のチャットルーム
            let targetChatRoom = state.chat.threadList.entities[payload.chatRoomId];
            if (!targetChatRoom) {
                // 対象のチャットルームが存在しない場合は何もしない
                return;
            }
            // 対象のチャットルームのトピックリスト
            const messages = targetChatRoom.messages;
            // 現在の最新トピックのメッセージID
            let latestMessageId = undefined;
            // チャットルーム内にメッセージが既に存在する場合は、その最新メッセージのnextIdを追加されたメッセージidで更新する
            if (messages.ids.length > 0) {
                latestMessageId = messages.ids[messages.ids.length - 1];
                chatThreadListMessageAdapter.updateOne(messages, {
                    id: latestMessageId,
                    changes: { nextId: payload.topicId }, // 追加するメッセージのIDをnextIdに設定
                });
            }
            // トピックを追加
            // 各プロパティの値は初期値を設定
            chatThreadListMessageAdapter.addOne(targetChatRoom.messages, {
                id: payload.topicId,
                chatRoomId: payload.addTargetChatRoomId,
                replyCount: 0,
                prevId: latestMessageId ? latestMessageId : undefined,
                hasMorePrev: true, // 読み込まれていない歯抜けになったトピックがある可能性があるため、trueを設定
                nextId: undefined,
                hasMoreNext: false, // このトピック以降の新しいメッセージは存在しないため、falseを設定
                createdById: payload.createdById,
            });
        },
        // ThreadList形式のmessagesデータ追加処理
        addChatThreadListMessages: (state, action) => {
            const payload = action.payload;
            const targetChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            // undefined(初期化されていない)場合は処理しない
            if (!targetChatThreadListEntity) {
                return;
            }
            // 対象チャットルームのメッセージDictionary
            const chatThreadListMessageDictionary = targetChatThreadListEntity.messages.entities;
            // 対象チャットルームのIDリスト
            const chatThreadListMessageIds = targetChatThreadListEntity.messages.ids;
            // 追加されたデータをもとにnextId,prevIdを再計算する必要があるので、
            // 対象チャットルームの現在のThreadListMessagesをすべて取得する
            const currentThreadListMessages = chatThreadListMessageIds.reduce((result, id) => {
                const message = chatThreadListMessageDictionary[id];
                if (message) {
                    result.push(message);
                }
                return result;
            }, []);
            // 既存データと追加データをもとにマージする（prevId,nextIdの再計算）
            const mergedThreadList = mergeThreadListMessages(payload.direction, payload.isLoadedOfEndOfSourceItems, ...payload.threadListMessages, // 今回追加するデータから生成したThreadListMessages
            ...currentThreadListMessages // 既に存在するThreadListMessages
            );
            // 対象チャットルームに紐づけてmessagesを追加する
            chatThreadListMessageAdapter.setMany(targetChatThreadListEntity.messages, mergedThreadList);
        },
        // ThreadList形式の指定されたmessageデータ削除処理
        deleteChatThreadListMessage: (state, action) => {
            const payload = action.payload;
            // 処理対象のチャットルームを取得
            const targetChatRoom = state.chat.threadList.entities[payload.chatRoomId];
            if (!targetChatRoom) {
                // undefinedである(ChatThreadListが作成されていない)場合は処理しない
                return;
            }
            // 処理対象のトピックを取得
            const targetTopic = targetChatRoom.messages.entities[payload.chatMessageId];
            if (!targetTopic) {
                // トピックが存在しない場合は処理しない
                return;
            }
            // 削除対象のメッセージがトピックか返信かで処理を分ける
            if (payload.isTopicMessage) {
                /** トピックの場合：トピックを削除する */
                /**
                 * 削除対象のアイテムのnextIdがnullでない → より新しいアイテムが存在する（prevIdが削除対象のidであるもの）ので、その新しいアイテムの
                 * 参照する古いアイテムを削除対象のアイテムの次のアイテムに差し替える
                 *
                 * 【例】
                 * (itemA) → (itemB) → (itemC)
                 * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
                 */
                if (targetTopic.nextId) {
                    chatThreadListMessageAdapter.updateOne(targetChatRoom.messages, {
                        id: targetTopic.nextId,
                        changes: {
                            prevId: targetTopic.prevId,
                        },
                    });
                }
                /**
                 * 削除対象のアイテムのprevIdがnullでない → より古いアイテムが存在する（nextIdが削除対象のidであるもの）ので、その古いアイテムの
                 * 参照する新しいアイテムを削除対象のアイテムの前のアイテムに差し替える
                 *
                 * 【例】
                 * (itemA) → (itemB) → (itemC)
                 * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
                 */
                if (targetTopic.prevId) {
                    chatThreadListMessageAdapter.updateOne(targetChatRoom.messages, {
                        id: targetTopic.prevId,
                        changes: {
                            nextId: targetTopic.nextId,
                        },
                    });
                }
                // 対象チャットルームに紐づく、指定されたmessageを削除する
                chatThreadListMessageAdapter.removeOne(targetChatRoom.messages, payload.chatMessageId);
            }
            else {
                /** 返信の場合：返信件数を更新（-1） */
                // 返信件数を-1で更新する
                chatThreadListMessageAdapter.updateOne(targetChatRoom.messages, {
                    id: payload.chatMessageId,
                    changes: {
                        replyCount: targetTopic.replyCount ? targetTopic.replyCount - 1 : 0,
                    },
                });
            }
        },
        // ThreadList形式の指定されたmessageデータ削除処理
        deleteAllChatThreadListMessages: (state, action) => {
            const payload = action.payload;
            // 処理対象のEntityを取得
            const targetChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            // undefinedである(ChatThreadListが作成されていない)場合は処理しない
            if (!targetChatThreadListEntity) {
                return;
            }
            // 対象チャットルームに紐づく、指定されたmessageを全削除する
            chatThreadListMessageAdapter.removeAll(targetChatThreadListEntity.messages);
        },
        // ThreadList形式のmessagesデータ差し替え処理
        replaceChatThreadListMessages: (state, action) => {
            const payload = action.payload;
            const targetChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            // undefined(初期化されていない)場合は処理しない
            if (!targetChatThreadListEntity) {
                return;
            }
            // 全て差し替える
            chatThreadListMessageAdapter.setAll(targetChatThreadListEntity.messages, payload.threadListMessages);
        },
        // ThreadList形式のスクロール位置保存
        setThreadListScrollToMessageId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            if (!targetChatThreadListEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatThreadListAdapter.addOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    messages: chatThreadListMessageAdapter.getInitialState(),
                    scrollToMessageId: payload.chatMessageId,
                    topicId: undefined, // 初期化なのでundefinedを設定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は更新する
                chatThreadListAdapter.updateOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    changes: {
                        scrollToMessageId: payload.chatMessageId,
                    },
                });
            }
        },
        // ThreadList形式のスクロール位置リセット
        resetThreadListScrollToMessageId: (state, action) => {
            const payload = action.payload;
            // リセット対象のデータを取得
            const targetChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            // データが取得できない場合は初期化しつつ設定する
            if (!targetChatThreadListEntity) {
                chatThreadListAdapter.addOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    messages: chatThreadListMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // リセットなのでundefinedを設定する
                    topicId: undefined, // 初期化なのでundefinedを設定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は対象データをundefinedで更新する
                chatThreadListAdapter.updateOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    changes: {
                        scrollToMessageId: undefined,
                    },
                });
            }
        },
        // ThreadList形式の返信件数を+1する
        incrementReplyCountToThreadList: (state, action) => {
            const payload = action.payload;
            // 現在のチャットの表示スタイル
            const currentStyle = state.chat.current.style;
            /**
             * 2ペイン表示の場合、中央パネル、右パネルでそれぞれメッセージ追加の処理が走ることにより、
             * この処理が2回走ってしまい返信件数が二重に加算されてしまうため、
             * publish時のtargetId(チャットルームID)が投稿先のチャットルームではない場合は、返信件数を加算しないようにする。
             */
            if (currentStyle === Chat.Style.Full && // 2ペイン表示
                payload.chatRoomId !== payload.pubsubTargetChatRoomId // 投稿先がsubscribe対象のチャットルームではない
            ) {
                return;
            }
            // 対象のチャットルーム
            const targetChatRoom = state.chat.threadList.entities[payload.chatRoomId];
            if (!targetChatRoom) {
                // チャットルームが存在しない場合は何もしない
                return;
            }
            // 対象のトピック
            const targetTopic = targetChatRoom.messages.entities[payload.topicId];
            if (!targetTopic) {
                // トピックが存在しない場合は何もしない
                return;
            }
            // 返信件数を+1で更新する
            chatThreadListMessageAdapter.updateOne(targetChatRoom.messages, {
                id: payload.topicId,
                changes: {
                    replyCount: targetTopic.replyCount ? targetTopic.replyCount + 1 : 1,
                },
            });
        },
        // ThreadList形式のトピックID保存
        setThreadListTopicId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            // データが取得できない場合は初期化しつつ設定する
            if (!targetChatThreadListEntity) {
                chatThreadListAdapter.addOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    messages: chatThreadListMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを設定する
                    topicId: payload.chatMessageId,
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は更新する
                chatThreadListAdapter.updateOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    changes: {
                        topicId: payload.chatMessageId,
                    },
                });
            }
            // currentのchatThreadに値をセットする
            // この処理が走る際にはcurrent.chatRoomは設定されているはずだが、型の都合上undefinedのチェックを行う
            if (state.chat.current.chatRoom) {
                state.chat.current.chatThread = {
                    topicId: payload.chatMessageId,
                    chatRoomId: payload.chatRoomId,
                    entityType: state.chat.current.chatRoom.entityType,
                    entityRecordId: state.chat.current.chatRoom.entityRecordId,
                };
            }
        },
        // ThreadList形式のトピックIDリセット
        resetThreadListTopicId: (state, action) => {
            const payload = action.payload;
            // リセット対象のデータを取得
            const targetChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            // データが取得できない場合は初期化しつつ設定する
            if (!targetChatThreadListEntity) {
                chatThreadListAdapter.addOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    messages: chatThreadListMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを設定する
                    topicId: undefined, // リセットなのでundefinedを設定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は対象データをundefinedで更新する
                chatThreadListAdapter.updateOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    changes: {
                        topicId: undefined,
                    },
                });
            }
            // currentのchatThreadをリセットする
            state.chat.current.chatThread = undefined;
        },
        // ThreadList形式の検索範囲保存
        updateThreadListSearchRange: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            if (!targetChatThreadListEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatThreadListAdapter.addOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    messages: chatThreadListMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを設定する
                    topicId: undefined, // 初期化なのでundefinedを設定する
                    searchRange: payload.searchRange,
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は更新する
                chatThreadListAdapter.updateOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    changes: {
                        searchRange: payload.searchRange,
                    },
                });
            }
        },
        // 選択されたメッセージIDを設定する
        setThreadListSelectedMessageId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            if (!targetChatThreadListEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatThreadListAdapter.addOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    messages: chatThreadListMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを設定する
                    topicId: undefined, // 初期化なのでundefinedを設定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: payload.chatMessageId,
                });
            }
            else {
                // データが取得できた場合は更新する
                chatThreadListAdapter.updateOne(state.chat.threadList, {
                    id: payload.chatRoomId,
                    changes: {
                        selectedMessageId: payload.chatMessageId,
                        scrollToMessageId: undefined, // 画面切り替え時のスクロール位置が干渉するため、リセットする（メッセージ選択が優先）
                    },
                });
            }
        },
        // 選択されたメッセージIDをリセットする
        resetThreadListSelectedMessageId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            if (!targetChatChatThreadListEntity) {
                // データが取得できない場合は設定してもしょうがないので処理しない
                return;
            }
            chatThreadListAdapter.updateOne(state.chat.threadList, {
                id: payload.chatRoomId,
                changes: {
                    selectedMessageId: undefined,
                },
            });
        },
        // 最新メッセージのhasMoreNextを強制的にtrueにする
        forceUpdateThreadListMessageHasMoreNext: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatChatThreadListEntity = state.chat.threadList.entities[payload.chatRoomId];
            if (!targetChatChatThreadListEntity) {
                // データが取得できない場合は処理しない
                return;
            }
            if (targetChatChatThreadListEntity.messages.ids.length > 0) {
                // 最新のメッセージIDを取得
                const latestMessageId = targetChatChatThreadListEntity.messages.ids[targetChatChatThreadListEntity.messages.ids.length - 1];
                // hasMoreNextをtrueに更新する
                chatThreadListMessageAdapter.updateOne(targetChatChatThreadListEntity.messages, {
                    id: latestMessageId,
                    changes: {
                        hasMoreNext: true,
                    },
                });
            }
        },
        // --------------------------------------------
        // Chat スレッド形式
        // --------------------------------------------
        // Thread形式のデータ初期化処理
        initializeThreadTopic: (state, action) => {
            const payload = action.payload;
            // 初期化対象のデータを取得
            const targetChatThreadEntity = state.chat.thread.entities[payload.topicId];
            // データが取得できない場合は初期化する
            if (!targetChatThreadEntity) {
                chatThreadAdapter.addOne(state.chat.thread, {
                    id: payload.topicId,
                    messages: chatThreadMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを指定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
        },
        // Thread形式のmessagesデータ追加処理
        addChatThreadMessages: (state, action) => {
            const payload = action.payload;
            const targetChatThreadEntity = state.chat.thread.entities[payload.topicId];
            // undefined(初期化されていない)場合は処理しない
            if (!targetChatThreadEntity) {
                return;
            }
            // 対象チャットルームのメッセージDictionary
            const chatThreadMessageDictionary = targetChatThreadEntity.messages.entities;
            // 対象チャットルームのIDリスト
            const chatThreadMessageIds = targetChatThreadEntity.messages.ids;
            // 追加されたデータをもとにnextId,prevIdを再計算する必要があるので、
            // 対象チャットルームの現在のThreadMessagesをすべて取得する
            const currentThreadMessages = chatThreadMessageIds.reduce((result, id) => {
                const message = chatThreadMessageDictionary[id];
                if (message) {
                    result.push(message);
                }
                return result;
            }, []);
            // 既存データと追加データをもとにマージする（prevId,nextIdの再計算）
            const mergedThread = mergeThreadMessages(payload.direction, payload.isLoadedOfEndOfSourceItems, ...payload.threadMessages, // 今回追加するデータから生成したThreadMessages
            ...currentThreadMessages // 既に存在するThreadMessages
            );
            // 対象チャットルームに紐づけてmessagesを追加する
            chatThreadMessageAdapter.setMany(targetChatThreadEntity.messages, mergedThread);
        },
        // Thread形式の指定されたmessageデータ削除処理
        deleteChatThreadMessage: (state, action) => {
            const payload = action.payload;
            // 削除メッセージの情報をキャッシュから取得
            const deletedMessage = state.chat.cacheMessages.entities[payload.chatMessageId];
            if (!deletedMessage) {
                // 削除メッセージがキャッシュに存在しない場合は処理しない
                return;
            }
            // 対象のメッセージがトピックかどうか
            const isTopicMessage = deletedMessage.parentChatMessageId === null;
            if (isTopicMessage) {
                /** トピックの場合：トピックを削除 */
                chatThreadAdapter.removeOne(state.chat.thread, payload.chatMessageId);
            }
            else {
                /** 返信の場合：該当のメッセージを削除（prevId、nextIdで参照されている箇所も更新） */
                // 返信の場合、トピックがnullのケースはありえないが、後続処理で型制約エラーが発生するため型チェック
                if (!deletedMessage.parentChatMessageId) {
                    return;
                }
                // 処理対象のトピックを取得
                const targetTopic = state.chat.thread.entities[deletedMessage.parentChatMessageId];
                if (!targetTopic) {
                    // undefinedである(ChatThreadが作成されていない)場合は処理しない
                    return;
                }
                // 削除対象のメッセージ
                const targetMessage = targetTopic.messages.entities[payload.chatMessageId];
                if (!targetMessage) {
                    // 削除対象のメッセージが存在しない場合は処理しない
                    return;
                }
                /**
                 * 削除対象のアイテムのnextIdがnullでない → より新しいアイテムが存在する（prevIdが削除対象のidであるもの）ので、その新しいアイテムの
                 * 参照する古いアイテムを削除対象のアイテムの次のアイテムに差し替える
                 *
                 * 【例】
                 * (itemA) → (itemB) → (itemC)
                 * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
                 */
                if (targetMessage.nextId) {
                    chatThreadMessageAdapter.updateOne(targetTopic.messages, {
                        id: targetMessage.nextId,
                        changes: {
                            prevId: targetMessage.prevId,
                        },
                    });
                }
                /**
                 * 削除対象のアイテムのprevIdがnullでない → より古いアイテムが存在する（nextIdが削除対象のidであるもの）ので、その古いアイテムの
                 * 参照する新しいアイテムを削除対象のアイテムの前のアイテムに差し替える
                 *
                 * 【例】
                 * (itemA) → (itemB) → (itemC)
                 * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
                 */
                if (targetMessage.prevId) {
                    chatThreadMessageAdapter.updateOne(targetTopic.messages, {
                        id: targetMessage.prevId,
                        changes: {
                            nextId: targetMessage.nextId,
                        },
                    });
                }
                // 対象トピックに紐づく、指定されたmessageを削除する
                chatThreadMessageAdapter.removeOne(targetTopic.messages, payload.chatMessageId);
            }
        },
        // Thread形式の指定されたmessageデータ削除処理
        deleteAllChatThreadMessages: (state, action) => {
            const payload = action.payload;
            // 処理対象のEntityを取得
            const targetChatThreadEntity = state.chat.thread.entities[payload.topicId];
            // undefinedである(ChatThreadが作成されていない)場合は処理しない
            if (!targetChatThreadEntity) {
                return;
            }
            // 対象チャットルームに紐づく、指定されたmessageを全削除する
            chatThreadMessageAdapter.removeAll(targetChatThreadEntity.messages);
        },
        // Thread形式のmessagesデータ差し替え処理
        replaceChatThreadMessages: (state, action) => {
            const payload = action.payload;
            const targetChatThreadEntity = state.chat.thread.entities[payload.topicId];
            // undefined(初期化されていない)場合は処理しない
            if (!targetChatThreadEntity) {
                return;
            }
            // 全て差し替える
            chatThreadMessageAdapter.setAll(targetChatThreadEntity.messages, payload.threadMessages);
        },
        // Thread形式のスクロール位置保存
        setThreadScrollToMessageId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatThreadEntity = state.chat.thread.entities[payload.topicId];
            // データが取得できない場合は初期化しつつ設定する
            if (!targetChatThreadEntity) {
                chatThreadAdapter.addOne(state.chat.thread, {
                    id: payload.topicId,
                    messages: chatThreadMessageAdapter.getInitialState(),
                    scrollToMessageId: payload.chatMessageId,
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は更新する
                chatThreadAdapter.updateOne(state.chat.thread, {
                    id: payload.topicId,
                    changes: {
                        scrollToMessageId: payload.chatMessageId,
                    },
                });
            }
        },
        // Thread形式のスクロール位置リセット
        resetThreadScrollToMessageId: (state, action) => {
            const payload = action.payload;
            // リセット対象のデータを取得
            const targetChatThreadEntity = state.chat.thread.entities[payload.topicId];
            // データが取得できない場合は初期化しつつ設定する
            if (!targetChatThreadEntity) {
                chatThreadAdapter.addOne(state.chat.thread, {
                    id: payload.topicId,
                    messages: chatThreadMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // リセットなのでundefinedを設定する
                    searchRange: undefined, // 初期化なのでundefinedを指定する
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は対象データをundefinedで更新する
                chatThreadAdapter.updateOne(state.chat.thread, {
                    id: payload.topicId,
                    changes: {
                        scrollToMessageId: undefined,
                    },
                });
            }
        },
        // Thread形式の返信を1件追加する
        addThreadCommentMessage: (state, action) => {
            const payload = action.payload;
            // 対象のトピック
            const targetTopic = state.chat.thread.entities[payload.topicId];
            // 該当のトピックが存在しない場合は処理しない。事前に初期化処理が呼ばれているはずなので、この分岐には入らないはず
            if (!targetTopic) {
                return;
            }
            // 対象のメッセージリスト
            const targetMessages = targetTopic.messages;
            // 現在の最新メッセージID
            let latestMessageId = undefined;
            // トピックへのコメントメッセージが既に存在する場合は、その最新メッセージのnextIdを更新する
            if (targetMessages.ids.length > 0) {
                latestMessageId = targetMessages.ids[targetMessages.ids.length - 1];
                chatThreadMessageAdapter.updateOne(targetMessages, {
                    id: latestMessageId,
                    changes: { nextId: payload.commentId }, // nextIdに追加するメッセージのIDを設定
                });
            }
            // コメントを追加
            chatThreadMessageAdapter.addOne(targetMessages, {
                id: payload.commentId,
                prevId: latestMessageId ? latestMessageId : undefined,
                hasMorePrev: true, // 読み込まれていない歯抜けになったメッセージがある可能性があるため、trueを設定
                nextId: undefined,
                hasMoreNext: false, // このメッセージ以降の新しいメッセージは存在しないため、falseを設定
                createdById: payload.createdById,
            });
        },
        // 最新メッセージのhasMoreNextを強制的にtrueにする
        forceUpdateThreadMessageHasMoreNext: (state, action) => {
            const payload = action.payload;
            // 対象のトピック
            const targetTopic = state.chat.thread.entities[payload.topicId];
            // 該当のトピックが存在しない場合は処理しない。事前に初期化処理が呼ばれているはずなので、この分岐には入らないはず
            if (!targetTopic) {
                return;
            }
            if (targetTopic.messages.ids.length > 0) {
                // 最新のメッセージIDを取得
                const latestMessageId = targetTopic.messages.ids[targetTopic.messages.ids.length - 1];
                chatThreadMessageAdapter.updateOne(targetTopic.messages, {
                    id: latestMessageId,
                    changes: {
                        hasMoreNext: true,
                    },
                });
            }
        },
        // --------------------------------------------
        // Current Chat
        // --------------------------------------------
        // 表示するチャットルームを設定する
        setChatRoom: (state, action) => {
            const chatRoom = action.payload;
            state.chat.current.chatRoom = chatRoom;
            // チャットルームが変更された際、表示するチャットスレッドをリセットする
            state.chat.current.chatThread = undefined;
            // 表示するチャットルームが変更された場合は、モードを「チャット」に戻す
            state.chat.current.mode = Chat.Mode.Chat;
        },
        // 表示するチャットルームを設定して、対象チャットルームで表示対象になっていたトピックIDをcurrentに復元する
        setChatRoomAndRestoreThread: (state, action) => {
            const chatRoom = action.payload;
            state.chat.current.chatRoom = chatRoom;
            // チャットルームが変更された際、表示するチャットスレッドをリセットする
            state.chat.current.chatThread = undefined;
            // 表示するチャットルームが変更された場合は、対象チャットルームで表示対象になっていたトピックIDをcurrentに復元する
            if (state.chat.current.displayFormat === ChatView.Timeline) {
                const targetEntity = state.chat.timeline.entities[chatRoom.id];
                if (targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.topicId) {
                    state.chat.current.chatThread = {
                        topicId: targetEntity.topicId,
                        chatRoomId: chatRoom.id,
                        entityType: chatRoom.entityType,
                        entityRecordId: chatRoom.entityRecordId,
                    };
                }
            }
            else if (state.chat.current.displayFormat === ChatView.ThreadList) {
                const targetEntity = state.chat.threadList.entities[chatRoom.id];
                if (targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.topicId) {
                    state.chat.current.chatThread = {
                        topicId: targetEntity.topicId,
                        chatRoomId: chatRoom.id,
                        entityType: chatRoom.entityType,
                        entityRecordId: chatRoom.entityRecordId,
                    };
                }
            }
            // 表示するチャットルームが変更された場合は、モードを「チャット」に戻す
            state.chat.current.mode = Chat.Mode.Chat;
        },
        // 表示するチャットルームをリセットする
        resetChatRoom: (state) => {
            state.chat.current.chatRoom = undefined;
        },
        // 表示するチャットスレッドを設定する
        setChatThread: (state, action) => {
            const chatThread = action.payload;
            state.chat.current.chatThread = chatThread;
        },
        // 表示するチャットスレッドをリセットする
        resetChatThread: (state) => {
            state.chat.current.chatThread = undefined;
        },
        // チャットメッセージの下書きを保存する
        setChatDraft: (state, action) => {
            const payload = action.payload;
            chatDraftAdapter.upsertOne(state.chat.draft, {
                id: payload.id,
                text: payload.text,
                files: payload.files,
            });
        },
        // チャットメッセージの下書きを削除する
        deleteChatDraft: (state, action) => {
            chatDraftAdapter.removeOne(state.chat.draft, action.payload);
        },
        // モードを設定する
        setMode: (state, action) => {
            const mode = action.payload;
            state.chat.current.mode = mode;
        },
        // ChatThread形式の検索範囲保存
        updateChatThreadSearchRange: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatThreadEntity = state.chat.thread.entities[payload.topicId];
            if (!targetChatThreadEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatThreadAdapter.addOne(state.chat.thread, {
                    id: payload.topicId,
                    messages: chatThreadMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを指定する
                    searchRange: payload.searchRange,
                    selectedMessageId: undefined, // 初期化なのでundefinedを指定する
                });
            }
            else {
                // データが取得できた場合は更新する
                chatThreadAdapter.updateOne(state.chat.thread, {
                    id: payload.topicId,
                    changes: {
                        searchRange: payload.searchRange,
                    },
                });
            }
        },
        // 選択されたメッセージIDを設定する
        setChatThreadSelectedMessageId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatThreadEntity = state.chat.thread.entities[payload.topicId];
            if (!targetChatThreadEntity) {
                // データが取得できない場合は初期化しつつ設定する
                chatThreadAdapter.addOne(state.chat.thread, {
                    id: payload.topicId,
                    messages: chatThreadMessageAdapter.getInitialState(),
                    scrollToMessageId: undefined, // 初期化なのでundefinedを指定する
                    searchRange: undefined,
                    selectedMessageId: payload.chatMessageId,
                });
            }
            else {
                // データが取得できた場合は更新する
                chatThreadAdapter.updateOne(state.chat.thread, {
                    id: payload.topicId,
                    changes: {
                        selectedMessageId: payload.chatMessageId,
                        scrollToMessageId: undefined, // 画面切り替え時のスクロール位置が干渉するため、リセットする（メッセージ選択が優先）
                    },
                });
            }
        },
        // 選択されたメッセージIDをリセットする
        resetChatThreadSelectedMessageId: (state, action) => {
            const payload = action.payload;
            // 設定対象のデータを取得
            const targetChatThreadEntity = state.chat.thread.entities[payload.topicId];
            if (!targetChatThreadEntity) {
                // データが取得できない場合は設定してもしょうがないので処理しない
                return;
            }
            chatThreadAdapter.updateOne(state.chat.thread, {
                id: payload.topicId,
                changes: {
                    selectedMessageId: undefined,
                },
            });
        },
        // --------------------------------------------
        // Chat 検索形式
        // --------------------------------------------
        // 検索形式のmessagesデータ追加処理
        addChatSearchMessages: (state, action) => {
            const payload = action.payload;
            const targetChatSearchEntity = state.chat.search.entities[payload.chatRoomId];
            // undefined(初期化されていない)場合は処理しない
            if (!targetChatSearchEntity) {
                return;
            }
            // 対象チャットルームのメッセージDictionary
            const chatSearchMessageDictionary = targetChatSearchEntity.messages.entities;
            // 対象チャットルームのIDリスト
            const chatSearchMessageIds = targetChatSearchEntity.messages.ids;
            // 追加されたデータをもとにnextId,prevIdを再計算する必要があるので、
            // 対象チャットルームの現在のMessagesをすべて取得する
            const currentSearchMessages = chatSearchMessageIds.reduce((result, id) => {
                const message = chatSearchMessageDictionary[id];
                if (message) {
                    result.push(message);
                }
                return result;
            }, []);
            // 既存データと追加データをもとにマージする（prevId,nextIdの再計算）
            const mergedSearchMessages = mergeSearchMessages(payload.direction, payload.isLoadedOfEndOfSourceItems, ...payload.searchMessages, // 今回追加するデータから生成したSearchMessages
            ...currentSearchMessages // 既に存在するSearchMessages
            );
            // messagesを追加する
            chatSearchMessageAdapter.setMany(targetChatSearchEntity.messages, mergedSearchMessages);
        },
        // キーワードを設定する
        setChatSearchKeyword: (state, action) => {
            const payload = action.payload;
            const targetChatSearchEntity = state.chat.search.entities[payload.chatRoomId];
            // undefined(初期化されていない)場合は処理しない
            if (!targetChatSearchEntity) {
                return;
            }
            // キーワードが変更されている場合はメッセージ一覧を初期化する
            if (targetChatSearchEntity.keyword !== payload.keyword) {
                chatSearchAdapter.updateOne(state.chat.search, {
                    id: payload.chatRoomId,
                    changes: {
                        keyword: payload.keyword,
                        messages: chatSearchMessageAdapter.getInitialState(),
                    },
                });
            }
        },
        // 検索範囲を設定する
        setChatSearchSearchRange: (state, action) => {
            const payload = action.payload;
            const targetChatSearchEntity = state.chat.search.entities[payload.chatRoomId];
            // undefined(初期化されていない)場合は初期化する
            if (!targetChatSearchEntity) {
                chatSearchAdapter.addOne(state.chat.search, {
                    id: payload.chatRoomId,
                    messages: chatSearchMessageAdapter.getInitialState(),
                    searchRange: payload.searchRange,
                    keyword: undefined,
                });
            }
            else {
                // 検索範囲が変更されている場合はキーワード・メッセージ一覧をリセットする
                if (targetChatSearchEntity.searchRange !== payload.searchRange) {
                    chatSearchAdapter.updateOne(state.chat.search, {
                        id: payload.chatRoomId,
                        changes: {
                            searchRange: payload.searchRange,
                            keyword: undefined,
                            messages: chatSearchMessageAdapter.getInitialState(),
                        },
                    });
                }
            }
        },
        // --------------------------------------------
        // Attention
        // --------------------------------------------
        // Attentionメッセージを1件追加する
        addAttentionMessage: (state, action) => {
            const payload = action.payload;
            // 更新対象の表示形式をリスト化
            const targetDisplayTypes = [
                AttentionDisplayGroup.All, // すべてのAttentionメッセージを表示するリスト
                payload.attentionType, // 指定された種類のAttentionメッセージを表示するリスト
            ];
            // 各表示形式のメッセージリストに対して処理を行う
            targetDisplayTypes
                .map((type) => state.attention[type].messages)
                .forEach((messages) => {
                // 現在の最新メッセージID
                let latestMessageId = undefined;
                // リスト内に既にメッセージが存在する場合は、その最新メッセージのnextIdを追加されたメッセージidで更新する
                if (messages.ids.length > 0) {
                    const latestAttentionMessage = messages.entities[messages.ids.length - 1];
                    // messages.entitiesの最後の要素を取得しているので必ず値は入っているが、型の制約上undefinedのチェックを行う
                    if (latestAttentionMessage) {
                        latestMessageId = latestAttentionMessage.id;
                        // 既存の最新メッセージIDが、今回追加するメッセージIDよりも大きい場合は処理しない
                        // その状態でnextIdを更新する処理を走らせてしまうと、メッセージ順序の整合性が取れなくなるため
                        if (compareUlid(latestMessageId, '>=', payload.id)) {
                            return;
                        }
                        attentionMessageAdapter.updateOne(messages, {
                            id: latestMessageId,
                            changes: { nextId: payload.id }, // 追加するメッセージのIDをnextIdに設定
                        });
                    }
                }
                // Attentionメッセージを追加
                attentionMessageAdapter.addOne(messages, {
                    id: payload.id,
                    attentionType: payload.attentionType,
                    attentionUserName: payload.attentionUserName,
                    chatMessageId: payload.chatMessageId,
                    prevId: latestMessageId, // Attentionにメッセージが存在する場合は、その最新メッセージのIDをprevIdに設定し、存在しない場合はundefinedとなる
                    hasMorePrev: true, // 読み込まれていない歯抜けになったメッセージがある可能性があるため、trueを設定
                    nextId: undefined,
                    hasMoreNext: false, // このメッセージ以降の新しいメッセージは存在しないため、falseを設定
                });
            });
        },
        // AttensionMessagesデータ追加処理
        addAttentionMessages: (state, action) => {
            const payload = action.payload;
            const targetMessages = state.attention[payload.targetGroup].messages;
            const targetAttentionMessagesDictionary = targetMessages.entities;
            const attentionIds = targetMessages.ids;
            // 追加されたデータをもとにnextId,prevIdを再計算する必要があるので、
            // 対象チャットルームの現在のAttentionsをすべて取得する
            const currentAttentionMessages = attentionIds.reduce((result, id) => {
                const item = targetAttentionMessagesDictionary[id];
                if (item) {
                    result.push(item);
                }
                return result;
            }, []);
            // 既存データと追加データをもとにマージする（prevId,nextIdの再計算）
            const mergedAttentions = mergeAttentions(payload.direction, payload.isLoadedOfEndOfSourceItems, ...payload.messages, // 今回追加するデータから生成したAttentions
            ...currentAttentionMessages // 既に存在するAttentions
            );
            // マージ済みのmessagesをSliceに追加する
            attentionMessageAdapter.setMany(targetMessages, mergedAttentions);
        },
        // Attentionメッセージを1件削除する
        deleteAttentionMessage: (state, action) => {
            const payload = action.payload;
            // 全ての表示グループを対象に削除を行う
            Object.values(AttentionDisplayGroup).forEach((displayGroup) => {
                const messages = state.attention[displayGroup].messages;
                // 削除対象のAttentionを取得
                const deleteTargetAttentionEntity = messages.entities[payload.attentionId];
                // undefinedである(対象メッセージが存在しない)場合は処理しない
                if (!deleteTargetAttentionEntity) {
                    return;
                }
                /**
                 * 削除対象のアイテムのnextIdがnullでない → より新しいアイテムが存在する（prevIdが削除対象のidであるもの）ので、その新しいアイテムの
                 * 参照する古いアイテムを削除対象のアイテムの次のアイテムに差し替える
                 *
                 * 【例】
                 * (itemA) → (itemB) → (itemC)
                 * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
                 */
                if (deleteTargetAttentionEntity.nextId) {
                    attentionMessageAdapter.updateOne(messages, {
                        id: deleteTargetAttentionEntity.nextId,
                        changes: {
                            prevId: deleteTargetAttentionEntity.prevId,
                        },
                    });
                }
                /**
                 * 削除対象のアイテムのprevIdがnullでない → より古いアイテムが存在する（nextIdが削除対象のidであるもの）ので、その古いアイテムの
                 * 参照する新しいアイテムを削除対象のアイテムの前のアイテムに差し替える
                 *
                 * 【例】
                 * (itemA) → (itemB) → (itemC)
                 * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
                 */
                if (deleteTargetAttentionEntity.prevId) {
                    attentionMessageAdapter.updateOne(messages, {
                        id: deleteTargetAttentionEntity.prevId,
                        changes: {
                            nextId: deleteTargetAttentionEntity.nextId,
                        },
                    });
                }
                // 指定されたmessageを削除する
                attentionMessageAdapter.removeOne(messages, payload.attentionId);
            });
        },
        // Attentionのスクロール位置保存
        setAttentionScrollToEntityRecordId: (state, action) => {
            const payload = action.payload;
            state.attention[payload.targetGroup].scrollToEntityRecordId =
                payload.entityRecordId;
        },
        // Attentionのスクロール位置リセット
        resetAttentionScrollToEntityRecordId: (state, action) => {
            const payload = action.payload;
            state.attention[payload.targetGroup].scrollToEntityRecordId = undefined;
        },
        // --------------------------------------------
        // Subscription
        // --------------------------------------------
        // 購読対象にチャットルームIDを追加（チャットルームmetaチャンネル）
        addChatRoomMetaChannelSubscription: (state, action) => {
            const payload = action.payload;
            // 既にチャットルームIDが購読対象に存在する場合は何もしない
            if (state.subscription.chatRoomMetaChannels.entities[payload.id]) {
                return;
            }
            subscriptionChatInfoAdapter.addOne(state.subscription.chatRoomMetaChannels, {
                id: payload.id,
                subscribeState: Subscription.State.notSubscribed,
                handlers: subscriptionHandlerAdapter.getInitialState(),
            });
        },
        // 購読対象にチャットルームIDを追加（チャットルームdataチャンネル）
        addChatRoomDataChannelSubscription: (state, action) => {
            const payload = action.payload;
            // 既にチャットルームIDが購読対象に存在する場合は何もしない
            if (state.subscription.chatRoomDataChannels.entities[payload.id]) {
                return;
            }
            subscriptionChatInfoAdapter.addOne(state.subscription.chatRoomDataChannels, {
                id: payload.id,
                subscribeState: Subscription.State.notSubscribed,
                handlers: subscriptionHandlerAdapter.getInitialState(),
            });
        },
        // 購読対象にユーザーIDを追加（プレゼンスmetaチャンネル）
        addUserPresenceMetaChannelSubscription: (state, action) => {
            const payload = action.payload;
            // 既にユーザーIDが購読対象に存在する場合は何もしない
            if (state.subscription.presenceMetaChannels.entities[payload.userId]) {
                return;
            }
            subscriptionPresenceInfoAdapter.addOne(state.subscription.presenceMetaChannels, {
                userId: payload.userId,
                subscribeState: Subscription.State.notSubscribed,
                handlers: subscriptionHandlerAdapter.getInitialState(),
            });
        },
        // ハンドラー追加（ユーザーmetaチャンネル）
        addUserMetaChannelHandler: (state, action) => {
            // 既にハンドラーが登録済みの場合は何もしない
            if (state.subscription.userMetaChannel.handlers.entities[action.payload.messageType]) {
                return;
            }
            // ハンドラーを追加
            subscriptionHandlerAdapter.addOne(state.subscription.userMetaChannel.handlers, action.payload);
        },
        // ハンドラー追加（ユーザーdataチャンネル）
        addUserDataChannelHandler: (state, action) => {
            // 既にハンドラーが登録済みの場合は何もしない
            if (state.subscription.userDataChannel.handlers.entities[action.payload.messageType]) {
                return;
            }
            // ハンドラーを追加
            subscriptionHandlerAdapter.addOne(state.subscription.userDataChannel.handlers, action.payload);
        },
        // ハンドラー追加（チャットルームmetaチャンネル）
        addChatRoomMetaChannelHandler: (state, action) => {
            const payload = action.payload;
            const chatRoomMetaChannelEntity = state.subscription.chatRoomMetaChannels.entities[payload.id];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!chatRoomMetaChannelEntity) {
                return;
            }
            // 既にハンドラーが登録済みの場合は何もしない
            if (chatRoomMetaChannelEntity.handlers.entities[action.payload.messageType]) {
                return;
            }
            // ハンドラーを追加
            subscriptionHandlerAdapter.addOne(chatRoomMetaChannelEntity.handlers, {
                messageType: payload.messageType,
                subscriptionId: payload.subscriptionId,
            });
        },
        // ハンドラー追加（チャットルームdataチャンネル）
        addChatRoomDataChannelHandler: (state, action) => {
            const payload = action.payload;
            const chatRoomDataChannelEntity = state.subscription.chatRoomDataChannels.entities[payload.id];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!chatRoomDataChannelEntity) {
                return;
            }
            // 既にハンドラーが登録済みの場合は何もしない
            if (chatRoomDataChannelEntity.handlers.entities[action.payload.messageType]) {
                return;
            }
            // ハンドラーを追加
            subscriptionHandlerAdapter.addOne(chatRoomDataChannelEntity.handlers, {
                messageType: payload.messageType,
                subscriptionId: payload.subscriptionId,
            });
        },
        // ハンドラー追加（ユーザー在籍状態metaチャンネル）
        addUserPresenceMetaChannelHandler: (state, action) => {
            const payload = action.payload;
            const presenceMetaChannelEntity = state.subscription.presenceMetaChannels.entities[payload.userId];
            // 該当のユーザー情報が存在しない場合は何もしない
            if (!presenceMetaChannelEntity) {
                return;
            }
            // 既にハンドラーが登録済みの場合は何もしない
            if (presenceMetaChannelEntity.handlers.entities[action.payload.messageType]) {
                return;
            }
            // ハンドラーを追加
            subscriptionHandlerAdapter.addOne(presenceMetaChannelEntity.handlers, {
                messageType: payload.messageType,
                subscriptionId: payload.subscriptionId,
            });
        },
        // ハンドラー削除（ユーザーmetaチャンネル）
        deleteUserMetaChannelHandler: (state, action) => {
            subscriptionHandlerAdapter.removeOne(state.subscription.userMetaChannel.handlers, action.payload.messageType);
        },
        // ハンドラー削除（ユーザーdataチャンネル）
        deleteUserDataChannelHandler: (state, action) => {
            subscriptionHandlerAdapter.removeOne(state.subscription.userDataChannel.handlers, action.payload.messageType);
        },
        // ハンドラー削除（チャットルームmetaチャンネル）
        deleteChatRoomMetaChannelHandler: (state, action) => {
            const payload = action.payload;
            const chatRoomMetaChannelEntity = state.subscription.chatRoomMetaChannels.entities[payload.id];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!chatRoomMetaChannelEntity) {
                return;
            }
            // ハンドラー削除
            subscriptionHandlerAdapter.removeOne(chatRoomMetaChannelEntity.handlers, action.payload.messageType);
        },
        // ハンドラー削除（チャットルームdataチャンネル）
        deleteChatRoomDataChannelHandler: (state, action) => {
            const payload = action.payload;
            const chatRoomDataChannelEntity = state.subscription.chatRoomDataChannels.entities[payload.id];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!chatRoomDataChannelEntity) {
                return;
            }
            // ハンドラー削除
            subscriptionHandlerAdapter.removeOne(chatRoomDataChannelEntity.handlers, action.payload.messageType);
        },
        // ハンドラー削除（ユーザー在籍状態metaチャンネル）
        deleteUserPresenceMetaChannelHandler: (state, action) => {
            const payload = action.payload;
            const presenceMetaChannelEntity = state.subscription.presenceMetaChannels.entities[payload.userId];
            // 該当のユーザー情報が存在しない場合は何もしない
            if (!presenceMetaChannelEntity) {
                return;
            }
            // ハンドラー削除
            subscriptionHandlerAdapter.removeOne(presenceMetaChannelEntity.handlers, action.payload.messageType);
        },
        // チャンネルの購読状態をnotSubscribedに更新（ユーザーmetaチャンネル）
        updateUserMetaChannelSubscribeStateNotSubscribed: (state) => {
            state.subscription.userMetaChannel.subscribeState =
                Subscription.State.notSubscribed;
        },
        // チャンネルの購読状態をsubscribingに更新（ユーザーmetaチャンネル）
        updateUserMetaChannelSubscribeStateSubscribing: (state) => {
            // 既にsubscribedになっている場合は巻き戻らないようにするため処理しない
            if (state.subscription.userMetaChannel.subscribeState ===
                Subscription.State.subscribed) {
                return;
            }
            state.subscription.userMetaChannel.subscribeState =
                Subscription.State.subscribing;
        },
        // チャンネルの購読状態をsubscribedに更新（ユーザーmetaチャンネル）
        updateUserMetaChannelSubscribeStateSubscribed: (state) => {
            state.subscription.userMetaChannel.subscribeState =
                Subscription.State.subscribed;
        },
        // チャンネルの購読状態をnotSubscribedに更新（ユーザーdataチャンネル）
        updateUserDataChannelSubscribeStateNotSubscribed: (state) => {
            state.subscription.userDataChannel.subscribeState =
                Subscription.State.notSubscribed;
        },
        // チャンネルの購読状態をsubscribingに更新（ユーザーdataチャンネル）
        updateUserDataChannelSubscribeStateSubscribing: (state) => {
            // 既にsubscribedになっている場合は巻き戻らないようにするため処理しない
            if (state.subscription.userDataChannel.subscribeState ===
                Subscription.State.subscribed) {
                return;
            }
            state.subscription.userDataChannel.subscribeState =
                Subscription.State.subscribing;
        },
        // チャンネルの購読状態をsubscribedに更新（ユーザーdataチャンネル）
        updateUserDataChannelSubscribeStateSubscribed: (state) => {
            state.subscription.userDataChannel.subscribeState =
                Subscription.State.subscribed;
        },
        // チャンネルの購読状態をnotSubscribedに更新（チャットルームmetaチャンネル）
        updateChatRoomMetaChannelSubscribeStateNotSubscribed: (state, action) => {
            const payload = action.payload;
            const chatRoomMetaChannelEntity = state.subscription.chatRoomMetaChannels.entities[payload.id];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!chatRoomMetaChannelEntity) {
                return;
            }
            chatRoomMetaChannelEntity.subscribeState =
                Subscription.State.notSubscribed;
        },
        // チャンネルの購読状態をsubscribingに更新（チャットルームmetaチャンネル）
        updateChatRoomMetaChannelSubscribeStateSubscribing: (state, action) => {
            const payload = action.payload;
            const chatRoomMetaChannelEntity = state.subscription.chatRoomMetaChannels.entities[payload.id];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!chatRoomMetaChannelEntity) {
                return;
            }
            // 既にsubscribedになっている場合は巻き戻らないようにするため処理しない
            if (chatRoomMetaChannelEntity.subscribeState ===
                Subscription.State.subscribed) {
                return;
            }
            chatRoomMetaChannelEntity.subscribeState = Subscription.State.subscribing;
        },
        // チャンネルの購読状態をsubscribedに更新（チャットルームmetaチャンネル）
        updateChatRoomMetaChannelSubscribeStateSubscribed: (state, action) => {
            const payload = action.payload;
            const chatRoomMetaChannelEntity = state.subscription.chatRoomMetaChannels.entities[payload.id];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!chatRoomMetaChannelEntity) {
                return;
            }
            chatRoomMetaChannelEntity.subscribeState = Subscription.State.subscribed;
        },
        // チャンネルの購読状態をnotSubscribedに更新（チャットルームdataチャンネル）
        updateChatRoomDataChannelSubscribeStateNotSubscribed: (state, action) => {
            const payload = action.payload;
            const chatRoomDataChannelEntity = state.subscription.chatRoomDataChannels.entities[payload.id];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!chatRoomDataChannelEntity) {
                return;
            }
            chatRoomDataChannelEntity.subscribeState =
                Subscription.State.notSubscribed;
        },
        // チャンネルの購読状態をsubscribingに更新（チャットルームdataチャンネル）
        updateChatRoomDataChannelSubscribeStateSubscribing: (state, action) => {
            const payload = action.payload;
            const chatRoomDataChannelEntity = state.subscription.chatRoomDataChannels.entities[payload.id];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!chatRoomDataChannelEntity) {
                return;
            }
            // 既にsubscribedになっている場合は巻き戻らないようにするため処理しない
            if (chatRoomDataChannelEntity.subscribeState ===
                Subscription.State.subscribed) {
                return;
            }
            chatRoomDataChannelEntity.subscribeState = Subscription.State.subscribing;
        },
        // チャンネルの購読状態をsubscribedに更新（チャットルームdataチャンネル）
        updateChatRoomDataChannelSubscribeStateSubscribed: (state, action) => {
            const payload = action.payload;
            const chatRoomDataChannelEntity = state.subscription.chatRoomDataChannels.entities[payload.id];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!chatRoomDataChannelEntity) {
                return;
            }
            chatRoomDataChannelEntity.subscribeState = Subscription.State.subscribed;
        },
        // チャンネルの購読状態をnotSubscribedに更新（ユーザー在籍状態metaチャンネル）
        updateUserPresenceMetaChannelSubscribeStateNotSubscribed: (state, action) => {
            const payload = action.payload;
            const presenceMetaChannelEntity = state.subscription.presenceMetaChannels.entities[payload.userId];
            // 該当のユーザー情報が存在しない場合は何もしない
            if (!presenceMetaChannelEntity) {
                return;
            }
            presenceMetaChannelEntity.subscribeState =
                Subscription.State.notSubscribed;
        },
        // チャンネルの購読状態をsubscribingに更新（ユーザー在籍状態metaチャンネル）
        updateUserPresenceMetaChannelSubscribeStateSubscribing: (state, action) => {
            const payload = action.payload;
            const presenceMetaChannelEntity = state.subscription.presenceMetaChannels.entities[payload.userId];
            // 該当のユーザー情報が存在しない場合は何もしない
            if (!presenceMetaChannelEntity) {
                return;
            }
            // 既にsubscribedになっている場合は巻き戻らないようにするため処理しない
            if (presenceMetaChannelEntity.subscribeState ===
                Subscription.State.subscribed) {
                return;
            }
            presenceMetaChannelEntity.subscribeState = Subscription.State.subscribing;
        },
        // チャンネルの購読状態をsubscribedに更新（ユーザー在籍状態metaチャンネル）
        updateUserPresenceMetaChannelSubscribeStateSubscribed: (state, action) => {
            const payload = action.payload;
            const presenceMetaChannelEntity = state.subscription.presenceMetaChannels.entities[payload.userId];
            // 該当のチャットルーム情報が存在しない場合は何もしない
            if (!presenceMetaChannelEntity) {
                return;
            }
            presenceMetaChannelEntity.subscribeState = Subscription.State.subscribed;
        },
        // --------------------------------------------
        // Unread
        // --------------------------------------------
        /**
         * chatRoom未読件数をセット(上書き)する
         * @param state
         * @param action
         */
        setChatRoomUnreadCount: (state, action) => {
            var _a, _b;
            const { count, chatRoomId, lastReadMessageId, updateSerial } = action.payload;
            // chat系はEntityAdapterを使って更新
            const targetInfo = state.unread.chatRooms;
            // payloadの方が古い場合は更新しない
            if (compareUlid(updateSerial, '<', (_b = (_a = targetInfo.entities[chatRoomId]) === null || _a === void 0 ? void 0 : _a.updateSerial) !== null && _b !== void 0 ? _b : ULID_ZERO)) {
                return;
            }
            // changesのみを更新するため、また新規追加の可能性もあるためaddやupdateでなくupsertを使う
            unreadChatInfoAdapter.upsertOne(targetInfo, {
                id: chatRoomId,
                unreadCount: count, // shallowなので各要素は上書きされる
                lastReadMessageId: lastReadMessageId,
                updateSerial,
            });
        },
        /**
         * chatThread未読件数をセット(上書き)する
         * @param state
         * @param action
         */
        setChatThreadUnreadCount: (state, action) => {
            var _a, _b;
            const { count, topicId, lastReadMessageId, updateSerial } = action.payload;
            // chat系はEntityAdapterを使って更新
            const targetInfo = state.unread.chatThreads;
            // payloadの方が古い場合は更新しない
            if (compareUlid(updateSerial, '<', (_b = (_a = targetInfo.entities[topicId]) === null || _a === void 0 ? void 0 : _a.updateSerial) !== null && _b !== void 0 ? _b : ULID_ZERO)) {
                return;
            }
            // changesのみを更新するため、また新規追加の可能性もあるためaddやupdateでなくupsertを使う
            unreadChatInfoAdapter.upsertOne(targetInfo, {
                id: topicId,
                unreadCount: count, // shallowなので各要素は上書きされる
                lastReadMessageId: lastReadMessageId,
                updateSerial,
            });
        },
        /**
         * chatThread未読件数一覧をセット(上書き)する
         * @param state
         * @param action
         */
        setChatThreadUnreadCounts: (state, action) => {
            // チャットスレッドの未読件数一覧
            const threadUnreadCounts = action.payload.threadUnreadCounts;
            // 操作対象の未読情報（チャットスレッド）
            const targetInfo = state.unread.chatThreads;
            // チャットスレッド毎に処理する
            threadUnreadCounts.forEach((threadUnreadCount) => {
                var _a, _b;
                // payloadの方が古い場合は更新しない
                if (compareUlid(threadUnreadCount.updateSerial, '<', (_b = (_a = targetInfo.entities[threadUnreadCount.topicId]) === null || _a === void 0 ? void 0 : _a.updateSerial) !== null && _b !== void 0 ? _b : ULID_ZERO)) {
                    return;
                }
                // 未読情報を更新
                unreadChatInfoAdapter.upsertOne(targetInfo, {
                    id: threadUnreadCount.topicId,
                    unreadCount: threadUnreadCount.count, // shallowなので各要素は上書きされる
                    updateSerial: threadUnreadCount.updateSerial,
                    lastReadMessageId: threadUnreadCount.lastReadMessageId,
                });
            });
        },
        /**
         * chatRoomの既読メッセージIDを更新する。指定したIDが現在の既読IDよりも古いものの場合は何もしない
         * @param state
         * @param action
         */
        updateChatRoomLastReadMessageId: (state, action) => {
            var _a, _b, _c;
            const { chatRoomId, messageId } = action.payload;
            // chat系はEntityAdapterを使って更新
            const targetInfo = state.unread.chatRooms;
            const currentLastReadMessageId = (_a = targetInfo.entities[chatRoomId]) === null || _a === void 0 ? void 0 : _a.lastReadMessageId;
            if (currentLastReadMessageId &&
                compareUlid(currentLastReadMessageId, '>=', messageId)) {
                // 指定されたIDが現在のIDと等しいか古い、何もしない
                console.info(`updateLastReadMessageId: no update. current:${currentLastReadMessageId}, param:${messageId}}`);
                return;
            }
            // updateSerialはここでは更新しない。もし新規追加の場合は、どのようなserialでも更新されるようULID_ZEROを当てておく
            const updateSerial = (_c = (_b = targetInfo.entities[chatRoomId]) === null || _b === void 0 ? void 0 : _b.updateSerial) !== null && _c !== void 0 ? _c : ULID_ZERO;
            // changesのみを更新するため、また新規追加の可能性もあるためaddやupdateでなくupsertを使う
            unreadChatInfoAdapter.upsertOne(targetInfo, {
                id: chatRoomId,
                lastReadMessageId: messageId,
                updateSerial,
            });
        },
        /**
         * ChatThreadの既読メッセージIDを更新する。指定したIDが現在の既読IDよりも古いものの場合は何もしない
         * @param state
         * @param action
         */
        updateChatThreadLastReadMessageId: (state, action) => {
            var _a, _b, _c;
            const { topicId, messageId } = action.payload;
            // chat系はEntityAdapterを使って更新
            const targetInfo = state.unread.chatThreads;
            const currentLastReadMessageId = (_a = targetInfo.entities[topicId]) === null || _a === void 0 ? void 0 : _a.lastReadMessageId;
            if (currentLastReadMessageId &&
                compareUlid(currentLastReadMessageId, '>=', messageId)) {
                // 指定されたIDが現在のIDと等しいか古い、何もしない
                console.info(`updateLastReadMessageId: no update. current:${currentLastReadMessageId}, param:${messageId}}`);
                return;
            }
            // updateSerialはここでは更新しない。もし新規追加の場合は、どのようなserialでも更新されるようULID_ZEROを当てておく
            const updateSerial = (_c = (_b = targetInfo.entities[topicId]) === null || _b === void 0 ? void 0 : _b.updateSerial) !== null && _c !== void 0 ? _c : ULID_ZERO;
            // changesのみを更新するため、また新規追加の可能性もあるためaddやupdateでなくupsertを使う
            unreadChatInfoAdapter.upsertOne(targetInfo, {
                id: topicId,
                lastReadMessageId: messageId,
                updateSerial,
            });
        },
        /**
         * チャットルーム・チャットスレッドのすべての未読情報をリセットする
         * @param state
         */
        resetAllChatUnread: (state) => {
            // チャットルーム・チャットスレッドのすべての未読情報をリセットする
            unreadChatInfoAdapter.removeAll(state.unread.chatRooms);
            unreadChatInfoAdapter.removeAll(state.unread.chatThreads);
        },
        // --------------------------------------------
        // Feed
        // --------------------------------------------
        // Feedに最新のメッセージを1件追加する
        // WebSocket経由で最新データを追加する際の処理となる
        addFeedLatestMessage: (state, action) => {
            const payload = action.payload;
            // 対象フィード内のメッセージリスト
            const messages = state.feed.messages;
            // 現在の最新メッセージIDを格納する変数
            let latestFeedMessageId = undefined;
            // Feedにメッセージが既に存在する場合は、その既存の最新メッセージのnextIdを、追加するメッセージIDで更新する
            if (messages.ids.length > 0) {
                const latestFeedMessage = messages.entities[messages.ids.length - 1];
                // messages.entitiesの最後の要素を取得しているので必ず値は入っているが、型の制約上undefinedのチェックを行う
                if (latestFeedMessage) {
                    latestFeedMessageId = latestFeedMessage.id;
                    // 既存の最新メッセージIDが、今回追加するメッセージIDよりも大きい場合は処理しない
                    // その状態でnextIdを更新する処理を走らせてしまうと、メッセージ順序の整合性が取れなくなるため
                    if (compareUlid(latestFeedMessageId, '>=', payload.id)) {
                        return;
                    }
                    feedAdapter.updateOne(messages, {
                        id: latestFeedMessageId,
                        changes: { nextId: payload.id }, // 追加するメッセージのIDをnextIdに設定
                    });
                }
            }
            feedAdapter.addOne(messages, {
                id: payload.id, // 現状、IDにはチャットメッセージIDと同じものを使用する
                chatMessageId: payload.id,
                prevId: latestFeedMessageId, // Feedにメッセージが存在する場合は、その最新メッセージのIDをprevIdに設定し、存在しない場合はundefinedとなる
                hasMorePrev: false,
                nextId: undefined,
                hasMoreNext: false,
            });
        },
        // Feedのmessagesデータ追加処理
        addFeedMessages: (state, action) => {
            const payload = action.payload;
            const targetFeedMessagesDictionary = state.feed.messages.entities;
            const feedIds = state.feed.messages.ids;
            // 追加されたデータをもとにnextId,prevIdを再計算する必要があるので、
            // 現在のデータをすべて取得する
            const currentFeedMessages = feedIds.reduce((result, id) => {
                const item = targetFeedMessagesDictionary[id];
                if (item) {
                    result.push(item);
                }
                return result;
            }, []);
            // 既存データと追加データをもとにマージする（prevId,nextIdの再計算）
            const mergedFeed = mergeFeedMessages(payload.direction, payload.isLoadedOfEndOfSourceItems, ...payload.feedMessages, // 今回追加するデータから生成したTimelineMessages
            ...currentFeedMessages // 既に存在するTimelineMessages
            );
            // マージしたFeedのmessagesをSliceに適用する
            feedAdapter.setMany(state.feed.messages, mergedFeed);
        },
        // Feedの指定されたmessageデータ削除処理
        deleteFeedMessage: (state, action) => {
            const payload = action.payload;
            // 削除対象のFeedMessageを取得
            const deleteTargetFeedMessageEntity = state.feed.messages.entities[payload.id];
            // undefinedである(対象メッセージが存在しない)場合は処理しない
            if (!deleteTargetFeedMessageEntity) {
                return;
            }
            /**
             * 削除対象のアイテムのnextIdがnullでない → より新しいアイテムが存在する（prevIdが削除対象のidであるもの）ので、その新しいアイテムの
             * 参照する古いアイテムを削除対象のアイテムの次のアイテムに差し替える
             *
             * 【例】
             * (itemA) → (itemB) → (itemC)
             * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
             */
            if (deleteTargetFeedMessageEntity.nextId) {
                feedAdapter.updateOne(state.feed.messages, {
                    id: deleteTargetFeedMessageEntity.nextId,
                    changes: {
                        prevId: deleteTargetFeedMessageEntity.prevId,
                    },
                });
            }
            /**
             * 削除対象のアイテムのprevIdがnullでない → より古いアイテムが存在する（nextIdが削除対象のidであるもの）ので、その古いアイテムの
             * 参照する新しいアイテムを削除対象のアイテムの前のアイテムに差し替える
             *
             * 【例】
             * (itemA) → (itemB) → (itemC)
             * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
             */
            if (deleteTargetFeedMessageEntity.prevId) {
                feedAdapter.updateOne(state.feed.messages, {
                    id: deleteTargetFeedMessageEntity.prevId,
                    changes: {
                        nextId: deleteTargetFeedMessageEntity.nextId,
                    },
                });
            }
            // 指定されたIDのmessageを削除する
            feedAdapter.removeOne(state.feed.messages, payload.id);
        },
        // Feedのスクロール位置保存
        setFeedScrollToMessageId: (state, action) => {
            const payload = action.payload;
            state.feed.scrollToMessageId = payload.chatMessageId;
        },
        // --------------------------------------------
        // Bookmark
        // --------------------------------------------
        // 取得したデータをリセットする
        // フィルタ項目変更時、既に取得済みのデータをリセットする必要があるため
        // そのため、アーカイブフィルタとキーワードはリセット対象外
        resetBookmarks: (state) => {
            state.bookmark.messages = bookmarkAdapter.getInitialState();
            state.bookmark.scrollToMessageId = undefined;
        },
        // Bookmarkメッセージを1件追加する
        addBookmarkMessage: (state, action) => {
            const payload = action.payload;
            // 対象のメッセージリスト
            const messages = state.bookmark.messages;
            // 現在の最新メッセージIDを格納する変数
            let latestMessageId = undefined;
            // bookmarkにメッセージが既に存在する場合は、その既存の最新メッセージのnextIdを、追加するメッセージIDで更新する
            if (messages.ids.length > 0) {
                const latestMessage = messages.entities[messages.ids.length - 1];
                // messages.entitiesの最後の要素を取得しているので必ず値は入っているが、型の制約上undefinedのチェックを行う
                if (latestMessage) {
                    latestMessageId = latestMessage.id;
                    // 既存の最新メッセージの登録日時が、今回追加するメッセージの登録日時よりも大きい場合
                    // (latestMessage.createdAt >= payload.createdAt)は処理しない
                    // その状態でnextIdを更新する処理を走らせてしまうと、メッセージ順序の整合性が取れなくなるため
                    const latestMessageCreatedAt = dayjs(latestMessage.createdAt);
                    const payloadCreatedAt = dayjs(payload.createdAt);
                    if (latestMessageCreatedAt.isSame(payloadCreatedAt) ||
                        latestMessageCreatedAt.isAfter(payloadCreatedAt)) {
                        return;
                    }
                    bookmarkAdapter.updateOne(messages, {
                        id: latestMessageId,
                        changes: { nextId: payload.chatMessageId }, // 追加するメッセージのIDをnextIdに設定
                    });
                }
            }
            // Bookmarkメッセージを追加
            bookmarkAdapter.addOne(messages, {
                id: payload.chatMessageId,
                archived: payload.archived,
                createdAt: payload.createdAt,
                prevId: latestMessageId ? latestMessageId : undefined,
                hasMorePrev: true, // 読み込まれていない歯抜けになったメッセージがある可能性があるため、trueを設定
                nextId: undefined,
                hasMoreNext: false, // このメッセージ以降の新しいメッセージは存在しないため、falseを設定
            });
        },
        // BookmarkMessagesデータ追加処理
        addBookmarkMessages: (state, action) => {
            const payload = action.payload;
            const targetBookmarkMessagesDictionary = state.bookmark.messages.entities;
            const bookmarkIds = state.bookmark.messages.ids;
            // 追加されたデータをもとにnextId,prevIdを再計算する必要があるので、
            // 現在のデータをすべて取得する
            const currentBookmarkMessages = bookmarkIds.reduce((result, id) => {
                const item = targetBookmarkMessagesDictionary[id];
                if (item) {
                    result.push(item);
                }
                return result;
            }, []);
            // 既存データと追加データをもとにマージする（prevId,nextIdの再計算）
            const mergedBookmarks = mergeBookmarks(payload.direction, payload.isLoadedOfEndOfSourceItems, ...payload.messages, // 今回追加するデータから生成したBookmarks
            ...currentBookmarkMessages // 既に存在するBookmarks
            );
            // Bookmarkにmessagesを追加する
            bookmarkAdapter.setMany(state.bookmark.messages, mergedBookmarks);
        },
        // Bookmarkメッセージを1件削除する
        deleteBookmarkMessage: (state, action) => {
            const payload = action.payload;
            // 処理対象のEntityを取得
            const targetBookmarksEntity = state.bookmark;
            // undefinedである(Bookmarkが作成されていない)場合は処理しない
            if (!targetBookmarksEntity) {
                return;
            }
            // 削除対象のBookmarkを取得
            const deleteTargetBookmark = targetBookmarksEntity.messages.entities[payload.bookmarkId];
            // undefinedである(対象メッセージが存在しない)場合は処理しない
            if (!deleteTargetBookmark) {
                return;
            }
            /**
             * 削除対象のアイテムのnextIdがnullでない → より新しいアイテムが存在する（prevIdが削除対象のidであるもの）ので、その新しいアイテムの
             * 参照する古いアイテムを削除対象のアイテムの次のアイテムに差し替える
             *
             * 【例】
             * (itemA) → (itemB) → (itemC)
             * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
             */
            if (deleteTargetBookmark.nextId) {
                bookmarkAdapter.updateOne(targetBookmarksEntity.messages, {
                    id: deleteTargetBookmark.nextId,
                    changes: {
                        prevId: deleteTargetBookmark.prevId,
                    },
                });
            }
            /**
             * 削除対象のアイテムのprevIdがnullでない → より古いアイテムが存在する（nextIdが削除対象のidであるもの）ので、その古いアイテムの
             * 参照する新しいアイテムを削除対象のアイテムの前のアイテムに差し替える
             *
             * 【例】
             * (itemA) → (itemB) → (itemC)
             * この構造でitemBを削除する場合、itemAのnextIdをitemCのidに、itemCのprevIdをitemAのidに差し替える
             */
            if (deleteTargetBookmark.prevId) {
                bookmarkAdapter.updateOne(targetBookmarksEntity.messages, {
                    id: deleteTargetBookmark.prevId,
                    changes: {
                        nextId: deleteTargetBookmark.nextId,
                    },
                });
            }
            // 対象ブックマークに紐づく、指定されたmessageを削除する
            bookmarkAdapter.removeOne(targetBookmarksEntity.messages, payload.bookmarkId);
        },
        // Bookmarkのスクロール位置保存
        setBookmarkScrollToMessageId: (state, action) => {
            const payload = action.payload;
            state.bookmark.scrollToMessageId = payload.chatMessageId;
        },
        // Bookmarkのスクロール位置リセット
        resetBookmarkScrollToMessageId: (state) => {
            state.bookmark.scrollToMessageId = undefined;
        },
        // Bookmarkのアーカイブフィルタ保存
        setBookmarkArchiveFilter: (state, action) => {
            const payload = action.payload;
            state.bookmark.archiveFilter = payload.archiveFilter;
        },
        // Bookmarkのキーワード保存
        setBookmarKeyword: (state, action) => {
            const payload = action.payload;
            state.bookmark.keyword = payload.keyword;
        },
        // Bookmarkのキーワードリセット
        resetBookmarkKeyword: (state) => {
            state.bookmark.keyword = undefined;
        },
        // -----------------------------------
        // CacheMessage
        // -----------------------------------
        // チャットメッセージのキャッシュを複数件追加する
        addCacheMessages: (state, action) => {
            const payload = action.payload;
            // 新規項目は追加、既存項目は置換
            // https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
            cacheMessageAdapter.setMany(state.chat.cacheMessages, payload.chatMessages);
        },
        // チャットメッセージのキャッシュを1件追加する。既に存在する場合は置換する
        setCacheMessage: (state, action) => {
            const payload = action.payload;
            // 新規項目は追加、既存項目は置換
            // https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
            cacheMessageAdapter.setOne(state.chat.cacheMessages, payload.chatMessage);
        },
        // 指定されたチャットメッセージのキャッシュを削除する
        deleteCacheMessage: (state, action) => {
            // キャッシュから削除
            const payload = action.payload;
            cacheMessageAdapter.removeOne(state.chat.cacheMessages, payload.chatMessageId);
        },
        // キャッシュ済みのメッセージにリアクションを追加する
        insertReaction: (state, action) => {
            var _a;
            const newReaction = action.payload.reaction;
            const message = state.chat.cacheMessages.entities[newReaction.chatMessageId];
            // メッセージがredux内に存在しない場合、何もしない
            if (!message) {
                return;
            }
            // 既に同じユーザーからの同じリアクションがあるのはおかしいのでその場合はreturnする
            // マイページのフィードのアイテムクリック時に、右側のチャットパネルに表示されたmessageにリアクションを付けるとreactionInsertedが二回走り、
            // フィードとチャットパネルでsliceを共有していることにより1つ余分にリアクションが増えてしまうため
            if (message.reactions.some((reaction) => isSameReaction(reaction, newReaction))) {
                return;
            }
            // 上のチェックで同じリアクションが存在しない場合はpayloadを追加する
            const newReactions = [...((_a = message.reactions) !== null && _a !== void 0 ? _a : []), newReaction];
            cacheMessageAdapter.updateOne(state.chat.cacheMessages, {
                id: newReaction.chatMessageId,
                changes: { reactions: newReactions },
            });
        },
        // キャッシュ済みのメッセージからリアクションを削除する
        deleteReaction: (state, action) => {
            const deleteReaction = action.payload.reaction;
            const message = state.chat.cacheMessages.entities[deleteReaction.chatMessageId];
            // メッセージがredux内に存在しない場合、何もしない
            if (!message) {
                return;
            }
            // 既存のリアクションから、削除したリアクションを除外
            const newReactions = message.reactions.filter((reaction) => !isSameReaction(reaction, deleteReaction));
            cacheMessageAdapter.updateOne(state.chat.cacheMessages, {
                id: deleteReaction.chatMessageId,
                changes: { reactions: newReactions },
            });
        },
        setActiveChatRoomWithUnreadCount: (state, action) => {
            var _a, _b, _c, _d, _e, _f, _g;
            const activeChatRoom = action.payload.activeChatRoom;
            // アクティブチャットルームになれるのはprojectのみ
            if (activeChatRoom.referenceEntityType === EntityType.Project) {
                // アクティブチャットルーム情報の操作
                const targetActiveChatRoomInfo = state.activeChatRoom.chatRooms;
                // nullチェック
                if (activeChatRoom.isFavorite === null ||
                    activeChatRoom.projectName === null ||
                    activeChatRoom.projectScope === null ||
                    activeChatRoom.projectType === null ||
                    activeChatRoom.projectVersion === null) {
                    console.error('activeChatRoom is invalid. backend error?', activeChatRoom);
                    return;
                }
                // setOneする（初回時は追加、既存の場合は差し替え）
                activeChatRoomAdapter.setOne(targetActiveChatRoomInfo, {
                    chatRoomId: activeChatRoom.chatRoomId,
                    parentChatRoomId: activeChatRoom.parentChatRoomId,
                    isFavorite: activeChatRoom.isFavorite,
                    name: activeChatRoom.projectName,
                    scope: activeChatRoom.projectScope,
                    projectType: activeChatRoom.projectType,
                    projectId: activeChatRoom.referenceEntityRecordId,
                    projectVersion: activeChatRoom.projectVersion,
                });
            }
            // 祖先/子孫情報の操作
            {
                const targetAncestorDescendantsInfo = state.unread.ancestorDescendants;
                const chatRoomInfo = targetAncestorDescendantsInfo.entities[activeChatRoom.chatRoomId];
                // 自分の親を登録する
                unreadAncestorDescendantsAdapter.upsertOne(targetAncestorDescendantsInfo, {
                    id: activeChatRoom.chatRoomId,
                    parentChatRoomId: activeChatRoom.parentChatRoomId,
                    descendants: (_a = chatRoomInfo === null || chatRoomInfo === void 0 ? void 0 : chatRoomInfo.descendants) !== null && _a !== void 0 ? _a : [],
                });
                // 親をたどり、子孫を登録していく
                let parentId = activeChatRoom.parentChatRoomId;
                let descendants = [
                    activeChatRoom.chatRoomId,
                    ...((_b = chatRoomInfo === null || chatRoomInfo === void 0 ? void 0 : chatRoomInfo.descendants) !== null && _b !== void 0 ? _b : []),
                ];
                // 階層構造を取りうることを想定し、ループで親を辿って最初の祖先まで更新する
                while (parentId) {
                    const parentInfo = targetAncestorDescendantsInfo.entities[parentId];
                    let parentDescendants = (_c = parentInfo === null || parentInfo === void 0 ? void 0 : parentInfo.descendants) !== null && _c !== void 0 ? _c : [];
                    if (parentInfo === undefined || // 親の情報が存在しない場合、または
                        descendants.some((descendant) => !parentDescendants.includes(descendant)) // ここまでの子孫のいずれかが親に登録されていない場合
                    ) {
                        // 親の子孫情報を更新する
                        parentDescendants = arrayUniq([
                            ...parentDescendants,
                            ...descendants,
                        ]);
                        unreadAncestorDescendantsAdapter.upsertOne(targetAncestorDescendantsInfo, {
                            id: parentId,
                            parentChatRoomId: (_d = parentInfo === null || parentInfo === void 0 ? void 0 : parentInfo.parentChatRoomId) !== null && _d !== void 0 ? _d : null,
                            descendants: parentDescendants,
                        });
                    }
                    // 最初の祖先まで辿る
                    descendants = parentDescendants;
                    parentId = (_e = parentInfo === null || parentInfo === void 0 ? void 0 : parentInfo.parentChatRoomId) !== null && _e !== void 0 ? _e : null;
                }
            }
            // 未読情報の操作
            {
                const targetUnreadInfo = state.unread.chatRooms;
                // payloadの方が古い場合は更新しない
                if (compareUlid(activeChatRoom.updateSerial, '<', (_g = (_f = targetUnreadInfo.entities[activeChatRoom.chatRoomId]) === null || _f === void 0 ? void 0 : _f.updateSerial) !== null && _g !== void 0 ? _g : ULID_ZERO)) {
                    return;
                }
                // setOneする（初回時は追加、既存の場合は差し替え）
                unreadChatInfoAdapter.setOne(targetUnreadInfo, {
                    id: activeChatRoom.chatRoomId,
                    unreadCount: activeChatRoom.unreadCount,
                    updateSerial: activeChatRoom.updateSerial,
                    lastReadMessageId: activeChatRoom.lastReadMessageId,
                });
            }
        },
        /**
         * アクティブチャットルーム一覧取得時に実行する関数
         * 既存のアクティブチャットルーム情報や未読情報は破棄し、すべてpayloadの情報で差し替える
         * @param state
         * @param action
         */
        setActiveChatRoomsWithUnreadCount: (state, action) => {
            const activeChatRooms = action.payload.activeChatRooms;
            // アクティブチャットルーム情報の操作
            const targetActiveChatRoomInfo = state.activeChatRoom.chatRooms;
            // setなので一旦全部消す
            activeChatRoomAdapter.removeAll(targetActiveChatRoomInfo);
            // 未読情報の操作対象を取得
            const targetUnreadInfo = state.unread.chatRooms;
            // この処理は全てを差し替える操作のため、既存の全ての未読情報を削除する
            unreadChatInfoAdapter.removeAll(targetUnreadInfo);
            // チャットルーム分だけ処理する
            activeChatRooms.forEach((activeChatRoom) => {
                var _a, _b, _c, _d, _e;
                // アクティブチャットルームになれるのはprojectのみ
                if (activeChatRoom.referenceEntityType === EntityType.Project) {
                    // nullチェック
                    if (activeChatRoom.isFavorite === null ||
                        activeChatRoom.projectName === null ||
                        activeChatRoom.projectScope === null ||
                        activeChatRoom.projectType === null ||
                        activeChatRoom.projectVersion === null) {
                        console.error('activeChatRoom is invalid. backend error?', activeChatRoom);
                        return;
                    }
                    // setOneする(常に新しい値を使用する)
                    activeChatRoomAdapter.setOne(targetActiveChatRoomInfo, {
                        chatRoomId: activeChatRoom.chatRoomId,
                        parentChatRoomId: activeChatRoom.parentChatRoomId,
                        isFavorite: activeChatRoom.isFavorite,
                        name: activeChatRoom.projectName,
                        scope: activeChatRoom.projectScope,
                        projectType: activeChatRoom.projectType,
                        projectId: activeChatRoom.referenceEntityRecordId,
                        projectVersion: activeChatRoom.projectVersion,
                    });
                }
                // 祖先/子孫情報の操作
                {
                    const targetAncestorDescendantsInfo = state.unread.ancestorDescendants;
                    const chatRoomInfo = targetAncestorDescendantsInfo.entities[activeChatRoom.chatRoomId];
                    // 自分の親を登録する
                    unreadAncestorDescendantsAdapter.upsertOne(targetAncestorDescendantsInfo, {
                        id: activeChatRoom.chatRoomId,
                        parentChatRoomId: activeChatRoom.parentChatRoomId,
                        descendants: (_a = chatRoomInfo === null || chatRoomInfo === void 0 ? void 0 : chatRoomInfo.descendants) !== null && _a !== void 0 ? _a : [],
                    });
                    // 親をたどり、子孫を登録していく
                    let parentId = activeChatRoom.parentChatRoomId;
                    let descendants = [
                        activeChatRoom.chatRoomId,
                        ...((_b = chatRoomInfo === null || chatRoomInfo === void 0 ? void 0 : chatRoomInfo.descendants) !== null && _b !== void 0 ? _b : []),
                    ];
                    // 階層構造を取りうることを想定し、ループで親を辿って最初の祖先まで更新する
                    while (parentId) {
                        const parentInfo = targetAncestorDescendantsInfo.entities[parentId];
                        let parentDescendants = (_c = parentInfo === null || parentInfo === void 0 ? void 0 : parentInfo.descendants) !== null && _c !== void 0 ? _c : [];
                        if (parentInfo === undefined || // 親の情報が存在しない場合、または
                            descendants.some((descendant) => !parentDescendants.includes(descendant)) // ここまでの子孫のいずれかが親に登録されていない場合
                        ) {
                            // 親の子孫情報を更新する
                            parentDescendants = arrayUniq([
                                ...parentDescendants,
                                ...descendants,
                            ]);
                            unreadAncestorDescendantsAdapter.upsertOne(targetAncestorDescendantsInfo, {
                                id: parentId,
                                parentChatRoomId: (_d = parentInfo === null || parentInfo === void 0 ? void 0 : parentInfo.parentChatRoomId) !== null && _d !== void 0 ? _d : null,
                                descendants: parentDescendants,
                            });
                        }
                        // 最初の祖先まで辿る
                        descendants = parentDescendants;
                        parentId = (_e = parentInfo === null || parentInfo === void 0 ? void 0 : parentInfo.parentChatRoomId) !== null && _e !== void 0 ? _e : null;
                    }
                }
                // 未読情報の操作
                {
                    const targetUnreadInfo = state.unread.chatRooms;
                    // この処理は既存の未読情報に関わらず、すべての未読情報を差し替えるため、
                    // updateSerialによる時系列比較は行わず、setOneする(常に新しい値を使用する)
                    unreadChatInfoAdapter.setOne(targetUnreadInfo, {
                        id: activeChatRoom.chatRoomId,
                        unreadCount: activeChatRoom.unreadCount,
                        updateSerial: activeChatRoom.updateSerial,
                        lastReadMessageId: activeChatRoom.lastReadMessageId,
                    });
                }
            });
        },
    },
});
const actions = messageSlice.actions;
// ログアウト時に呼び出すためにexport
const { resetMessages } = actions;
export { resetMessages };
// チャットルーム未読情報のselector
const unreadChatRoomSelectors = unreadChatInfoAdapter.getSelectors((state) => state[messageSlice.name].unread.chatRooms);
// チャットスレッド未読情報のselector
const unreadChatThreadSelectors = unreadChatInfoAdapter.getSelectors((state) => state[messageSlice.name].unread.chatThreads);
// アクティブチャットルームのselector
const activeChatRoomSelectors = activeChatRoomAdapter.getSelectors((state) => state[messageSlice.name].activeChatRoom.chatRooms);
// 子孫情報のselector
const unreadAncestorDescendantsSelectors = unreadAncestorDescendantsAdapter.getSelectors((state) => state[messageSlice.name].unread.ancestorDescendants);
/**
 * 未読がないアクティブチャットルームを返すselector
 */
const selectAlreadyReadActiveChatRooms = createSelector(
// すべてのチャットルーム未読のエンティティとすべてのアクティブチャットルームが処理対象
[
    unreadChatRoomSelectors.selectEntities,
    activeChatRoomSelectors.selectAll,
    unreadAncestorDescendantsSelectors.selectEntities,
], 
// アクティブチャットルームのうち、未読がないものを取得する
(chatRooms, activeChatRooms, descendants) => activeChatRooms.filter((activeChatRoom) => {
    var _a, _b, _c, _d, _e;
    // 自分の未読件数
    const selfUnreadCountTotal = (_c = (_b = (_a = chatRooms[activeChatRoom.chatRoomId]) === null || _a === void 0 ? void 0 : _a.unreadCount) === null || _b === void 0 ? void 0 : _b.total) !== null && _c !== void 0 ? _c : 0;
    // 子孫の未読件数
    const descendantsUnreadCountTotal = (_e = (_d = descendants[activeChatRoom.chatRoomId]) === null || _d === void 0 ? void 0 : _d.descendants.reduce((prev, descendant) => { var _a, _b, _c; return prev + ((_c = (_b = (_a = chatRooms[descendant]) === null || _a === void 0 ? void 0 : _a.unreadCount) === null || _b === void 0 ? void 0 : _b.total) !== null && _c !== void 0 ? _c : 0); }, 0)) !== null && _e !== void 0 ? _e : 0;
    // 未読がない
    return selfUnreadCountTotal + descendantsUnreadCountTotal === 0;
}));
/**
 * 未読がないお気に入りアクティブチャットルームを返すselector
 */
const selectAlreadyReadFavoriteActiveChatRooms = createSelector(
//未読が無いアクティブチャットルームが処理対象
[selectAlreadyReadActiveChatRooms], 
// お気に入りのみを取得する
(activeChatRooms) => activeChatRooms.filter((activeChatRoom) => activeChatRoom.isFavorite));
/**
 * Unread.CountからSelectUnreadCountResultを生成するoutput selector
 * @param chatInfo
 * @returns
 */
const outputSelectUnreadCountToUnreadCountResult = (unreadCount, hasUnreadInfo) => {
    var _a, _b, _c, _d, _e;
    // totalからmentionToMeは単純にそのまま使用する
    const total = (_a = unreadCount === null || unreadCount === void 0 ? void 0 : unreadCount.total) !== null && _a !== void 0 ? _a : 0;
    const normal = (_b = unreadCount === null || unreadCount === void 0 ? void 0 : unreadCount.normal) !== null && _b !== void 0 ? _b : 0;
    const reply = (_c = unreadCount === null || unreadCount === void 0 ? void 0 : unreadCount.reply) !== null && _c !== void 0 ? _c : 0;
    const mentionToGroup = (_d = unreadCount === null || unreadCount === void 0 ? void 0 : unreadCount.mentionToGroup) !== null && _d !== void 0 ? _d : 0;
    const mentionToMe = (_e = unreadCount === null || unreadCount === void 0 ? void 0 : unreadCount.mentionToMe) !== null && _e !== void 0 ? _e : 0;
    // mentionTotalはmentionToGroup+mentionToMe
    const mentionTotal = mentionToGroup + mentionToMe;
    // attentionはreply+mentionTotal
    const attention = reply + mentionTotal;
    return {
        total,
        normal,
        reply,
        mentionToGroup,
        mentionToMe,
        attention,
        mentionTotal,
        hasUnreadInfo,
    };
};
/**
 * Unread.ChatInfoからSelectUnreadCountResultを生成するoutput selector
 * @param chatInfo
 * @returns
 */
const outputSelectUnreadChatInfoToUnreadCountResult = (chatInfo) => {
    return outputSelectUnreadCountToUnreadCountResult(chatInfo === null || chatInfo === void 0 ? void 0 : chatInfo.unreadCount, chatInfo !== undefined);
};
/**
 * 合計未読件数を返すselector
 */
export const selectUnreadCountTotal = (newsMentionAndReplies) => createSelector(
// すべてのチャットルーム未読が処理対象
[unreadChatRoomSelectors.selectAll], 
// 未読情報を集計する
(chatRooms) => Object.values(chatRooms).reduce(
// 集計処理
(acc, chatRoom) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    // totalからmentionToMeは単純にすべて加算する
    const total = acc.total + ((_b = (_a = chatRoom === null || chatRoom === void 0 ? void 0 : chatRoom.unreadCount) === null || _a === void 0 ? void 0 : _a.total) !== null && _b !== void 0 ? _b : 0);
    const normal = acc.normal + ((_d = (_c = chatRoom === null || chatRoom === void 0 ? void 0 : chatRoom.unreadCount) === null || _c === void 0 ? void 0 : _c.normal) !== null && _d !== void 0 ? _d : 0);
    const reply = acc.reply + ((_f = (_e = chatRoom === null || chatRoom === void 0 ? void 0 : chatRoom.unreadCount) === null || _e === void 0 ? void 0 : _e.reply) !== null && _f !== void 0 ? _f : 0);
    const mentionToGroup = acc.mentionToGroup + ((_h = (_g = chatRoom === null || chatRoom === void 0 ? void 0 : chatRoom.unreadCount) === null || _g === void 0 ? void 0 : _g.mentionToGroup) !== null && _h !== void 0 ? _h : 0);
    const mentionToMe = acc.mentionToMe + ((_k = (_j = chatRoom === null || chatRoom === void 0 ? void 0 : chatRoom.unreadCount) === null || _j === void 0 ? void 0 : _j.mentionToMe) !== null && _k !== void 0 ? _k : 0);
    // mentionTotalはmentionToGroup+mentionToMe
    const mentionTotal = mentionToGroup + mentionToMe;
    // attention
    let attention;
    if (newsMentionAndReplies === NewsMentionAndReplies.Mention.value) {
        // 通知する内容＝「メンションのみ」の場合：mentionTotal
        attention = mentionTotal;
    }
    else {
        // それ以外：reply + mentionTotal
        attention = reply + mentionTotal;
    }
    return {
        total,
        normal,
        reply,
        mentionToGroup,
        mentionToMe,
        attention,
        mentionTotal,
        hasUnreadInfo: acc.hasUnreadInfo || chatRoom !== undefined, // 一つでも未読情報があればtrue
    };
}, 
// 初期値
{
    total: 0,
    normal: 0,
    reply: 0,
    mentionToGroup: 0,
    mentionToMe: 0,
    attention: 0,
    mentionTotal: 0,
    hasUnreadInfo: false,
}));
/**
 * 何らかの未読メッセージがあるかどうかを返すselector
 */
export const selectHasUnreadAny = (newsMentionAndReplies) => createSelector(
// 合計未読件数が判定対象
[selectUnreadCountTotal(newsMentionAndReplies)], 
// 未読件数が1件以上あればtrue
(unreadCount) => unreadCount.total > 0);
/*
 * 未読のAttentionがあるかどうかを返すselector
 */
export const selectHasUnreadAttention = (newsMentionAndReplies) => createSelector(
// 合計未読件数が判定対象
[selectUnreadCountTotal(newsMentionAndReplies)], 
// 「メンションと返信」に未読があればtrue
(unreadCount) => unreadCount.attention > 0);
/**
 * 未読があるアクティブチャットルームを返すselector
 */
export const selectUnreadActiveChatRooms = createSelector(
// すべてのチャットルーム未読のエンティティとすべてのアクティブチャットルームが処理対象
[
    unreadChatRoomSelectors.selectEntities,
    activeChatRoomSelectors.selectAll,
    unreadAncestorDescendantsSelectors.selectEntities,
], 
// アクティブチャットルームのうち、未読が1件以上あるものを取得する
(chatRooms, activeChatRooms, descendants) => activeChatRooms.filter((activeChatRoom) => {
    var _a, _b, _c, _d, _e;
    // 自分の未読件数
    const selfUnreadCountTotal = (_c = (_b = (_a = chatRooms[activeChatRoom.chatRoomId]) === null || _a === void 0 ? void 0 : _a.unreadCount) === null || _b === void 0 ? void 0 : _b.total) !== null && _c !== void 0 ? _c : 0;
    // 子孫の未読件数
    const descendantsUnreadCountTotal = (_e = (_d = descendants[activeChatRoom.chatRoomId]) === null || _d === void 0 ? void 0 : _d.descendants.reduce((prev, descendant) => { var _a, _b, _c; return prev + ((_c = (_b = (_a = chatRooms[descendant]) === null || _a === void 0 ? void 0 : _a.unreadCount) === null || _b === void 0 ? void 0 : _b.total) !== null && _c !== void 0 ? _c : 0); }, 0)) !== null && _e !== void 0 ? _e : 0;
    // 未読が1件以上ある
    return selfUnreadCountTotal + descendantsUnreadCountTotal > 0;
}));
/**
 * 未読がないお気に入りプロジェクトのアクティブチャットルームを返すselector
 */
export const selectAlreadyReadFavoriteProjectActiveChatRooms = createSelector(
// 未読がないお気に入りアクティブチャットルームが処理対象
[selectAlreadyReadFavoriteActiveChatRooms], 
// プロジェクトのみを取得する
(activeChatRooms) => activeChatRooms.filter((activeChatRoom) => activeChatRoom.projectType === ProjectType.Project));
/**
 * 未読がないお気に入りダイレクトチャンネルのアクティブチャットルームを返すselector
 */
export const selectAlreadyReadFavoriteDirectChannelActiveChatRooms = createSelector(
// 未読がないお気に入りアクティブチャットルームが処理対象
[selectAlreadyReadFavoriteActiveChatRooms], 
// ダイレクトチャンネルのみを取得する
(activeChatRooms) => activeChatRooms.filter((activeChatRoom) => activeChatRoom.projectType === ProjectType.DirectChannel));
//**************************************************** 追加引数ありセレクタ ***********************************************************
// createSelectorで生成したselectorは値をmemoするが、現状は1パラメータセット分しか値を保存しない。
// そのためstoreの他にも引数があるselectorを複数のComponentから利用すると、引数の違いでmemoが効かなくなってしまう。
// それを避けるため各コンポーネント毎にcreateSelector()を呼ぶ必要があるが、レンダリングの度に新しいselectorを生成すると、やはりmemoが効かなくなってしまう。
// よって、createSelector()で生成したselectorをmemoして使う構造とする必要がある。
// https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
//
// なおその他に、createSelector()にオプションを渡し、memoする数を増やす方法もある。
// この方法ではselector毎に同時に使われるパラメータが何種類程度あるかをある程度見越しておく必要があり、設定が難しい。
// https://reselect.js.org/api/lruMemoize
/**
 * 指定したチャットルームの未読件数を返すselectorを作成するhook
 * @returns selector関数
 */
export const useSelectUnreadCountInChatRoom = () => useMemo(() => createSelector(
// チャットルーム未読からIDで1件取得する
[unreadChatRoomSelectors.selectById], 
// 未読情報をSelectUnreadCountResultに変換する
outputSelectUnreadChatInfoToUnreadCountResult), []);
/**
 * 指定したチャットルームとその子孫の未読件数の合計を返すselectorを作成するhook
 * @returns selector関数
 */
export const useSelectUnreadCountInChatRoomWithDescendants = () => {
    return useMemo(() => createSelector([
        // IDで指定したチャットルームの未読を取得する
        unreadChatRoomSelectors.selectById,
        // IDで指定したチャットルームの子孫の未読を配列で取得する
        (state, chatRoomId) => {
            var _a, _b;
            //まずIDで指定したチャットルームの子孫情報を取得する
            return (_b = (_a = unreadAncestorDescendantsSelectors
                .selectById(state, chatRoomId)) === null || _a === void 0 ? void 0 : _a.descendants.map((descendant) => unreadChatRoomSelectors.selectById(state, descendant))) !== null && _b !== void 0 ? _b : [];
        },
    ], 
    // チャットルーム未読と子孫の未読を合計する
    (chatRoomUnreadInfo, descendantUnreadInfos) => {
        var _a, _b, _c, _d, _e;
        const chatRoomUnreadCount = chatRoomUnreadInfo === null || chatRoomUnreadInfo === void 0 ? void 0 : chatRoomUnreadInfo.unreadCount;
        const totalUnreadCount = {
            total: (_a = chatRoomUnreadCount === null || chatRoomUnreadCount === void 0 ? void 0 : chatRoomUnreadCount.total) !== null && _a !== void 0 ? _a : 0,
            normal: (_b = chatRoomUnreadCount === null || chatRoomUnreadCount === void 0 ? void 0 : chatRoomUnreadCount.normal) !== null && _b !== void 0 ? _b : 0,
            reply: (_c = chatRoomUnreadCount === null || chatRoomUnreadCount === void 0 ? void 0 : chatRoomUnreadCount.reply) !== null && _c !== void 0 ? _c : 0,
            mentionToGroup: (_d = chatRoomUnreadCount === null || chatRoomUnreadCount === void 0 ? void 0 : chatRoomUnreadCount.mentionToGroup) !== null && _d !== void 0 ? _d : 0,
            mentionToMe: (_e = chatRoomUnreadCount === null || chatRoomUnreadCount === void 0 ? void 0 : chatRoomUnreadCount.mentionToMe) !== null && _e !== void 0 ? _e : 0,
        };
        descendantUnreadInfos.forEach((descendantUnreadInfo) => {
            const descendantUnreadCount = descendantUnreadInfo === null || descendantUnreadInfo === void 0 ? void 0 : descendantUnreadInfo.unreadCount;
            if (descendantUnreadCount) {
                totalUnreadCount.total += descendantUnreadCount.total;
                totalUnreadCount.normal += descendantUnreadCount.normal;
                totalUnreadCount.reply += descendantUnreadCount.reply;
                totalUnreadCount.mentionToGroup +=
                    descendantUnreadCount.mentionToGroup;
                totalUnreadCount.mentionToMe += descendantUnreadCount.mentionToMe;
            }
        });
        return outputSelectUnreadCountToUnreadCountResult(totalUnreadCount, chatRoomUnreadInfo !== undefined);
    }, {
        // 2つ目のセレクタが配列を生成して返すため、値が配列の場合は配列の内部が同一か比較する関数を同一チェック関数として利用する。
        // これがないと常に出力関数が再計算されてしまう
        memoizeOptions: (a, b) => Array.isArray(a) && Array.isArray(b)
            ? a.length === b.length && a.every((v, i) => v === b[i])
            : a === b,
    }), []);
};
/**
 * 指定したスレッドの未読件数を返すselectorを作成するhook
 * @returns selector関数
 */
export const useSelectUnreadCountInThread = () => useMemo(() => createSelector(
// チャットスレッド未読からIDで1件取得する
[unreadChatThreadSelectors.selectById], 
// 未読情報をSelectUnreadCountResultに変換する
outputSelectUnreadChatInfoToUnreadCountResult), []);
/**
 * 指定したチャットルームの最終既読メッセージIDを返すselectorを作成する関数
 * @returns selector関数
 */
const createSelectLastReadMessageIdInChatRoom = () => createSelector(
// チャットルーム未読からIDで1件取得する
[unreadChatRoomSelectors.selectById], 
// 最終既読メッセージIDを取得する
(chatRoom) => chatRoom === null || chatRoom === void 0 ? void 0 : chatRoom.lastReadMessageId);
/**
 * 指定したチャットルームの最終既読メッセージIDを返すselectorを作成するhook
 * @returns selector関数
 */
export const useSelectLastReadMessageIdInChatRoom = () => useMemo(createSelectLastReadMessageIdInChatRoom, []);
/**
 * 指定したチャットルームとその子孫のうち、未読があるものの最終既読メッセージIDのmapを返すselectorを作成するhook
 * @returns {[key:chatRoomId]: lastReadMessageId}を返すselector関数
 */
export const useSelectLastReadMessageIdsInChatRoomClan = () => {
    return useMemo(() => createSelector([
        // IDで指定したチャットルームの未読情報を取得する
        unreadChatRoomSelectors.selectById,
        // IDで指定したチャットルームの子孫の未読情報を配列で取得する
        (state, chatRoomId) => {
            var _a, _b;
            //まずIDで指定したチャットルームの子孫情報を取得する
            return (_b = (_a = unreadAncestorDescendantsSelectors
                .selectById(state, chatRoomId)) === null || _a === void 0 ? void 0 : _a.descendants.map((descendant) => unreadChatRoomSelectors.selectById(state, descendant))) !== null && _b !== void 0 ? _b : [];
        },
    ], 
    // チャットルーム未読と子孫の未読情報から最終既読メッセージIDを取得する
    (chatRoom, descendantUnreadInfos) => {
        // チャットルームとその子孫チャットルームのうち、未読があるものの最終既読メッセージIDを取得する
        const lastReadMessageIds = [
            chatRoom, // チャットルームの未読情報
            ...descendantUnreadInfos, // 子孫の未読情報
        ]
            .filter((unreadInfo) => { var _a, _b; return ((_b = (_a = unreadInfo === null || unreadInfo === void 0 ? void 0 : unreadInfo.unreadCount) === null || _a === void 0 ? void 0 : _a.total) !== null && _b !== void 0 ? _b : 0) > 0; }) // 未読が1件以上あるものだけを対象に、
            .reduce((out, unreadInfo) => {
            // 未読があるものの最終既読メッセージIDをmapで取得する
            if ((unreadInfo === null || unreadInfo === void 0 ? void 0 : unreadInfo.lastReadMessageId) !== undefined) {
                out[unreadInfo.id] = unreadInfo.lastReadMessageId;
            }
            return out;
        }, {});
        return lastReadMessageIds;
    }, {
        // 2つ目のセレクタが配列を生成して返すため、値が配列の場合は配列の内部が同一か比較する関数を同一チェック関数として利用する。
        // これがないと常に出力関数が再計算されてしまう
        memoizeOptions: (a, b) => Array.isArray(a) && Array.isArray(b)
            ? a.length === b.length && a.every((v, i) => v === b[i])
            : a === b,
    }), []);
};
/**
 * 指定したスレッドの最終既読メッセージIDを返すselectorを作成する関数
 * @returns selector関数
 */
const createSelectLastReadMessageIdInThread = () => createSelector(
// チャットスレッド未読からIDで1件取得する
[unreadChatThreadSelectors.selectById], 
// 最終既読メッセージIDを取得する
(chatThread) => chatThread === null || chatThread === void 0 ? void 0 : chatThread.lastReadMessageId);
/**
 * 指定したスレッドの最終既読メッセージIDを返すselectorを作成するhook
 * @returns selector関数
 */
export const useSelectLastReadMessageIdInThread = () => useMemo(createSelectLastReadMessageIdInThread, []);
// チャットルーム既読から対象メッセージが未読かどうかを判定するSelectorを生成
// slice内部でしか使用しないためexportしない
const createSelectIsUnreadInChatRoom = () => createSelector([
    // 未読情報のstateから指定されたチャットルームの未読情報を取得
    (state, chatRoomId) => state.chatRooms.entities[chatRoomId],
    // 全てのinput selectorは同じ引数で呼び出されるため、型を揃える必要がある
    // そのため使わない引数も受け取る必要がある
    (state, chatRoomId, 
    // 未読判定対象のメッセージID
    // この引数は1つ目のinput selectorにはないが、多い分にはduck typeにより同じ型で呼出可能
    targetChatMessageId) => targetChatMessageId, // targetChatMessageIdをそのままoutput selectorに渡す
], (chatRoom, targetChatMessageId) => {
    // 最終既読メッセージIDがあればそれと比較し、未読かどうかを返す
    if (chatRoom === null || chatRoom === void 0 ? void 0 : chatRoom.lastReadMessageId) {
        // 比較対象のチャットメッセージIDの方が新しい場合は、未読となる
        return compareUlid(targetChatMessageId, '>', chatRoom.lastReadMessageId);
    }
    // 最終既読メッセージIDが存在しない場合は、すべて既読とみなすため、falseを返す
    return false;
});
// チャットスレッド既読から対象メッセージが未読かどうかを判定するSelectorを生成
// slice内部でしか使用しないためexportしない
const createSelectIsUnreadInChatThread = () => createSelector([
    // 未読情報のstateから指定されたチャットスレッドの未読情報を取得
    (state, chatThreadId) => state.chatThreads.entities[chatThreadId],
    // 全てのinput selectorは同じ引数で呼び出されるため、型を揃える必要がある
    // そのため使わない引数も受け取る必要がある
    (state, chatThreadId, 
    // 未読判定対象のメッセージID
    // この引数は1つ目のinput selectorにはないが、多い分にはduck typeにより同じ型で呼出可能
    targetChatMessageId) => targetChatMessageId, // targetChatMessageIdをそのままoutput selectorに渡す
], (chatThread, targetChatMessageId) => {
    // 最終既読メッセージIDがあればそれと比較し、未読かどうかを返す
    if (chatThread === null || chatThread === void 0 ? void 0 : chatThread.lastReadMessageId) {
        // 比較対象のチャットメッセージIDの方が新しい場合は、未読となる
        return compareUlid(targetChatMessageId, '>', chatThread.lastReadMessageId);
    }
    // 最終既読メッセージIDが存在しない場合は、すべて既読とみなすため、falseを返す
    return false;
});
/**
 * 未読かどうかを判定するSelectorを生成する
 *
 * 利用イメージは以下の通り
 * const SomeComponent = () => {
 *   // 未読かをチェックしたいチャットメッセージIDを取得
 *   const chatMessageId = 'chatMessageId'
 *
 *   // 対象のメッセージが未読かどうかを取得する
 *   const selectIsUnread = useMemo(createSelectIsUnreadMessage, [])
 *   const isUnreadMessage = useAppSelector((state) =>
 *     selectIsUnread(state, chatMessageId)
 *   )
 *
 *   return <div>{ isUnreadMessage ? '未読': '既読' } </div>
 * }
 */
export const createSelectIsUnreadMessage = () => {
    // チャットルーム未読から未読かどうかを判定するSelectorを生成
    const selectIsUnreadInChatRoom = createSelectIsUnreadInChatRoom();
    // チャットスレッド未読から未読かどうかを判定するSelectorを生成
    const selectIsUnreadInChatThread = createSelectIsUnreadInChatThread();
    return createSelector([
        // 未読情報の取得先を選択するためのstateを取得
        (state) => state.message.unread,
        // 未読情報の取得先を選択するためのチャット表示形式を取得
        (state) => state.message.chat.current.displayFormat,
        // 未読かを確認するチャットメッセージを取得
        (state, chatMessageId) => state.message.chat.cacheMessages.entities[chatMessageId],
    ], (state, displayFormat, targetChatMessage) => {
        // 未読かどうかのチェック対象のメッセージがない場合はチェック自体不要であり、
        // 型の制約上、未読として扱わないこととし、falseを返す
        if (!targetChatMessage) {
            return false;
        }
        // チャットの表示形式をもとに、比較対象の「最終既読メッセージID」の取得先を振り分ける
        if (displayFormat === ChatView.Timeline) {
            // タイムライン表示の場合はチャットルーム既読情報から未読かどうかをチェックする
            return selectIsUnreadInChatRoom(state, targetChatMessage.chatRoomId, targetChatMessage.id);
        }
        else if (displayFormat === ChatView.ThreadList) {
            // スレッドリスト表示の場合は、チェック対象のメッセージがトピックかどうかで振り分ける
            // チェック対象のメッセージがトピックでない(返信)の場合
            if (targetChatMessage.parentChatMessageId) {
                // チャットスレッド既読情報から未読かどうかをチェックする
                return selectIsUnreadInChatThread(state, targetChatMessage.parentChatMessageId, targetChatMessage.id);
            }
            // チェック対象のメッセージがトピックの場合は、チャットルーム既読情報から未読かどうかをチェックする
            return selectIsUnreadInChatRoom(state, targetChatMessage.chatRoomId, targetChatMessage.id);
        }
        // チャット表示形式がタイムラインでもスレッドリストでもない場合は、通常あり得ないが、
        // 型の制約上、未読として扱わないこととし、falseを返す
        return false;
    });
};
/**
 * message系dispatcherを取得するcustom hook
 */
export const useMessageDispatch = () => useDispatch();
/**
 * message系selectorを取得するcustom hook
 */
export const useMessageSelector = useSelector;
// ----------------------------------
// Subscription
// ----------------------------------
// WebSocketのsubscribe情報を制御するカスタムフック
export const useSubscriptionService = () => {
    const dispatch = useMessageDispatch();
    // サブスクリプションのハンドラーを追加
    const addSubscriptionHandler = useCallback((params) => {
        // 各チャンネルのハンドラーを追加
        if (params.targetType === PubSubEventTargetType.User) {
            if (params.dataType === PubSubEventDataType.Meta) {
                // ユーザーmetaチャンネル
                // ハンドラを追加
                dispatch(messageSlice.actions.addUserMetaChannelHandler({
                    messageType: params.messageType,
                    subscriptionId: params.subscriptionId,
                }));
            }
            else if (params.dataType === PubSubEventDataType.Data) {
                // ユーザーdataチャンネル
                // ハンドラを追加
                dispatch(messageSlice.actions.addUserDataChannelHandler({
                    messageType: params.messageType,
                    subscriptionId: params.subscriptionId,
                }));
            }
        }
        else if (params.targetType === PubSubEventTargetType.ChatRoom) {
            // targetType=chat_roomの場合はID必須
            if (params.id === undefined) {
                console.warn('[messageSlice] id is undefined');
                return;
            }
            if (params.dataType === PubSubEventDataType.Meta) {
                // チャットルームmetaチャンネル
                // チャットルームを購読対象に追加（対象のチャットルームが購読対象に存在しない可能性があるため）
                dispatch(messageSlice.actions.addChatRoomMetaChannelSubscription({
                    id: params.id,
                }));
                // ハンドラを追加
                dispatch(messageSlice.actions.addChatRoomMetaChannelHandler({
                    id: params.id,
                    messageType: params.messageType,
                    subscriptionId: params.subscriptionId,
                }));
            }
            else if (params.dataType === PubSubEventDataType.Data) {
                // チャットルームdataチャンネル
                // チャットルームを購読対象に追加（対象のチャットルームが購読対象に存在しない可能性があるため）
                dispatch(messageSlice.actions.addChatRoomDataChannelSubscription({
                    id: params.id,
                }));
                // ハンドラを追加
                dispatch(messageSlice.actions.addChatRoomDataChannelHandler({
                    id: params.id,
                    messageType: params.messageType,
                    subscriptionId: params.subscriptionId,
                }));
            }
        }
        else if (params.targetType === PubSubEventTargetType.Presence) {
            // ユーザー在籍状態の場合、IDは必須
            if (params.id === undefined) {
                console.warn('[messageSlice] id is undefined');
                return;
            }
            if (params.dataType === PubSubEventDataType.Meta) {
                // プレゼンスmetaチャンネル
                // 対象ユーザーの在籍状態を購読対象に追加（対象ユーザーが購読対象に存在しない可能性があるため）
                dispatch(messageSlice.actions.addUserPresenceMetaChannelSubscription({
                    userId: params.id,
                }));
                // ハンドラを追加
                dispatch(messageSlice.actions.addUserPresenceMetaChannelHandler({
                    userId: params.id,
                    messageType: params.messageType,
                    subscriptionId: params.subscriptionId,
                }));
            }
        }
    }, [dispatch]);
    // サブスクリプションのハンドラーを削除
    const deleteSubscriptionHandler = useCallback((params) => {
        // 各チャンネルのハンドラーを削除
        if (params.targetType === PubSubEventTargetType.User) {
            if (params.dataType === PubSubEventDataType.Meta) {
                // ユーザーmetaチャンネル
                dispatch(messageSlice.actions.deleteUserMetaChannelHandler({
                    messageType: params.messageType,
                }));
            }
            else if (params.dataType === PubSubEventDataType.Data) {
                // ユーザーdataチャンネル
                dispatch(messageSlice.actions.deleteUserDataChannelHandler({
                    messageType: params.messageType,
                }));
            }
        }
        else if (params.targetType === PubSubEventTargetType.ChatRoom) {
            // targetType=chat_roomの場合はID必須
            if (params.id === undefined) {
                console.warn('[messageSlice] id is undefined');
                return;
            }
            if (params.dataType === PubSubEventDataType.Meta) {
                // チャットルームmetaチャンネル
                dispatch(messageSlice.actions.deleteChatRoomMetaChannelHandler({
                    id: params.id,
                    messageType: params.messageType,
                }));
            }
            else if (params.dataType === PubSubEventDataType.Data) {
                // チャットルームdataチャンネル
                dispatch(messageSlice.actions.deleteChatRoomDataChannelHandler({
                    id: params.id,
                    messageType: params.messageType,
                }));
            }
        }
        else if (params.targetType === PubSubEventTargetType.Presence) {
            // ユーザー在籍状態の場合、IDは必須
            if (params.id === undefined) {
                console.warn('[messageSlice] id is undefined');
                return;
            }
            if (params.dataType === PubSubEventDataType.Meta) {
                // ユーザー在籍状態metaチャンネル
                dispatch(messageSlice.actions.deleteUserPresenceMetaChannelHandler({
                    userId: params.id,
                    messageType: params.messageType,
                }));
            }
            else {
                assertNever(params.dataType, `[messageSlice] invalid dataType:${params.dataType}`);
            }
        }
        else {
            assertNever(params.targetType, `[messageSlice] invalid targetType:${params.targetType}`);
        }
    }, [dispatch]);
    // チャンネルのサブスクライブ状態をnotSubscribedに更新する
    const updateSubscribeStateNotSubscribed = useCallback((params) => {
        switch (params.targetType) {
            case PubSubEventTargetType.User:
                switch (params.dataType) {
                    case PubSubEventDataType.Meta:
                        dispatch(messageSlice.actions.updateUserMetaChannelSubscribeStateNotSubscribed());
                        break;
                    case PubSubEventDataType.Data:
                        dispatch(messageSlice.actions.updateUserDataChannelSubscribeStateNotSubscribed());
                        break;
                    default:
                        assertNever(params.dataType, `[useSubscriptionService] invalid dataType: ${params.dataType}`);
                }
                break;
            case PubSubEventTargetType.ChatRoom:
                // targetIdは必須
                if (!params.targetId) {
                    console.warn(`[useSubscriptionService] target is chatRoom, but targetId is not specified.`);
                    return;
                }
                switch (params.dataType) {
                    case PubSubEventDataType.Meta:
                        // 対象Entityが存在していない場合データが追加できないため先に初期化する
                        dispatch(actions.addChatRoomMetaChannelSubscription({
                            id: params.targetId,
                        }));
                        // サブスクライブ状態を更新
                        dispatch(messageSlice.actions.updateChatRoomMetaChannelSubscribeStateNotSubscribed({
                            id: params.targetId,
                        }));
                        break;
                    case PubSubEventDataType.Data:
                        // 対象Entityが存在していない場合データが追加できないため先に初期化する
                        dispatch(actions.addChatRoomDataChannelSubscription({
                            id: params.targetId,
                        }));
                        // サブスクライブ状態を更新
                        dispatch(messageSlice.actions.updateChatRoomDataChannelSubscribeStateNotSubscribed({
                            id: params.targetId,
                        }));
                        break;
                    default:
                        assertNever(params.dataType, `[useSubscriptionService] invalid dataType: ${params.dataType}`);
                }
                break;
            case PubSubEventTargetType.Presence:
                // targetIdは必須
                if (!params.targetId) {
                    console.warn(`[useSubscriptionService] target is userId, but targetId is not specified.`);
                    return;
                }
                switch (params.dataType) {
                    case PubSubEventDataType.Meta:
                        // 対象Entityが存在していない場合データが追加できないため先に初期化する
                        dispatch(actions.addUserPresenceMetaChannelSubscription({
                            userId: params.targetId,
                        }));
                        // サブスクライブ状態を更新
                        dispatch(messageSlice.actions.updateUserPresenceMetaChannelSubscribeStateNotSubscribed({
                            userId: params.targetId,
                        }));
                        break;
                    default:
                        assertNever(params.dataType, `[useSubscriptionService] invalid dataType: ${params.dataType}`);
                }
                break;
            default:
                assertNever(params.targetType, `[useSubscriptionService] invalid targetType: ${params.targetType}`);
        }
    }, [dispatch]);
    // チャンネルのサブスクライブ状態をsubscribingに更新する
    const updateSubscribeStateSubscribing = useCallback((params) => {
        switch (params.targetType) {
            case PubSubEventTargetType.User:
                switch (params.dataType) {
                    case PubSubEventDataType.Meta:
                        dispatch(messageSlice.actions.updateUserMetaChannelSubscribeStateSubscribing());
                        break;
                    case PubSubEventDataType.Data:
                        dispatch(messageSlice.actions.updateUserDataChannelSubscribeStateSubscribing());
                        break;
                    default:
                        assertNever(params.dataType, `[useSubscriptionService] invalid dataType: ${params.dataType}`);
                }
                break;
            case PubSubEventTargetType.ChatRoom:
                // targetIdは必須
                if (!params.targetId) {
                    console.warn(`[useSubscriptionService] target is chatRoom, but targetId is not specified.`);
                    return;
                }
                switch (params.dataType) {
                    case PubSubEventDataType.Meta:
                        // 対象Entityが存在していない場合データが追加できないため先に初期化する
                        dispatch(actions.addChatRoomMetaChannelSubscription({
                            id: params.targetId,
                        }));
                        // サブスクライブ状態を更新
                        dispatch(messageSlice.actions.updateChatRoomMetaChannelSubscribeStateSubscribing({
                            id: params.targetId,
                        }));
                        break;
                    case PubSubEventDataType.Data:
                        // 対象Entityが存在していない場合データが追加できないため先に初期化する
                        dispatch(actions.addChatRoomDataChannelSubscription({
                            id: params.targetId,
                        }));
                        // サブスクライブ状態を更新
                        dispatch(messageSlice.actions.updateChatRoomDataChannelSubscribeStateSubscribing({
                            id: params.targetId,
                        }));
                        break;
                    default:
                        assertNever(params.dataType, `[useSubscriptionService] invalid dataType: ${params.dataType}`);
                }
                break;
            case PubSubEventTargetType.Presence:
                // targetIdは必須
                if (!params.targetId) {
                    console.warn(`[useSubscriptionService] target is userId, but targetId is not specified.`);
                    return;
                }
                switch (params.dataType) {
                    case PubSubEventDataType.Meta:
                        // 対象Entityが存在していない場合データが追加できないため先に初期化する
                        dispatch(actions.addUserPresenceMetaChannelSubscription({
                            userId: params.targetId,
                        }));
                        // サブスクライブ状態を更新
                        dispatch(messageSlice.actions.updateUserPresenceMetaChannelSubscribeStateSubscribing({
                            userId: params.targetId,
                        }));
                        break;
                    default:
                        assertNever(params.dataType, `[useSubscriptionService] invalid dataType: ${params.dataType}`);
                }
                break;
            default:
                assertNever(params.targetType, `[useSubscriptionService] invalid targetType: ${params.targetType}`);
        }
    }, [dispatch]);
    // チャンネルのサブスクライブ状態をsubscribedに更新する
    const updateSubscribeStateSubscribed = useCallback((params) => {
        switch (params.targetType) {
            case PubSubEventTargetType.User:
                switch (params.dataType) {
                    case PubSubEventDataType.Meta:
                        dispatch(messageSlice.actions.updateUserMetaChannelSubscribeStateSubscribed());
                        break;
                    case PubSubEventDataType.Data:
                        dispatch(messageSlice.actions.updateUserDataChannelSubscribeStateSubscribed());
                        break;
                    default:
                        assertNever(params.dataType, `[useSubscriptionService] invalid dataType: ${params.dataType}`);
                }
                break;
            case PubSubEventTargetType.ChatRoom:
                // targetIdは必須
                if (!params.targetId) {
                    console.warn(`[useSubscriptionService] target is chatRoom, but targetId is not specified.`);
                    return;
                }
                switch (params.dataType) {
                    case PubSubEventDataType.Meta:
                        // 対象Entityが存在していない場合データが追加できないため先に初期化する
                        dispatch(actions.addChatRoomMetaChannelSubscription({
                            id: params.targetId,
                        }));
                        // サブスクライブ状態を更新
                        dispatch(messageSlice.actions.updateChatRoomMetaChannelSubscribeStateSubscribed({
                            id: params.targetId,
                        }));
                        break;
                    case PubSubEventDataType.Data:
                        // 対象Entityが存在していない場合データが追加できないため先に初期化する
                        dispatch(actions.addChatRoomDataChannelSubscription({
                            id: params.targetId,
                        }));
                        // サブスクライブ状態を更新
                        dispatch(messageSlice.actions.updateChatRoomDataChannelSubscribeStateSubscribed({
                            id: params.targetId,
                        }));
                        break;
                    default:
                        assertNever(params.dataType, `[useSubscriptionService] invalid dataType: ${params.dataType}`);
                }
                break;
            case PubSubEventTargetType.Presence:
                // targetIdは必須
                if (!params.targetId) {
                    console.warn(`[useSubscriptionService] target is userId, but targetId is not specified.`);
                    return;
                }
                switch (params.dataType) {
                    case PubSubEventDataType.Meta:
                        // 対象Entityが存在していない場合データが追加できないため先に初期化する
                        dispatch(actions.addUserPresenceMetaChannelSubscription({
                            userId: params.targetId,
                        }));
                        // サブスクライブ状態を更新
                        dispatch(messageSlice.actions.updateUserPresenceMetaChannelSubscribeStateSubscribed({
                            userId: params.targetId,
                        }));
                        break;
                    default:
                        assertNever(params.dataType, `[useSubscriptionService] invalid dataType: ${params.dataType}`);
                }
                break;
            default:
                assertNever(params.targetType, `[useSubscriptionService] invalid targetType: ${params.targetType}`);
        }
    }, [dispatch]);
    return useMemo(() => ({
        addSubscriptionHandler,
        deleteSubscriptionHandler,
        updateSubscribeStateNotSubscribed,
        updateSubscribeStateSubscribing,
        updateSubscribeStateSubscribed,
    }), [
        addSubscriptionHandler,
        deleteSubscriptionHandler,
        updateSubscribeStateNotSubscribed,
        updateSubscribeStateSubscribing,
        updateSubscribeStateSubscribed,
    ]);
};
// TODO: Selectorは削除する必要があるが、現状のWebSocketProviderの処理をPubSubProviderと分離する際に対応する
//       https://break-tmc.atlassian.net/browse/CREW-11503
/**
 * Websocketのsubscribe情報を取得するselector関数を生成するcustom hook
 * @param param0
 */
export const useSubscribeInfoSelector = ({ targetType, targetId, dataType, }) => {
    const selectSubscribeInfo = useCallback((state) => {
        switch (targetType) {
            case PubSubEventTargetType.User:
                switch (dataType) {
                    case PubSubEventDataType.Meta:
                        return state.message.subscription.userMetaChannel;
                    case PubSubEventDataType.Data:
                        return state.message.subscription.userDataChannel;
                    default:
                        assertNever(dataType, `[useSubscribeInfoSelector] invalid dataType: ${dataType}`);
                }
                break;
            case PubSubEventTargetType.ChatRoom:
                if (!targetId) {
                    const err = `[useSubscribeInfoSelector] target is chatRoom, but targetId is not specified.`;
                    console.error(err);
                    throw new Error(err);
                }
                switch (dataType) {
                    case PubSubEventDataType.Meta:
                        return state.message.subscription.chatRoomMetaChannels.entities[targetId];
                    case PubSubEventDataType.Data:
                        return state.message.subscription.chatRoomDataChannels.entities[targetId];
                    default:
                        assertNever(dataType, `[useSubscribeInfoSelector] invalid dataType: ${dataType}`);
                }
                break;
            case PubSubEventTargetType.Presence:
                // targetIdは必須のため、指定されていない場合はエラー
                if (!targetId) {
                    const err = `[useSubscribeInfoSelector] target is userId, but targetId is not specified.`;
                    console.error(err);
                    throw new Error(err);
                }
                switch (dataType) {
                    case PubSubEventDataType.Meta:
                        return state.message.subscription.presenceMetaChannels.entities[targetId];
                    default:
                        assertNever(dataType, `[useSubscribeInfoSelector] invalid dataType: ${dataType}`);
                }
                break;
            default:
                assertNever(targetType, `[useSubscribeInfoSelector] invalid targetType: ${targetType}`);
        }
    }, [dataType, targetId, targetType]);
    return useMessageSelector(selectSubscribeInfo);
};
/**
 * 指定したチャットルームのTimeline形式のデータを扱うカスタムフック
 * @param dispatch 処理対象のdispatch
 * @returns
 */
export const useChatTimelineService = (dispatch) => {
    // Timeline形式のデータのmessagesをSliceに追加
    const addChatTimelineMessages = useCallback((params) => {
        // 対象Entityが存在していない場合データが追加できないため先に初期化する
        dispatch(actions.initializeTimelineChatRoom({
            chatRoomId: params.chatRoomId,
        }));
        // 渡されたパラメータからTimelineMessagesを作る
        const structuredTimelineMessageData = createTimelineMessagesByParams(params.messages, params.criterionMessageId, params.direction, params.isLoadedOfEndOfSourceItems);
        // Timeline形式のデータのmessagesをSliceに追加
        dispatch(actions.addChatTimelineMessages({
            direction: params.direction,
            isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfSourceItems,
            chatRoomId: params.chatRoomId,
            timelineMessages: structuredTimelineMessageData,
        }));
    }, [dispatch]);
    // Timeline形式のチャットメッセージをすべて削除する
    const deleteAllChatTimelineMessages = useCallback((params) => {
        dispatch(actions.deleteAllChatTimelineMessage({
            chatRoomId: params.chatRoomId,
        }));
    }, [dispatch]);
    // Timeline形式のデータのmessagesでSliceの値を差し替える
    const replaceChatTimelineMessages = useCallback((params) => {
        // 対象Entityが存在していない場合データが追加できないため先に初期化する
        dispatch(actions.initializeTimelineChatRoom({
            chatRoomId: params.chatRoomId,
        }));
        const structuredTimelineMessageData = createTimelineMessagesByParams(params.messages, params.criterionMessageId, params.direction, params.isLoadedOfEndOfSourceItems);
        dispatch(actions.replaceChatTimelineMessages({
            chatRoomId: params.chatRoomId,
            timelineMessages: structuredTimelineMessageData,
        }));
    }, [dispatch]);
    // スクロール位置保存設定
    const setTimelineScrollToMessageId = useCallback((params) => {
        dispatch(actions.setTimelineScrollToMessageId({
            chatRoomId: params.chatRoomId,
            chatMessageId: params.chatMessageId,
        }));
    }, [dispatch]);
    // スクロール位置リセット
    const resetTimelineScrollToMessageId = useCallback((params) => {
        dispatch(actions.resetTimelineScrollToMessageId({
            chatRoomId: params.chatRoomId,
        }));
    }, [dispatch]);
    // 表示トピックID保存設定
    const setTimelineTopicId = useCallback((params) => {
        dispatch(actions.setTimelineTopicId({
            chatRoomId: params.chatRoomId,
            chatMessageId: params.chatMessageId,
        }));
    }, [dispatch]);
    // 表示トピックIDリセット
    const resetTimelineTopicId = useCallback((params) => {
        dispatch(actions.resetTimelineTopicId({
            chatRoomId: params.chatRoomId,
        }));
    }, [dispatch]);
    // 検索範囲保存
    const updateTimelineSearchRange = useCallback((params) => {
        dispatch(actions.updateTimelineSearchRange({
            chatRoomId: params.chatRoomId,
            searchRange: params.searchRange,
        }));
    }, [dispatch]);
    // 選択メッセージID設定
    const setTimelineSelectedMessageId = useCallback((params) => {
        dispatch(actions.setTimelineSelectedMessageId({
            chatRoomId: params.chatRoomId,
            chatMessageId: params.chatMessageId,
        }));
    }, [dispatch]);
    // 選択メッセージIDリセット
    const resetTimelineSelectedMessageId = useCallback((params) => {
        dispatch(actions.resetTimelineSelectedMessageId({
            chatRoomId: params.chatRoomId,
        }));
    }, [dispatch]);
    // 最新のメッセージのhasMoreNextを強制的にtrueにする
    const forceUpdateTimelineMessageHasMoreNext = useCallback((params) => {
        dispatch(actions.forceUpdateTimelineMessageHasMoreNext(params));
    }, [dispatch]);
    return useMemo(() => ({
        addChatTimelineMessages,
        deleteAllChatTimelineMessages,
        replaceChatTimelineMessages,
        setTimelineScrollToMessageId,
        resetTimelineScrollToMessageId,
        setTimelineTopicId,
        resetTimelineTopicId,
        updateTimelineSearchRange,
        setTimelineSelectedMessageId,
        resetTimelineSelectedMessageId,
        forceUpdateTimelineMessageHasMoreNext,
    }), [
        addChatTimelineMessages,
        deleteAllChatTimelineMessages,
        replaceChatTimelineMessages,
        resetTimelineScrollToMessageId,
        resetTimelineTopicId,
        setTimelineScrollToMessageId,
        setTimelineTopicId,
        updateTimelineSearchRange,
        setTimelineSelectedMessageId,
        resetTimelineSelectedMessageId,
        forceUpdateTimelineMessageHasMoreNext,
    ]);
};
/**
 * 指定したチャットルームのThreadList形式のデータを扱うカスタムフック
 * @param dispatch 処理対象のdispatch
 * @returns
 */
export const useChatThreadListService = (dispatch) => {
    // ThreadList形式のデータのmessagesをSliceに追加
    const addChatThreadListMessages = useCallback((params) => {
        // 対象Entityが存在していない場合データが追加できないため先に初期化する
        dispatch(actions.initializeThreadListChatRoom({
            chatRoomId: params.chatRoomId,
        }));
        // 渡されたパラメータからThreadListMessagesを作る
        const structuredThreadListMessageData = createThreadListMessagesByParams(params.messages, params.criterionMessageId, params.direction, params.isLoadedOfEndOfSourceItems);
        // ThreadList形式のデータのmessagesをSliceに追加
        dispatch(actions.addChatThreadListMessages({
            direction: params.direction,
            isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfSourceItems,
            chatRoomId: params.chatRoomId,
            threadListMessages: structuredThreadListMessageData,
        }));
    }, [dispatch]);
    // ThreadList形式のチャットメッセージをすべて削除する
    const deleteAllChatThreadListMessages = useCallback((params) => {
        dispatch(actions.deleteAllChatThreadListMessages({
            chatRoomId: params.chatRoomId,
        }));
    }, [dispatch]);
    // ThreadList形式のデータのmessagesでSliceの値を差し替える
    const replaceChatThreadListMessages = useCallback((params) => {
        // 対象Entityが存在していない場合データが追加できないため先に初期化する
        dispatch(actions.initializeThreadListChatRoom({
            chatRoomId: params.chatRoomId,
        }));
        const structuredThreadListMessageData = createThreadListMessagesByParams(params.messages, params.criterionMessageId, params.direction, params.isLoadedOfEndOfSourceItems);
        dispatch(actions.replaceChatThreadListMessages({
            chatRoomId: params.chatRoomId,
            threadListMessages: structuredThreadListMessageData,
        }));
    }, [dispatch]);
    // スクロール位置保存
    const setThreadListScrollToMessageId = useCallback((params) => {
        dispatch(actions.setThreadListScrollToMessageId({
            chatRoomId: params.chatRoomId,
            chatMessageId: params.chatMessageId,
        }));
    }, [dispatch]);
    // スクロール位置リセット
    const resetThreadListScrollToMessageId = useCallback((params) => {
        dispatch(actions.resetThreadListScrollToMessageId({
            chatRoomId: params.chatRoomId,
        }));
    }, [dispatch]);
    // 表示トピックID保存設定
    const setThreadListTopicId = useCallback((params) => {
        dispatch(actions.setThreadListTopicId({
            chatRoomId: params.chatRoomId,
            chatMessageId: params.chatMessageId,
        }));
    }, [dispatch]);
    // 表示トピックIDリセット
    const resetThreadListTopicId = useCallback((params) => {
        dispatch(actions.resetThreadListTopicId({
            chatRoomId: params.chatRoomId,
        }));
    }, [dispatch]);
    // 検索範囲保存
    const updateThreadListSearchRange = useCallback((params) => {
        dispatch(actions.updateThreadListSearchRange({
            chatRoomId: params.chatRoomId,
            searchRange: params.searchRange,
        }));
    }, [dispatch]);
    // 選択メッセージID設定
    const setThreadListSelectedMessageId = useCallback((params) => {
        dispatch(actions.setThreadListSelectedMessageId({
            chatRoomId: params.chatRoomId,
            chatMessageId: params.chatMessageId,
        }));
    }, [dispatch]);
    // 選択メッセージIDリセット
    const resetThreadListSelectedMessageId = useCallback((params) => {
        dispatch(actions.resetThreadListSelectedMessageId({
            chatRoomId: params.chatRoomId,
        }));
    }, [dispatch]);
    // 最新のメッセージのhasMoreNextを強制的にtrueにする
    const forceUpdateThreadListMessageHasMoreNext = useCallback((params) => {
        dispatch(actions.forceUpdateThreadListMessageHasMoreNext(params));
    }, [dispatch]);
    return useMemo(() => ({
        addChatThreadListMessages,
        deleteAllChatThreadListMessages,
        replaceChatThreadListMessages,
        setThreadListScrollToMessageId,
        resetThreadListScrollToMessageId,
        setThreadListTopicId,
        resetThreadListTopicId,
        updateThreadListSearchRange,
        setThreadListSelectedMessageId,
        resetThreadListSelectedMessageId,
        forceUpdateThreadListMessageHasMoreNext,
    }), [
        addChatThreadListMessages,
        deleteAllChatThreadListMessages,
        replaceChatThreadListMessages,
        resetThreadListScrollToMessageId,
        resetThreadListTopicId,
        setThreadListScrollToMessageId,
        setThreadListTopicId,
        updateThreadListSearchRange,
        setThreadListSelectedMessageId,
        resetThreadListSelectedMessageId,
        forceUpdateThreadListMessageHasMoreNext,
    ]);
};
/**
 * 指定したトピックのThread形式のデータを扱うカスタムフック
 * @param dispatch 処理対象のdispatch
 * @returns
 */
export const useChatThreadService = (dispatch) => {
    // Thread形式のデータのmessagesをSliceに追加
    const addChatThreadMessages = useCallback((params) => {
        // 対象Entityが存在していない場合データが追加できないため先に初期化する
        dispatch(actions.initializeThreadTopic({
            topicId: params.topicId,
        }));
        // 渡されたパラメータからThreadMessagesを作る
        const structuredThreadMessageData = createThreadMessagesByParams(params.messages, params.criterionMessageId, params.direction, params.isLoadedOfEndOfSourceItems);
        // Thread形式のデータのmessagesをSliceに追加
        dispatch(actions.addChatThreadMessages({
            topicId: params.topicId,
            threadMessages: structuredThreadMessageData,
            direction: params.direction,
            isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfSourceItems,
        }));
    }, [dispatch]);
    // ChatThread形式のチャットメッセージをすべて削除する
    const deleteAllChatThreadMessages = useCallback((params) => {
        dispatch(actions.deleteAllChatThreadMessages({
            topicId: params.topicId,
        }));
    }, [dispatch]);
    // Thread形式のデータのmessagesでSliceの値を差し替える
    const replaceChatThreadMessages = useCallback((params) => {
        // 対象Entityが存在していない場合データが追加できないため先に初期化する
        dispatch(actions.initializeThreadTopic({
            topicId: params.topicId,
        }));
        const structuredThreadMessageData = createThreadMessagesByParams(params.messages, params.criterionMessageId, params.direction, params.isLoadedOfEndOfSourceItems);
        dispatch(actions.replaceChatThreadMessages({
            topicId: params.topicId,
            threadMessages: structuredThreadMessageData,
        }));
    }, [dispatch]);
    // スクロール位置保存
    const setThreadScrollToMessageId = useCallback((params) => {
        dispatch(actions.setThreadScrollToMessageId({
            topicId: params.topicId,
            chatMessageId: params.chatMessageId,
        }));
    }, [dispatch]);
    // スクロール位置リセット
    const resetThreadScrollToMessageId = useCallback((params) => {
        dispatch(actions.resetThreadScrollToMessageId({
            topicId: params.topicId,
        }));
    }, [dispatch]);
    // 検索範囲保存
    const updateChatThreadSearchRange = useCallback((params) => {
        dispatch(actions.updateChatThreadSearchRange({
            topicId: params.topicId,
            searchRange: params.searchRange,
        }));
    }, [dispatch]);
    // 選択メッセージID設定
    const setChatThreadSelectedMessageId = useCallback((params) => {
        dispatch(actions.setChatThreadSelectedMessageId({
            topicId: params.topicId,
            chatMessageId: params.chatMessageId,
        }));
    }, [dispatch]);
    // 選択メッセージIDリセット
    const resetChatThreadSelectedMessageId = useCallback((params) => {
        dispatch(actions.resetChatThreadSelectedMessageId({
            topicId: params.topicId,
        }));
    }, [dispatch]);
    // 最新のメッセージのhasMoreNextを強制的にtrueにする
    const forceUpdateThreadMessageHasMoreNext = useCallback((params) => {
        dispatch(actions.forceUpdateThreadMessageHasMoreNext(params));
    }, [dispatch]);
    return useMemo(() => ({
        addChatThreadMessages,
        deleteAllChatThreadMessages,
        replaceChatThreadMessages,
        setThreadScrollToMessageId,
        resetThreadScrollToMessageId,
        updateChatThreadSearchRange,
        setChatThreadSelectedMessageId,
        resetChatThreadSelectedMessageId,
        forceUpdateThreadMessageHasMoreNext,
    }), [
        addChatThreadMessages,
        deleteAllChatThreadMessages,
        replaceChatThreadMessages,
        setThreadScrollToMessageId,
        resetThreadScrollToMessageId,
        updateChatThreadSearchRange,
        setChatThreadSelectedMessageId,
        resetChatThreadSelectedMessageId,
        forceUpdateThreadMessageHasMoreNext,
    ]);
};
/**
 * 指定したチャットルームのChatSearch形式のデータを扱うカスタムフック
 * @param dispatch 処理対象のdispatch
 * @returns
 */
export const useChatSearchService = (dispatch) => {
    // ChatSearch形式のデータのmessagesをSliceに追加
    const addChatSearchMessages = useCallback((params) => {
        // 渡されたパラメータからSearchMessagesを作る
        const structuredSearchMessageData = createSearchMessagesByParams(params.messages, params.criterionMessageId, params.direction, params.isLoadedOfEndOfSourceItems);
        // ChatSearch形式のデータのmessagesをSliceに追加
        dispatch(actions.addChatSearchMessages({
            chatRoomId: params.chatRoomId,
            searchMessages: structuredSearchMessageData,
            direction: params.direction,
            isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfSourceItems,
        }));
    }, [dispatch]);
    // キーワードを保存
    const setKeyword = useCallback((parms) => {
        dispatch(actions.setChatSearchKeyword({
            chatRoomId: parms.chatRoomId,
            keyword: parms.keyword,
        }));
    }, [dispatch]);
    // 検索範囲を保存
    const setSearchRange = useCallback((parms) => {
        dispatch(actions.setChatSearchSearchRange({
            chatRoomId: parms.chatRoomId,
            searchRange: parms.searchRange,
        }));
    }, [dispatch]);
    return useMemo(() => ({
        addChatSearchMessages,
        setSearchRange,
        setKeyword,
    }), [addChatSearchMessages, setKeyword, setSearchRange]);
};
/**
 * 指定したアテンションのデータを扱うカスタムフック
 * @param dispatch 処理対象のdispatch
 * @returns
 */
export const useAttentionService = (dispatch) => {
    // Attentionを1件追加する
    const addAttentionMessage = useCallback((params) => {
        dispatch(actions.addAttentionMessage({
            id: params.id,
            attentionType: params.attentionType,
            attentionUserName: params.attentionUserName,
            chatMessageId: params.chatMessageId,
        }));
    }, [dispatch]);
    // アテンションのデータをSliceに追加
    const addAttentionMessages = useCallback((params) => {
        // 渡されたパラメータからAttentionsを作る
        const structuredAttentionData = createAttentionsByParams(params.messages, params.criterionMessageId, params.direction, params.isLoadedOfEndOfSourceItems);
        // attentionsをSliceに追加
        dispatch(actions.addAttentionMessages({
            targetGroup: params.targetGroup,
            messages: structuredAttentionData,
            direction: params.direction,
            isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfSourceItems,
        }));
    }, [dispatch]);
    // アテンションのデータを1件削除する
    const deleteAttentionMessage = useCallback((params) => {
        dispatch(actions.deleteAttentionMessage({
            attentionId: params.attentionId,
        }));
    }, [dispatch]);
    // スクロール位置保存設定
    const setAttentionScrollToEntityRecordId = useCallback((params) => {
        dispatch(actions.setAttentionScrollToEntityRecordId(params));
    }, [dispatch]);
    // スクロール位置リセット
    const resetAttentionScrollToEntityRecordId = useCallback((params) => {
        dispatch(actions.resetAttentionScrollToEntityRecordId(params));
    }, [dispatch]);
    return useMemo(() => ({
        addAttentionMessage,
        addAttentionMessages,
        deleteAttentionMessage,
        setAttentionScrollToEntityRecordId,
        resetAttentionScrollToEntityRecordId,
    }), [
        addAttentionMessage,
        addAttentionMessages,
        deleteAttentionMessage,
        resetAttentionScrollToEntityRecordId,
        setAttentionScrollToEntityRecordId,
    ]);
};
/**
 * チャットの表示形式のデータ操作を扱うカスタムフック
 * @param dispatch 処理対象のdispatch
 * @returns
 */
export const useChatCurrentService = (dispatch) => {
    // チャットの表示形式を設定する(2ペイン表示かコンパクト表示か)
    const setChatCurrentStyle = useCallback((style) => {
        dispatch(actions.setChatCurrentStyle(style));
    }, [dispatch]);
    // 表示するチャットルームを設定する
    const setCurrentChatRoom = useCallback((chatRoom) => {
        dispatch(actions.setChatRoom(chatRoom));
    }, [dispatch]);
    // 表示するチャットルームを設定して、スレッドを復元する
    const setCurrentChatRoomAndRestoreThread = useCallback((chatRoom) => {
        dispatch(actions.setChatRoomAndRestoreThread(chatRoom));
    }, [dispatch]);
    // 表示するチャットルームをリセットする
    const resetCurrentChatRoom = useCallback(() => {
        dispatch(actions.resetChatRoom());
    }, [dispatch]);
    // 表示するチャットスレッドを設定する
    const setCurrentChatThread = useCallback((chatThread) => {
        dispatch(actions.setChatThread(chatThread));
    }, [dispatch]);
    // 表示するチャットスレッドをリセットする
    const resetCurrentChatThread = useCallback(() => {
        dispatch(actions.resetChatThread());
    }, [dispatch]);
    // チャットに表示するViewを設定する（個人設定値をもとに設定される）
    const setChatCurrentDisplayFormat = useCallback((chatView) => {
        dispatch(actions.setChatCurrentDisplayFormat(chatView));
    }, [dispatch]);
    // モードを設定する
    const setCurrentMode = useCallback((mode) => {
        dispatch(actions.setMode(mode));
    }, [dispatch]);
    return useMemo(() => ({
        setChatCurrentStyle,
        setCurrentChatRoom,
        resetCurrentChatRoom,
        setCurrentChatThread,
        resetCurrentChatThread,
        setChatCurrentDisplayFormat,
        setCurrentMode,
        setCurrentChatRoomAndRestoreThread,
    }), [
        resetCurrentChatRoom,
        resetCurrentChatThread,
        setChatCurrentDisplayFormat,
        setChatCurrentStyle,
        setCurrentChatRoom,
        setCurrentChatThread,
        setCurrentMode,
        setCurrentChatRoomAndRestoreThread,
    ]);
};
// ----------------------------------
// Unread
// ----------------------------------
/**
 * 未読情報を操作するcustom hook
 * @param params
 * @returns 未読情報を操作するための関数セット
 */
export const useUnreadService = () => {
    const dispatch = useMessageDispatch();
    const setChatRoomUnreadCount = useCallback((params) => {
        dispatch(actions.setChatRoomUnreadCount(params));
    }, [dispatch]);
    const setChatThreadUnreadCount = useCallback((params) => {
        dispatch(actions.setChatThreadUnreadCount(params));
    }, [dispatch]);
    /**
     * チャットスレッドの未読情報一覧を保存する
     */
    const setChatThreadUnreadCounts = useCallback((params) => {
        dispatch(actions.setChatThreadUnreadCounts(params));
    }, [dispatch]);
    const updateChatRoomLastReadMessageId = useCallback((params) => {
        dispatch(actions.updateChatRoomLastReadMessageId(params));
    }, [dispatch]);
    const updateChatThreadLastReadMessageId = useCallback((params) => {
        dispatch(actions.updateChatThreadLastReadMessageId(params));
    }, [dispatch]);
    const resetAllChatUnread = useCallback(() => {
        dispatch(actions.resetAllChatUnread());
    }, [dispatch]);
    return useMemo(() => ({
        setChatRoomUnreadCount,
        setChatThreadUnreadCount,
        setChatThreadUnreadCounts,
        updateChatRoomLastReadMessageId,
        updateChatThreadLastReadMessageId,
        resetAllChatUnread,
    }), [
        setChatRoomUnreadCount,
        setChatThreadUnreadCount,
        setChatThreadUnreadCounts,
        updateChatRoomLastReadMessageId,
        updateChatThreadLastReadMessageId,
        resetAllChatUnread,
    ]);
};
/**
 * チャットメッセージの下書きのデータ操作を扱うカスタムフック
 * @param dispatch 処理対象のdispatch
 * @returns
 */
export const useChatDraftService = (dispatch) => {
    /**
     * チャットメッセージの下書きを設定する
     */
    const saveChatDraft = useCallback((params) => {
        dispatch(actions.setChatDraft(params));
    }, [dispatch]);
    /**
     * チャットメッセージの下書きを削除する
     */
    const deleteChatDraft = useCallback((targetId) => {
        dispatch(actions.deleteChatDraft(targetId));
    }, [dispatch]);
    return useMemo(() => ({
        saveChatDraft,
        deleteChatDraft,
    }), [deleteChatDraft, saveChatDraft]);
};
/**
 * Feedのデータを扱うカスタムフック
 * @param dispatch 処理対象のdispatch
 * @returns
 */
export const useFeedService = (dispatch) => {
    // Feedに最新のメッセージを1件追加する
    const addFeedLatestMessage = useCallback((params) => {
        dispatch(actions.addFeedLatestMessage({
            id: params.chatMessage.id,
        }));
    }, [dispatch]);
    // Feedで扱うmessagesをSliceに追加
    const addFeedMessages = useCallback((params) => {
        // 渡されたパラメータからFeedMessagesを作る
        const structuredFeedMessageData = createFeedMessagesByParams(params.messages, params.criterionMessageId, params.direction, params.isLoadedOfEndOfSourceItems);
        // Feedで扱うmessagesをSliceに追加
        dispatch(actions.addFeedMessages({
            feedMessages: structuredFeedMessageData,
            direction: params.direction,
            isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfSourceItems,
        }));
    }, [dispatch]);
    // Feedのメッセージを1件削除する
    const deleteFeedMessage = useCallback((params) => {
        dispatch(actions.deleteFeedMessage({
            id: params.chatMessageId,
        }));
    }, [dispatch]);
    // スクロール位置保存設定
    const setFeedScrollToMessageId = useCallback((params) => {
        dispatch(actions.setFeedScrollToMessageId(params));
    }, [dispatch]);
    return useMemo(() => ({
        addFeedLatestMessage,
        addFeedMessages,
        deleteFeedMessage,
        setFeedScrollToMessageId,
    }), [
        addFeedLatestMessage,
        addFeedMessages,
        deleteFeedMessage,
        setFeedScrollToMessageId,
    ]);
};
/**
 * Bookmarkのデータを扱うカスタムフック
 * @param dispatch 処理対象のdispatch
 * @returns
 */
export const useBookmarkService = (dispatch) => {
    // Bookmarkに最新のメッセージを1件追加する
    const addBookmarkMessage = useCallback((params) => {
        dispatch(actions.addBookmarkMessage({
            chatMessageId: params.chatMessageId,
            archived: params.archived,
            createdAt: params.createdAt,
        }));
    }, [dispatch]);
    // Bookmarkで扱うmessagesをSliceに追加
    const addBookmarkMessages = useCallback((params) => {
        // 渡されたパラメータからBookmarkMessagesを作る
        const structuredBookmarkMessageData = createBookmarksByParams(params.messages, params.criterionMessageId, params.direction, params.isLoadedOfEndOfSourceItems);
        // Bookmarkで扱うmessagesをSliceに追加
        dispatch(actions.addBookmarkMessages({
            messages: structuredBookmarkMessageData,
            direction: params.direction,
            isLoadedOfEndOfSourceItems: params.isLoadedOfEndOfSourceItems,
        }));
    }, [dispatch]);
    // Bookmarkのメッセージを1件削除する
    const deleteBookmarkMessage = useCallback((params) => {
        dispatch(actions.deleteBookmarkMessage({
            bookmarkId: params.bookmarkId,
        }));
    }, [dispatch]);
    // スクロール位置保存設定
    const setBookmarkScrollToMessageId = useCallback((params) => {
        dispatch(actions.setBookmarkScrollToMessageId(params));
    }, [dispatch]);
    // スクロール位置リセット
    const resetBookmarkScrollToMessageId = useCallback(() => {
        dispatch(actions.resetBookmarkScrollToMessageId());
    }, [dispatch]);
    // アーカイブフィルタを設定する
    const setBookmarkArchiveFilter = useCallback((params) => {
        dispatch(actions.setBookmarkArchiveFilter(params));
    }, [dispatch]);
    // キーワードを設定する
    const setBookmarkKeyword = useCallback((params) => {
        dispatch(actions.setBookmarKeyword(params));
    }, [dispatch]);
    // キーワードを初期化する
    const resetBookmarkKeyword = useCallback(() => {
        dispatch(actions.resetBookmarkKeyword());
    }, [dispatch]);
    // 取得したデータをリセットする
    const resetBookmarks = useCallback(() => {
        dispatch(actions.resetBookmarks());
    }, [dispatch]);
    return useMemo(() => ({
        addBookmarkMessage,
        addBookmarkMessages,
        deleteBookmarkMessage,
        setBookmarkScrollToMessageId,
        resetBookmarkScrollToMessageId,
        setBookmarkArchiveFilter,
        setBookmarkKeyword,
        resetBookmarkKeyword,
        resetBookmarks,
    }), [
        addBookmarkMessage,
        addBookmarkMessages,
        deleteBookmarkMessage,
        setBookmarkScrollToMessageId,
        resetBookmarkScrollToMessageId,
        setBookmarkArchiveFilter,
        setBookmarkKeyword,
        resetBookmarkKeyword,
        resetBookmarks,
    ]);
};
// ----------------------------------------------------
//  ChatMessage
// ----------------------------------------------------
/**
 * チャットメッセージのデータ操作を扱うカスタムフック
 */
export const useChatMessageService = (dispatch) => {
    /**
     * チャットメッセージを複数件追加する。新規項目は追加、既存項目は置換する
     */
    const addChatMessagesToCache = useCallback((params) => {
        // キャッシュへ追加
        dispatch(actions.addCacheMessages(params));
        // 各表示スタイル毎のviewmodelへの追加は呼び出し元で行う
    }, [dispatch]);
    /**
     * チャットメッセージを1件追加する。新規項目は追加、既存項目は置換する
     */
    const addChatMessageToCache = useCallback((params) => {
        // キャッシュへ追加
        dispatch(actions.setCacheMessage(params));
        // 各表示スタイル毎のviewmodelへの追加は呼び出し元で行う
    }, [dispatch]);
    /**
     * チャットメッセージを1件追加する
     */
    const addChatMessage = useCallback((params) => {
        var _a, _b;
        const chatRoomId = params.chatMessage.chatRoomId;
        const parentChatRoomId = params.chatMessage.chatRoom.parentChatRoomId;
        // 各表示スタイル毎のViewModelを更新
        /**
         * タイムライン形式：メッセージを追加
         */
        dispatch(actions.addChatTimelineMessage({
            chatRoomId: chatRoomId,
            chatMessageId: params.chatMessage.id,
            topicId: (_a = params.chatMessage.parentChatMessageId) !== null && _a !== void 0 ? _a : undefined,
            addTargetChatRoomId: chatRoomId,
            createdById: params.chatMessage.createdById,
        }));
        // 親チャットルームが存在する場合、その親チャットルームに対してもメッセージを追加する
        if (parentChatRoomId) {
            dispatch(actions.addChatTimelineMessage({
                chatRoomId: parentChatRoomId,
                chatMessageId: params.chatMessage.id,
                topicId: (_b = params.chatMessage.parentChatMessageId) !== null && _b !== void 0 ? _b : undefined,
                addTargetChatRoomId: chatRoomId,
                createdById: params.chatMessage.createdById,
            }));
        }
        if (params.chatMessage.parentChatMessageId) {
            /* 親メッセージIDがある＝返信の場合 */
            /**
             * スレッドリスト形式：返信件数のみ更新（+1）
             */
            dispatch(actions.incrementReplyCountToThreadList({
                chatRoomId: chatRoomId,
                topicId: params.chatMessage.parentChatMessageId,
                pubsubTargetChatRoomId: params.pubsubTargetChatRoomId,
            }));
            // 親チャットルームが存在する場合、その親チャットルームに対しても返信件数を更新
            if (parentChatRoomId) {
                dispatch(actions.incrementReplyCountToThreadList({
                    chatRoomId: parentChatRoomId,
                    topicId: params.chatMessage.parentChatMessageId,
                    pubsubTargetChatRoomId: params.pubsubTargetChatRoomId,
                }));
            }
            /**
             * スレッド形式：トピックへの返信として追加
             */
            // ViewModelが初期化されていない場合初期化する
            dispatch(actions.initializeThreadTopic({
                topicId: params.chatMessage.parentChatMessageId,
            }));
            // トピックへの返信として追加
            dispatch(actions.addThreadCommentMessage({
                topicId: params.chatMessage.parentChatMessageId,
                commentId: params.chatMessage.id,
                createdById: params.chatMessage.createdById,
            }));
        }
        else {
            /* 親メッセージIDがない＝トピックの場合 */
            /**
             * スレッドリスト形式：トピックとして追加
             */
            dispatch(actions.addChatThreadListMessage({
                chatRoomId: chatRoomId,
                topicId: params.chatMessage.id,
                addTargetChatRoomId: chatRoomId,
                createdById: params.chatMessage.createdById,
            }));
            // 親チャットルームが存在する場合、その親チャットルームに対してもトピックを追加
            if (parentChatRoomId) {
                dispatch(actions.addChatThreadListMessage({
                    chatRoomId: parentChatRoomId,
                    topicId: params.chatMessage.id,
                    addTargetChatRoomId: chatRoomId,
                    createdById: params.chatMessage.createdById,
                }));
            }
            /**
             * スレッド形式：ユーザが「返信」ボタンを押してスレッドを表示するまではスレッド情報をstateに保持する必要がないため、何もしない
             */
        }
        // キャッシュへ追加。既存の場合は置換
        addChatMessageToCache(params);
    }, [addChatMessageToCache, dispatch]);
    /**
     * チャットメッセージを一件更新する
     */
    const setChatMessage = useCallback((params) => {
        // キャッシュを更新。存在しない場合は追加
        dispatch(actions.setCacheMessage(params));
    }, [dispatch]);
    /**
     * 指定されたチャットメッセージを削除する
     */
    const deleteChatMessage = useCallback((params) => {
        // 各表示スタイル毎のViewModelからメッセージを削除
        /**
         * タイムライン形式：該当のメッセージを削除
         */
        dispatch(actions.deleteChatTimelineMessage({
            chatRoomId: params.chatRoomId,
            chatMessageId: params.chatMessageId,
        }));
        // 親チャットルームが存在する場合、その親チャットルームからもメッセージを削除
        if (params.parentChatRoomId) {
            dispatch(actions.deleteChatTimelineMessage({
                chatRoomId: params.parentChatRoomId,
                chatMessageId: params.chatMessageId,
            }));
        }
        /**
         * スレッドリスト形式
         *   削除されたメッセージがトピックの場合：トピックを削除
         *   削除されたメッセージが返信の場合：返信件数を更新（-1）
         */
        dispatch(actions.deleteChatThreadListMessage({
            chatRoomId: params.chatRoomId,
            chatMessageId: params.chatMessageId,
            isTopicMessage: params.isTopicMessage,
        }));
        // 親チャットルームが存在する場合、その親チャットルームからもメッセージを削除
        if (params.parentChatRoomId) {
            dispatch(actions.deleteChatThreadListMessage({
                chatRoomId: params.parentChatRoomId,
                chatMessageId: params.chatMessageId,
                isTopicMessage: params.isTopicMessage,
            }));
        }
        /**
         * スレッド形式
         *    削除されたメッセージがトピックの場合：トピックを削除
         *    削除されたメッセージが返信の場合：該当のメッセージを削除（prevId、nextIdで参照されている箇所も更新）
         */
        dispatch(actions.deleteChatThreadMessage({
            chatMessageId: params.chatMessageId,
        }));
        // キャッシュからメッセージを削除
        dispatch(actions.deleteCacheMessage(params));
    }, [dispatch]);
    /**
     * リアクション追加時に既存のメッセージにリアクションを追加する
     */
    const insertReaction = useCallback((params) => {
        dispatch(actions.insertReaction(params));
    }, [dispatch]);
    /**
     * リアクション削除時に既存のメッセージからリアクションを削除する
     */
    const deleteReaction = useCallback((params) => {
        dispatch(actions.deleteReaction(params));
    }, [dispatch]);
    return useMemo(() => ({
        addChatMessagesToCache,
        addChatMessageToCache,
        addChatMessage,
        setChatMessage,
        deleteChatMessage,
        insertReaction,
        deleteReaction,
    }), [
        addChatMessagesToCache,
        addChatMessageToCache,
        addChatMessage,
        deleteChatMessage,
        deleteReaction,
        insertReaction,
        setChatMessage,
    ]);
};
// ----------------------------------
// ActiveChatRoom
// ----------------------------------
/**
 * アクティブチャットルームの情報を操作するcustom hook
 * @returns アクティブチャットルームの情報を操作するための関数セット
 */
export const useActiveChatRoomService = () => {
    const dispatch = useMessageDispatch();
    /**
     * アクティブチャットルームの情報を未読件数とともにセットする
     */
    const setActiveChatRoomWithUnreadCount = useCallback((params) => {
        dispatch(actions.setActiveChatRoomWithUnreadCount(params));
    }, [dispatch]);
    /**
     * 複数アクティブチャットルームの情報を未読件数とともにセットする
     */
    const setActiveChatRoomsWithUnreadCount = useCallback((params) => {
        dispatch(actions.setActiveChatRoomsWithUnreadCount(params));
    }, [dispatch]);
    return useMemo(() => ({
        setActiveChatRoomWithUnreadCount,
        setActiveChatRoomsWithUnreadCount,
    }), [setActiveChatRoomWithUnreadCount, setActiveChatRoomsWithUnreadCount]);
};
/**
 * チャットメッセージの取得を行うカスタムフック
 * キャッシュに存在しない場合は、APIから取得する
 * @param id 取得するメッセージのID
 * @returns
 */
export const useChatMessage = (id, dispatch, useAppSelector) => {
    const chatMessageService = useChatMessageService(dispatch);
    const message = useAppSelector((state) => state.message.chat.cacheMessages.entities[id]);
    const [lazyGetChatMessageQuery, lazyGetChatMessageQueryResult] = useLazyGetChatMessageQuery();
    useEffect(() => {
        const getChatMessage = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const data = yield lazyGetChatMessageQuery({ messageId: id }).unwrap();
                if (data.chatMessage) {
                    // キャッシュに保存
                    chatMessageService.setChatMessage({
                        chatMessage: data.chatMessage,
                    });
                }
            }
            catch (err) { }
        });
        // メッセージが存在しない かつ idが指定されている場合、APIから取得する
        if (!message && id) {
            getChatMessage();
        }
    }, [id, lazyGetChatMessageQuery, message, chatMessageService]);
    return useMemo(() => ({
        message,
        isError: lazyGetChatMessageQueryResult.isError,
    }), [lazyGetChatMessageQueryResult.isError, message]);
};
/**
 * チャットメッセージの再取得を行うカスタムフック
 */
export const useChatMessageReloader = (dispatch) => {
    const chatMessageService = useChatMessageService(dispatch);
    const [lazyGetChatMessageQuery] = useLazyGetChatMessageQuery();
    // 対象のメッセージを読み直し、キャッシュを強制的に入れ替える
    const reloadChatMessage = useCallback((id) => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield lazyGetChatMessageQuery({ messageId: id }).unwrap();
        if (data.chatMessage) {
            chatMessageService.setChatMessage({
                chatMessage: data.chatMessage,
            });
        }
    }), [lazyGetChatMessageQuery, chatMessageService]);
    return useMemo(() => ({ reloadChatMessage }), [reloadChatMessage]);
};
/**
 *  スレッド内のメッセージリストを取得する
 */
export const useChatThreadMessages = (id, useSelector) => {
    const messagesDictionary = useSelector((state) => state.message.chat.cacheMessages.entities);
    // dictionary -> 配列に変換
    const messages = useMemo(() => Object.values(messagesDictionary).filter((value) => value !== undefined), [messagesDictionary]);
    const childrenMessages = messages.filter((message) => (message === null || message === void 0 ? void 0 : message.parentChatMessageId) === id);
    return useMemo(() => ({ childrenMessages }), [childrenMessages]);
};
