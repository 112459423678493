import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { FC, memo, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useAppSelector } from 'states/hooks'

import { FormValues, usePresenceSettingForm } from './usePresenceSettingForm'
import { useTranslation } from '@crew/modules/dist/i18n'
import { PresenceStateType } from '@crew/enums/dist/domain'
import { GetUserPresenceStateRequest } from '@crew/apis/dist/user/models/getUserPresenceState/request'
import { useGetUserPresenceStateQuery } from '@crew/apis/dist/user/userApis'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useValueChangeEffect } from '@crew/hooks'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewRadioGroupField } from 'components/forms/crewRadioGroupField'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewPresenceStateIcon } from 'components/elements/crewPresenceStateIcon/crewPresenceStateIcon'

// render content
// renderとして使うのでmemo不可
const PresenceRadioItem: FC<{
  presenceStateType: PresenceStateType
  name: string
}> = (props) => (
  <div className="flex flex-row items-center gap-1.5 my-2">
    <CrewPresenceStateIcon presenceStateType={props.presenceStateType} />

    <div>{props.name}</div>
  </div>
)

type PresenceSettingFormProps = {
  onClose: () => void
}

export const PresenceSettingForm: FC<PresenceSettingFormProps> = memo(
  (props) => {
    const { t } = useTranslation()
    const [showApiErrors] = useShowApiErrors()
    const {
      control,
      reset,
      handleSubmit,
      updateUserPresenceState,
      isLoadingUpdateUserPresenceState,
    } = usePresenceSettingForm()

    const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

    // get user presence state
    const paramsUserPresenceState: GetUserPresenceStateRequest | undefined =
      loggedInUser?.id
        ? {
            userId: loggedInUser.id,
          }
        : undefined

    const { data: dataUserPresenceState } = useGetUserPresenceStateQuery(
      paramsUserPresenceState ?? skipToken
    )

    // initialize form values
    useValueChangeEffect(
      () => {
        reset({
          presenceStateId:
            dataUserPresenceState?.userPresenceState?.presenceStateId,
          presenceStateMessage:
            dataUserPresenceState?.userPresenceState?.presenceStateMessage,
        })
      },
      [
        dataUserPresenceState?.userPresenceState?.presenceStateId,
        dataUserPresenceState?.userPresenceState?.presenceStateMessage,
        reset,
      ],
      dataUserPresenceState?.userPresenceState
    )

    const presenceStates = useAppSelector(
      (state) => state.presenceStates.presenceStates
    )

    const presenceStateData = useMemo(
      () =>
        Object.values(presenceStates).map((presenceState) => ({
          id: presenceState.id,
          name: presenceState.name,
          presenceStateType: presenceState.presenceStateType,
        })),
      [presenceStates]
    )

    // handle submit button press
    const handleSubmitButtonPress = async () => {
      const onSubmitForm: SubmitHandler<FormValues> = async (values) => {
        try {
          await updateUserPresenceState(values)

          props.onClose()
        } catch (err) {
          // サーバーエラーの場合はトースト表示
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmitForm)()
    }

    return (
      <form className="flex flex-col h-full">
        <div className="flex flex-col gap-2.5 flex-1 px-2.5">
          {/* presence statuses */}
          <CrewRadioGroupField
            id="presenceStateId"
            control={control}
            name="presenceStateId"
            dataSource={presenceStateData}
            valueExpr="id"
            displayExpr="name"
            layout="vertical"
            itemRender={PresenceRadioItem}
          />

          {/* メッセージ */}
          <CrewTextBoxField
            id="presenceStateMessage"
            control={control}
            name="presenceStateMessage"
            label={t('label.message')}
          />
        </div>

        <div className="flex gap-x-5 justify-end">
          {/* 追加 */}
          <CrewButton
            onClick={handleSubmitButtonPress}
            type="primary"
            text={t('action.register')}
            disabled={isLoadingUpdateUserPresenceState}
          />

          {/* キャンセル */}
          <CrewButton
            onClick={props.onClose}
            type="normal"
            text={t('action.cancel')}
          />
        </div>
      </form>
    )
  }
)
