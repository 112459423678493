import { CrewAvatarSize } from 'components/elements/crewAvatar/crewAvatar'
import { TaskDetailCommentItemActionMenu } from './components/taskDetailCommentItemActionMenu/taskDetailCommentItemActionMenu'
import { CommentButtonGroup } from 'enums/app'
import { memo } from 'react'
import { useTaskDetailCommentItem } from './useTaskDetailCommentItem'
import { TaskDetailCommentInputForm } from 'features/task/components/taskDetailPage/components/taskDetailCommentList/components/taskDetailCommentListPanel/components/taskDetailCommentInput/components/taskDetailCommentInputForm/taskDetailCommentInputForm'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { HTMLEDITOR_VIEW_STYLE } from 'configs/constants'
import { CrewAttachments } from 'components/elements/crewAttachments/crewAttachments'
import { CrewTaskHistory } from 'components/elements/crewTaskHistory/crewTaskHistory'
import { CrewUserAvatar } from 'components/elements/crewUserAvatar/crewUserAvatar'
import { CrewAttachmentThumbnails } from 'components/elements/crewAttachmentThumbnails/crewAttachmentThumbnails'
import { useCallback, useState, useMemo, useContext, useEffect } from 'react'
import { useTranslation } from '@crew/modules/i18n'
import { useModal } from 'components/layouts/modal/useModal'
import { useToast } from 'hooks/useToast'
import { TaskDetailCommentListPageContext } from '../../useTaskDetailCommentListPanel'
import { isImageFile } from '@crew/utils/chat'
import { useAppSelector } from 'states/hooks'
import { CrewHtmlContent } from 'components/elements/crewHtmlContent/crewHtmlContent'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { TaskRef, TaskStateRef, UserRef } from '@crew/models/refs'
import { FileHistory } from '@crew/models/domain'

type TaskHistory = {
  originalId: string | null
  originalHistory: TaskHistory
  assignToUserId: string | null
  assignToUser: UserRef | null
  taskPriority: number
  taskStateId: string
  taskState: TaskStateRef
  actualProgress: number | null
  wbsNumber: string | null
  isProgressManagementDisabled: boolean
  parentTaskId: string | null
  parentTask: TaskRef | null
}

type TaskCommentAndHistory = {
  taskCommentId: string | null
  comment: string | null
  taskHistoryId: string | null
  taskHistory: TaskHistory
  createdById: string
  createdByUser: UserRef
  createdAt: string
  version: number
  fileHistories: FileHistory[]
}

type TaskDetailCommentItemProps = {
  hasFileDeletePermission: boolean
  hasFileDownloadPermission: boolean
  taskComment: TaskCommentAndHistory
  latestTaskHistoryId: string | null
}

export const TaskDetailCommentItem = memo(
  (props: TaskDetailCommentItemProps) => {
    const { deleteTaskComment, deleteTaskHistory } = useTaskDetailCommentItem()

    const { t } = useTranslation()
    const toast = useToast()

    const selectedCommentDisplayType = useAppSelector(
      (state) => state.taskDetail.selectedCommentButtonGroupItem
    )

    const { editItemId, setEditItemId } = useContext(
      TaskDetailCommentListPageContext
    )

    const [visibleActionMenu, setVisibleActionMenu] = useState<boolean>(false)
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false)
    const [isEditComment, setIsEditComment] = useState<boolean>(false)

    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
      useModal()

    // 確認ダイアログメッセージ
    const [confirmMessage, setConfirmMessage] = useState('')

    const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

    // エラーダイアログメッセージ
    const [errorMessage, setErrorMessage] = useState('')

    const [showApiErrors] = useShowApiErrors()

    const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

    // ユーザー設定からデフォルトのユーザープロファイル地域を取得
    const defaultUserProfileRegion = useUserSetting(
      SettingKeyType.UserProfileRegion,
      Region.Japan.value
    )

    // ログインユーザのコメント判定
    const isMyComment = useMemo(
      () => props.taskComment.createdById === loggedInUser?.id,
      [loggedInUser?.id, props.taskComment.createdById]
    )

    // List image file
    const imageFiles = useMemo(
      () =>
        props.taskComment.fileHistories.filter((item) =>
          isImageFile(item.name)
        ),
      [props.taskComment.fileHistories]
    )

    // List normal file
    const files = useMemo(
      () =>
        props.taskComment.fileHistories.filter(
          (item) => !isImageFile(item.name)
        ),
      [props.taskComment.fileHistories]
    )

    //call when mouse hover element
    const handleMouseEnter = useCallback(() => {
      setVisibleActionMenu(true)
    }, [])

    //call when mouse leave element
    const handleMouseLeave = useCallback(() => {
      setVisibleActionMenu(false)
    }, [])

    //click button cancel close comment panel
    const handleCloseCommentInput = useCallback(() => {
      setIsEditComment(false)
    }, [])

    //click action menu edit item
    const handleEditActionMenuButtonClick = useCallback(() => {
      setIsEditComment(true)
      setEditItemId(props.taskComment.taskCommentId)
    }, [setEditItemId, props.taskComment.taskCommentId])

    //Click action menu delete item
    const handleDeleteActionMenuButtonClick = useCallback(async () => {
      // 確認ダイアログの表示（処理は確認ダイアログのOKボタン押下時に行う）
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()
    }, [openConfirmDialog, t])

    // 削除確認ダイアログ OKボタン
    const handleDeletePermitButtonClick = useCallback(async () => {
      closeConfirmDialog()

      if (!props.taskComment.taskCommentId) {
        if (!props.latestTaskHistoryId) {
          return
        }

        try {
          //delete latest task history
          await deleteTaskHistory(props.latestTaskHistoryId)

          toast.success(t('message.task.taskHistoryDeleted'))
        } catch (err) {
          setErrorMessage(t('message.general.errorMessage.delete'))
          openErrorDialog()
        }
      } else {
        try {
          //delete task comment
          await deleteTaskComment(
            props.taskComment.taskCommentId,
            props.taskComment.version
          )

          // FIXME: CREW-4418 あるべき文言について考える
          // https://break-tmc.atlassian.net/browse/CREW-4418
          toast.success(t('message.task.taskCommentDeleted'))
        } catch (err) {
          showApiErrors(err)
        }
      }
    }, [
      closeConfirmDialog,
      deleteTaskComment,
      deleteTaskHistory,
      openErrorDialog,
      props.latestTaskHistoryId,
      props.taskComment.taskCommentId,
      props.taskComment.version,
      showApiErrors,
      t,
      toast,
    ])

    //when confirm dialog cancel button click
    const handleCancelButtonClick = useCallback(() => {
      closeConfirmDialog()
    }, [closeConfirmDialog])

    //close edit comment input when other edit comment input open or open create comment input
    useEffect(() => {
      if (!editItemId || editItemId !== props.taskComment.taskCommentId) {
        setIsEditComment(false)
      }
    }, [editItemId, props.taskComment.taskCommentId])

    // Only show the action menu when it's my comment
    // and, if it is the Comment tab or History tab, only the action menu of the latest item will be displayed
    const showCommentActionMenu = useMemo(() => {
      const isCorrectCommentType =
        selectedCommentDisplayType ===
        CommentButtonGroup.CommentAndHistory.value
      const hasTaskCommentId = props.taskComment.taskCommentId

      return (
        isMyComment &&
        isCorrectCommentType &&
        hasTaskCommentId &&
        (visibleActionMenu || showContextMenu)
      )
    }, [
      isMyComment,
      props.taskComment.taskCommentId,
      selectedCommentDisplayType,
      showContextMenu,
      visibleActionMenu,
    ])

    return (
      <>
        {isEditComment ? (
          <TaskDetailCommentInputForm
            onClose={handleCloseCommentInput}
            isEditMode={true}
            description={props.taskComment.comment ?? undefined}
            taskCommentId={props.taskComment.taskCommentId ?? undefined}
          />
        ) : (
          // In case of displaying History tab, the history item is only displayed when there is a change in task information

          <div
            className="flex flex-col gap-y-2 border-b border-crew-gray-2-light dark:border-crew-gray-3-dark py-2 relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex gap-x-2 items-center">
              <div className="min-w-0">
                <CrewUserAvatar
                  userId={props.taskComment.createdByUser.id}
                  displayName={props.taskComment.createdByUser.displayName}
                  size={CrewAvatarSize.xs}
                  showLabel={true}
                  cacheValue={
                    props.taskComment.createdByUser.id +
                    props.taskComment.createdByUser.version
                  }
                />
              </div>
              <span>
                {t('format.datetime', {
                  value: props.taskComment.createdAt,
                  timeZone: defaultUserProfileRegion,
                })}
              </span>
            </div>

            {(selectedCommentDisplayType ===
              CommentButtonGroup.CommentAndHistory.value ||
              selectedCommentDisplayType ===
                CommentButtonGroup.Comment.value) &&
              props.taskComment.comment && (
                <div className={HTMLEDITOR_VIEW_STYLE}>
                  <CrewHtmlContent html={props.taskComment.comment} />
                </div>
              )}

            {/* if comment display type is comment and history or history show list history */}
            {(selectedCommentDisplayType ===
              CommentButtonGroup.CommentAndHistory.value ||
              selectedCommentDisplayType ===
                CommentButtonGroup.History.value) &&
              props.taskComment.taskHistory && (
                <CrewTaskHistory taskHistory={props.taskComment.taskHistory} />
              )}

            {showCommentActionMenu && (
              // タスクコメント枠の右上に３点リーダーを表示するためabsoluteを使用
              <div className="absolute top-0 right-0 py-2">
                <TaskDetailCommentItemActionMenu
                  onContextMenuOpen={setShowContextMenu}
                  onEditComment={handleEditActionMenuButtonClick}
                  onDeleteComment={handleDeleteActionMenuButtonClick}
                  // hide delete menu when the task history is not the latest task history
                  isHideContextMenuItemDelete={
                    props.latestTaskHistoryId !==
                    props.taskComment.taskHistoryId
                  }
                  id={`taskDetailCommentItemActionMenu-${props.taskComment.taskCommentId}-${props.taskComment.taskHistoryId}`}
                />
              </div>
            )}

            {props.taskComment.fileHistories.length > 0 && (
              <>
                {/* 添付画像ファイル */}
                {imageFiles && (
                  <CrewAttachmentThumbnails
                    attachmentThumbnails={imageFiles}
                    showDeleteButton={
                      // is my comment and have delete file permission
                      isMyComment && props.hasFileDeletePermission
                    }
                    canDownload={props.hasFileDownloadPermission}
                  />
                )}
                {/* 添付ファイル */}
                {files && (
                  <CrewAttachments
                    attachments={files}
                    showDeleteButton={
                      // is my comment and have delete file permission
                      isMyComment && props.hasFileDeletePermission
                    }
                    canDownload={props.hasFileDownloadPermission}
                  />
                )}
              </>
            )}
          </div>
        )}

        {/* 削除確認ダイアログ */}
        <CrewConfirmDialog
          isOpen={isConfirmDialogOpen}
          message={confirmMessage}
          onPermitButtonClick={handleDeletePermitButtonClick}
          onCancelButtonClick={handleCancelButtonClick}
        />

        {/* エラーダイアログ */}
        <CrewErrorDialog
          isOpen={isErrorDialogOpen}
          message={errorMessage}
          onCloseButtonClick={closeErrorDialog}
        />
      </>
    )
  }
)
