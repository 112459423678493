import { initializeApp } from 'firebase/app'

import { firebaseConfig } from '../../firebase.js'
import { isIOS } from 'utils'

// firebaseのインスタンス作成
const initializeFirebase = () => {
  if (
    firebaseConfig.apiKey &&
    firebaseConfig.authDomain &&
    firebaseConfig.projectId &&
    firebaseConfig.storageBucket &&
    firebaseConfig.messagingSenderId &&
    firebaseConfig.appId
  ) {
    return initializeApp(firebaseConfig)
  } else {
    console.error(
      '[firebase] Environment variables for push notification must be set.'
    )
  }
}

// firebase初期化
// ※iOSはWebプッシュ通知をサポートしていないため、iOSの場合を除く
export const firebaseInstance = !isIOS() ? initializeFirebase() : undefined
