import { TenantSettingTabs } from 'enums/app'
import { TenantSettingTab } from 'features/tenant/components/tenantSettingPage/components/tenantSettingTab/tenantSettingTab'
import { ProjectGroupList } from 'features/tenant/components/tenantSettingPage/components/projectGroupList/projectGroupList'

import { memo } from 'react'
import OutlineSettings from '~icons/ic/outline-settings'
import { UserList } from './components/userList/userList'
import { Route, Routes } from 'react-router-dom'
import { CrewErrorBoundary } from 'components/functions/crewErrorBoundary'
import { AuthoritySettingPanel } from './components/authoritySettingPanel/authoritySettingPanel'
import { OrganizationSettingPanel } from './components/organizationSettingPanel/organizationSettingPanel'
import { TenantSettingDetailPanel } from './components/tenantSettingDetailPanel/tenantSettingDetailPanel'
import { useSystemPermissions } from '@crew/hooks'
import { useTranslation } from '@crew/modules/dist/i18n'
import { PresenceStatusList } from './components/presenceStatusList/presenceStatusList'

export const TenantSettingPage = memo(() => {
  const { t } = useTranslation()

  const { hasSysTenantSettingViewEditPermission } = useSystemPermissions()

  return hasSysTenantSettingViewEditPermission ? (
    // 「組織設定の表示／変更」権限を持っている場合　：表示する
    <div className="flex flex-col h-full gap-2 p-2">
      <div className="w-full flex justify-between items-center h-9">
        {/* ページタイトル */}
        <div className="flex gap-2 items-center text-crew-gray-3-light dark:text-crew-gray-2-dark">
          <OutlineSettings width={24} height={24} />
          <p className="font-bold">{t('label.organizationSettings')}</p>
        </div>
      </div>
      <CrewErrorBoundary>
        <TenantSettingDetailPanel />
      </CrewErrorBoundary>

      <CrewErrorBoundary>
        <div className="flex-1 flex flex-col h-full gap-2 overflow-y-hidden">
          <TenantSettingTab />
          <div className="grow min-h-0 w-full flex flex-col h-full overflow-y-scroll">
            {/* URLに応じて表示するコンポーネントを切り替える */}
            <Routes>
              {/* 
                TODO: 一時的にデフォルトルート（index）を除去している
                以下タスク対応時にデフォルトルートを設定する予定
                https://break-tmc.atlassian.net/browse/CREW-9163
              */}
              {/* 組織タブ */}
              <Route
                path={TenantSettingTabs.Organization.value}
                element={<OrganizationSettingPanel />}
              />
              {/* 権限タブ */}
              <Route
                path={TenantSettingTabs.Authority.value}
                element={<AuthoritySettingPanel />}
              />
              <Route
                path={TenantSettingTabs.User.value}
                element={<UserList />}
              />
              <Route
                path={TenantSettingTabs.PresenceStatus.value}
                element={<PresenceStatusList />}
              />
              <Route
                path={TenantSettingTabs.ProjectGroup.value}
                element={<ProjectGroupList />}
              />
            </Routes>
          </div>
        </div>
      </CrewErrorBoundary>
    </div>
  ) : (
    <></>
  )
})
