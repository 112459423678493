import { UpdateUserPresenceStateRequest } from '@crew/apis/userPresenceState/models/updateUserPresenceState/request'
import { useUpdateUserPresenceStateMutation } from '@crew/apis/userPresenceState/userPresenceStateApis'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

export type FormValues = {
  presenceStateId: string
  presenceStateMessage: string | null
}

export const usePresenceSettingForm = () => {
  // react-hook-formの各種データを取得
  const { control, reset, handleSubmit } = useForm<FormValues>({
    criteriaMode: 'all',
  })

  const [
    updateUserPresenceStateMutation,
    { isLoading: isLoadingUpdateUserPresenceState },
  ] = useUpdateUserPresenceStateMutation()

  // ユーザ在籍状態を更新
  const updateUserPresenceState = useCallback(
    async (data: FormValues) => {
      const payload: UpdateUserPresenceStateRequest = {
        userPresenceState: {
          presenceStateId: data.presenceStateId,
          presenceStateMessage: data.presenceStateMessage ?? undefined,
        },
      }

      await updateUserPresenceStateMutation(payload).unwrap()
    },
    [updateUserPresenceStateMutation]
  )

  return {
    control,
    reset,
    handleSubmit,
    updateUserPresenceState,
    isLoadingUpdateUserPresenceState,
  }
}
