import { useLogoutMutation } from '@crew/apis/app/appApis'
import {
  useDeleteUserDeviceMutation,
  useInsertUserDeviceMutation,
} from '@crew/apis/userDevice/userDeviceApis'
import { ChatView, SettingKeyType } from '@crew/enums/app'
import {
  EntityType,
  KeywordFilterCondition,
  PresenceStateType,
  UserType,
} from '@crew/enums/domain'
import { DeviceType } from '@crew/enums/dist/system'
import { useSystemPermissions } from '@crew/hooks'
import { useTranslation } from '@crew/modules/dist/i18n'
import {
  PresenceState,
  useChatCurrentService,
  useUserSetting,
} from '@crew/states'
import { generateImageAvatarUrl } from '@crew/utils/dist/avatar'
import { getDefaultTabValue } from '@crew/utils/dist/enum'
import { Menu as MenuGroup, Transition } from '@headlessui/react'
import AccountCircleOutline from '~icons/material-symbols/account-circle-outline'
import Groups from '~icons/material-symbols/groups'
import ChatOutline from '~icons/material-symbols/chat-outline'
import EventAvailable from '~icons/material-symbols/event-available'
import MoreVert from '~icons/material-symbols/more-vert'
import OutlineInsertDriveFile from '~icons/ic/outline-insert-drive-file'
import BaseOutlineVideocam from '~icons/ic/outline-videocam'
import Menu from '~icons/material-symbols/menu'
import classNames from 'classnames'
import { CrewTextBox } from 'components/devextreme/crewTextBox'
import {
  CrewAvatar,
  CrewAvatarShapeType,
  CrewAvatarSize,
} from 'components/elements/crewAvatar/crewAvatar'
import { DEFAULT_PAGING_PARAMS, OPERATION_KEY } from 'configs/constants'
import { NativeEventInfo } from 'devextreme/events'
import { loadMessages, locale } from 'devextreme/localization'
import enMessages from 'devextreme/localization/messages/en.json'
import jaMessages from 'devextreme/localization/messages/ja.json'
import { TextBoxInstance } from 'devextreme/ui/text_box'
import themes from 'devextreme/ui/themes'
import {
  AppLanguage,
  AppTheme,
  DisplayAnonymousFile,
  SearchTabs,
  TenantSettingTabs,
  UserChatSettingDisplayFormat,
} from 'enums/app'
import {
  changeTheme,
  toggleLeftSideBar,
  toggleRightSideBar,
} from 'features/app/states/appSlice'
import { useDeviceToken } from 'hooks/pushNotification/useDeviceToken'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import useIsPWA from 'hooks/useIsPWA'
import { useLogoutFrontend } from 'hooks/useLogoutFrontend'
import {
  FC,
  Fragment,
  MouseEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { BarNavLink } from './components/barNavLink/barNavLink'
import { ButtonNavigation } from './components/buttonNavigation/buttonNavigation'
import { useGetLookupFiltersQuery } from '@crew/apis/lookup/lookupApis'
import { buildObjUrlParamsFromFilterDefinition } from '@crew/utils'
import qs from 'qs'
import { useGetUserQuery } from '@crew/apis/dist/user/userApis'
import { useStartSsoFreshdeskMutation } from '@crew/apis/dist/sso/ssoApis'
import {
  useLazyGetCommonRequestAuthzProtectedAccessTokenQuery,
  useAuthorizeCommonRequestAuthzProtectedAccessTokenMutation,
} from '@crew/apis/dist/app/appApis'
import { useToast } from 'hooks/useToast'
import { useGetTenantSettingsQuery } from '@crew/apis/setting/settingApis'
import { GetTenantSettingsRequest } from '@crew/apis/setting/models/getTenantSettings/request'
import { useGetTenantQuery } from '@crew/apis/tenantSetting/tenantSettingApis'
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import { ButtonTypes } from 'devextreme-react/cjs/button'
import { CrewPresenceStateIcon } from 'components/elements/crewPresenceStateIcon/crewPresenceStateIcon'
import { PresenceStateItem } from './components/presenceStateItem/presenceStateItem'
import { useUpdateUserPresenceStateMutation } from '@crew/apis/userPresenceState/userPresenceStateApis'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { UpdateUserPresenceStateRequest } from '@crew/apis/userPresenceState/models/updateUserPresenceState/request'
import { useModal } from 'components/layouts/modal/useModal'
import { PresenceSettingDialog } from './components/presenceSettingDialog/presenceSettingDialog'

//return type for reduce function
type ReduceReturnType = {
  [key in SettingKeyType]?: string | null
}

export const AppBar: FC = memo(() => {
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
  const filterEventMessage = useAppSelector(
    (state) => state.app.filterEventMessage
  )
  const tenantSettingEventMessage = useAppSelector(
    (state) => state.app.tenantSettingEventMessage
  )

  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const isPWA = useIsPWA()
  const { error } = useToast()
  const [showApiErrors] = useShowApiErrors()

  const { navigate } = useCrewNavigate()
  const [deleteUserDeviceMutation] = useDeleteUserDeviceMutation()
  const [insertUserDeviceMutation] = useInsertUserDeviceMutation()
  const [logoutMutation] = useLogoutMutation()
  const [startSsoFreshdeskutation] = useStartSsoFreshdeskMutation()
  const [lazyGetCommonRequestAuthzProtectedAccessTokenQuery] =
    useLazyGetCommonRequestAuthzProtectedAccessTokenQuery()
  const [authorizeCommonRequestAuthzProtectedAccessTokenMutation] =
    useAuthorizeCommonRequestAuthzProtectedAccessTokenMutation()
  const [updateUserPresenceStateMutation] = useUpdateUserPresenceStateMutation()
  const logoutFrontend = useLogoutFrontend()
  const deviceToken = useDeviceToken()
  const chatCurrentService = useChatCurrentService(dispatch)

  const [
    isOpenPresenceSettingDialog,
    openPresenceSettingDialog,
    closePresenceSettingDialog,
  ] = useModal()

  const [searchConditionMode, setSearchConditionMode] =
    useState<KeywordFilterCondition>('and')

  // user情報、「サポートポータル」の表示 / 非表示に使用する
  const { data: user } = useGetUserQuery({
    userId: loggedInUser?.id ?? '',
  })

  // Get project filter
  const { data: projectFilters, refetch: refetchProjectFilter } =
    useGetLookupFiltersQuery({
      entityType: EntityType.Project,
    })

  // When an event related to Filter arises, perform get project filters again
  useEffect(() => {
    refetchProjectFilter()
  }, [filterEventMessage, refetchProjectFilter])

  // Get event filter
  const { data: eventFilters, refetch: refetchEventFilter } =
    useGetLookupFiltersQuery({
      entityType: EntityType.Event,
    })

  // When an event related to Filter arises, perform get event filters again
  useEffect(() => {
    refetchEventFilter()
  }, [filterEventMessage, refetchEventFilter])

  // Get task filter
  const { data: taskFilters, refetch: refetchTaskFilter } =
    useGetLookupFiltersQuery({
      entityType: EntityType.Task,
    })

  // When an event related to Filter arises, perform get task filters again
  useEffect(() => {
    refetchTaskFilter()
  }, [filterEventMessage, refetchTaskFilter])

  // Get file filter
  const { data: fileFilters, refetch: refetchFileFilter } =
    useGetLookupFiltersQuery({
      entityType: EntityType.File,
    })

  // When an event related to Filter arises, perform get file filters again
  useEffect(() => {
    refetchFileFilter()
  }, [filterEventMessage, refetchFileFilter])

  // Get system permission for logged in user
  const {
    hasSysTenantSettingViewEditPermission,
    hasSysContractViewEditPermission,
    hasSysUserListViewPermission,
  } = useSystemPermissions()

  // Get personal setting for logged in user
  const defaultUserProfileLanguage = useUserSetting(
    SettingKeyType.UserProfileLanguage,
    AppLanguage.Ja
  )
  const defaultAppearanceTheme = useUserSetting(
    SettingKeyType.AppearanceTheme,
    AppTheme.Light
  )
  const defaultPageSize = useUserSetting(
    SettingKeyType.ListDisplayNumber,
    DEFAULT_PAGING_PARAMS.pageSize
  )

  // アテンションやフィードでも未読状態を制御するため、チャット表示形式を取得してSliceに格納する
  const displayFormat = useUserSetting(
    SettingKeyType.ChatDisplayFormat,
    UserChatSettingDisplayFormat.Timeline.value
  )
  if (displayFormat === UserChatSettingDisplayFormat.Timeline.value) {
    chatCurrentService.setChatCurrentDisplayFormat(ChatView.Timeline)
  } else if (displayFormat === UserChatSettingDisplayFormat.Thread.value) {
    chatCurrentService.setChatCurrentDisplayFormat(ChatView.ThreadList)
  }

  useEffect(() => {
    // ログイン済且つデバイストークンが取得済ならバックエンドに登録する
    if (loggedInUser && deviceToken) {
      const doInsertToken = async () => {
        insertUserDeviceMutation({
          // https://redmine.break-tmc.works/issues/1047#note-3
          // PCからの通知はFCMで要件を満たせるので、fcmで固定する
          type: DeviceType.Fcm,
          token: deviceToken,
        })
        console.log('Device token has been registered.')
      }
      doInsertToken()
    }
  }, [deviceToken, insertUserDeviceMutation, loggedInUser])

  // 言語設定変更時、表示言語を切り替える
  useEffect(() => {
    // change language mode
    if (defaultUserProfileLanguage === AppLanguage.En) {
      i18n.changeLanguage('en')
      loadMessages(enMessages)
      locale('en')
    } else if (defaultUserProfileLanguage === AppLanguage.Ja) {
      i18n.changeLanguage('ja')
      loadMessages(jaMessages)
      locale('ja')
    }

    // change theme mode
    if (defaultAppearanceTheme === AppTheme.Dark) {
      document.documentElement.classList.add('dark')
      themes.current('generic.dark')
      dispatch(changeTheme(AppTheme.Dark))
    } else {
      document.documentElement.classList.remove('dark')
      themes.current('generic.light')
      dispatch(changeTheme(AppTheme.Light))
    }
  }, [i18n, dispatch, defaultUserProfileLanguage, defaultAppearanceTheme])

  const leftSideBarOpened = useAppSelector(
    (state) => state.app.leftSideBarOpened
  )

  const rightSideBarOpened = useAppSelector(
    (state) => state.app.rightSideBarOpened
  )

  // 左サイドバー開閉ボタンクリック
  const handleToggleLeftSideBarButtonClick = useCallback(() => {
    dispatch(toggleLeftSideBar())
  }, [dispatch])

  // 右サイドバー開閉ボタンクリック
  const handleToggleRightSideBarButtonClick = useCallback(() => {
    dispatch(toggleRightSideBar())
  }, [dispatch])

  const handleLogout = useCallback(() => {
    // TODO: CREW-713 足りない処理があれば追加する
    // https://break-tmc.atlassian.net/browse/CREW-713

    const doLogout = async () => {
      // デバイストークン取得済みの場合にデバイストークンを登録解除する
      if (deviceToken) {
        await deleteUserDeviceMutation({
          // https://redmine.break-tmc.works/issues/1047#note-3
          // PCからの通知はFCMで要件を満たせるので、fcmで固定する
          type: DeviceType.Fcm,
          token: deviceToken,
        }).unwrap()

        console.log('Device token has been unregistered.')
      }

      // バックエンドログアウト
      await logoutMutation().unwrap()

      // フロントエンドログアウト
      logoutFrontend()
    }

    doLogout()
  }, [deleteUserDeviceMutation, deviceToken, logoutFrontend, logoutMutation])

  // Apply search keyword to cross search
  const handleCrossSearchChanged = useCallback(
    (e: NativeEventInfo<TextBoxInstance, KeyboardEvent>) => {
      const keyword = e.component.option('text')
      keyword &&
        navigate(`/search/${getDefaultTabValue(SearchTabs)}`, {
          keyword,
          [OPERATION_KEY]: searchConditionMode,
        })
    },
    [navigate, searchConditionMode]
  )

  // Declare default pagination information
  const defaultPagination = `?pageIndex=${DEFAULT_PAGING_PARAMS.pageIndex}&pageSize=${defaultPageSize}`

  // ====================================================
  // Build url parameter for the link of Project menu
  // ====================================================
  // get project default filter
  const projectDefaultFilter = useMemo(
    () => projectFilters?.filters.find((filter) => filter.default),
    [projectFilters?.filters]
  )

  // project params
  const projectFilterCondition =
    buildObjUrlParamsFromFilterDefinition(projectDefaultFilter)
  const projectFilterUrlParams = qs.stringify(projectFilterCondition, {
    arrayFormat: 'repeat',
    skipNulls: true,
  })

  const projectParams = `${defaultPagination}&${projectFilterUrlParams}`

  // ====================================================
  // Build url parameter for the link of Event menu
  // ====================================================
  // get event default filter
  const eventDefaultFilter = useMemo(
    () => eventFilters?.filters.find((filter) => filter.default),
    [eventFilters?.filters]
  )

  // event params
  const eventFilterCondition =
    buildObjUrlParamsFromFilterDefinition(eventDefaultFilter)

  const eventFilterUrlParams = qs.stringify(eventFilterCondition, {
    arrayFormat: 'repeat',
    skipNulls: true,
  })
  const eventParams = `${defaultPagination}&${eventFilterUrlParams}`

  // ====================================================
  // Build url parameter for the link of Task menu
  // ====================================================
  // get task default filter
  const taskDefaultFilter = useMemo(
    () => taskFilters?.filters.find((filter) => filter.default),
    [taskFilters?.filters]
  )

  // task params
  const taskFilterCondition =
    buildObjUrlParamsFromFilterDefinition(taskDefaultFilter)

  const taskFilterUrlParams = qs.stringify(taskFilterCondition, {
    arrayFormat: 'repeat',
    skipNulls: true,
  })
  const taskParams = `${defaultPagination}&${taskFilterUrlParams}`

  // ====================================================
  // Build url parameter for the link of File menu
  // ====================================================
  // get file default filter
  const fileDefaultFilter = useMemo(
    () => fileFilters?.filters.find((filter) => filter.default),
    [fileFilters?.filters]
  )

  // file params
  const fileFilterCondition =
    buildObjUrlParamsFromFilterDefinition(fileDefaultFilter)
  const fileFilterUrlParams = qs.stringify(fileFilterCondition, {
    arrayFormat: 'repeat',
    skipNulls: true,
  })
  // デフォルトは匿名ファイルを表示しない
  const fileParams =
    `${defaultPagination}&${fileFilterUrlParams}&anonymousFile=` +
    DisplayAnonymousFile.DoNotDisplay.value

  // サポートポータルリンククリック時の実処理
  const startSso = useCallback(async () => {
    try {
      // a. ヘルプデスクSSO開始APIを呼び出す
      const startSsoFreshdeskResponse =
        await startSsoFreshdeskutation().unwrap()

      // b. 共通APIアクセストークン要求APIを呼び出し、共通APIアクセストークンを取得する
      const getCommonRequestAuthzProtectedAccessTokenResponse =
        await lazyGetCommonRequestAuthzProtectedAccessTokenQuery().unwrap()

      // c. 共通公開APIのアクセス認可APIを呼び出す
      await authorizeCommonRequestAuthzProtectedAccessTokenMutation({
        token:
          getCommonRequestAuthzProtectedAccessTokenResponse.token
            .commonRequestAuthzProtectedAccessToken,
      }).unwrap()

      // d. ヘルプデスクSSO開始APIで取得したFreshdeskのURLに遷移する
      window.open(startSsoFreshdeskResponse.loginUrl, '_blank')
    } catch {
      error(t('message.general.failedToRetrieveData'))
    }
  }, [
    authorizeCommonRequestAuthzProtectedAccessTokenMutation,
    error,
    lazyGetCommonRequestAuthzProtectedAccessTokenQuery,
    startSsoFreshdeskutation,
    t,
  ])

  // サポートポータルリンククリック時のイベントハンドラ
  const handleSupportPortalLinkClick: MouseEventHandler<HTMLAnchorElement> =
    useCallback(
      (e) => {
        e.preventDefault() // リンクのデフォルト動作をキャンセル
        startSso()
      },
      [startSso]
    )

  // テナント情報取得（テナントアバター用にバージョンが必要なため）
  const { data: getTenant } = useGetTenantQuery()

  const getTenantSettingsRequestParams: GetTenantSettingsRequest = {
    keys: [SettingKeyType.OrganizationName],
  }
  // get tenant setting data
  const { data: getTenantSettings, refetch: reloadGetTenantSettings } =
    useGetTenantSettingsQuery(getTenantSettingsRequestParams)

  // refresh tenant name
  useEffect(() => {
    reloadGetTenantSettings()
  }, [tenantSettingEventMessage, reloadGetTenantSettings])

  // tenant name
  const tenantName = useMemo(() => {
    //convert tenant settings to object
    const tenantSettings =
      getTenantSettings?.tenantSettings.reduce<ReduceReturnType>(
        (tenantSetting, currentValue) => ({
          ...tenantSetting,
          [currentValue.key]: currentValue.value,
        }),
        {}
      )
    return tenantSettings?.[SettingKeyType.OrganizationName]
  }, [getTenantSettings])

  // tenant avatar url
  // TODO: Web: generateImageAvatarUrlのリファクタリング
  // https://break-tmc.atlassian.net/browse/CREW-9777
  const tenantAvatarUrl = generateImageAvatarUrl(EntityType.Tenant)

  const searchConditionButton = useMemo<ButtonTypes.Properties>(
    () => ({
      stylingMode: 'text',
      onClick: () => {
        setSearchConditionMode((prevConditionMode: KeywordFilterCondition) =>
          prevConditionMode === 'and' ? 'or' : 'and'
        )
      },
      text: searchConditionMode.toLocaleUpperCase(),
    }),
    [searchConditionMode]
  )

  const presenceStates = useAppSelector(
    (state) => state.presenceStates.presenceStates
  )

  // その他の状態をクリックしたときの処理
  const handleOtherPresenceStateClick = useCallback(() => {
    openPresenceSettingDialog()
  }, [openPresenceSettingDialog])

  // プレゼンスステート変更時の処理
  const handlePresenceStateChange = useCallback(
    async (presenceState: PresenceState) => {
      try {
        const payload: UpdateUserPresenceStateRequest = {
          userPresenceState: {
            presenceStateId: presenceState.id,
            presenceStateMessage: undefined,
          },
        }
        await updateUserPresenceStateMutation(payload).unwrap()
      } catch (err) {
        showApiErrors(err)
      }
    },
    [showApiErrors, updateUserPresenceStateMutation]
  )

  return (
    <div
      id="app-bar"
      data-testid="app-bar"
      className="flex flex-row flex-none h-10 pr-4 items-center gap-4 border-b crew-text-gray-4 crew-bg-gray-1 crew-border-gray"
    >
      <div className="flex flex-row gap-4 items-center h-full lg:max-w-[15vw] pl-4">
        <div className="flex flex-row gap-2.5 items-center grow min-w-0 w-6 lg:w-auto">
          {/* Tenant Avatar */}
          <CrewAvatar
            displayName={tenantName ?? ''}
            shape={CrewAvatarShapeType.Square}
            imageURL={tenantAvatarUrl}
            cacheValue={
              // 型制約上nullチェックが必要なためチェックを行うが、基本はnullになることはない
              getTenant?.tenant
                ? getTenant.tenant.id + getTenant.tenant.version
                : ''
            }
            size={CrewAvatarSize.xs}
          />

          {/* tenant name */}
          {/* 画面の横幅が小さい場合、テナント名を非表示にする */}
          <p className="crew-text-gray-4 truncate hidden lg:block">
            {tenantName}
          </p>
        </div>
        <div
          className={classNames(
            'flex flex-row items-center gap-2 rounded-md px-2 py-1 cursor-pointer',
            {
              'crew-bg-gray-2': leftSideBarOpened,
            }
          )}
          onClick={handleToggleLeftSideBarButtonClick}
        >
          <Menu width={24} height={24} />
        </div>
      </div>
      <div className="flex flex-row gap-2 items-center h-full">
        <BarNavLink
          to={`/projects${projectParams}`}
          icon={<Groups width={24} height={24} />}
        >
          {t('label.project')}
        </BarNavLink>
        <BarNavLink
          to={`/events${eventParams}`}
          icon={<BaseOutlineVideocam width={24} height={24} />}
        >
          {t('label.meeting')}
        </BarNavLink>
        <BarNavLink
          to={`/tasks${taskParams}`}
          icon={<EventAvailable width={24} height={24} />}
        >
          {t('label.task')}
        </BarNavLink>
        <BarNavLink
          to={`files${fileParams}`}
          icon={<OutlineInsertDriveFile width={24} height={24} />}
        >
          {t('label.file')}
        </BarNavLink>
        {hasSysUserListViewPermission && (
          <BarNavLink
            to={`/users${defaultPagination}`}
            icon={<AccountCircleOutline width={24} height={24} />}
          >
            {t('label.user')}
          </BarNavLink>
        )}
      </div>
      <div className="flex-1" />
      {/* show when running in progressive web app mode */}
      {isPWA && <ButtonNavigation />}
      {/* Cross input search */}
      <CrewTextBox
        id="search"
        name="search"
        placeholder={t('action.search')}
        mode="search"
        onEnterKey={handleCrossSearchChanged}
      >
        <TextBoxButton
          name="searchCondition"
          location="after"
          options={searchConditionButton}
        />
      </CrewTextBox>
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row items-center">
          {/* Navigation User Menu */}
          <MenuGroup as="div" className="relative inline-block text-left">
            <div>
              <MenuGroup.Button className="flex items-center rounded-md px-2 py-1 crew-hover-gray-2">
                <span className="flex items-center space-x-1">
                  <CrewAvatar
                    key={loggedInUser?.avatarUrl ?? ''} //refresh avatar when avatarUrl changed
                    displayName={loggedInUser?.displayName ?? ''}
                    size={CrewAvatarSize.xs}
                    imageURL={
                      loggedInUser?.id
                        ? generateImageAvatarUrl(
                            EntityType.User,
                            loggedInUser.id
                          )
                        : undefined
                    }
                    cacheValue={
                      // 型制約上nullチェックが必要なためチェックを行うが、基本はnullになることはない
                      loggedInUser ? loggedInUser.id + loggedInUser.version : ''
                    }
                  />
                </span>
              </MenuGroup.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              {/* メニューの位置を固定し、一番上に表示する必要があるため、absoluteとz-50を指定している。 */}
              <MenuGroup.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y crew-divide-gray rounded-md crew-bg-default shadow-lg ring-1 ring-crew-gray-200 dark:ring-crew-gray-700 focus:outline-none">
                <div className="py-1">
                  <MenuGroup.Item>
                    {/* 個人設定 */}
                    {({ active }) => (
                      // CrewLinkを使うとテキストが青くなってしまうので、Linkを使っている
                      <Link
                        to={'/personal-settings/profile'}
                        className={classNames(
                          { 'crew-bg-gray-2': active },
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {t('label.personalSetting')}
                      </Link>
                    )}
                  </MenuGroup.Item>
                  <MenuGroup.Item>
                    {/* ログアウト */}
                    {({ active }) => (
                      <span
                        className={classNames(
                          { 'crew-bg-gray-2': active },
                          'block px-4 py-2 text-sm',
                          'cursor-pointer'
                        )}
                        onClick={handleLogout}
                      >
                        {t('label.logout')}
                      </span>
                    )}
                  </MenuGroup.Item>
                </div>

                {/* プレゼンスステート */}
                <div className="py-1">
                  {Object.values(presenceStates).map((presenceState) => (
                    <PresenceStateItem
                      key={presenceState.id}
                      presenceState={presenceState}
                      onPresenceStateChange={handlePresenceStateChange}
                    />
                  ))}

                  {/* その他の状態 */}
                  <MenuGroup.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          { 'crew-bg-gray-2': active },
                          'px-4 py-2 text-sm flex flex-row items-center gap-1.5',
                          'cursor-pointer'
                        )}
                        onClick={handleOtherPresenceStateClick}
                      >
                        <CrewPresenceStateIcon
                          presenceStateType={PresenceStateType.Unknown}
                        />
                        <span>{t('label.otherState')}</span>
                      </div>
                    )}
                  </MenuGroup.Item>
                </div>
              </MenuGroup.Items>
            </Transition>
          </MenuGroup>
        </div>
      </div>
      <div
        className={classNames(
          'flex flex-row items-center rounded-md px-2 py-1 cursor-pointer',
          {
            'crew-bg-gray-2': rightSideBarOpened,
          }
        )}
        onClick={handleToggleRightSideBarButtonClick}
      >
        <ChatOutline width={24} height={24} />
      </div>
      <div className="flex flex-row items-center">
        {/* Navigation General Menu */}
        <MenuGroup as="div" className="relative inline-block text-left">
          <div>
            <MenuGroup.Button className="flex items-center rounded-md px-2 py-1 crew-hover-gray-2">
              <MoreVert width={24} height={24} />
            </MenuGroup.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            {/* メニューの位置を固定し、一番上に表示する必要があるため、absoluteとz-50を指定している。 */}
            <MenuGroup.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md crew-bg-gray-1 shadow-lg ring-1 ring-crew-gray-200 dark:ring-crew-gray-700 focus:outline-none">
              <div className="py-1">
                {hasSysTenantSettingViewEditPermission && (
                  <MenuGroup.Item>
                    {/* 組織設定 */}
                    {({ active }) => (
                      // CrewLinkを使うとテキストが青くなってしまうので、Linkを使っている
                      <Link
                        to={`/tenant-settings/${getDefaultTabValue(
                          TenantSettingTabs
                        )}`}
                        className={classNames(
                          { 'crew-bg-gray-2': active },
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {t('label.tenantSetting')}
                      </Link>
                    )}
                  </MenuGroup.Item>
                )}
                {hasSysContractViewEditPermission && (
                  <MenuGroup.Item>
                    {/* 契約情報 */}
                    {({ active }) => (
                      // CrewLinkを使うとテキストが青くなってしまうので、Linkを使っている
                      <Link
                        to={`/contract/portal`}
                        className={classNames(
                          { 'crew-bg-gray-2': active },
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {t('label.contractInformation')}
                      </Link>
                    )}
                  </MenuGroup.Item>
                )}
                {/** 外部ユーザー以外の場合はサポートポータルへのボタンを表示する */}
                {user?.user?.userType !== UserType.External && (
                  <MenuGroup.Item>
                    {/* サポートポータル */}
                    {({ active }) => (
                      // CrewLinkを使うとテキストが青くなってしまうので、Linkを使っている
                      <Link
                        to=""
                        className={classNames(
                          { 'crew-bg-gray-2': active },
                          'block px-4 py-2 text-sm'
                        )}
                        onClick={handleSupportPortalLinkClick}
                      >
                        {t('label.support')}
                      </Link>
                    )}
                  </MenuGroup.Item>
                )}
              </div>
            </MenuGroup.Items>
          </Transition>
        </MenuGroup>
      </div>

      {/* 在席状態の設定 */}
      <PresenceSettingDialog
        isOpen={isOpenPresenceSettingDialog}
        onClose={closePresenceSettingDialog}
        title={t('label.presenceStatusSetting')}
      />
    </div>
  )
})
