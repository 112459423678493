import {
  arrayMove,
  CrewSortableList,
} from 'components/elements/crewSortableList/crewSortableList'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { PresenceStatusTableHeader } from './components/presenceStatusTableHeader/presenceStatusTableHeader'
import { PresenceStatusListItem } from './components/presenceStatusListItem/presenceStatusListItem'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { useModal } from 'components/layouts/modal/useModal'
import { useTranslation } from '@crew/modules/i18n'
import { useToast } from 'hooks/useToast'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { PresenceStatusEntryDialog } from '../presenceStatusEntryDialog/presenceStatusEntryDialog'
import { useLazyGetPresenceStatesQuery } from '@crew/apis/presenceState/presenceStateApis'
import { PresenceState } from '@crew/models/domain'
import { useEffectOnce } from '@dx-system/react-use'
import {
  SelectedPresenceStatus,
  usePresenceStateListGrid,
} from './usePresenceStatusListGrid'
import { useAppSelector } from 'states/hooks'

export const PresenceStatusListGrid: FC = memo(() => {
  const { t } = useTranslation()
  const { success } = useToast()
  const [showApiErrors] = useShowApiErrors()
  const { deletePresenceState, reorderPresenceState } =
    usePresenceStateListGrid()

  const presenceStateEventMessage = useAppSelector(
    (state) => state.app.presenceEventMessage
  )

  const [
    isPresenceStatusEntryDialogOpen,
    openPresenceStatusEntryDialog,
    closePresenceStatusEntryDialog,
  ] = useModal()

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  const [isErrorDialogOpen, openErrorDialog, closeErrorDialog] = useModal()

  const [confirmMessage, setConfirmMessage] = useState('')

  const [errorMessage, setErrorMessage] = useState('')

  const [presenceStatus, setPresenceStatus] = useState<PresenceState[]>()

  const [selectedPresenceStatus, setSelectedPresenceStatus] = useState<
    SelectedPresenceStatus | undefined
  >()

  const [lazyGetTaskStatesQuery] = useLazyGetPresenceStatesQuery()

  // fetch presence states
  const fetchPresenceStates = useCallback(async () => {
    const result = await lazyGetTaskStatesQuery()
    setPresenceStatus(result.data?.presenceStates as PresenceState[])
  }, [lazyGetTaskStatesQuery])

  // fetch presence states on mount
  useEffectOnce(() => {
    fetchPresenceStates()
  })

  // refresh presence state list
  useEffect(() => {
    if (presenceStateEventMessage) {
      fetchPresenceStates()
    }
  }, [fetchPresenceStates, presenceStateEventMessage])

  // handle reorder
  const handleReorder = useCallback(
    async (fromId: string, toId: string) => {
      // ちらつき防止のため、APIを呼ぶ前にUI上での並び替えを先行して行う
      setPresenceStatus((items) => {
        if (!items) {
          return items
        }

        const from = items.findIndex((item) => item.id === fromId)
        const to = items.findIndex((item) => item.id === toId)
        return arrayMove(items, from, to)
      })

      try {
        await reorderPresenceState(fromId, toId)
      } catch (err) {
        setErrorMessage(t('message.general.errorMessage.reorder'))
        openErrorDialog()
      }

      // APIの実行結果にかかわらず再読み込みを行い、Backendに同期させる
      fetchPresenceStates()
    },
    [fetchPresenceStates, openErrorDialog, reorderPresenceState, t]
  )

  // handle edit button click
  const handlePresenceStatusEditButtonClick = useCallback(
    (presenceStatusId: string, presenceStatusVersion: number) => {
      // id値はイベントオブジェクトから取得可能
      setSelectedPresenceStatus({
        presenceStatusId: presenceStatusId,
        version: presenceStatusVersion,
      })

      openPresenceStatusEntryDialog()
    },
    [openPresenceStatusEntryDialog]
  )

  // handle edit button click
  const handlePresenceStatusDeleteButtonClick = useCallback(
    (presenceStatusId: string, presenceStatusVersion: number) => {
      setConfirmMessage(t('message.general.confirmMessage.delete'))
      openConfirmDialog()

      setSelectedPresenceStatus({
        presenceStatusId: presenceStatusId,
        version: presenceStatusVersion,
      })
    },
    [openConfirmDialog, t]
  )

  // handle delete permit button click
  const handleDeletePermitButtonClick = useCallback(async () => {
    if (selectedPresenceStatus) {
      try {
        await deletePresenceState(
          selectedPresenceStatus.presenceStatusId,
          selectedPresenceStatus.version
        )
        success(t('message.taskKind.taskKindDeleted'))
      } catch (error) {
        showApiErrors(error)
      }
    }

    closeConfirmDialog()
  }, [
    closeConfirmDialog,
    deletePresenceState,
    selectedPresenceStatus,
    showApiErrors,
    success,
    t,
  ])

  return (
    <div className="h-full flex flex-col">
      {presenceStatus && (
        <CrewSortableList
          items={presenceStatus}
          onReorder={handleReorder}
          header={<PresenceStatusTableHeader />}
          renderItem={(item) => (
            <CrewSortableList.Item id={item.id}>
              <PresenceStatusListItem
                key={item.id}
                presenceStatus={item}
                onEditButtonClick={handlePresenceStatusEditButtonClick}
                onDeleteButtonClick={handlePresenceStatusDeleteButtonClick}
                dragHandle={<CrewSortableList.DragHandle />}
              />
            </CrewSortableList.Item>
          )}
        />
      )}

      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />

      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />

      <PresenceStatusEntryDialog
        isEditMode={true}
        title={t('label.presenceStatusEdit')}
        isOpen={isPresenceStatusEntryDialogOpen}
        onClose={closePresenceStatusEntryDialog}
        presenceStatusId={selectedPresenceStatus?.presenceStatusId}
      />
    </div>
  )
})
