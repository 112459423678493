/* eslint-disable @typescript-eslint/no-redeclare */
/**
 *  エンティティタイプ
 * 　（関連先タイプに使用するenumはapp.tsに定義）
 */
export const EntityType = {
    Customer: 'customers',
    ChatMessage: 'chat_messages',
    Opportunity: 'opportunities',
    OpportunityActivity: 'opportunity_activities',
    Project: 'projects',
    Event: 'events',
    Task: 'tasks',
    TaskActivity: 'task_activities',
    TaskComment: 'task_comments',
    File: 'files',
    User: 'users',
    Department: 'departments',
    DirectChannel: 'direct_channels',
    ProjectMemberPendings: 'project_member_pendings',
    TenantMemberPendings: 'tenant_member_pendings',
    Tenant: 'tenants',
    TaskHistories: 'task_histories',
    FileHistories: 'file_histories',
    Folders: 'folders',
};
/**
 *  アテンションタイプ
 */
export const AttentionType = {
    Mention: 'mention',
    Reply: 'reply',
    Reaction: 'reaction',
};
/**
 * メッセージタイプ
 */
export const MessageType = {
    MessageNormal: 'message_normal',
    MassageSurvey: 'message_survey',
    TaskAdded: 'task_added',
    TaskUpdated: 'task_updated',
    TaskDeleted: 'task_deleted',
    TaskCommentUpdated: 'task_comment_updated',
    EventAdded: 'event_added',
    EventUpdated: 'event_updated',
    EventDeleted: 'event_deleted',
    EventJoined: 'event_joined',
    FileAdded: 'file_added',
    FileUpdated: 'file_updated',
    ProjectMemberRequested: 'project_member_requested',
    ProjectMemberJoined: 'project_member_joined',
    ProjectMemberLeft: 'project_member_left',
};
/**
 *  非同期通信用メッセージタイプ
 */
export const PubSubEventType = {
    // チャットメッセージ
    ChatMessageAdded: 'chat_message_added',
    ChatMessageUpdated: 'chat_message_updated',
    ChatMessageDeleted: 'chat_message_deleted',
    // リアクション
    ChatMessageReactionAdded: 'chat_message_reaction_added',
    ChatMessageReactionDeleted: 'chat_message_reaction_deleted',
    // フィードアイテム
    FeedItemAdded: 'feed_item_added',
    FeedItemUpdated: 'feed_item_updated',
    FeedItemDeleted: 'feed_item_deleted',
    // アテンションアイテム
    AttentionItemAdded: 'attention_item_added',
    AttentionItemDeleted: 'attention_item_deleted',
    // ブックマーク
    BookmarkAdded: 'bookmark_added',
    BookmarkDeleted: 'bookmark_deleted',
    // DM
    DirectChannelAdded: 'direct_channel_added',
    // 未読件数
    UnreadUpdated: 'unread_updated',
    UnreadChatRoomAllRead: 'unread_chat_room_all_read',
    UnreadAllChatRead: 'unread_all_chat_read',
    // ユーザー在籍状態
    UserPresenceUpdated: 'user_presence_updated',
};
/**
 * 制御系メッセージタイプ
 */
export const IncomingControlMessageType = {
    Success: 'success',
    Failure: 'failure',
    SubscribeReconnected: 'subscribe_reconnected',
};
/**
 * WebSocket受信メッセージタイプ
 */
export const WebSocketIncomingMessageType = Object.assign(Object.assign({}, PubSubEventType), IncomingControlMessageType);
/**
 * WebSocket送信メッセージタイプ
 */
export const WebSocketOutgoingMessageType = {
    Subscribe: 'subscribe',
    Unsubscribe: 'unsubscribe',
};
/**
 * PubSub購読先タイプ
 */
export const PubSubEventTargetType = {
    User: 'user',
    ChatRoom: 'chat_room',
    Presence: 'presence',
};
/**
 * PubSub購読先データタイプ
 */
export const PubSubEventDataType = {
    Data: 'data',
    Meta: 'meta',
};
/**
 * 未読情報タイプ
 */
export const UnreadInfoTarget = {
    ChatRoom: 'chatRoom',
    ChatThread: 'chatThread',
};
/**
 * 宛先タイプ
 */
export const DestinationType = {
    Email: `email`,
    LoginId: `login_id`,
    UserId: `user_id`,
};
/**
 * 保留状態
 */
export const PendingState = {
    Inviting: `inviting`,
    WaitingApproval: `waiting_approval`,
};
/**
 * 役割タイプ
 */
export const RoleType = {
    System: `system`,
    Project: `project`,
};
/**
 * ユーザータイプ
 */
export const UserType = {
    Internal: `internal`,
    External: `external`,
};
/**
 *  出欠タイプ
 */
export const AttendanceType = {
    Attendance: 'attendance',
    Absence: 'absence',
};
// action type in project setting member grid
export const ProjectSettingMemberActionType = {
    Cancel: 'cancel',
    Reject: 'reject',
    Delete: 'delete',
};
/**
 *  Signup type
 */
export const SignupType = {
    ProjectMemberPendings: 'project_member_pendings',
    TenantMemberPendings: 'tenant_member_pendings',
};
/**
 * Member State
 */
export const TenantMemberState = {
    Enabled: `enabled`,
    Disabled: `disabled`,
    Inviting: `inviting`,
    WaitingApproval: `waiting_approval`,
};
/**
 * Task dependency
 */
export const TaskDependencyType = {
    // Finish to Start。先行が完了してから後続を開始する
    FinishToStart: 'fs',
    // Start to Start。先行が開始してから後続が開始する
    StartToStart: 'ss',
    // Finish to Finish。先行が完了してから後続が完了する
    FinishToFinish: 'ff',
    // Start to Finish。先行が開始してから後続が完了する
    StartToFinish: 'sf',
};
/**
 * Task Dependencies Insertion Type
 */
export const TaskDependenciesInsertionType = {
    PredecessorTask: 'predecessor_task',
    SuccessorTask: 'successor_task',
};
/**
 * Project Type
 */
export const ProjectType = {
    Project: 'project',
    DirectChannel: 'direct_channel',
};
/**
 * WBS updatable column
 */
export const WbsUpdatableColumn = {
    Subject: 'subject',
    WbsNumber: 'wbs_number',
    AssignToUserId: 'assign_to_user_id',
    StartDate: 'start_date',
    DueDate: 'due_date',
    EstimatedWorkTimes: 'estimated_work_times',
    ParentTaskId: 'parent_task_id',
};
/**
 * Event Type
 */
export const EventType = {
    Event: 'event',
    Meeting: 'meeting',
};
/**
 * Group schedule filter
 */
export const GroupScheduleFilterType = {
    GroupScheduleProjectFilter: 'project',
    GroupScheduleCustomFilter: 'custom',
};
/**
 * Event scope
 */
export const EventScope = {
    Public: 'public',
    Private: 'private',
};
/**
 * KeywordFilterCondition
 */
export const KeywordFilterCondition = {
    And: 'and',
    Or: 'or',
};
export const VerifyBulkInsertUsersResultCode = {
    Invalid: 'invalid',
    Available: 'available',
    Warning: 'warning',
    Registered: 'registered',
};
/**
 * MeetingAttendeeJoinState
 */
export const MeetingAttendeeJoinState = {
    Waiting: 'waiting',
    Joining: 'joining',
    Exited: 'exited',
};
/**
 *  Type tenant setting members filter
 */
export const TenantMemberFilter = {
    AllTenantMembers: 'allTenantMembers',
    EnabledTenantMembers: 'enabledTenantMembers',
    DisabledTenantMembers: 'disabledTenantMembers',
    InvitingTenantMembers: 'invitingTenantMembers',
};
/**
 * Kanban updatable column
 */
export const KanbanUpdatableColumn = {
    TaskStateId: 'task_state_id',
    AssignToUserId: 'assign_to_user_id',
    TaskKindId: 'task_kind_id',
    TaskCategoryId: 'task_category_id',
    TaskPriority: 'task_priority',
};
/**
 *  Type of kanban bucket
 */
export const KanbanBucketType = {
    TaskState: 'task_state',
    AssignToUser: 'assign_to_user',
    TaskKind: 'task_kind',
    TaskCategory: 'task_category',
};
/**
 * PresenceStateType
 */
export const PresenceStateType = {
    Present: 'present',
    Busy: 'busy',
    Away: 'away',
    Absent: 'absent',
    Unknown: 'unknown',
};
