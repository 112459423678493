import { TaskKanbanBucketType } from '@crew/enums/app'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ProjectDetailEventListDisplayMode,
  FileListDisplayMode,
  TaskDisplayModeButtonGroup,
} from 'enums/app'

type ProjectDetailStateType = {
  isExpandedDescription: boolean
  selectedTaskListDisplayMode: number
  selectedUserIds: string[]
  projectSubject: string
  isShowEditWBS: boolean
  selectedFileListDisplayMode: number
  selectedEventListDisplayMode: number
  isWbsNumberRenumbering: boolean
  selectedTaskKanbanBucketType: string
}

const initialProjectDetailState: ProjectDetailStateType = {
  isExpandedDescription: false,
  selectedTaskListDisplayMode: TaskDisplayModeButtonGroup.List.id,
  selectedUserIds: [],
  projectSubject: '',
  isShowEditWBS: false,
  selectedFileListDisplayMode: FileListDisplayMode.List.id,
  selectedEventListDisplayMode: ProjectDetailEventListDisplayMode.List.id,
  isWbsNumberRenumbering: false,
  selectedTaskKanbanBucketType: TaskKanbanBucketType.TaskState.value,
}

export const projectDetailSlice = createSlice({
  name: 'projectDetail',
  initialState: initialProjectDetailState,
  reducers: {
    updateIsExpandedDescription: (state, action: PayloadAction<boolean>) => {
      state.isExpandedDescription = action.payload
    },
    setSelectedTaskListDisplayMode: (state, action: PayloadAction<number>) => {
      state.selectedTaskListDisplayMode = action.payload
    },

    setSelectedUserIds: (state, action: PayloadAction<string[]>) => {
      state.selectedUserIds = action.payload
    },
    setProjectSubject: (state, action: PayloadAction<string>) => {
      state.projectSubject = action.payload
    },
    setIsShowEditWBS: (state, action: PayloadAction<boolean>) => {
      state.isShowEditWBS = action.payload
    },
    setSelectedFileListDisplayMode: (state, action: PayloadAction<number>) => {
      state.selectedFileListDisplayMode = action.payload
    },
    setSelectedEventListDisplayMode: (state, action: PayloadAction<number>) => {
      state.selectedEventListDisplayMode = action.payload
    },
    setIsWbsNumberRenumbering: (state, action: PayloadAction<boolean>) => {
      state.isWbsNumberRenumbering = action.payload
    },
    setSelectedTaskKanbanBucketType: (state, action: PayloadAction<string>) => {
      state.selectedTaskKanbanBucketType = action.payload
    },
  },
})

export const {
  updateIsExpandedDescription,
  setSelectedTaskListDisplayMode,
  setSelectedUserIds,
  setProjectSubject,
  setIsShowEditWBS,
  setSelectedFileListDisplayMode,
  setSelectedEventListDisplayMode,
  setIsWbsNumberRenumbering,
  setSelectedTaskKanbanBucketType,
} = projectDetailSlice.actions
