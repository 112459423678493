import { createUninitializedContext } from '@crew/utils/context'
import { useCallback } from 'react'
import dayjs from '@crew/modules'
import { JsonDateFormat } from '@crew/enums/system'
import { useInsertUserPendingsMutation } from '@crew/apis/tenantSetting/tenantSettingApis'
import {
  ObjectEventMessage,
  notifyTenantSettingUserEventMessageEvent,
} from 'features/app/states/appSlice'
import { NotifyEventType } from 'enums/app'
import { TenantSettingMember } from '@crew/apis/dist/tenantSetting/models/getTenantSettingMembers/response'
import { useAppDispatch } from 'states/hooks'

export type NewUserType = {
  emailOrLoginID: string
  invitationRoleType: string
  expirationDatetime: string | null
}

/** Context用の型 */
type TenantUserEntryDialogContextType = {
  selectedNewUsers: NewUserType[]
  setSelectedNewUsers: (newUsers: NewUserType[]) => void
  organizationDomain: string | null
}

/** Context */
const {
  context: tenantUserEntryDialogContext,
  useInitializedContext: useTenantUserEntryContext,
} = createUninitializedContext<TenantUserEntryDialogContextType>()

export { useTenantUserEntryContext }

export const useTenantUserEntryDialog = () => {
  const dispatch = useAppDispatch()

  // Get function for insert new user
  const [
    insertUserPendingsMutation,
    { isLoading: isLoadingInsertUserPending },
  ] = useInsertUserPendingsMutation()

  // Insert users pending process
  const insertUserPendings = useCallback(
    async (newUsers: NewUserType[]) => {
      const userPendings = newUsers.map((user) => ({
        invitationRoleType: user.invitationRoleType,
        destination: user.emailOrLoginID,
        expirationDatetime: user.expirationDatetime
          ? dayjs(user.expirationDatetime).format(JsonDateFormat.YYYYMMDD)
          : null,
      }))

      //invite users to project
      const result = await insertUserPendingsMutation({
        userPendings,
      }).unwrap()

      if (result.userPendings) {
        // mutation操作の後は、レスポンスを通知する
        const objectEventMessage: ObjectEventMessage<TenantSettingMember> = {
          eventType: NotifyEventType.Inserted,
          id: result.userPendings[0].id,
          object: undefined,
        }
        //trigger event refresh user list
        dispatch(notifyTenantSettingUserEventMessageEvent(objectEventMessage))
      }
    },
    [dispatch, insertUserPendingsMutation]
  )

  return {
    tenantUserEntryDialogContext,
    insertUserPendings,
    isLoadingInsertUserPending,
  }
}
