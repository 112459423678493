import { FC, memo, useState } from 'react'

import { useLocalVideo } from 'modules/amazon-chime-sdk-component-library-devextreme'

import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useTranslation } from '@crew/modules/dist/i18n'
import { useBackgroundEffect } from './backgroundEffectProvider'
import { useValueChangeEffect } from '@crew/hooks'
import IconBaselineVideocam from '~icons/ic/baseline-videocam'
import IconBaselineVideocamOff from '~icons/ic/baseline-videocam-off'

type CrewCameraToggleButtonProps = {
  disabled?: boolean
}

/**
 * カメラの有効・無効を切り替えるボタン
 */
export const CrewCameraToggleButton: FC<CrewCameraToggleButtonProps> = memo(
  (props) => {
    const { isVideoEnabled, toggleVideo } = useLocalVideo()

    // TODO: 背景の初期値をONにしているが、カメラをONにしないと背景エフェクトが有効にできない。そのため、カメラの初回ON時に背景エフェクトを有効にする暫定対応を行う。
    //       恒久対応についてはCREW-13320で調査する
    //       https://break-tmc.atlassian.net/browse/CREW-13320
    const {
      isBackgroundEffectEnabled,
      isNowBackgroundEffectEnabled,
      enableBackgroundEffect,
    } = useBackgroundEffect()

    const [isCameraSwitchFirstChanged, setIsCameraSwitchFirstChanged] =
      useState(false)

    // カメラが有効になった初回時に背景エフェクトを有効にする
    useValueChangeEffect(
      () => {
        // カメラ初回ON時且つ、カメラのデバイスが認識済み、背景がON、背景エフェクトが有効じゃない場合に背景エフェクトを有効にする
        if (
          !isCameraSwitchFirstChanged &&
          isVideoEnabled &&
          isBackgroundEffectEnabled &&
          !isNowBackgroundEffectEnabled
        ) {
          enableBackgroundEffect(true)
          setIsCameraSwitchFirstChanged(true)
        }
      },
      [
        enableBackgroundEffect,
        isBackgroundEffectEnabled,
        isCameraSwitchFirstChanged,
        isNowBackgroundEffectEnabled,
        isVideoEnabled,
      ],
      isVideoEnabled
    )
    // --- 暫定対応ここまで

    const { t } = useTranslation()

    return (
      <CrewButton
        onClick={toggleVideo}
        text={t('label.camera')}
        type={isVideoEnabled ? 'success' : 'normal'}
        stylingMode={isVideoEnabled ? 'contained' : 'outlined'}
        icon={
          isVideoEnabled ? (
            <IconBaselineVideocam width={24} height={24} />
          ) : (
            <IconBaselineVideocamOff width={24} height={24} />
          )
        }
        disabled={props.disabled}
      />
    )
  }
)
