import { FC, memo } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'

import { useTranslation } from '@crew/modules/i18n'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useCallback, useMemo } from 'react'
import { useEffectOnce } from '@dx-system/react-use'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'

import { ReactNode } from 'react'
import { useGetPresenceStatesQuery } from '@crew/apis/presenceState/presenceStateApis'
import { PresenceStateType } from '@crew/enums/domain'
import { CrewPresenceStateIcon } from 'components/elements/crewPresenceStateIcon/crewPresenceStateIcon'
import {
  FormValues,
  usePresenceStatusEntryForm,
} from './usePresenceStatusEntryForm'

export type PresenceStatusFormProps = {
  isEditMode: boolean
  presenceStatusId?: string | undefined
  onSubmit: () => void
  onClose: () => void
}

export const presenceStatusSelectBoxItemRender = (data: {
  id: number
  name: string
  presenceStateType: PresenceStateType
}): ReactNode => {
  return (
    <div className="flex gap-2 items-center">
      <CrewPresenceStateIcon presenceStateType={data.presenceStateType} />
      <span className="flex-1 truncate">{data.name}</span>
    </div>
  )
}

// SelectBoxのfieldRenderで使うコンポーネント。renderとして使われるのでmemoできない
export const crewPresenceStatusSelectBoxFieldRender = (data?: {
  id: number
  name: string
  presenceStateType: PresenceStateType
}): ReactNode => {
  return (
    <div className="dx-texteditor-input">
      {data && (
        <div className="flex gap-2 items-center">
          <CrewPresenceStateIcon presenceStateType={data.presenceStateType} />
          <span className="flex-1 truncate">{data.name}</span>
        </div>
      )}
    </div>
  )
}

const PRESENCE_TYPE_BUILT_IN = 'built-in'

export const PresenceStatusEntryForm: FC<PresenceStatusFormProps> = memo(
  (props) => {
    const {
      control,
      reset,
      formState,
      handleSubmit,
      clearErrors,
      setError,

      validateRules,

      insertPresenceStatus,
      updatePresenceStatus,
      isLoadingInsertPresence,
      isLoadingUpdatePresence,
    } = usePresenceStatusEntryForm()

    const { t } = useTranslation()
    const { success } = useToast()

    const [showApiErrors] = useShowApiErrorsWithForm(setError)

    const { data: getPresenceStatesResult } = useGetPresenceStatesQuery()

    // list presence states type
    const listPresenceStatesType = useMemo(() => {
      return getPresenceStatesResult?.presenceStates.filter(
        (presence) => presence.definitionType === PRESENCE_TYPE_BUILT_IN
      )
    }, [getPresenceStatesResult?.presenceStates])

    // initialize form
    const initializeForm = useCallback(() => {
      if (props.isEditMode && props.presenceStatusId) {
        const currentPresenceState =
          getPresenceStatesResult?.presenceStates.find(
            (presenceState) => presenceState.id === props.presenceStatusId
          )
        if (currentPresenceState?.id) {
          reset({
            id: currentPresenceState.id,
            name: currentPresenceState.name,
            version: currentPresenceState.version,
            presenceStateType: currentPresenceState.presenceStateType,
          })
        }
      }
    }, [
      getPresenceStatesResult?.presenceStates,
      props.isEditMode,
      props.presenceStatusId,
      reset,
    ])

    useEffectOnce(() => {
      initializeForm()
    })

    // handle submit button click
    const handleSubmitButtonClick = useCallback(() => {
      const onSubmit = async (data: FormValues) => {
        try {
          if (props.isEditMode) {
            await updatePresenceStatus(data)
            success(t('message.presenceStatus.presenceUpdated'))
          } else {
            await insertPresenceStatus(data)
            success(t('message.presenceStatus.presenceRegistered'))
          }
          reset()
          clearErrors()
          props.onSubmit && props.onSubmit()
        } catch (err) {
          showApiErrors(err)
        }
      }
      handleSubmit(onSubmit)()
    }, [
      clearErrors,
      handleSubmit,
      insertPresenceStatus,
      props,
      reset,
      showApiErrors,
      success,
      t,
      updatePresenceStatus,
    ])

    // handle cancel button click
    const handleCancelButtonClick = useCallback(() => {
      props.onClose()
    }, [props])

    const canSend = useMemo(
      // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
      () =>
        Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
      // formStateはproxyなのでformState自体をlistenする必要がある
      // https://react-hook-form.com/api/useform/formstate
      [formState]
    )

    // disable update presence status name and type when presence type is built-in
    const isDisableUpdatePresenceTypeBuiltIn = useMemo(() => {
      const currentPresenceState = getPresenceStatesResult?.presenceStates.find(
        (presenceState) => presenceState.id === props.presenceStatusId
      )
      return (
        props.isEditMode &&
        currentPresenceState?.definitionType === PRESENCE_TYPE_BUILT_IN
      )
    }, [
      getPresenceStatesResult?.presenceStates,
      props.isEditMode,
      props.presenceStatusId,
    ])

    return (
      <form>
        <div>
          {/* presence status name */}
          <div className="h-20">
            <CrewTextBoxField
              id="name"
              name="name"
              control={control}
              labelMode="hidden"
              label={t('label.name')}
              required={true}
              valueChangeEvent="keyup"
              rules={validateRules.name}
              disabled={isDisableUpdatePresenceTypeBuiltIn}
            />
          </div>

          {/* presence status type */}
          <div className="h-20">
            <CrewSelectBoxField
              id="type"
              name="presenceStateType"
              control={control}
              dataSource={listPresenceStatesType}
              itemRender={presenceStatusSelectBoxItemRender}
              fieldRender={crewPresenceStatusSelectBoxFieldRender}
              labelMode="hidden"
              valueExpr="presenceStateType"
              displayExpr="name"
              searchEnabled={false}
              minSearchLength={0}
              rules={validateRules.presenceStateType}
              label={t('label.type')}
              required={true}
              showClearButton={false}
              disabled={isDisableUpdatePresenceTypeBuiltIn}
            />
          </div>
        </div>
        <div className="flex justify-between items-center mt-3">
          <div className="ml-auto flex gap-x-5">
            <CrewButton
              text={t('action.register')}
              type="primary"
              disabled={
                !canSend ||
                isLoadingInsertPresence ||
                isLoadingUpdatePresence ||
                isDisableUpdatePresenceTypeBuiltIn
              }
              onClick={handleSubmitButtonClick}
            />
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={handleCancelButtonClick}
            />
          </div>
        </div>
      </form>
    )
  }
)
