import {
  AppTheme,
  UserChatSettingDisplayFormat,
  UserChatSettingDisplayRange,
  Sort,
} from 'enums/app'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useInsertOrReplaceSettingMutation } from '@crew/apis/setting/settingApis'
import { ValidateRules } from '@crew/utils/dist/form'
import { InsertOrReplaceSettingRequest } from '@crew/apis/dist/setting/models/insertOrReplaceSetting/request'
import { SettingKeyType } from '@crew/enums/dist/app'
import { useTranslation } from '@crew/modules/dist/i18n'

export const DEFAULT_LIST_DISPLAY_NUMBER = 100

export type FormValues = {
  theme: string
  displayNumber: number
  displayFormat: string
  displayRange: string
  historyDisplayOrder: string
  showNewArrival: boolean
  showMySchedule: boolean
  showMyTask: boolean
}
export const formInitialValues: FormValues = {
  theme: AppTheme.Light, //default theme
  displayNumber: DEFAULT_LIST_DISPLAY_NUMBER, //default display number
  displayFormat: UserChatSettingDisplayFormat.Timeline.value, //default display format
  displayRange: UserChatSettingDisplayRange.DisplayAll.value, //default display range (all)
  historyDisplayOrder: Sort.Ascending.value, //default display order (asc)
  showNewArrival: false, // 表示しない
  showMySchedule: true, // 表示する
  showMyTask: true, // 表示する
}

export const usePersonalSettingDisplayForm = () => {
  const { t } = useTranslation()

  const { control, reset, handleSubmit, setError } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // Get update user setting function
  const [
    insertOrReplaceSettingMutation,
    { isLoading: isLoadingInsertOrReplaceSetting },
  ] = useInsertOrReplaceSettingMutation()

  // Update user setting process
  const updateUserSettings = useCallback(
    async (data: FormValues) => {
      // user setting params
      const userSettingData: InsertOrReplaceSettingRequest = {
        userSettings: [
          {
            key: SettingKeyType.AppearanceTheme,
            value: data.theme,
          },
          {
            key: SettingKeyType.ListDisplayNumber,
            value: String(data.displayNumber),
          },
          {
            key: SettingKeyType.ChatDisplayFormat,
            value: data.displayFormat,
          },
          {
            key: SettingKeyType.ChatDisplayRange,
            value: data.displayRange,
          },
          {
            key: SettingKeyType.TaskHistoryDisplayOrder,
            value: data.historyDisplayOrder,
          },
          {
            key: SettingKeyType.ShowNewArrival,
            value: String(data.showNewArrival),
          },
          {
            key: SettingKeyType.ShowMySchedule,
            value: String(data.showMySchedule),
          },
          {
            key: SettingKeyType.ShowMyTask,
            value: String(data.showMyTask),
          },
        ],
      }
      // call api save setting user
      await insertOrReplaceSettingMutation(userSettingData).unwrap()
    },
    [insertOrReplaceSettingMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      displayFormat: {
        required: t('message.general.required'),
      },
      displayNumber: {
        required: t('message.general.required'),
      },
      displayRange: {
        required: t('message.general.required'),
      },
      historyDisplayOrder: {
        required: t('message.general.required'),
      },
      theme: {
        required: t('message.general.required'),
      },
      showNewArrival: {},
      showMySchedule: {},
      showMyTask: {},
    }),
    [t]
  )

  return {
    control,
    reset,
    handleSubmit,
    setError,
    validateRules,
    updateUserSettings,
    isLoadingInsertOrReplaceSetting,
  }
}
