import { FC, Fragment, memo, useCallback, useRef, useState } from 'react'

import { useFeedMessageList } from './useFeedMessageList'
import { FeedMessageListItem } from './components/feedMessageListItem/feedMessageListItem'
import { useSubscribeFeed } from '@crew/providers/websocket'
import { useEffectOnce } from '@dx-system/react-use'
import { TimelineDirection } from '@crew/enums/app'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useValueChangeEffect } from '@crew/hooks'
import { setRightSideBarSelectedMessageId } from 'features/app/states/appSlice'

export const FeedMessageList: FC = memo(() => {
  const { displayItems, loadFeeds, messageInView } = useFeedMessageList()

  const dispatch = useAppDispatch()

  // 画面遷移前のスクロール位置
  const scrollToMessageId = useAppSelector(
    (state) => state.message.feed.scrollToMessageId
  )

  // メッセージ表示領域のref
  const itemsScrollableDivRef = useRef<HTMLDivElement>(null)

  // 返信ボタンで選択されたメッセージのID
  const [selectedMessageId, setSelectedMessageId] = useState<
    string | undefined
  >(undefined)

  useValueChangeEffect(
    () => {
      // When the selected message ID changes, set it to the chat panel
      dispatch(setRightSideBarSelectedMessageId(selectedMessageId))

      return () => {
        // When unmounting, reset the selected message ID
        dispatch(setRightSideBarSelectedMessageId(undefined))
      }
    },
    [dispatch, selectedMessageId],
    selectedMessageId
  )

  // フィード関連のメッセージをwebsocket経由でsubscribeする
  useSubscribeFeed(undefined, dispatch, useAppSelector)

  // 他画面への遷移から戻った際に、スクロール位置を元の位置に戻すための処理
  useEffectOnce(() => {
    // scrollToMessageIdがなければ処理しない
    if (scrollToMessageId === undefined) {
      return
    }

    // スクロール対象のDOM要素
    const displayTargetElement = itemsScrollableDivRef.current?.querySelector(
      `#feed_${scrollToMessageId}`
    )
    // スクロール対象のメッセージを表示アイテムの中から取得する
    const item = displayItems.find(
      (item) => item.messageId === scrollToMessageId
    )
    // スクロール対象のメッセージが表示アイテムの中にあり、スクロール対象のDOM要素がある場合のみ処理を行う
    if (item && displayTargetElement) {
      // 表示対象のDOM要素を表示領域内にスクロールする
      displayTargetElement.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      })
    }
  })

  // 初期ロード
  useEffectOnce(() => {
    loadFeeds({
      criterionMessageId: undefined, // 最新のものをロード
      direction: TimelineDirection.Older,
    })
  })

  // メッセージ全体表示イベントハンドラ
  const handleMessageInView = useCallback(
    (inView: boolean, id: string, messageId: string) => {
      if (inView) {
        messageInView(id, messageId)
      }
    },
    [messageInView]
  )

  // データが存在しない場合はその旨のメッセージを表示する
  if (displayItems.length === 0) {
    return <></>
  }

  return (
    <div
      className="flex-1 flex-col overflow-y-scroll"
      ref={itemsScrollableDivRef}
    >
      {displayItems.map((item) => (
        <Fragment key={item.id}>
          <FeedMessageListItem
            // スクロール位置設定時、数字から始まるidはエラーになるため、idの先頭に文字列を付与する
            id={`feed_${item.id}`}
            key={item.id}
            itemId={item.id}
            chatMessageId={item.messageId}
            container={itemsScrollableDivRef}
            onMessageInView={handleMessageInView}
            onAdditionalLoading={item.handleAdditionalLoading}
            onFirstUnreadMessageViewed={undefined}
            selectedItemId={selectedMessageId}
            setSelectedItemId={setSelectedMessageId}
            isFirstUnreadMessage={false}
          />
        </Fragment>
      ))}
    </div>
  )
})
