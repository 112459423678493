import { FC, memo, useMemo } from 'react'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { CrewSelectBoxField } from 'components/forms/crewSelectBoxField'
import { CrewDatePickerField } from 'components/forms/crewDatePickerField'
import { CrewTimePickerField } from 'components/forms/crewTimePickerField'
import { CrewCheckBoxField } from 'components/forms/crewCheckBoxField'
import { CrewTagBoxField } from 'components/forms/crewTagBoxField'
import { CrewHtmlEditorField } from 'components/forms/crewHtmlEditorField'
import { SEARCH_TIMEOUT_MSEC } from '@crew/configs/constants'
import { CrewConfirmDialog } from 'components/elements/crewConfirmDialog/crewConfirmDialog'
import { CrewErrorDialog } from 'components/elements/crewErrorDialog/crewErrorDialog'
import { useEventEntryForm } from 'features/project/components/eventEntryDialog/components/eventEntryForm/useEventEntryForm'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewScrollView } from 'components/devextreme/crewScrollView'
import { CrewErrorSummary } from 'components/forms/crewErrorSummary'
import { DatePickerDateFormat } from 'enums/system'
import { CrewBadgeSelectBoxField } from 'components/forms/crewBadgeSelectBoxField'
import { useFocusInput } from 'hooks/useFocusInput'
import { EntityType, EventScope } from '@crew/enums/domain'
import { useProjectPermissions, useValueChangeEffect } from '@crew/hooks'
import { useAppSelector } from 'states/hooks'
import { useGetEventQuery } from '@crew/apis/project/projectApis'
import { Event } from '@crew/apis/project/models/getEvent/response'
import { useToast } from 'hooks/useToast'
import { useModal } from 'components/layouts/modal/useModal'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { skipToken } from '@reduxjs/toolkit/query'
import { GetEventRequest } from '@crew/apis/project/models/getEvent/request'
import { EventKindRef } from '@crew/models/refs'
import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useEffect, useState } from 'react'
import dayjs from '@crew/modules'
import { JsonDateFormat } from '@crew/enums/system'
import { formInitialValues, FormValues } from './useEventEntryForm'
import { UploadFile } from 'models/domain/uploadFile'
import { useUserSetting } from '@crew/states'
import { Region, SettingKeyType } from '@crew/enums/app'
import ArrowRight from '~icons/material-symbols/arrow-right'
import { ValueChangedEvent } from 'devextreme/ui/select_box'

export type EventEntryFormProps = {
  projectId?: string
  isEditMode: boolean
  eventId?: string
  onSubmit: (eventId: string, deleteFlg?: boolean) => void
  onCancel: () => void
  startDatetime?: Date
  endDatetime?: Date
}

export const EventEntryForm: FC<EventEntryFormProps> = memo((props) => {
  const [projectId, setProjectId] = useState<string | undefined>(
    props.projectId
  )

  const {
    handleSubmit,
    control,
    formState,
    reset,
    getValues,
    setValue,
    clearErrors,
    setError,
    trigger,
    validateRules,

    eventAttendeeDataSource,
    projectDataSource,
    eventOrganizerDataSource,
    eventKindDataSource,

    insertEvent,
    updateEvent,
    deleteEvent,
    isLoadingInsertEvent,
    isLoadingUpdateEvent,
    isLoadingDeleteEvent,
  } = useEventEntryForm(projectId)

  const toast = useToast()
  const { t } = useTranslation()
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)

  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useModal()

  // ユーザー設定からデフォルトのユーザープロファイル地域を取得
  const defaultUserProfileRegion = useUserSetting(
    SettingKeyType.UserProfileRegion,
    Region.Japan.value
  )

  // イベント詳細を取得する
  // 三項演算子になっていて少し見づらいが、内部のパラメータがundefinedを受け付けないため三項演算子を使用している
  const getEventParam: GetEventRequest | undefined = props.eventId
    ? {
        eventId: props.eventId,
      }
    : undefined
  const { data: getEventResult } = useGetEventQuery(getEventParam ?? skipToken)

  // 添付ファイル（アップロードファイル）情報格納用の配列
  const [uploadedFileList, setUploadedFileList] = useState<UploadFile[]>([])

  // 確認ダイアログメッセージ
  const [confirmMessage, setConfirmMessage] = useState('')

  const canSend = useMemo(
    // formState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState]
  )

  const [isErrorDialogOpen, closeErrorDialog] = useModal()

  // エラーダイアログメッセージ
  const [errorMessage] = useState('')

  //create event state to update default value for form edit mode
  const [event, setEvent] = useState<Event>()

  // Get initial event kind id
  const getInitialEventKindId = useCallback(async () => {
    //Need get eventKinds from eventKindDataSource
    const eventKinds = (await eventKindDataSource
      .store()
      .load()) as EventKindRef[]

    if (eventKinds.length === 0) return null

    // get initial event type
    const initialEventKind = eventKinds.find(
      (eventKind) => eventKind.initialValue
    )

    let initEventKindId = null
    // if initial task type is found
    if (initialEventKind) {
      //set initial task type
      initEventKindId = initialEventKind.id
    } else {
      // 初期値の設定されている項目が無ければそのテナントの最初の項目
      initEventKindId = eventKinds[0].id
    }

    return initEventKindId
  }, [eventKindDataSource])

  // フォーム初期化処理関数
  const initializeForm = useCallback(() => {
    if (props.isEditMode && props.eventId) {
      // 編集時
      if (getEventResult?.event) {
        reset({
          eventKindId: getEventResult.event.eventKind.id,
          subject: getEventResult.event.subject,
          startDate: new Date(
            dayjs(getEventResult.event.startDatetime)
              .tz(String(defaultUserProfileRegion))
              .format(JsonDateFormat.YYYYMMDDHHmmss)
          ),
          startTime: new Date(
            dayjs(getEventResult.event.startDatetime)
              .tz(String(defaultUserProfileRegion))
              .format(JsonDateFormat.YYYYMMDDHHmmss)
          ),
          endDate: new Date(
            dayjs(getEventResult.event.endDatetime)
              .tz(String(defaultUserProfileRegion))
              .format(JsonDateFormat.YYYYMMDDHHmmss)
          ),
          endTime: new Date(
            dayjs(getEventResult.event.endDatetime)
              .tz(String(defaultUserProfileRegion))
              .format(JsonDateFormat.YYYYMMDDHHmmss)
          ),
          projectId: getEventResult.event.entityRecordId ?? undefined,
          eventAttendeeIds: getEventResult.event.eventAttendees.map(
            (attendee) => attendee.id
          ),
          eventOrganizerIds: getEventResult.event.eventOrganizers.map(
            (organizer) => organizer.id
          ),
          scope: getEventResult.event.scope === EventScope.Private,
          confirmAttendance: getEventResult.event.confirmAttendance,
          allowNonAttendees: getEventResult.event.allowNonAttendees ?? false,
          approvalRequired: getEventResult.event.approvalRequired ?? false,
          description: getEventResult.event.description ?? undefined,
          // TODO: Web: イベント分類の非表示対応
          // https://break-tmc.atlassian.net/browse/CREW-15049
          // eventCategoryId: getEventResult.event.eventCategory?.id ?? undefined,
        })

        setEvent(getEventResult.event)
      }
    } else if (!props.isEditMode) {
      const initializeData = async () => {
        // 初期値: 現在時刻切り上げ
        // 13:15なら14:00
        const startDateTime = new Date()
        startDateTime.setHours(startDateTime.getHours() + 1)
        startDateTime.setMinutes(0)
        // 初期値: 開始時刻の1時間後
        const endDateTime = new Date()
        endDateTime.setHours(endDateTime.getHours() + 2)
        endDateTime.setMinutes(0)
        reset({
          ...formInitialValues,
          eventOrganizerIds: [loggedInUser?.id],
          startDate: props.startDatetime ?? startDateTime,
          endDate: props.endDatetime ?? endDateTime,
          startTime: props.startDatetime ?? startDateTime,
          endTime: props.endDatetime ?? endDateTime,
          projectId: props.projectId,
        })
      }
      initializeData()
    }
  }, [
    props.isEditMode,
    props.eventId,
    props.startDatetime,
    props.endDatetime,
    props.projectId,
    getEventResult?.event,
    reset,
    defaultUserProfileRegion,
    loggedInUser?.id,
  ])

  // 初期化処理をuseEffect化
  // TODO: 本当はuseEffectを外したいのだが、レンダリングが大量に走ってしまうためこのようにしている。以下タスクで調査・対応予定
  // https://break-tmc.atlassian.net/browse/CREW-6028
  useEffect(() => {
    // フォーム初期化を実行
    initializeForm()
  }, [initializeForm])

  // プロジェクトIDが変更された場合の処理
  useValueChangeEffect(
    () => {
      if (!props.isEditMode) {
        // プロジェクトIDが変更された場合、初期値を設定する
        const initializeData = async (projectId: string) => {
          const eventKindId = await getInitialEventKindId()
          setValue('eventKindId', eventKindId)

          setValue('projectId', projectId)

          // When the project is changed, the event organizer and event attendee are reset
          if (!loggedInUser?.id) {
            setValue('eventOrganizerIds', [])
          } else {
            setValue('eventOrganizerIds', [loggedInUser.id])
          }
          setValue('eventAttendeeIds', [])
        }

        if (projectId) {
          initializeData(projectId)
        }
      }
    },
    [
      getInitialEventKindId,
      loggedInUser?.id,
      projectId,
      props.isEditMode,
      props.projectId,
      setValue,
    ],
    projectId
  )
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // 削除確認ダイアログ OKボタン
  const handleDeletePermitButtonClick = useCallback(async () => {
    closeConfirmDialog()

    if (!props.eventId || !event) {
      return
    }

    try {
      // Execute delete event process
      await deleteEvent(props.eventId, event.version)

      // Display a toast indicating successful event deleted
      toast.success(t('message.meeting.eventDeleted'))

      // Move to project detail screen
      props.onSubmit(props.eventId ?? '', true)
    } catch (err) {
      showApiErrors(err)
    }
  }, [closeConfirmDialog, props, event, deleteEvent, toast, t, showApiErrors])

  // handle submit form and call api register event
  const handleSubmitButtonClick = useCallback(() => {
    // react-hook-formのhandleSubmitに渡すコールバック関数を定義する
    const onSubmit = async (data: FormValues) => {
      const startDatetime =
        dayjs(data.startDate).format(JsonDateFormat.YYYYMMDD) +
        ' ' +
        dayjs(data.startTime).format(JsonDateFormat.HHmmss)

      const endDatetime =
        dayjs(data.endDate).format(JsonDateFormat.YYYYMMDD) +
        ' ' +
        dayjs(data.endTime).format(JsonDateFormat.HHmmss)

      closeConfirmDialog()
      try {
        if (props.isEditMode && event) {
          // Execute update event process
          const result = await updateEvent(
            event,
            data,
            startDatetime,
            endDatetime
          )

          if (result.event) {
            // Display a toast indicating successful event updated
            toast.success(t('message.meeting.meetingUpdated'))

            // Close event entry dialog
            props.onSubmit(result.event.id)
          }
        } else {
          // Execute insert event process
          const result = await insertEvent(
            data,
            startDatetime,
            endDatetime,
            uploadedFileList
          )

          // Display a toast indicating successful event registration
          toast.success(t('message.meeting.meetingRegistered'))

          // Move to event detail screen
          props.onSubmit(result.event?.id ?? '')
        }
      } catch (err) {
        showApiErrors(err)
      }
    }

    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    closeConfirmDialog,
    props,
    event,
    updateEvent,
    toast,
    t,
    insertEvent,
    uploadedFileList,
    showApiErrors,
  ])

  // handle delete event
  const handleDeleteButtonClick = useCallback(() => {
    if (!props.isEditMode) return
    // 確認ダイアログの表示（処理は確認ダイアログのOKボタン押下時に行う）
    setConfirmMessage(t('message.general.confirmMessage.delete'))
    openConfirmDialog()
  }, [openConfirmDialog, props.isEditMode, t])

  // handle close register event dialog
  const handleCancelButtonClick = useCallback(() => {
    reset()
    clearErrors()
    props.onCancel && props.onCancel()
  }, [clearErrors, props, reset])

  // ファイルアップロード中かどうか
  const isFileUploading = useMemo(() => {
    return uploadedFileList.some((file) => file.progress)
  }, [uploadedFileList])

  // 添付ファイルアップロード完了後
  const handleUploaded = useCallback((file: UploadFile) => {
    // ファイル一覧にアップロードしたファイルを追加
    setUploadedFileList((baseData) => {
      //replace same file name
      const index = baseData.findIndex((item) => item.name === file.name)
      if (index === -1) {
        return [...baseData, file]
      } else {
        // replace the file with the same name
        baseData[index] = file
        return [...baseData]
      }
    })
  }, [])

  // 添付ファイル削除ボタン押下時
  const handleDeleteFile = useCallback((file: UploadFile) => {
    // uploadedFileListに格納している該当ファイル情報を削除する
    setUploadedFileList((baseData) =>
      baseData.filter((item) => item.name !== file.name)
    )
  }, [])

  useFocusInput('subject')

  const { hasPrjFileCreatePermission, hasPrjEventDeletePermission } =
    useProjectPermissions(EntityType.Project, props.projectId)

  // ファイルアップローダー使用可否（編集時または権限がない場合は使用不可）
  const isFileUploaderDisabled = useMemo(
    () => props.isEditMode || !hasPrjFileCreatePermission,
    [props.isEditMode, hasPrjFileCreatePermission]
  )

  // Set end time based on start time
  // end time = start time + one hour
  const setEndTimeBasedOnStartTime = useCallback(async () => {
    const startTime = getValues('startTime')
    const endTime = getValues('endTime')

    // When start time changes, if startDate equal endDate, control the value of end time according to the following spec
    //    - If start time has not been set, set end time = start time + 1
    //    - If start time >= end time then set end time = start time + 1
    if (getValues('startDate') && getValues('endDate') && startTime) {
      const startDate = dayjs(getValues('startDate')).format(
        JsonDateFormat.YYYYMMDD
      )
      const endDate = dayjs(getValues('endDate')).format(
        JsonDateFormat.YYYYMMDD
      )
      if (startDate === endDate) {
        if (!endTime || startTime >= endTime) {
          const endTime = new Date(startTime)
          endTime.setHours(endTime.getHours() + 1)
          endTime.setMinutes(0)
          setValue('endTime', endTime)

          const isValidationSuccessful = await trigger(`startTime`)
          if (!isValidationSuccessful) {
            // 上記の処理ではEndTimeがStartTimeの値を元に設定されたため、
            // StartTimeがEndTimeより大きいことによるStartTimeのバリデーションエラーが発生した場合は、そのエラーをクリアする
            clearErrors('startTime')
          }
        }
      }
    }
  }, [clearErrors, getValues, setValue, trigger])

  // Event handle when the start date is changed
  const handleStartDateValueChanged = useCallback(() => {
    // When start date changes, control the value of end date according to the following spec
    //    - If end date has not been set, set end date = start date
    //    - If start date >= end date then set end date = start date
    // If the end date is changed, set endTime = startTime + 1
    //    ex: startTime = 12:00 -> endTime = 13:00
    if (getValues('startDate')) {
      const startDate = dayjs(getValues('startDate')).format(
        JsonDateFormat.YYYYMMDD
      )
      const endDate = dayjs(getValues('endDate')).format(
        JsonDateFormat.YYYYMMDD
      )

      // Compare the dates without time
      if (!endDate || startDate >= endDate) {
        // set value for endDate
        setValue('endDate', getValues('startDate'))

        // set value for endTime
        setEndTimeBasedOnStartTime()
      }
    }
  }, [getValues, setEndTimeBasedOnStartTime, setValue])

  // Event handle when the start time is changed
  const handleStartTimeValueChanged = useCallback(() => {
    // set value for endTime
    setEndTimeBasedOnStartTime()
  }, [setEndTimeBasedOnStartTime])

  // Set start time based on end time
  // start time = end time - one hour
  const setStartTimeBasedOnEndTime = useCallback(async () => {
    const startTime = getValues('startTime')
    const endTime = getValues('endTime')

    // When end time changes, if startDate equal endDate, control the value of start time according to the following spec
    //    - If start time has not been set, set start time = end time - 1
    //    - If end time <= start time then set start time = end time - 1
    if (getValues('startDate') && getValues('endDate') && endTime) {
      const startDate = dayjs(getValues('startDate')).format(
        JsonDateFormat.YYYYMMDD
      )
      const endDate = dayjs(getValues('endDate')).format(
        JsonDateFormat.YYYYMMDD
      )
      if (startDate === endDate) {
        if (!startTime || startTime >= endTime) {
          const startTime = new Date(endTime)
          startTime.setHours(startTime.getHours() - 1)
          startTime.setMinutes(0)
          setValue('startTime', startTime)

          const isValidationSuccessful = await trigger(`endTime`)
          if (!isValidationSuccessful) {
            // 上記の処理ではStartTimeがEndTimeの値を元に設定されたため、
            // EndTimeがStartTimeより小さいことによるEndTimeのバリデーションエラーが発生した場合は、そのエラーをクリアする
            clearErrors('endTime')
          }
        }
      }
    }
  }, [clearErrors, getValues, setValue, trigger])

  // Event handle when the end date is changed
  const handleEndDateValueChanged = useCallback(() => {
    // When end date changes, control the value of start date according to the following spec
    //    - If start date has not been set, set start date = end date
    //    - If end date <= start date then set start date = end date
    // If the start date is changed, set startTime = endTime - 1
    //    ex: endTime = 13:00 -> startTime = 12:00

    if (getValues('endDate')) {
      const startDate = dayjs(getValues('startDate')).format(
        JsonDateFormat.YYYYMMDD
      )
      const endDate = dayjs(getValues('endDate')).format(
        JsonDateFormat.YYYYMMDD
      )

      // Compare the dates without time
      if (!startDate || startDate >= endDate) {
        // set value for startDate
        setValue('startDate', getValues('endDate'))

        // set value for endTime
        setStartTimeBasedOnEndTime()
      }
    }
  }, [getValues, setStartTimeBasedOnEndTime, setValue])

  // Event handle when the end time is changed
  const handleEndTimeValueChanged = useCallback(() => {
    setStartTimeBasedOnEndTime()
  }, [setStartTimeBasedOnEndTime])

  // Event handle when the project is changed
  const handleProjectValueChanged = useCallback(
    (event: ValueChangedEvent) => {
      if (projectId !== event.value) {
        setProjectId(event.value)
      }
    },
    [projectId]
  )

  return (
    <>
      <form className="flex flex-col h-full">
        <CrewScrollView>
          {/* モーダルの最小幅を制限し、開始日、終了日、所有者など画面の各入力項目が正しく表示されるようにする */}
          <div className="overflow-x-auto">
            <div className="flex flex-col gap-y-2.5 min-w-[640px]">
              <div className="grid grid-cols-4 gap-x-2.5">
                {/* 種別 */}
                <div>
                  <CrewBadgeSelectBoxField
                    id="eventKindId"
                    name="eventKindId"
                    control={control}
                    dataSource={eventKindDataSource}
                    label={t('label.classification')}
                    required={true}
                    showClearButton={false}
                    deferRendering={false}
                  />
                </div>
                {/* 件名 */}
                <div className="col-span-3">
                  <CrewTextBoxField
                    id="subject"
                    name="subject"
                    control={control}
                    rules={validateRules.subject}
                    labelMode="hidden"
                    label={t('label.subject')}
                    required={true}
                  />
                </div>
              </div>
              <div className="flex gap-x-2.5">
                {/* 開始 */}
                <div className="w-5/12">
                  <div className="flex gap-x-2.5 items-start">
                    <div className="w-7/12">
                      <CrewDatePickerField
                        id="startDate"
                        name="startDate"
                        pickerType="calendar"
                        control={control}
                        className="w-full"
                        rules={validateRules.startDate}
                        displayFormat={DatePickerDateFormat.YYYYMMDD}
                        required={true}
                        label={t('label.start')}
                        onValueChanged={handleStartDateValueChanged}
                      />
                    </div>
                    <div className="w-5/12">
                      <CrewTimePickerField
                        id="startTime"
                        name="startTime"
                        control={control}
                        className="w-full"
                        rules={validateRules.startTime}
                        displayFormat="HH:mm"
                        onValueChanged={handleStartTimeValueChanged}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col">
                  <ArrowRight width={24} height={24} className="mt-8" />
                </div>

                {/* 終了 */}
                <div className="w-5/12">
                  <div className="flex gap-x-2.5 items-start">
                    <div className="w-7/12">
                      <CrewDatePickerField
                        id="endDate"
                        name="endDate"
                        pickerType="calendar"
                        control={control}
                        className="w-full"
                        rules={validateRules.endDate}
                        displayFormat={DatePickerDateFormat.YYYYMMDD}
                        required={true}
                        label={t('label.end')}
                        onValueChanged={handleEndDateValueChanged}
                      />
                    </div>
                    <div className="w-5/12">
                      <CrewTimePickerField
                        id="endTime"
                        name="endTime"
                        control={control}
                        className="w-full"
                        rules={validateRules.endTime}
                        displayFormat="HH:mm"
                        onValueChanged={handleEndTimeValueChanged}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* プロジェクト * */}
              <CrewSelectBoxField
                id="projectId"
                name="projectId"
                control={control}
                dataSource={projectDataSource}
                labelMode="hidden"
                valueExpr="id"
                displayExpr="name"
                searchExpr="name"
                searchEnabled={true}
                // NOTE: searchEnabled=falseだとデフォルト値の"1"が適用となりloadされないので"0"を設定
                minSearchLength={0}
                rules={validateRules.projectId}
                label={t('general.entityType.projects')}
                required={true}
                readOnly={props.projectId || props.isEditMode ? true : false}
                showClearButton={false}
                onValueChanged={handleProjectValueChanged}
              />

              {/* 主催者 */}
              <CrewTagBoxField
                id="eventOrganizerIds"
                name="eventOrganizerIds"
                control={control}
                displayExpr="displayName"
                valueExpr="id"
                dataSource={eventOrganizerDataSource}
                searchEnabled={true}
                searchMode="contains"
                searchExpr="displayName"
                searchTimeout={SEARCH_TIMEOUT_MSEC}
                minSearchLength={0}
                label={t('label.organizer')}
                rules={validateRules.eventOrganizerIds}
                required
              />

              {/* 分類 */}
              {/* TODO: Web: イベント分類の非表示対応
                https://break-tmc.atlassian.net/browse/CREW-15049
                <div>
                  <CrewSelectBoxField
                    id="eventCategoryId"
                    name="eventCategoryId"
                    control={control}
                    dataSource={eventCategoryDataSource}
                    valueExpr="id"
                    displayExpr="name"
                    searchEnabled={false}
                    minSearchLength={0}
                    labelMode="hidden"
                    label={t('label.category')}
                  />
                </div> */}

              {/* 参加者 */}
              <CrewTagBoxField
                id="eventAttendeeIds"
                name="eventAttendeeIds"
                control={control}
                displayExpr="displayName"
                valueExpr="id"
                dataSource={eventAttendeeDataSource}
                searchEnabled={true}
                searchMode="contains"
                searchExpr="displayName"
                searchTimeout={SEARCH_TIMEOUT_MSEC}
                minSearchLength={0}
                label={t('label.attendee')}
                rules={validateRules.eventAttendeeIds}
              />

              {/* プロジェクトメンバー以外には公開しない */}
              <CrewCheckBoxField
                id="scope"
                control={control}
                name="scope"
                label={t('label.privateToProjectMembers')}
                rules={validateRules.scope}
              />

              <div className="flex flex-row items-center gap-2.5 flex-wrap">
                {/* 出欠を確認する */}
                <CrewCheckBoxField
                  name="confirmAttendance"
                  control={control}
                  rules={validateRules.confirmAttendance}
                  label={t('label.confirmAttendance')}
                />

                {/* 参加者以外の入室を許可する */}
                <CrewCheckBoxField
                  name="allowNonAttendees"
                  control={control}
                  rules={validateRules.allowNonAttendees}
                  label={t('label.allowNonAttendees')}
                />

                {
                  // TODO: Web + Mobile: 会議登録・編集ダイアログ: 「主催者による入室許可が必要」チェックを表示する
                  // https://break-tmc.atlassian.net/browse/CREW-16312
                }
                {/* 主催者による入室許可が必要 */}
                {/* <CrewCheckBoxField
                  name="approvalRequired"
                  control={control}
                  rules={validateRules.approvalRequired}
                  label={t('label.approvalRequired')}
                /> */}
              </div>

              {/* FIXME: CrewHtmlEditor自体にスタイルクラスを組み込むようにするべきか検討する
                          https://break-tmc.atlassian.net/browse/CREW-4450 */}
              {/* 内容 */}
              <div className="crew-slim-toolbar-item">
                <CrewHtmlEditorField
                  id="description"
                  name="description"
                  control={control}
                  minHeight="8rem"
                  label={t('label.content')}
                  fileUploaderDisabled={isFileUploaderDisabled}
                  uploadedFileList={uploadedFileList}
                  onUploaded={handleUploaded}
                  onDeleteUploadedFile={handleDeleteFile}
                  disabledMention={true}
                />
              </div>

              {/* チャットに投稿する */}
              <CrewCheckBoxField
                id="needNotification"
                name="needNotification"
                control={control}
                label={t('label.needNotification')}
              />
              <CrewErrorSummary formState={formState} />
            </div>
          </div>
        </CrewScrollView>
        <div className="flex justify-between items-center">
          {props.isEditMode && hasPrjEventDeletePermission && (
            <CrewButton
              text={t('action.delete')}
              type="danger"
              onClick={handleDeleteButtonClick}
              disabled={isLoadingDeleteEvent}
            />
          )}

          <div className="ml-auto flex gap-x-5">
            <CrewButton
              text={t('action.register')}
              type="primary"
              onClick={handleSubmitButtonClick}
              disabled={
                !canSend ||
                isFileUploading ||
                isLoadingInsertEvent ||
                isLoadingUpdateEvent
              }
            />
            <CrewButton
              text={t('action.cancel')}
              type="normal"
              stylingMode="outlined"
              onClick={handleCancelButtonClick}
            />
          </div>
        </div>
      </form>
      {/* 削除確認ダイアログ */}
      <CrewConfirmDialog
        isOpen={isConfirmDialogOpen}
        message={confirmMessage}
        onPermitButtonClick={handleDeletePermitButtonClick}
        onCancelButtonClick={closeConfirmDialog}
      />

      {/* エラーダイアログ */}
      <CrewErrorDialog
        isOpen={isErrorDialogOpen}
        message={errorMessage}
        onCloseButtonClick={closeErrorDialog}
      />
    </>
  )
})
