import { memo, FC } from 'react'
import { Navigate } from 'react-router-dom'
import { EntityType } from '@crew/enums/domain'
import { Event } from '@crew/models/domain'
import { useLocation, useSearchParams } from 'react-router-dom'
import { getDefaultTabValue } from '@crew/utils/dist/enum'
import {
  FileDetailListTabs,
  EventDetailTabs,
  ProjectListTabs,
  TaskDetailListTabs,
} from 'enums/app'

// バックエンド側からプッシュ通知で受信したデータを扱う型
// referenceDataがEntityTypeに応じて変わるためgenerics型としているが、不要な場合はT指定をしなくて良いようにunknown型を指定しておく
type EntityRecordData<T = unknown> = {
  id: string
  referenceData: T
}

// プッシュ通知アクセス時の遷移先URLを生成する関数
const generateRedirectUrl = (
  entityType: EntityType,
  entityRecordData: string,
  chatMessageId: string
) => {
  // EntityTypeに応じてURL生成を行う
  switch (entityType) {
    case EntityType.Project:
      var projectData = JSON.parse(entityRecordData) as EntityRecordData
      return `/projects/${projectData.id}/${getDefaultTabValue(
        ProjectListTabs
      )}?messageId=${chatMessageId}`
    case EntityType.Event:
      var eventData = JSON.parse(entityRecordData) as EntityRecordData<Event>
      return `/projects/${eventData.referenceData.entityRecordId}/events/${
        eventData.id
      }/${getDefaultTabValue(EventDetailTabs)}?messageId=${chatMessageId}`
    case EntityType.Task:
      var taskData = JSON.parse(entityRecordData) as EntityRecordData
      return `/tasks/${taskData.id}/${getDefaultTabValue(
        TaskDetailListTabs
      )}?messageId=${chatMessageId}`
    case EntityType.File:
      var fileData = JSON.parse(entityRecordData) as EntityRecordData
      return `/files/${fileData.id}/${getDefaultTabValue(
        FileDetailListTabs
      )}?messageId=${chatMessageId}`
    default:
      return null
  }
}

export const AppRedirectRouteHandler: FC = memo(() => {
  const location = useLocation()

  // クエリパラメータから画面制御に必要なデータを取得する
  const [searchParams] = useSearchParams()
  var entityType = searchParams.get('entityType') || undefined
  var entityRecordData = searchParams.get('entityRecordData') || undefined
  var chatMessageId = searchParams.get('chatMessageId') || undefined

  // 遷移先URLを生成
  var targetUrl = generateRedirectUrl(
    entityType as EntityType,
    entityRecordData as string,
    chatMessageId as string
  )

  if (targetUrl == null) {
    // TODO: （通常ありえないが）遷移先URLが生成できない場合の制御について以下タスクで方針決めの後対応する
    // https://break-tmc.atlassian.net/browse/CREW-4116
    // 現状はトーストでアクセスできない旨を表示しつつルートへ遷移させる
    return <Navigate to={'/'} state={{ referrer: location }} replace />
  }
  return <Navigate to={targetUrl} state={{ referrer: location }} replace />
})
