import { useEffect, useState } from 'react'
import {
  currentPermission,
  eventChangePermissions,
  requestPermission,
} from './pushNotifcation'

/**
 * 通知の許可を取得・リクエストするカスタムフック
 * @returns
 */
export const useNotificationPermission = () => {
  const [permission, setPermission] = useState(currentPermission)

  // permissionの変更はReactの管理外で行われるうため、イベントリスナで検知する
  useEffect(() => {
    eventChangePermissions.add(setPermission)
    return () => {
      // クリーンアップ時にイベントリスナ配列から削除する
      eventChangePermissions.delete(setPermission)
    }
  }, [])

  // hookの機能として「取得」と「要求」をセットにするため、requestPermissionもあわせて返す
  return [permission, requestPermission] as const
}
