import { FC, memo, useCallback, useEffect } from 'react'
import { useWebMeetingJoinForm } from '../webMeetingJoinForm/useWebMeetingJoinForm'
import { WebMeetingSettingItems } from 'features/webMeeting/components/webMeetingSettingItems/webMeetingSettingItems'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useAppSelector } from 'states/hooks'
import { useShowApiErrors } from 'hooks/useShowApiErrors'
import { useTranslation } from '@crew/modules/i18n'
import BaselineVideocam from '~icons/ic/baseline-videocam'
import { useAudioVideo } from 'modules/amazon-chime-sdk-component-library-devextreme/providers/AudioVideoProvider'
import {
  LIFE_TIME_MS,
  TOPIC_MEETING_USER_JOIN_STATE_CHANGED,
} from 'configs/constants'
import { MeetingAttendeeJoinState } from '@crew/enums/domain'

export type WebMeetingJoinFormProps = {
  eventId: string
  onClose: () => void
  onCancelMeeting: () => void
}

export const WebMeetingJoinForm: FC<WebMeetingJoinFormProps> = memo((props) => {
  const { joinMeeting, startMeeting } = useWebMeetingJoinForm()
  const audioVideo = useAudioVideo()
  const { t } = useTranslation()

  const [showApiErrors] = useShowApiErrors()

  // ログインユーザ情報取得
  const loggedInUser = useAppSelector((state) => state.app.loggedInUser)
  // ユーザーがWeb会議の開催者かどうかの情報
  const isOrganizer = useAppSelector((state) => state.webMeeting.isOrganizer)

  // 参加状態
  const joinState = useAppSelector((state) => state.webMeeting.joinState)

  // 会議開始・参加処理 実行
  const handleJoinMeeting = useCallback(async () => {
    try {
      await joinMeeting(loggedInUser?.displayName as string, props.eventId)
    } catch (err) {
      showApiErrors(err)
    }
  }, [joinMeeting, loggedInUser?.displayName, props.eventId, showApiErrors])

  // 会議開始
  useEffect(() => {
    handleJoinMeeting()
    // マウント時のみ実行したいので依存は不要
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // キャンセルボタンクリック処理
  const handleCancelButtonClick = useCallback(() => {
    props.onCancelMeeting()
  }, [props])

  // 会議開始・参加ボタンクリック処理
  const handleStartButtonClick = useCallback(async () => {
    if (!audioVideo) return

    await startMeeting()

    // JoinState= Waiting の場合は、「参加者が変更された」メッセージを送信する
    if (joinState === MeetingAttendeeJoinState.Waiting) {
      // wait for a while to send the message
      window.setTimeout(() => {
        // Send realtime message that the meeting attendees has changed
        audioVideo.realtimeSendDataMessage(
          TOPIC_MEETING_USER_JOIN_STATE_CHANGED,
          t('message.meeting.meetingAttendeesWaitingForApproval'),
          LIFE_TIME_MS
        )
      }, LIFE_TIME_MS)
    }

    // ダイアログを閉じる
    props.onClose()
  }, [audioVideo, joinState, props, startMeeting, t])

  return (
    <div className="flex flex-col gap-y-5 h-full">
      <WebMeetingSettingItems showAutoRecordingSwitch={true} />
      <div className="flex justify-between items-center">
        <div className="ml-auto flex gap-x-5">
          {/* 会議開始ボタン（開催者以外は参加ボタン） */}
          <CrewButton
            icon={<BaselineVideocam width={24} height={24} />}
            text={
              isOrganizer ? t('action.startMeeting') : t('action.joinMeeting')
            }
            type="primary"
            onClick={handleStartButtonClick}
          />

          <CrewButton
            text={t('action.cancel')}
            type="normal"
            stylingMode="outlined"
            onClick={handleCancelButtonClick}
          />
        </div>
      </div>
    </div>
  )
})
