import { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useVerifyTokenExpirationTimeQuery } from '@crew/apis/dist/app/appApis'
import {
  DEFAULT_PASSWORD_COMPLEX_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_SYMBOLS,
} from '@crew/configs/constants'
import { useTranslation } from '@crew/modules/i18n'

import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewReCaptcha } from 'components/elements/crewReCaptcha/crewReCaptcha'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useReCaptcha } from 'hooks/useReCaptcha'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useAppSelector } from 'states/hooks'
import { validateBillingCycle, validateContractPlan } from 'utils'

import { TenantIdTextBoxField } from './components/tenantIdTextBoxField'

import { FormValues, useAppSignup } from './useAppSignup'

import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import { ContractPlan } from '@crew/enums/app'
import { ValueChangedEvent } from 'devextreme/ui/text_box'
import { VerifyTokenExpirationTimeRequest } from '@crew/apis/dist/app/models/verifyTokenExpirationTime/request'
import { skipToken } from '@reduxjs/toolkit/query'
import { isApiErrorResult } from '@crew/apis/dist/errors'
import { jwtDecode } from 'jwt-decode'

// Params check exist email
const ConstraintName = 'ak_accounts_email'
const TableName = 'accounts'

type SignupError = {
  data: {
    internal: {
      ConstraintName: string
      TableName: string
    }
  }
}
export const AppSignup = memo(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { token } = useParams()
  const [searchParams] = useSearchParams()
  const plan = searchParams.get('plan')
  const billingCycle = searchParams.get('billingCycle')
  const themeMode = useAppSelector((state) => state.app.currentTheme)

  const {
    control,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    trigger,
    validateRules,

    tenantAliasValidationStatus,
    setTenantAliasValidationStatus,

    signup,
    checkValidSubdomain,
    verifyTenantId,
    verifyLoginId,
    isSignupLoading,

    loginIdValidationStatus,
    setLoginIdValidationStatus,
    passwordValidationStatus,
    setPasswordValidationStatus,
    confirmPasswordValidationStatus,
    setConfirmPasswordValidationStatus,
  } = useAppSignup(plan)

  const { error } = useToast()

  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  const { isReCaptchaVerified } = useReCaptcha()

  const canSend = useMemo(
    // formState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    () =>
      formState.isDirty &&
      Object.keys(formState.errors).length === 0 &&
      isReCaptchaVerified &&
      !isSignupLoading, // 多重送信防止
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState, isReCaptchaVerified, isSignupLoading]
  )

  const [isSignupSuccess, setIsSignupSuccess] = useState<boolean>(false)

  // 有効期限
  const jwtExpirationDatetime = useMemo(() => {
    if (!token) {
      return null
    }
    const decoded = jwtDecode(token)
    if (decoded && decoded.exp) {
      return t('format.datetime', {
        value: new Date(decoded.exp * 1000), // expは秒単位なのでミリ秒に変換
      })
    }

    return null
  }, [t, token])

  // Check expiration time of signup token
  const verifyTokenExpirationTimeRequest:
    | VerifyTokenExpirationTimeRequest
    | undefined = token
    ? {
        token,
      }
    : undefined
  const { data: verifyTokenExpirationTimeResult } =
    useVerifyTokenExpirationTimeQuery(
      verifyTokenExpirationTimeRequest ?? skipToken
    )

  // Handle checking to valid tenant ID
  const handleTenantIdChange = useCallback(
    (e: ValueChangedEvent) => {
      const value = e.value

      // キーワード未入力時は何もしない
      if (value.trim().length === 0) {
        return
      }

      clearErrors('tenantAlias')

      // Set loading state before call api checking duplicate tenant ID
      setTenantAliasValidationStatus('pending')

      checkValidSubdomain(value).then(async (isValidSubdomain) => {
        if (!isValidSubdomain) {
          // Check valid subdmain
          setTenantAliasValidationStatus('invalid')
          setError('tenantAlias', {
            type: 'manual',
            message: t('message.signup.register.invalidFormatTenantAlias'),
          })
          return
        }

        // Check the existence of tenantId
        await verifyTenantId(value)
      })
    },
    [
      checkValidSubdomain,
      clearErrors,
      setError,
      setTenantAliasValidationStatus,
      t,
      verifyTenantId,
    ]
  )

  const handleContactNameChange = useCallback(
    (e: ValueChangedEvent) => {
      const value = e.value

      // キーワード未入力時は何もしない
      if (value.trim().length === 0) {
        return
      }

      clearErrors('contactName')
    },
    [clearErrors]
  )

  const handleTelChange = useCallback(
    (e: ValueChangedEvent) => {
      const value = e.value

      // キーワード未入力時は何もしない
      if (value.trim().length === 0) {
        return
      }

      clearErrors('tel')
    },
    [clearErrors]
  )

  // Event handle when password has changed
  // this method verify the password using password policy
  const handlePasswordChange = useCallback(
    async (e: ValueChangedEvent) => {
      const value = e.value

      // if password is not entered, do not check password policy
      if (value.trim().length === 0) {
        return
      }

      const isValidationSuccessful = await trigger(`password`)

      // if current value is invalid, do not check password policy
      if (!isValidationSuccessful) {
        setPasswordValidationStatus('invalid')
        setError('password', {
          type: 'manual',
          message: t('message.signup.register.invalidFormatPassword'),
        })
        return
      }

      // サインアップ時のパスワードのポリシーは「複雑なパスワードを強制する」と同じポリシーとする
      // 「複雑なパスワード」かつ「8文字以上」かつ「32文字以下」
      const regex = new RegExp(
        `^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[${PASSWORD_SYMBOLS}]).{${DEFAULT_PASSWORD_COMPLEX_MIN_LENGTH},${PASSWORD_MAX_LENGTH}}$`
      )

      // Check password policy
      if (!regex.test(getValues('password'))) {
        setPasswordValidationStatus('invalid')
        setError('password', {
          type: 'manual',
          message: t('message.signup.register.invalidFormatPassword'),
        })
        return
      }

      // password is valid
      setPasswordValidationStatus('valid')
      clearErrors('password')
    },
    [clearErrors, getValues, setError, setPasswordValidationStatus, t, trigger]
  )

  // Event handle when confirm password has changed
  // this method compare confirm password and pasword
  const handleConfirmPasswordChange = useCallback(
    async (e: ValueChangedEvent) => {
      const value = e.value

      // if confirm password is not entered, do not compare
      if (value.trim().length === 0) {
        return
      }

      // if current value is invalid, do not compare
      const isValidationSuccessful = await trigger(`confirmPassword`)
      if (!isValidationSuccessful) {
        setConfirmPasswordValidationStatus('invalid')
        setError('confirmPassword', {
          type: 'manual',
          message: t('message.signup.register.invalidFormatConfirmPassword'),
        })
        return
      }

      // compare confirm password and password
      if (value !== getValues('password')) {
        setConfirmPasswordValidationStatus('invalid')
        setError('confirmPassword', {
          type: 'manual',
          message: t('message.signup.register.invalidFormatConfirmPassword'),
        })

        return
      }

      // confirm password is valid
      setConfirmPasswordValidationStatus('valid')
      clearErrors('confirmPassword')
    },
    [
      trigger,
      getValues,
      setConfirmPasswordValidationStatus,
      clearErrors,
      setError,
      t,
    ]
  )

  // Handle checking to valid login ID
  const handleLoginIdChange = useCallback(
    async (e: ValueChangedEvent) => {
      const value = e.value

      // キーワード未入力時は何もしない
      if (value.trim().length === 0) {
        return
      }

      // ログインIDのバリデーションチェック
      const isValidationSuccessful = await trigger(`loginId`)
      if (!isValidationSuccessful) {
        // エラー時はフォームにエラー情報をセットする
        setLoginIdValidationStatus('invalid')
        setError('loginId', {
          type: 'manual',
          message: t('message.signup.register.invalidLoginId'),
        })
        return
      }

      // バリデーションエラーがないため、フォームのエラー情報をクリア
      clearErrors('loginId')

      // Set loading state before call api checking duplicate login ID
      setLoginIdValidationStatus('pending')

      try {
        // Check the existence of loginId
        await verifyLoginId(value)
      } catch (err) {
        setLoginIdValidationStatus('invalid')
        showApiErrors(err)
      }
    },
    [
      clearErrors,
      setError,
      setLoginIdValidationStatus,
      showApiErrors,
      t,
      trigger,
      verifyLoginId,
    ]
  )

  // Handle signup tenant and initial first account of tenant
  const handleFormSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      // stop form submit redirect
      e.preventDefault()

      const onSubmit = async (values: FormValues) => {
        // Check must be valid token, tenant ID and login ID
        if (
          token &&
          tenantAliasValidationStatus === 'valid' &&
          loginIdValidationStatus === 'valid' &&
          passwordValidationStatus === 'valid' &&
          confirmPasswordValidationStatus === 'valid'
        ) {
          // プランの妥当性チェック
          if (!validateContractPlan(plan)) {
            error(t('message.signup.invalidPlan'))
            return
          }
          // 請求サイクルの妥当性チェック
          if (!validateBillingCycle(billingCycle)) {
            error(t('message.signup.invalidBillingCycle'))
            return
          }

          try {
            // Execute signup process
            await signup(values, token, plan, billingCycle)

            // サインアップAPIに成功した場合
            setIsSignupSuccess(true)
          } catch (err) {
            //TODO: バックエンドからのエラーの戻りを型をチェックせず変換している。リファクタリング対象
            //      https://break-tmc.atlassian.net/browse/CREW-10162
            const { data } = err as SignupError
            // eメール重複
            if (
              data?.internal?.ConstraintName === ConstraintName &&
              data?.internal?.TableName === TableName
            ) {
              // Redirect to page warning exist email
              navigate('/exist_email')
              return
            }

            // Show error from API response
            if (isApiErrorResult(err)) {
              showApiErrors(err)
              return
            } else {
              // その他
              // Show message サインアップできませんでした by toast
              error(t('message.auth.failedToSignup'))
            }
          }
        }
      }
      handleSubmit(onSubmit)()
    },
    [
      handleSubmit,
      token,
      tenantAliasValidationStatus,
      loginIdValidationStatus,
      passwordValidationStatus,
      confirmPasswordValidationStatus,
      plan,
      billingCycle,
      error,
      t,
      signup,
      navigate,
      showApiErrors,
    ]
  )

  // Redirect to tenant page after registered signup tenant
  const handleRedirectToLoginTenantButtonClick = useCallback(() => {
    // app.crew.break-tmc.works→リダイレクト→{tenantAlias}.crew.break-tmc.worksとなってしまう。
    // Remove subdomain from current host (app.crew.break-tmc.works → crew.break-tmc.works)
    const originHost = window.location.host.split('.').slice(1).join('.')
    // Replace current host to another subdomain (crew.break-tmc.works → {tenantAlias}.crew.break-tmc.works)
    window.location.href = `${window.location.protocol}//${getValues(
      'tenantAlias'
    )}.${originHost}/login`
  }, [getValues])

  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  return (
    // NOTE: min-h-[100dvh] について
    //       iOSのSafariでサインアップ情報入力画面から登録完了画面に遷移したときに、縦スクロールバーが残ってしまい
    //       スクロールした状態で、登録完了画面の部分が見えなくなってしまう問題を解消するために追加したもの
    //       tailwindcssでは、3.4系になるとdvhが対応してmin-h-dvhと書けるようになるが、現在は3.3系なのでmin-h-[100dvh]と書いている
    //       参考:https://zenn.dev/tak_dcxi/articles/2ac77656aa94c2cd40bf
    //            https://www.tak-dcxi.com/article/that-css-technique-you-learned-is-outdated
    //            https://qiita.com/degudegu2510/items/6d5d53ca9833aef7ec83#-dynamic-viewport%E3%82%92%E5%9F%BA%E6%BA%96%E3%81%AB%E3%81%97%E3%81%9F%E5%8D%98%E4%BD%8D
    <div className="flex flex-row pt-4 pb-12 sm:pt-12 justify-center crew-bg-gray-1 min-h-[100dvh]">
      <div className="flex flex-col gap-2 w-[570px]">
        <div className="flex flex-row items-center">
          {/* ロゴ */}
          <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto " />
        </div>
        {/* Show message for signup token is expired */}
        {!verifyTokenExpirationTimeResult?.valid ? (
          <div className="flex flex-col text-center sm:mt-6 whitespace-pre-line">
            {t('message.signup.singupLinkHasExpired')}
          </div>
        ) : /* Show signup form if signup token is valid */
        !isSignupSuccess ? (
          <form onSubmit={handleFormSubmit} className="flex flex-col gap-2.5">
            <div className="flex flex-col items-center gap-2.5">
              <span>{t('label.signUpHint')}</span>
              {jwtExpirationDatetime && (
                <p className="text-blue-500">
                  {t('label.expirationDatetimeHint', {
                    expirationDatetime: jwtExpirationDatetime,
                  })}
                </p>
              )}
            </div>

            <span className="text-2xl font-bold py-[10px]">
              {t('label.organizationInformation')}
            </span>
            {/* 会社名 */}
            <div className="flex flex-col gap-1">
              <CrewTextBoxField
                id="companyName"
                name="companyName"
                className="h-11"
                control={control}
                placeholder={t('label.companyNameSample')}
                label={t('label.companyName')}
                showLabel
                rules={validateRules.companyName}
                elementAttr={{
                  'data-testid': 'companyName',
                }}
                required
              />
              <span className="text-sm text-blue-500">
                {t('label.companyNameNotice')}
              </span>
            </div>

            <div className="flex flex-col gap-1">
              {/* 表示名（会社） */}
              <CrewTextBoxField
                id="organizationName"
                name="organizationName"
                className="h-11"
                control={control}
                placeholder={t('label.organizationNameSample')}
                label={t('label.displayName2')}
                showLabel
                rules={validateRules.organizationName}
                elementAttr={{
                  'data-testid': 'organizationName',
                }}
                required
              />
              <span className="text-sm text-blue-500">
                {t('label.displayName2Notice')}
              </span>
            </div>
            {/* 組織ID */}
            <div className="flex flex-col gap-1">
              <TenantIdTextBoxField
                id="tenantAlias"
                name="tenantAlias"
                className="h-12"
                control={control}
                placeholder={t('label.tenantIdSample')}
                label={t('label.tenantId')}
                showLabel
                rules={validateRules.tenantAlias}
                elementAttr={{
                  'data-testid': 'tenantAlias',
                }}
                validationStatus={tenantAliasValidationStatus}
                onValueChanged={handleTenantIdChange}
                required
              />
              <span className="text-sm crew-text-gray-4">
                {t('label.tenantPolicy')}
              </span>
              <span className="text-sm crew-text-gray-4">
                {t('label.tenantPolicy2')}
              </span>
              <span className="text-sm text-blue-500">
                {t('label.tenantPolicyNotice')}
              </span>
            </div>

            <span className="text-2xl font-bold py-[10px]">
              {t('label.systemAdministratorInformation')}
            </span>
            {/* 表示名(組織管理者) */}
            <div className="flex flex-col gap-1">
              <CrewTextBoxField
                id="displayName"
                name="displayName"
                className="h-11"
                control={control}
                placeholder={t('label.displayNameSample')}
                label={t('label.displayName2')}
                showLabel
                rules={validateRules.displayName}
                elementAttr={{
                  'data-testid': 'displayName',
                }}
                required
              />
              <span className="text-sm text-blue-500">
                {t('label.displayName2Notice')}
              </span>
            </div>
            {/* ログインID */}
            <div className="flex flex-col gap-1">
              <CrewTextBoxField
                id="loginId"
                name="loginId"
                className="h-11"
                control={control}
                placeholder={t('label.loginIdSample')}
                label={t('label.loginId')}
                showLabel
                rules={validateRules.loginId}
                elementAttr={{
                  'data-testid': 'loginId',
                }}
                validationStatus={loginIdValidationStatus}
                onValueChanged={handleLoginIdChange}
                required
              />
              <span className="text-sm text-blue-500">
                {t('label.loginIdNotice')}
              </span>
              <span className="text-sm crew-text-gray-4">
                {t('label.loginIdPolicy')}
              </span>
            </div>
            {/* パスワード */}
            <div className="flex flex-col gap-1">
              <CrewTextBoxField
                id="password"
                name="password"
                className="h-11"
                control={control}
                label={t('label.password')}
                showLabel
                rules={validateRules.password}
                mode="password"
                elementAttr={{
                  'data-testid': 'password',
                }}
                required
                validationStatus={passwordValidationStatus}
                onValueChanged={handlePasswordChange}
              />
              {/* サインアップ時は「複雑なパスワードを強制する」ONと同等の扱いとするため、ポリシーを表記 */}
              <span className="text-sm crew-text-gray-4">
                {t('label.passwordComplexityPolicy', {
                  symbol: PASSWORD_SYMBOLS,
                  minLength: DEFAULT_PASSWORD_COMPLEX_MIN_LENGTH,
                })}
              </span>
            </div>
            {/* パスワード確認 */}
            <CrewTextBoxField
              id="confirmPassword"
              name="confirmPassword"
              className="h-11"
              control={control}
              label={t('label.confirmPassword')}
              showLabel
              rules={validateRules.confirmPassword}
              mode="password"
              elementAttr={{
                'data-testid': 'confirmPassword',
              }}
              required
              validationStatus={confirmPasswordValidationStatus}
              onValueChanged={handleConfirmPasswordChange}
            />

            {/* 契約プランが無料プランの場合のみ組織管理者情報を入力する */}
            {validateContractPlan(plan) &&
              (plan === ContractPlan.Free ? (
                <>
                  {/* ここから組織管理者情報 */}
                  <span className="text-2xl font-bold py-[10px]">
                    {t('label.contactInformation')}
                  </span>

                  {/* 氏名 */}
                  <div className="flex flex-col gap-1">
                    <CrewTextBoxField
                      id="contactName"
                      name="contactName"
                      className="h-11"
                      control={control}
                      placeholder={t('label.contactNameSample')}
                      label={t('label.fullName')} // 取り扱い上は組織管理者情報のcontactNameだが、ラベルは「氏名」なのでfullNameを指定する
                      showLabel
                      rules={validateRules.contactName}
                      elementAttr={{
                        'data-testid': 'contactName',
                      }}
                      required
                      onValueChanged={handleContactNameChange}
                    />
                    <span className="text-sm text-blue-500">
                      {t('label.contactNameNotice')}
                    </span>
                  </div>

                  {/* 部門名 */}
                  <CrewTextBoxField
                    id="departmentName"
                    name="departmentName"
                    className="h-11"
                    control={control}
                    placeholder={t('label.departmentNameSample')}
                    label={t('label.departmentName')}
                    showLabel
                    rules={validateRules.departmentName}
                    elementAttr={{
                      'data-testid': 'departmentName',
                    }}
                  />

                  {/* 役職 */}
                  <CrewTextBoxField
                    id="officialPosition"
                    name="officialPosition"
                    className="h-11"
                    control={control}
                    placeholder={t('label.officialPositionSample')}
                    label={t('label.officialPosition')}
                    showLabel
                    rules={validateRules.officialPosition}
                    elementAttr={{
                      'data-testid': 'officialPosition',
                    }}
                  />

                  {/* 電話番号 */}
                  <CrewTextBoxField
                    id="tel"
                    name="tel"
                    className="h-11"
                    control={control}
                    placeholder={t('label.telSample')}
                    label={t('label.tel')}
                    showLabel
                    rules={validateRules.tel}
                    elementAttr={{
                      'data-testid': 'tel',
                    }}
                    required
                    onValueChanged={handleTelChange}
                  />
                </>
              ) : null)}

            {/* SignUp button */}
            <div className="flex flex-row justify-center w-3/4 mx-auto">
              <CrewButton
                className="grow"
                type="primary"
                useSubmitBehavior={true}
                disabled={!canSend}
                data-testid="signup"
                text={t('action.register')}
              />
            </div>

            {/* Recaptcha */}
            <CrewReCaptcha />
          </form>
        ) : (
          // サインアップAPIに成功した場合
          <div className="flex flex-col gap-2 items-center">
            <p>{t('message.signup.register.signupSuccess')}</p>
            <CrewButton
              type="primary"
              text={t('label.clickHereToRedirectToTenantPage')}
              onClick={handleRedirectToLoginTenantButtonClick}
            />
          </div>
        )}
      </div>
    </div>
  )
})
