import { memo } from 'react'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { useTranslation } from '@crew/modules/dist/i18n'
import { PresenceStatusEntryDialog } from '../presenceStatusEntryDialog/presenceStatusEntryDialog'
import { useModal } from 'components/layouts/modal/useModal'

export const PresenceStatusListToolbar = memo(() => {
  const { t } = useTranslation()

  const [
    isPresenceStatusEntryDialogOpen,
    openPresenceStatusEntryDialog,
    closePresenceStatusEntryDialog,
  ] = useModal()

  return (
    <>
      <div className="w-full flex">
        <div className="flex flex-1">
          <CrewButton
            type="primary"
            className="ml-auto"
            text={t('action.newPresenceStatus')}
            onClick={openPresenceStatusEntryDialog}
          />
        </div>
      </div>
      <PresenceStatusEntryDialog
        isEditMode={false}
        title={t('label.presenceStatusAdd')}
        isOpen={isPresenceStatusEntryDialogOpen}
        onClose={closePresenceStatusEntryDialog}
      />
    </>
  )
})
