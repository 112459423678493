import { FC, memo, useCallback, useMemo, useState } from 'react'

import { CrewButton } from 'components/elements/crewButton/crewButton'
import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { useAppLoginPage } from 'features/app/components/appLoginPage/useAppLoginPage'
import { useTranslation } from '@crew/modules/i18n'
import LogoImgLight from 'assets/images/svg/crewworks-slim-light.svg'
import LogoImgDark from 'assets/images/svg/crewworks-slim-dark.svg'
import { CrewLink } from 'components/elements/crewLink/crewLink'
import { LoadIndicator } from 'devextreme-react'
import { TenantNotExist } from './components/tenantNotExists/tenantNotExists'
import { useAppDispatch, useAppSelector } from 'states/hooks'
import { useCheckDuplicateTenantIdQuery } from '@crew/apis/dist/app/appApis'
import { useCrewNavigate } from 'hooks/useCrewNavigate'
import { Path, useLocation } from 'react-router-dom'
import { CheckDuplicateTenantIdRequest } from '@crew/apis/dist/app/models/checkDuplicateTenantId/request'
import { LoginFormValues } from './useAppLoginPage'
import { setLoggedInUser } from 'features/app/states/appSlice'
import { resetReduxState } from 'states/store'
import { isApiErrorResult } from '@crew/apis/errors'
import { HttpStatusCode } from '@crew/enums/system'
// referrerの型はuseCrewNavigate.tsのnavigateWrapperの第一引数と揃えること
type LocationState = { referrer: Partial<Path> } | undefined

export const AppLoginPage: FC = memo(() => {
  const { control, handleSubmit, validateRules, login, isLoadingLogin } =
    useAppLoginPage()

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { navigate } = useCrewNavigate()
  const locationState = useLocation().state as LocationState
  const [serverErrorMessage, setServerErrorMessage] = useState('')
  const themeMode = useAppSelector((state) => state.app.currentTheme)

  // TODO: 重複確認APIを存在確認とする汎化の対応を行う
  // https://break-tmc.atlassian.net/browse/CREW-8631
  // Get check duplicate tenant ID
  const getCheckDuplicateTenantIdParams:
    | CheckDuplicateTenantIdRequest
    | undefined = {
    tenantId: window.location.host.split('.').slice(0, 1).join(),
  }
  const {
    data: getCheckDuplicateTenantIdResult,
    isLoading: isLoadingCheckDuplicateTenantId,
    isError: isErrorCheckDuplicateTenantId,
  } = useCheckDuplicateTenantIdQuery(getCheckDuplicateTenantIdParams)

  // Handle login form submission
  const handleFormSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      // stop form submit redirect
      e.preventDefault()

      const onSubmit = async (values: LoginFormValues) => {
        try {
          // ログイン
          const response = await login({
            ...values,
          })

          // check 2 step login
          if (response?.isTwoFactorAuthRequired && response?.token) {
            // go verify login code
            navigate(`/login-verify-code/${response?.token}`)
            return
          }

          if (response.data) {
            /* ログイン成功時 */

            // reduxを初期化
            resetReduxState()

            // 秘匿ではないuser情報を保存
            dispatch(setLoggedInUser(response.data))

            // リダイレクト
            //     遷移元の情報がある場合：遷移元に遷移
            //     上記以外             ：ルート(ホーム画面)へ遷移
            navigate(locationState?.referrer.pathname ?? '/')
          } else {
            setServerErrorMessage(t('message.auth.failedToLogin'))
          }
        } catch (err) {
          if (isApiErrorResult(err) && err.status === HttpStatusCode.Locked) {
            setServerErrorMessage(t('message.auth.lockedUser'))
          } else {
            setServerErrorMessage(t('message.auth.failedToLogin'))
          }
        }
      }

      handleSubmit(onSubmit)()
    },
    [
      handleSubmit,
      login,
      navigate,
      dispatch,
      locationState?.referrer.pathname,
      t,
    ]
  )

  const LogoImg = useMemo(
    () => (themeMode === 'dark' ? LogoImgDark : LogoImgLight),
    [themeMode]
  )

  if (isErrorCheckDuplicateTenantId) {
    return <></>
  }

  if (isLoadingCheckDuplicateTenantId) {
    return <LoadIndicator height="1em" width="1em" />
  }

  // Checking exists tenant
  return getCheckDuplicateTenantIdResult?.duplicated ? (
    // Login form
    <div className="flex flex-row h-screen pt-4 sm:pt-12 justify-center crew-bg-gray-1">
      <div className="flex flex-col gap-4 sm:gap-6 w-full max-w-sm">
        <div className="flex flex-row items-center sm:mb-6">
          {/* ロゴ */}
          <img src={LogoImg} alt="logo" className="mx-auto h-16 w-auto" />
        </div>
        <form onSubmit={handleFormSubmit} className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            {/* サーバーエラーメッセージ */}
            <div
              className="crew-error-text text-center"
              data-testid="login-form-server-error-message"
            >
              {serverErrorMessage}
            </div>
            {/* Login ID */}
            <div>
              <CrewTextBoxField
                id="loginId"
                name="loginId"
                control={control}
                placeholder={t('label.userIdOrEmailAddress')}
                labelMode="floating"
                rules={validateRules.loginId}
                inputAttr={{
                  'data-testid': 'username',
                }}
                showLabel={false}
              />
            </div>
            {/* Password */}
            <div>
              <CrewTextBoxField
                id="password"
                name="password"
                control={control}
                placeholder={t('label.password')}
                labelMode="floating"
                rules={validateRules.password}
                mode="password"
                inputAttr={{
                  'data-testid': 'password',
                }}
                showLabel={false}
              />
            </div>
          </div>

          {/* 「パスワードをお忘れですか？」リンク */}
          <div className="flex justify-center">
            <CrewLink to="/forgot-password" className="text-sm">
              {t('label.forgotPassword')}
            </CrewLink>
          </div>

          {/* Login button */}
          <div className="flex flex-row justify-center">
            <CrewButton
              className="grow"
              type="primary"
              useSubmitBehavior={true}
              text={t('action.login')}
              disabled={isLoadingLogin}
            />
          </div>
        </form>
      </div>
    </div>
  ) : (
    // Warning tenant doesn't exist
    <TenantNotExist />
  )
})
