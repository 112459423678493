export const IsUpdateUserPresenceState = (v) => {
    if (typeof v !== 'object' || v === null)
        return false;
    const m = v;
    if (typeof m.userId !== 'string')
        return false;
    if (typeof m.presenceStateId !== 'string')
        return false;
    if (typeof m.presenceStateMessage !== 'string' &&
        m.presenceStateMessage !== null) {
        return false;
    }
    return true;
};
