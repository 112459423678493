import { CrewTextBoxField } from 'components/forms/crewTextBoxField'
import { memo, useCallback, useMemo } from 'react'
import { useChangePasswordForm } from './useChangePasswordForm'
import { CrewButton } from 'components/elements/crewButton/crewButton'
import { PASSWORD_SYMBOLS } from '@crew/configs/constants'
import { IsApiErrorInvalidPassword, isApiErrorResult } from '@crew/apis/errors'
import { GetTenantSettingsRequest } from '@crew/apis/setting/models/getTenantSettings/request'
import {
  DEFAULT_PASSWORD_COMPLEXITY,
  DEFAULT_PASSWORD_COMPLEX_MIN_LENGTH,
  DEFAULT_PASSWORD_MIN_LENGTH,
} from '@crew/configs/constants'
import { SettingKeyType } from '@crew/enums/app'
import { convertStringToBoolean } from '@crew/utils'
import { useShowApiErrorsWithForm } from 'hooks/useShowApiErrors'
import { useToast } from 'hooks/useToast'
import { useGetTenantSettingsQuery } from '@crew/apis/setting/settingApis'
import { FormValues } from './useChangePasswordForm'
import { useTranslation } from '@crew/modules/dist/i18n'

export type ChangePasswordFormProps = {
  onClosePasswordForm: () => void
}

export const ChangePasswordForm = memo((props: ChangePasswordFormProps) => {
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState,
    validateRules,
    updatePassword,
    isLoadingUpdateUserPassword,
  } = useChangePasswordForm()

  const { t } = useTranslation()
  const toast = useToast()
  const [showApiErrors] = useShowApiErrorsWithForm(setError)

  // Get the setting for password complex
  const tenantSettingsRequestParams: GetTenantSettingsRequest = {
    keys: [
      SettingKeyType.OrganizationForceComplexPassword,
      SettingKeyType.OrganizationPasswordLength,
    ],
  }
  const { data: getTenantSettings } = useGetTenantSettingsQuery(
    tenantSettingsRequestParams
  )

  const canSend = useMemo(
    // fromState.isValidはerrorsが空でもfalseになることがあるためerrorsで判定する
    () => Object.keys(formState.errors).length === 0 && !formState.isSubmitting,
    // formStateはproxyなのでformState自体をlistenする必要がある
    // https://react-hook-form.com/api/useform/formstate
    [formState]
  )

  // 複雑なパスワードを強制するか
  const tenantPasswordComplex = useMemo(() => {
    if (getTenantSettings?.tenantSettings) {
      const organizationForceComplexPassword =
        getTenantSettings.tenantSettings.find(
          (setting) =>
            setting.key === SettingKeyType.OrganizationForceComplexPassword
        )

      return organizationForceComplexPassword?.value
        ? convertStringToBoolean(organizationForceComplexPassword?.value)
        : DEFAULT_PASSWORD_COMPLEXITY
    }
  }, [getTenantSettings?.tenantSettings])

  // パスワードの最小文字数
  const tenantPasswordLength = useMemo(() => {
    if (getTenantSettings?.tenantSettings) {
      const organizationPasswordLength = getTenantSettings.tenantSettings.find(
        (setting) => setting.key === SettingKeyType.OrganizationPasswordLength
      )

      return organizationPasswordLength?.value
        ? Number(organizationPasswordLength.value) // 設定値が保存されている場合、その値を設定
        : tenantPasswordComplex // 設定値が保存されていない場合は、「複雑なパスワードを強制するか」によって設定値を判定
        ? DEFAULT_PASSWORD_COMPLEX_MIN_LENGTH // デフォルト値（複雑なパスワードを強制する場合）
        : DEFAULT_PASSWORD_MIN_LENGTH // デフォルト値（複雑なパスワードを強制しない場合）
    }
  }, [getTenantSettings?.tenantSettings, tenantPasswordComplex])

  // Event handle when the Submit button is clicked
  const handleSubmitButtonClick = useCallback(() => {
    const onSubmit = async (data: FormValues) => {
      try {
        // Execute update password process
        await updatePassword(data)

        // Display a toast indicating that the update password was successful
        toast.success(t('message.personalSetting.passwordUpdated'))

        // reset form values
        reset()

        // Close password form
        props.onClosePasswordForm()
      } catch (error) {
        if (isApiErrorResult(error) && IsApiErrorInvalidPassword(error.data)) {
          if (error.data.policy.complexity) {
            setError('newPassword', {
              type: 'manual',
              message: t('message.apiError.passwordInvalidComplex', {
                minLength: error.data.policy.minLength,
                symbols: error.data.policy.symbols,
              }),
            })
          } else {
            // 複雑なパスワードを強制しない場合：下記をチェック
            // 		・文字数

            // 最小文字数を確認する
            if (data.newPassword.length < error.data.policy.minLength) {
              setError('newPassword', {
                type: 'manual',
                message: t('message.apiError.passwordInvalidSimpleMinLength', {
                  minLength: error.data.policy.minLength,
                }),
              })
            }

            // 最大文字数を確認する
            if (data.newPassword.length > error.data.policy.maxLength) {
              setError('newPassword', {
                type: 'manual',
                message: t('message.apiError.passwordInvalidSimpleMaxLength', {
                  maxLength: error.data.policy.maxLength,
                }),
              })
            }
          }
        } else {
          showApiErrors(error)
        }
      }
    }

    handleSubmit(onSubmit)()
  }, [
    handleSubmit,
    props,
    reset,
    setError,
    showApiErrors,
    t,
    toast,
    updatePassword,
  ])

  return (
    <form className="flex flex-col gap-y-2.5 mt-2.5">
      {/* 現在のパスワード */}
      <CrewTextBoxField
        id="currentPassword"
        control={control}
        name="currentPassword"
        className="w-80"
        label={t('label.currentPassword')}
        showClearButton={false}
        mode="password"
        rules={validateRules.currentPassword}
      />

      {/* 新しいパスワード */}
      <CrewTextBoxField
        id="newPassword"
        control={control}
        name="newPassword"
        className="w-80"
        label={t('label.newPassword')}
        showClearButton={false}
        mode="password"
        rules={validateRules.newPassword}
      />

      {/* 新しいパスワード（確認） */}
      <div>
        <CrewTextBoxField
          id="newPasswordConfirmation"
          control={control}
          name="newPasswordConfirmation"
          className="w-80"
          label={t('label.newPasswordConfirm')}
          showClearButton={false}
          mode="password"
          rules={validateRules.newPasswordConfirmation}
        />
        {/* 「複雑なパスワードを強制する」ONの場合のみ、ポリシーを表記 */}
        {tenantPasswordComplex && (
          <span className="text-sm crew-text-gray-4">
            {t('label.passwordComplexityPolicy', {
              symbol: PASSWORD_SYMBOLS,
              minLength: tenantPasswordLength,
            })}
          </span>
        )}
      </div>

      {/* 変更ボタン */}
      <CrewButton
        text={t('action.change')}
        type="primary"
        className="mr-auto"
        onClick={handleSubmitButtonClick}
        disabled={!canSend || isLoadingUpdateUserPassword}
      />
    </form>
  )
})
