import { useTranslation } from '@crew/modules/i18n'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useInsertOrReplaceSettingMutation } from '@crew/apis/setting/settingApis'
import {
  NotificationMentionAndReplies,
  NotificationNewArrivals,
} from 'enums/app'

import { InsertOrReplaceSettingRequest } from '@crew/apis/setting/models/insertOrReplaceSetting/request'
import { ValidateRules } from '@crew/utils/dist/form'
import { NewsMentionAndReplies, SettingKeyType } from '@crew/enums/dist/app'

export type FormValues = {
  newsMentionAndReply: string | null
  notificationNewArrival: string | null
  notificationMentionAndReply: string | null
  notificationMeOnlyWhenOffline: boolean
}
export const formInitialValues: FormValues = {
  newsMentionAndReply: NewsMentionAndReplies.MentionAndReply.value,
  notificationNewArrival: NotificationNewArrivals.BannerAndEmail.value,
  notificationMentionAndReply:
    NotificationMentionAndReplies.BannerAndEmail.value,
  notificationMeOnlyWhenOffline: false,
}

export const usePersonalSettingNotificationForm = () => {
  const { t } = useTranslation()

  // react-hook-formの各種データを取得
  const { control, handleSubmit, reset, setError } = useForm<FormValues>({
    criteriaMode: 'all',
    defaultValues: formInitialValues,
  })

  // Get update user setting funciton
  const [
    insertOrReplaceSettingMutation,
    { isLoading: isLoadingInsertOrReplaceSetting },
  ] = useInsertOrReplaceSettingMutation()

  // Update user setting process
  const updateUserSettings = useCallback(
    async (data: FormValues) => {
      // user setting params
      const userSettingData: InsertOrReplaceSettingRequest = {
        userSettings: [
          {
            key: SettingKeyType.NewsMentionAndReplies,
            value: data.newsMentionAndReply ?? '',
          },
          {
            key: SettingKeyType.NotificationNewArrivals,
            value: data.notificationNewArrival ?? '',
          },
          {
            key: SettingKeyType.NotificationMentionAndReplies,
            value: data.notificationMentionAndReply ?? '',
          },
          {
            key: SettingKeyType.NotificationMeOnlyWhenOffline,
            value: String(data.notificationMeOnlyWhenOffline),
          },
        ],
      }

      // call api save setting user
      await insertOrReplaceSettingMutation(userSettingData).unwrap()
    },
    [insertOrReplaceSettingMutation]
  )

  // バリデーションルール
  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      newsNewArrival: {
        required: t('message.general.required'),
      },
      newsMentionAndReply: {
        required: t('message.general.required'),
      },
      notificationNewArrival: {
        required: t('message.general.required'),
      },
      notificationMentionAndReply: {
        required: t('message.general.required'),
      },
      notificationMeOnlyWhenOffline: {},
    }),
    [t]
  )

  return {
    control,
    handleSubmit,
    reset,
    setError,
    validateRules,

    updateUserSettings,
    isLoadingInsertOrReplaceSetting,
  }
}
