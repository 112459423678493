import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@crew/modules/i18n'
import { useAppDispatch } from 'states/hooks'
import {
  useInsertTaskCommentMutation,
  useUpdateTaskCommentMutation,
} from '@crew/apis/task/taskApis'
import dayjs from '@crew/modules/dayjs'
import { ValidateRules } from '@crew/utils/form'
import { JsonDateFormat } from '@crew/enums/system'
import { UploadFile } from 'models/domain/uploadFile'
import { useTaskPriorityDataSource } from 'hooks/dataSource/useResourceDataSource'
import { useMemberDataSource } from 'hooks/dataSource/useMemberDataSource'
import { useTaskStateDataSource } from 'hooks/dataSource/useTaskStateDataSource'
import {
  notifyTaskCommentEvent,
  ObjectEventMessage,
} from 'features/app/states/appSlice'
import { TaskComment } from '@crew/models/dist/domain'
import { NotifyEventType } from 'enums/app'
import { useNotificationRecipientDataSource } from 'hooks/dataSource/useNotificationRecipientDataSource'
import { EntityType } from '@crew/enums/domain'
import {
  convertScheduledTimeToMinutes,
  isRegexFormatScheduledTime,
} from '@crew/utils'

export type FormValues = {
  description: string | null
  taskStateId: string
  taskPriority: number | null
  assignToUserId: string | null
  startDate: Date | null
  dueDate: Date | null
  actualWorkTimes: string | null
  remainingWorkTimes: string | null
  notificationUserIds: string[] | null
  needNotification: boolean
  actualProgress: number | null
  workDate: Date | null
  startTime: Date | null
  endTime: Date | null
}

export const useTaskDetailCommentInputForm = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [entityType, setEntityType] = useState<EntityType | null>(null)
  const [entityRecordId, setEntityRecordId] = useState<string | null>(null)

  const [insertTaskCommentMutation, { isLoading: isLoadingInsertTaskComment }] =
    useInsertTaskCommentMutation()
  const [updateTaskCommentMutation, { isLoading: isLoadingUpdateTaskComment }] =
    useUpdateTaskCommentMutation()

  // react-hook-formの各種データを取得
  const {
    control,
    reset,
    getValues,
    setValue,
    formState,
    handleSubmit,
    setError,
    clearErrors,
  } = useForm<FormValues>({
    criteriaMode: 'all',
  })

  // タスク状態データソース
  const taskStateDataSource = useTaskStateDataSource(
    entityType ?? undefined,
    entityRecordId ?? undefined
  )

  // タスク優先度のカスタムデータソース
  const taskPriorityDataSource = useTaskPriorityDataSource()

  // 担当者DataSource構築（インクリメンタルサーチ）
  // get users by entity type and entity record id
  const userDataSource = useMemberDataSource(entityType, entityRecordId, true)

  // get users by entity type and entity record id
  const notificationRecipientDataSource = useNotificationRecipientDataSource(
    entityType,
    entityRecordId
  )

  // Insert task comment process
  const insertTaskComment = useCallback(
    async (
      taskId: string,
      data: FormValues,
      uploadedFileList: UploadFile[]
    ) => {
      const params = {
        taskId,
        taskStateId: data.taskStateId,
        taskPriority: data.taskPriority as number,
        assignToUserId: data.assignToUserId ?? undefined,
        description: data.description ?? undefined,
        startDate: data.startDate
          ? dayjs(data.startDate).format(JsonDateFormat.YYYYMMDD)
          : undefined,
        dueDate: data.dueDate
          ? dayjs(data.dueDate).format(JsonDateFormat.YYYYMMDD)
          : undefined,
        actualWorkTimes: convertScheduledTimeToMinutes(data.actualWorkTimes),
        remainingWorkTimes: convertScheduledTimeToMinutes(
          data.remainingWorkTimes
        ),
        notificationUserIds: data.notificationUserIds ?? undefined,
        needNotification: data.needNotification,
        files: uploadedFileList.length > 0 ? uploadedFileList : undefined,
        workDate: data.workDate
          ? dayjs(data.workDate).format(JsonDateFormat.YYYYMMDD)
          : undefined,
        startTime: data.startTime
          ? dayjs(data.startTime).format(JsonDateFormat.HHmmss)
          : undefined,
        endTime: data.endTime
          ? dayjs(data.endTime).format(JsonDateFormat.HHmmss)
          : undefined,
        actualProgress: data.actualProgress ?? undefined,
      }
      // タスク登録APIリクエスト
      const result = await insertTaskCommentMutation({
        taskComment: {
          ...params,
        },
      }).unwrap()

      // タスクコメントが登録された旨のEventMessageを通知
      // TODO: Backend: タスクコメント更新処理のResponseの内容について確認したい
      // https://break-tmc.atlassian.net/browse/CREW-9203
      const objectEventMessage: ObjectEventMessage<TaskComment> =
        !result.taskComment
          ? {
              eventType: NotifyEventType.Inserted,
              id: '',
              object: undefined,
            }
          : {
              eventType: NotifyEventType.Inserted,
              id: result.taskComment.taskId,
              object: result.taskComment,
            }
      dispatch(notifyTaskCommentEvent(objectEventMessage))
    },
    [dispatch, insertTaskCommentMutation]
  )

  // Update task comment process
  const updateTaskComment = useCallback(
    async (taskCommentId: string, data: FormValues, version: number) => {
      const params = {
        id: taskCommentId,
        description: data.description ?? undefined,
        version,
      }

      //Call api update task comment
      const result = await updateTaskCommentMutation({
        taskComment: {
          ...params,
        },
      }).unwrap()

      // タスクコメントが更新された旨のEventMessageを通知
      // TODO: Backend: タスクコメント更新処理のResponseの内容について確認したい
      // https://break-tmc.atlassian.net/browse/CREW-9203
      const objectEventMessage: ObjectEventMessage<TaskComment> = {
        eventType: !result.taskComment
          ? NotifyEventType.Deleted
          : NotifyEventType.Updated,
        id: result.taskComment?.taskId as string,
        object: result.taskComment,
      }
      dispatch(notifyTaskCommentEvent(objectEventMessage))
    },
    [dispatch, updateTaskCommentMutation]
  )

  //custom validate actual work times string
  const validateActualWorkTimes = useCallback(() => {
    const actualWorkTimes = getValues('actualWorkTimes')

    return isRegexFormatScheduledTime(actualWorkTimes)
  }, [getValues])

  //custom validate remaining work times string
  const validateRemainingWorkTimes = useCallback(() => {
    const remainingWorkTimes = getValues('remainingWorkTimes')

    return isRegexFormatScheduledTime(remainingWorkTimes)
  }, [getValues])

  // 説明欄のバリデーション
  // 説明欄に何も入力していない場合でも`<p><br></p>`という値がまだあります。（CrewHtmlEditorFieldコンポーネントのデフォルト値です）
  // そのため、説明の内容が入力されたかどうかをチェックするためにhtml文字列をプレーンテキストに変換する必要があります。
  const descriptionPlainTextRequired = useCallback(() => {
    // html文字列をプレーンテキストに変換
    const description = getValues('description')
    const plainText = description
      ? description.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')
      : ''

    // プレーンテキストが空でない場合のみOK
    return plainText !== ''
  }, [getValues])

  // check validate actual progress
  const validateActualProgress = useCallback(() => {
    const actualProgress = getValues('actualProgress')

    if (!actualProgress) return true

    // actual progress must be a integer
    if (!Number.isInteger(actualProgress)) return false

    // actual progress must be between 0 and 100
    if (actualProgress < 0 || actualProgress > 100) return false

    return true
  }, [getValues])

  //custom validate start time and end time
  const validateTimeRange = useCallback(() => {
    const startTime = getValues('startTime')
    const endTime = getValues('endTime')

    if (!startTime || !endTime || startTime <= endTime) {
      clearErrors('startTime')
      clearErrors('endTime')
      return true
    }

    return false
  }, [clearErrors, getValues])

  //custom validate start date and due date
  const validateDate = useCallback(() => {
    const startDate = getValues('startDate')
    const dueDate = getValues('dueDate')

    if (
      (startDate && dueDate && startDate <= dueDate) ||
      !startDate ||
      !dueDate
    ) {
      clearErrors('startDate')
      clearErrors('dueDate')
      return true
    }

    return false
  }, [clearErrors, getValues])

  // Actual work times duration validation
  const validateActualWorkTimesDuration = useCallback(() => {
    // 「開始日時」と「終了日時」の差分より大きい「実績時間」は登録できないようにする
    if (getValues('startTime') && getValues('endTime')) {
      const inputValue = getValues('actualWorkTimes')

      const startTime = dayjs(getValues('startTime'))
      const endTime = dayjs(getValues('endTime'))

      const diffMinutes = endTime.diff(startTime, 'minute')
      const actualWorkTimes = convertScheduledTimeToMinutes(inputValue)

      if (actualWorkTimes && actualWorkTimes > diffMinutes) {
        return false
      }
    }

    return true
  }, [getValues])

  // Required work times validation
  const requiredWorkTimes = useCallback(
    (inputValue: Date | string | null) => {
      // required if user input working date or start time or end time or actual work times or remaining work times
      const workDate = getValues('workDate')
      const startTime = getValues('startTime')
      const endTime = getValues('endTime')
      const actualWorkTimes = getValues('actualWorkTimes')
      const remainingWorkTimes = getValues('remainingWorkTimes')

      if (
        (workDate ||
          startTime ||
          endTime ||
          actualWorkTimes ||
          remainingWorkTimes) &&
        !inputValue
      ) {
        return false
      }

      return true
    },
    [getValues]
  )

  const validateRules: ValidateRules<FormValues> = useMemo(
    () => ({
      taskStateId: {
        required: t('message.general.required'),
      },
      taskPriority: {
        required: t('message.general.required'),
      },
      actualWorkTimes: {
        validate: {
          workTimes: () =>
            requiredWorkTimes(getValues('actualWorkTimes')) ||
            t('message.general.required'),
          always: () =>
            validateActualWorkTimes() || t('message.task.invalidFormatTime'),
          duration: () =>
            validateActualWorkTimesDuration() ||
            t('message.taskComment.invalidActualWorkTimesDuration'),
        },
      },
      remainingWorkTimes: {
        validate: {
          workTimes: () =>
            requiredWorkTimes(getValues('remainingWorkTimes')) ||
            t('message.general.required'),
          always: () =>
            validateRemainingWorkTimes() || t('message.task.invalidFormatTime'),
        },
      },
      // not validate below
      assignToUserId: {},
      description: {
        validate: {
          descriptionPlainTextRequired: () =>
            descriptionPlainTextRequired() ||
            t('message.general.isRequired', {
              name: t('label.message'),
            }),
        },
        // 「説明」にはHTMLコードが含まれるため、フロントエンドで上限値をチェックせずに、バックエンド側で実行します。
        // 「説明」の上限値は4000文字です。
      },
      dueDate: {
        validate: {
          always: () =>
            validateDate() || t('message.task.invalidTaskStartDate'),
        },
      },
      startDate: {
        validate: {
          always: () =>
            validateDate() || t('message.task.invalidTaskStartDate'),
        },
      },
      notificationUserIds: {},
      needNotification: {},
      actualProgress: {
        validate: {
          always: () =>
            validateActualProgress() ||
            t('message.general.invalidActualProgress'),
        },
      },
      workDate: {
        validate: {
          workTimes: () =>
            requiredWorkTimes(getValues('workDate')) ||
            t('message.general.required'),
        },
      },
      startTime: {
        validate: {
          checkTime: () =>
            validateTimeRange() || t('message.taskComment.invalidTimeRange'),
        },
      },
      endTime: {
        validate: {
          checkTime: () =>
            validateTimeRange() || t('message.taskComment.invalidTimeRange'),
        },
      },
    }),
    [
      descriptionPlainTextRequired,
      t,
      validateActualProgress,
      validateActualWorkTimes,
      validateActualWorkTimesDuration,
      validateDate,
      validateRemainingWorkTimes,
      validateTimeRange,
      requiredWorkTimes,
      getValues,
    ]
  )

  return {
    control,
    reset,
    getValues,
    setValue,
    formState,
    handleSubmit,
    setError,
    clearErrors,

    entityType,
    entityRecordId,
    setEntityType,
    setEntityRecordId,

    validateRules,

    taskStateDataSource,
    taskPriorityDataSource,
    userDataSource,
    notificationRecipientDataSource,

    insertTaskComment,
    updateTaskComment,
    isLoadingInsertTaskComment,
    isLoadingUpdateTaskComment,
  }
}
