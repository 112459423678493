import { Menu } from '@headlessui/react'
import {
  DropDownButtonClassNamesMap,
  DropDownButtonSize,
  DropDownButtonSizeClassNamesMap,
  DropDownButtonType,
  StylingMode,
} from '../../crewDropDownButton'
import classNames from 'classnames'
import ArrowDropDown from '~icons/material-symbols/arrow-drop-down'
import { genericMemo } from 'utils'

type ButtonProps<TItem> = {
  type: DropDownButtonType
  stylingMode: StylingMode
  className?: string
  onButtonClick?: (selectedItem?: TItem) => void
  render?: (itemData?: TItem) => React.ReactElement
  disabled?: boolean
  size: DropDownButtonSize
  selectedItem?: TItem
  text?: string
  icon?: React.ReactNode
}

export const DropDownButton = genericMemo(
  <TItem,>({
    type,
    stylingMode,
    className,
    onButtonClick,
    render,
    disabled,
    size,
    selectedItem,
    text,
    icon,
  }: ButtonProps<TItem>) => {
    // Handle button click
    const handleButtonClick = () => {
      onButtonClick?.(selectedItem)
    }

    return (
      <Menu.Button
        as="button"
        className={classNames(
          'crew-action-base',
          DropDownButtonSizeClassNamesMap[size],
          DropDownButtonClassNamesMap[type][stylingMode],
          icon && text && 'gap-2',
          className,
          disabled && 'pointer-events-none opacity-70'
        )}
        onClick={handleButtonClick}
      >
        {/* カスタマイズ用のレンダー。レンダーが指定されている場合はレンダーのみを表示する。 */}
        {render?.(selectedItem) || (
          <>
            {/* render icon */}
            {icon}

            {/* render text */}
            <span className="whitespace-nowrap">{text}</span>
          </>
        )}

        <ArrowDropDown className="ml-1" />
      </Menu.Button>
    )
  }
)
